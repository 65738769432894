import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  ChatBody,
  ChatBubble as Bubble,
  Reply,
  Reactions,
} from "./WhatsappStyles";
import { format } from "date-fns";
import { FaFile } from "react-icons/fa";
import { useChat } from "./context/WhatsAppContext";
import { Message } from "../../../types/types";

// Memoización del componente ChatBubble
const ChatBubble = React.memo(({ message }: { message: Message }) => {
  const findHTMLInText = useMemo(() => {
    const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi;
    return message.message
      .replace(
        urlRegex,
        (url) =>
          `<a href="${url}" target="_blank" style="color:#D1345B">${url}</a>`
      )
      .replace(/\n/g, "<br>");
  }, [message.message]);

  return (
    <Bubble className={message.direction}>
      {message.replyToMessage && (
        <Reply>
          <h5>{message.replyToMessage.possibleName}</h5>
          <div>{message.replyToMessage.message}</div>
        </Reply>
      )}

      {message.type === "text" && (
        <p dangerouslySetInnerHTML={{ __html: findHTMLInText }} />
      )}
      {message.type === "image" && (
        <div>
          <img
            src={message.mediaUrl}
            alt="Imagen"
            style={{ maxWidth: "400px", cursor: "pointer" }}
            onClick={() => window.open(message.mediaUrl, "_blank")}
          />
        </div>
      )}
      {message.type === "sticker" && (
        <div>
          <img
            src={message.mediaUrl}
            alt="Sticker"
            style={{ maxWidth: "400px", cursor: "pointer" }}
            onClick={() => window.open(message.mediaUrl, "_blank")}
          />
        </div>
      )}
      {message.type === "video" && (
        <div>
          <video controls style={{ maxWidth: "400px" }}>
            <source src={message.mediaUrl} type="video/mp4" />
            Tu navegador no soporta la etiqueta de video3.
          </video>
          {message.message && <p>{message.message}</p>}
        </div>
      )}
      {message.type === "audio" && (
        <div>
          <audio controls>
            <source src={message.mediaUrl} type="audio/mpeg" />
            Tu navegador no soporta la etiqueta de audio.
          </audio>
        </div>
      )}
      {message.type === "document" && (
        <div>
          {message.mediaUrl &&
            (() => {
              const url = new URL(message.mediaUrl);
              const pathname = url.pathname.toLowerCase();
              if (pathname.includes(".pdf")) {
                // Mostrar previsualización para PDFs
                return (
                  <iframe
                    src={message.mediaUrl}
                    style={{ width: "300px", height: "500px" }}
                    title={message.mediaUrl}
                  />
                );
              } else {
                // Mostrar ícono y enlace de descarga para otros tipos de documentos
                return (
                  <>
                    <FaFile />
                    <a
                      href={message.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {message.mediaUrl || "Documento recibido"}
                    </a>
                  </>
                );
              }
            })()}
        </div>
      )}
      {message.type === "location" && (
        <div>
          <p>
            Ubicación: Lat {message.latitude}, Lon {message.longitude}
          </p>
        </div>
      )}
      {message.reactions && message.reactions.length > 0 && (
        <Reactions>
          {message.reactions.map((reaction, index) => (
            <span key={index} title={`Reaccionado por ${reaction.user}`}>
              {reaction.reaction}
            </span>
          ))}
        </Reactions>
      )}
      <p>{format(new Date(message.timestamp), "HH:mm")}</p>
    </Bubble>
  );
});

const MessageList: React.FC = () => {
  const { messages, chatBodyRef, loadMoreMessages } = useChat();
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleScroll = useCallback(() => {
    if (chatBodyRef.current) {
      const { scrollTop } = chatBodyRef.current;
      const atTop = scrollTop === 0;

      if (atTop && !isLoadingMore) {
        setIsLoadingMore(true);
        loadMoreMessages();
      }
    }
  }, [chatBodyRef, isLoadingMore, loadMoreMessages]);

  useEffect(() => {
    const chatBody = chatBodyRef.current;
    if (chatBody) {
      chatBody.addEventListener("scroll", handleScroll);
      return () => chatBody.removeEventListener("scroll", handleScroll);
    }
  }, [chatBodyRef, handleScroll]);

  useEffect(() => {
    if (isLoadingMore) {
      setIsLoadingMore(false);
    }
  }, [messages]);

  useEffect(() => {
    if (chatBodyRef.current && !isLoadingMore) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages, chatBodyRef, isLoadingMore]);

  let lastDate: string | null = null;

  return (
    <ChatBody ref={chatBodyRef}>
      {messages.length === 0 ? (
        <ChatBubble
          message={{
            _id: "no-messages",
            user: "system",
            organization: "system",
            from: "system",
            to: "system",
            message: "No hay mensajes",
            timestamp: new Date().toISOString(),
            type: "text",
            WHATSAPP_PHONE_NUMBER_ID: "system",
            __v: 1,
            direction: "incoming",
            body: null,
            mediaUrl: "",
            latitude: 0,
            longitude: 0,
            cloudinaryUrl: "",
          }}
        />
      ) : (
        messages.map((message, index) => {
          const messageDate = format(new Date(message.timestamp), "dd/MM/yyyy");
          const showDate = lastDate !== messageDate;
          lastDate = messageDate;

          return (
            <React.Fragment key={index}>
              {showDate && (
                <div style={{ textAlign: "center", margin: "10px 0" }}>
                  <span style={{ fontStyle: "italic" }}>{messageDate}</span>
                </div>
              )}
              <ChatBubble message={message} />
            </React.Fragment>
          );
        })
      )}
    </ChatBody>
  );
};

export default MessageList;
