import axiosInstance from "./axiosConfig";

export const advancedSearch = async (searchValue: string) => {
  try {
    const response = await axiosInstance.post(
      `/advancedSearch?search=${searchValue}`
    );
    return response.data;
  } catch (error) {
    console.error("Error during advanced search:", error);
    throw error; // Lanzar el error para que el llamador pueda manejarlo
  }
};
