import React, { useEffect, useState } from "react";
import { Container, Content } from "./FormDealContactStyles";
import {
  createContact,
  getContacts,
  searchContact,
} from "../../../services/contactService";
import useDebounce from "../../../helpers/useDebounce";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";

interface FormDealContactModalProps {
  searchValue: string;
  setShowContactModal: any;
  setForm?: any;
  form?: any;
  setCustomer?: any;
}

const FormDealContactModal = ({
  searchValue,
  setShowContactModal,
  setForm,
  form,
  setCustomer,
}: FormDealContactModalProps) => {
  const [contacts, setContacts] = useState([]);
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const dispatch = useDispatch();

  const fetchContacts = async () => {
    const contacts = await getContacts();
    setContacts(contacts as any);
  };

  const findContact = async (search: string) => {
    if (search === "") {
      fetchContacts();
      return;
    }
    try {
      dispatch(setLoading(true));
      const contact = await searchContact(search);
      dispatch(setLoading(false));
      setContacts(contact);
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error);
    }
  };

  const submitHandler = async () => {
    if (searchValue === "") {
      alert("No se puede crear un contacto sin nombre");
      return; // Add return to prevent further execution
    }

    try {
      const newContact = await createContact({
        key: "firstName",
        value: searchValue,
      });

      if (setForm && form) {
        setForm({ ...form, associatedContactId: newContact._id });
      } else if (setCustomer) {
        setCustomer(newContact);
      } else {
        // Handle the case where neither setForm nor setCustomer is provided
        console.warn("Neither setForm nor setCustomer is provided.");
      }

      fetchContacts();
      setShowContactModal(false);
    } catch (error) {
      console.error("Error creating contact:", error);
      alert("Error creating contact.");
    }
  };

  const selectContact = (contact: any) => {
    if (form && setForm) {
      setForm({ ...form, associatedContactId: contact._id });
      setShowContactModal(false);
      return;
    } else if (setCustomer) {
      setCustomer(contact);
      setShowContactModal(false);
      return;
    }
  };

  useEffect(() => {
    findContact(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <Container>
      <Content>
        <ul>
          {contacts.map((contact: any) => (
            <li
              onClick={() => {
                selectContact(contact);
              }}
            >
              {contact.properties ? (
                <>
                  {
                    contact.properties.find((property: any) =>
                      property.key.includes("firstName")
                    )?.value
                  }{" "}
                  {
                    contact.properties.find((property: any) =>
                      property.key.includes("lastName")
                    )?.value
                  }
                </>
              ) : (
                "No contact properties available"
              )}
            </li>
          ))}
          {searchValue !== "" && (
            <li
              style={{
                color: "blue",
              }}
              onClick={submitHandler}
            >
              Crear contacto "{searchValue}"
            </li>
          )}
        </ul>
      </Content>
    </Container>
  );
};

export default FormDealContactModal;
