import { useState, useCallback, useEffect } from "react";

import { IProject, ITask } from "../../../../../types";
import { useDispatch } from "react-redux";

import { projectService } from "../../../../../services/projectService";
import { setLoading } from "../../../../../redux/slices/loadingSlice";
import { setAlert } from "../../../../../redux/slices/alertSlice";
import useDebounce from "helpers/useDebounce";

interface IProjectWithTasks {
  project: IProject;
  tasks: ITask[];
}

export const useProject = () => {
  const dispatch = useDispatch();

  // Estado para proyectos y tareas
  const [project, setProject] = useState<IProjectWithTasks>(
    {} as IProjectWithTasks
  );
  const [projects, setProjects] = useState<IProject[]>([]);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(null);

  // Estados para control de visibilidad y formularios
  const [openCreateProject, setOpenCreateProject] = useState<boolean>(false);
  const [form, setForm] = useState<any>({});
  const [menuIsVisible, setMenuIsVisible] = useState<boolean>(false);
  const [addTaskIsVisible, setAddTaskIsVisible] = useState<boolean>(false);
  const [isVisibleCreateTask, setIsVisibleCreateTask] =
    useState<boolean>(false);

  // Estados para búsqueda de tareas y proyectos
  const [queryTasks, setQueryTasks] = useState<string>("");
  const debouncedQueryTasks = useDebounce<string>(queryTasks, 500);

  const [queryProjects, setQueryProjects] = useState<string>("");
  const debouncedQueryProjects = useDebounce<string>(queryProjects, 500);

  // Funciones con useCallback para optimización
  const getProjects = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const projects = await projectService.getProjects();
      setProjects(projects);
    } catch (error) {
      console.error("Error al obtener proyectos:", error);
      dispatch(
        setAlert({
          title: "Error",
          message: "Ha ocurrido un error al obtener los proyectos",
          type: "error",
          show: true,
        })
      );
    }
    dispatch(setLoading(false));
  }, [dispatch]);

  const getProject = useCallback(
    async (id: string) => {
      dispatch(setLoading(true));
      try {
        const project = await projectService.getProject(id);
        setProject(project);
      } catch (error) {
        console.error("Error al obtener el proyecto:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al obtener el proyecto",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  const handleOpenCreateProject = useCallback(() => {
    setOpenCreateProject(true);
  }, []);

  const createProject = useCallback(
    async (project: IProject) => {
      dispatch(setLoading(true));
      try {
        const response = await projectService.createProject(project);
        setProjects((prevProjects) => [...prevProjects, response]);
      } catch (error) {
        console.error("Error al crear proyecto:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al crear el proyecto",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  const getAllTasks = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const tasks = await projectService.getAllTasks();
      const project: IProjectWithTasks = {
        project: {
          name: "Todas las tareas",
        },
        tasks: tasks,
      };
      setProject(project);
    } catch (error) {
      console.error("Error al obtener todas las tareas:", error);
      dispatch(
        setAlert({
          title: "Error",
          message: "Ha ocurrido un error al obtener las tareas",
          type: "error",
          show: true,
        })
      );
    }
    dispatch(setLoading(false));
  }, [dispatch]);

  const createTask = useCallback(
    async (newTask: ITask) => {
      if (!selectedProject || !selectedProject._id) {
        console.log("No hay proyecto seleccionado");
        return;
      }

      try {
        dispatch(setLoading(true));
        const response = await projectService.createTask({
          ...newTask,
          projectId: selectedProject._id,
        });

        setProject((prevProject: any) => ({
          ...prevProject,
          tasks: [...prevProject.tasks, response],
        }));

        setIsVisibleCreateTask(false);
        dispatch(
          setAlert({
            title: "Tarea creada",
            message: "La tarea se ha creado correctamente",
            type: "success",
            show: true,
          })
        );
      } catch (error) {
        console.error("Error al crear la tarea:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al crear la tarea",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [selectedProject, dispatch]
  );

  const updateTask = useCallback(
    async (task: ITask) => {
      try {
        dispatch(setLoading(true));
        const response = await projectService.updateTask(task);

        setProject((prevProject: any) => ({
          ...prevProject,
          tasks: prevProject.tasks.map((t: ITask) =>
            t._id === task._id ? response : t
          ),
        }));
        dispatch(
          setAlert({
            title: "Tarea actualizada",
            message: "La tarea se ha actualizado correctamente",
            type: "success",
            show: true,
          })
        );
      } catch (error) {
        console.error("Error al actualizar la tarea:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al actualizar la tarea",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  const searchProjects = useCallback(
    async (query: string) => {
      if (!query) {
        getProjects();
        return;
      }

      dispatch(setLoading(true));
      try {
        const filteredProjects = await projectService.searchProjects(query);
        setProjects(filteredProjects);
      } catch (error) {
        console.error("Error al buscar proyectos:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al buscar los proyectos",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [dispatch, getProjects]
  );

  const searchTasks = useCallback(
    async (query: string) => {
      if (!query) {
        getAllTasks();
        return;
      }

      dispatch(setLoading(true));
      try {
        const filteredTasks = await projectService.searchTasks(query);

        const project: IProjectWithTasks = {
          project: {
            name: "Todas las tareas",
          },
          tasks: filteredTasks,
        };

        setProject(project);
      } catch (error) {
        console.error("Error al buscar tareas:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al buscar las tareas",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [dispatch, getAllTasks]
  );

  const deleteProject = useCallback(async () => {
    if (!selectedProject) return;

    dispatch(setLoading(true));
    try {
      await projectService.deleteProject(selectedProject._id as string);
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project._id !== selectedProject._id)
      );
      dispatch(
        setAlert({
          title: "Proyecto eliminado",
          message: "El proyecto se ha eliminado correctamente",
          type: "success",
          show: true,
        })
      );
      setMenuIsVisible(false);
      setSelectedProject(null);
    } catch (error) {
      console.error("Error al eliminar el proyecto:", error);
      dispatch(
        setAlert({
          title: "Error",
          message: "Ha ocurrido un error al eliminar el proyecto",
          type: "error",
          show: true,
        })
      );
    }
    dispatch(setLoading(false));
  }, [dispatch, selectedProject]);

  const deleteTask = useCallback(
    async (taskId: string) => {
      dispatch(setLoading(true));
      try {
        await projectService.deleteTask(taskId);
        setProject((prevProject: any) => ({
          ...prevProject,
          tasks: prevProject.tasks.filter((task: ITask) => task._id !== taskId),
        }));
        dispatch(
          setAlert({
            title: "Tarea eliminada",
            message: "La tarea se ha eliminado correctamente",
            type: "success",
            show: true,
          })
        );
      } catch (error) {
        console.error("Error al eliminar la tarea:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Ha ocurrido un error al eliminar la tarea",
            type: "error",
            show: true,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [dispatch]
  );

  // Efecto para buscar tareas basado en queryTasks
  useEffect(() => {
    if (debouncedQueryTasks && debouncedQueryTasks.length > 1) {
      searchTasks(debouncedQueryTasks);
    } else {
      getAllTasks();
    }
  }, [debouncedQueryTasks, getAllTasks, searchTasks]);

  // Efecto para buscar proyectos basado en queryProjects
  useEffect(() => {
    if (debouncedQueryProjects && debouncedQueryProjects.length > 1) {
      searchProjects(debouncedQueryProjects);
    } else {
      getProjects();
    }
  }, [debouncedQueryProjects, getProjects, searchProjects]);

  // Efecto para obtener el proyecto seleccionado
  useEffect(() => {
    if (!selectedProject) {
      getAllTasks();
      return;
    }
    getProject(selectedProject._id as string);
  }, [selectedProject, getProject, getAllTasks]);

  return {
    getAllTasks,
    searchProjects,
    searchTasks,
    project,
    setProject,
    getProject,
    getProjects,
    projects,
    setProjects,
    selectedProject,
    setSelectedProject,
    openCreateProject,
    setOpenCreateProject,
    handleOpenCreateProject,
    createProject,
    isVisibleCreateTask,
    setIsVisibleCreateTask,
    form,
    setForm,
    createTask,
    updateTask,
    deleteProject,
    menuIsVisible,
    setMenuIsVisible,
    addTaskIsVisible,
    setAddTaskIsVisible,
    deleteTask,
    queryTasks,
    setQueryTasks,
    queryProjects,
    setQueryProjects,
  };
};
