import styled from "styled-components";

export const Card = styled.div`
  flex-basis: 22%;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  img {
    width: 100px;
    height: 100px;
  }

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s;
  }

  h3 {
    font-size: 1.2rem;
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  top: 25px; /* Ajusta este valor según la posición que quieras */
  left: 0;
  width: 150px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const ModalList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #ccc;
      cursor: pointer;
    }

    svg {
      margin-right: 8px;
    }
  }
`;
