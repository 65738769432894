import { CardBackground } from "./CardStyles";
import React, { useContext, useMemo } from "react";
import { TableMode } from "./Table";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";
import { Deals } from "../../types/types";

interface Props {
  business: Deals;
  tableMode: TableMode;
}

const BusinessCard = ({ business, tableMode }: Props) => {
  const { dealsFieldsByPipeline } = useContext(PipelineContext);

  const fieldsToShow = useMemo(() => {
    return dealsFieldsByPipeline.slice(0, 2);
  }, [dealsFieldsByPipeline]);

  const fieldsLength = useMemo(() => {
    return dealsFieldsByPipeline.length;
  }, [dealsFieldsByPipeline]);

  return (
    <CardBackground
      style={{
        margin: TableMode.Grid === tableMode ? "10px 0px" : "0px",
      }}
    >
      <h3>{business.title}</h3>
      {tableMode === TableMode.Grid && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "8px 0px",
            }}
          ></div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <p>
              Valor: ${" "}
              {business.amount.toLocaleString("es-CO", {
                maximumFractionDigits: 2,
              })}
            </p>
            <p>
              Fecha de Cierre:{" "}
              {new Date(business.closingDate).toISOString().split("T")[0]}
            </p>

            {fieldsToShow.map((field) => {
              const currentField = business.fields.find(
                (f) => f.field._id === field._id
              );
              return (
                <p
                  key={field._id}
                  style={{
                    whiteSpace: "wrap",
                    lineBreak: "anywhere",
                  }}
                >
                  {field.name}:{" "}
                  {currentField?.value ? currentField.value : "N/A"}
                </p>
              );
            })}
            <p>{business.associatedContactId?.properties[0].value}</p>
            <span
              style={{
                color: "#666",
                fontSize: "0.9rem",
                marginTop: "8px",
                border: "1px solid #ccc",
                backgroundColor: "#f9f9f9",
                padding: "4px",
                borderRadius: "5px",
                width: "fit-content",
              }}
            >
              {fieldsLength > 2 ? `+${fieldsLength - 2} campos` : ""}
            </span>
          </div>
        </>
      )}
    </CardBackground>
  );
};

export default BusinessCard;
