import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Form = styled.form`
  input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    transition: ease all 0.3s;
    cursor: pointer;
  }
`;
