import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  background-color: ${theme.colors.gris3};
  height: calc(100vh - 250px);
`;

export const ListBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const ListsChats = styled.div`
  width: 30%;
  height: 100%;
  overflow: visible;
`;

export const Chat = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${theme.colors.gris3};
  border-bottom: 1px solid ${theme.colors.gris3};
  height: 70px;
  svg {
    cursor: pointer;
  }
`;

export const ListChat = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${theme.colors.gris3};
  background-color: ${(props) =>
    props.selected ? theme.colors.rosadoClaro : "#f1f1f1"};
  height: 80px;
  align-items: center;
  padding: 0px 30px 0px 0px;
  cursor: pointer;

  svg {
    font-size: 30px;
    width: 50px;
  }
`;

export const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: ${theme.colors.gris3};
  border-bottom: 1px solid ${theme.colors.gris3};
  height: 70px;
  padding: 30px;

  button:last-child {
    margin-left: 10px;
    background-color: grey;
  }

  svg:first-child {
    font-size: 30px;
  }
  div {
    margin-left: 10px;
    h4 {
      margin: 0;
    }
    h5 {
      margin: 0;
      color: ${theme.colors.gris3};
    }
  }
`;

export const ChatBody = styled.div`
  flex-grow: 1;
  background-color: ${theme.colors.grisClaro};
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const ChatBubble = styled.div<{ className: string }>`
  /* Estilo común para todas las burbujas de chat */
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  max-width: 80%;
  word-wrap: break-word;
  position: relative;
  z-index: 0;

  /* Estilo para mensajes entrantes */
  &.incoming {
    background-color: #e0f7fa;
    align-self: flex-start;
  }

  /* Estilo para mensajes salientes */
  &.outgoing {
    background-color: #c8e6c9;
    align-self: flex-end;
  }
`;

export const Reactions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 50%);
  padding: 2px 5px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    margin-right: 2px;
  }
`;

export const Button = styled.button`
  background-color: ${theme.colors.rosado};
  border: none;
  padding: 10px 40px;
  color: white;
  border-radius: 5px;
  margin: 20px 0;
  cursor: pointer;
`;

export const Reply = styled.div`
  border-left: 3px solid ${theme.colors.rosado};
  border-radius: 5px;
  padding: 10px;
  background-color: #f1f1f1;
  margin: 10px 0;
`;
