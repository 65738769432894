import React from "react";
import { Link } from "react-router-dom";
import { IHeader } from "../../../types/types";
import { ListItemDetail } from "./TableStyles";

interface TableRowProps<T> {
  item: T;
  headers: any;
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

const TableRow = <T extends { _id: string }>({
  item,
  headers,
  selected,
  setSelected,
}: TableRowProps<T>) => {
  const handleCheckboxChange = () => {
    const newIndex = selected.indexOf(item._id);
    if (newIndex > -1) {
      setSelected(selected.filter((id) => id !== item._id));
    } else {
      setSelected([...selected, item._id]);
    }
  };

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          checked={selected.includes(item._id)}
          onChange={handleCheckboxChange}
        />
      </td>
      {headers.map((header: any, headerIndex: any) => {
        if (!header.isVisible) return null;

        // Usar getValue si está definido, de lo contrario usar el acceso directo
        const value = header.getValue
          ? header.getValue(item)
          : (item as any)[header.key] ||
            (item as any).properties?.find(
              (prop: any) => prop.key === header.key
            )?.value ||
            "";

        return (
          <ListItemDetail key={headerIndex}>
            {header.key === "firstName" && value ? (
              <Link to={`/contactos/${item._id}`}>{value}</Link>
            ) : (
              value
            )}
          </ListItemDetail>
        );
      })}
    </tr>
  );
};

export default TableRow;
