import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { updateContactProperties } from "../../redux/slices/organizationSlice";
import { setAlert } from "../../redux/slices/alertSlice";
import {
  Button,
  Form,
  ModalContainer,
  ModalContent,
} from "./ContactFieldsStyles";
import { IContactProperty, IOrganization } from "../../types/types";
import { updateOrganization } from "../../services/organizationService";

interface ModalProps {
  organization: IOrganization | null;
  setAddIsVisible: (visible: boolean) => void;
}

const ContactFieldsModal: React.FC<ModalProps> = ({
  organization,
  setAddIsVisible,
}) => {
  const [form, setForm] = useState<IContactProperty>({
    label: "",
    key: "",
    isVisible: false,
  });
  const dispatch = useDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: name === "isVisible" ? value === "true" : value,
    });
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!organization) {
      dispatch(
        setAlert({ message: "Organización no encontrada", type: "error" })
      );
      return;
    }

    const updatedContactProperties = [...organization.contactProperties, form];

    try {
      const updatedOrganization = await updateOrganization({
        contactProperties: updatedContactProperties,
      });

      // Update the global state with the new properties
      dispatch(updateContactProperties(updatedContactProperties));

      dispatch(
        setAlert({ message: "Campo agregado con éxito", type: "success" })
      );
      setAddIsVisible(false);
    } catch (error) {
      console.error("Error al actualizar la organización:", error);
      dispatch(
        setAlert({
          message: "Error al actualizar la organización",
          type: "error",
        })
      );
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <FaTimes onClick={() => setAddIsVisible(false)} />
        </div>
        <h3>Agregar Nuevo Campo</h3>
        <Form onSubmit={submitHandler}>
          <div>
            <label htmlFor="label">Label</label>
            <input
              type="text"
              name="label"
              id="label"
              value={form.label}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="key">Key</label>
            <input
              type="text"
              name="key"
              id="key"
              value={form.key}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="isVisible">isVisible</label>
            <select
              name="isVisible"
              id="isVisible"
              value={form.isVisible.toString()}
              onChange={handleChange}
              required
            >
              <option value="">- Seleccione -</option>
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          </div>
          <Button type="submit">Guardar</Button>
        </Form>
      </ModalContent>
    </ModalContainer>
  );
};

export default ContactFieldsModal;
