import axiosInstance from "./axiosConfig";

export const fetchEmails = async () => {
  try {
    const response = await axiosInstance.get("/email/inbox");
    return response.data;
  } catch (err) {
    console.log(err);
    return;
  }
};

export const fetchEmail = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/email/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
    return;
  }
};
