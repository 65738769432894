import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
  }

  label {
    font-size: 1rem;
    color: #666;
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }

  button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.rosado};
    margin: 0px 15px;
    font-weight: bold;
    cursor: pointer;
    min-width: 100px;
    background-color: ${theme.colors.rosado};
    color: white;
  }

  button[disabled] {
    background-color: #ccc;
    color: #666;
    border: 1px solid #ccc;
    cursor: default;
  }
`;
