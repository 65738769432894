import axiosInstance from "./axiosConfig";

// Create a new purchase order
export const createPurchase = async (formPurchaseOrder: any) => {
  try {
    const res = await axiosInstance.post(
      "/purchases/orders",
      formPurchaseOrder
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error creating purchase order"
    );
    throw new Error(
      error.response?.data?.message || "Error creating purchase order"
    );
  }
};

// Get a single purchase order by ID
export const getPurchaseOrder = async (purchaseOrderId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get("/purchaseOrders/" + purchaseOrderId, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error fetching purchase order"
    );
    throw new Error(
      error.response?.data?.message || "Error fetching purchase order"
    );
  }
};

// Get all purchase orders
export const getPurchaseOrders = async (
  limit: number = 10,
  page: number = 0
) => {
  try {
    const res = await axiosInstance.get("/purchases/orders");
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error fetching purchase orders"
    );
    throw new Error(
      error.response?.data?.message || "Error fetching purchase orders"
    );
  }
};

// Delete a purchase order by ID
export const deletePurchaseOrder = async (purchaseOrderId: string) => {
  try {
    const res = await axiosInstance.delete(
      "/purchases/orders/" + purchaseOrderId
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error deleting purchase order"
    );
    throw new Error(
      error.response?.data?.message || "Error deleting purchase order"
    );
  }
};

// Update a purchase order by ID
export const updatePurchaseOrder = async (
  purchaseOrderId: string,
  formPurchaseOrder: any
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(
      "/purchaseOrders/" + purchaseOrderId,
      { ...formPurchaseOrder, token: token },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error updating purchase order"
    );
    throw new Error(
      error.response?.data?.message || "Error updating purchase order"
    );
  }
};

// Search for purchase orders based on a term
export const searchPurchaseOrder = async (term: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get("/purchaseOrders/search/" + term, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error searching purchase orders"
    );
    throw new Error(
      error.response?.data?.message || "Error searching purchase orders"
    );
  }
};

// Advanced filter for purchase orders
export const advancedFilterPurchaseOrders = async (filters: any) => {
  const token = localStorage.getItem("token");

  try {
    const res = await axiosInstance.post(
      "/purchaseOrders/advanced-filter",
      filters,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error filtering purchase orders"
    );
    throw new Error(
      error.response?.data?.message || "Error filtering purchase orders"
    );
  }
};
