import axiosConfig from "./axiosConfig";

const getGoogleMaps = async (form: any) => {
  try {
    const response = await axiosConfig.get(
      `http://localhost:3001/api/v1/leads-generation/google-maps?textQuery=${form.textQuery}&limit=${form.limit}`
    );

    return response.data;
  } catch {
    console.log("error");
  }
};

export default {
  getGoogleMaps,
};
