import React, { useEffect, useState } from "react";
import {
  Container,
  Fragment,
  FragmentsContainer,
  SearchContainer,
  Title,
} from "./FragmentsStyles";
import { getFragments } from "../../../services/fragmentService";
import { IFragment } from "../../../types/types";
import {
  FaExternalLinkAlt,
  FaRegArrowAltCircleUp,
  FaSearch,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Fragments = ({ setNewMessage, setShowFragments }: any) => {
  const [fragments, setFragments] = useState<IFragment[]>([]);

  const fetchFragments = async () => {
    const fragments = await getFragments();
    setFragments(fragments);
  };

  const handleFragmentClick = (text: string) => {
    setNewMessage(text);
    setShowFragments(false);
  };

  const handleSearch = (e: any) => {
    if (e.target.value === "") {
      fetchFragments();
      return;
    }

    const search = e.target.value;
    const filteredFragments = fragments.filter(
      (fragment) =>
        fragment.atajo.toLowerCase().includes(search.toLowerCase()) ||
        fragment.name.toLowerCase().includes(search.toLowerCase())
    );

    setFragments(filteredFragments);
  };

  useEffect(() => {
    fetchFragments();
  }, []);

  return (
    <Container>
      <Title>
        <h1>Fragmentos</h1>
        <Link to="/conversaciones/fragmentos">
          <button>
            <FaExternalLinkAlt /> Gestionar
          </button>
        </Link>
      </Title>
      <SearchContainer>
        <FaSearch />
        <input
          type="text"
          placeholder="Buscar fragmento"
          onChange={handleSearch}
        />
      </SearchContainer>
      <FragmentsContainer>
        {fragments.map((fragment) => (
          <Fragment
            key={fragment._id}
            onClick={() => handleFragmentClick(fragment.text)}
          >
            <h3>{fragment.name}</h3>
            <p>{fragment.text}</p>
          </Fragment>
        ))}
      </FragmentsContainer>
    </Container>
  );
};

export default Fragments;
