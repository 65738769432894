import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FaEnvelope,
  FaEye,
  FaEyeSlash,
  FaFacebook,
  FaGoogle,
  FaLock,
} from "react-icons/fa";
import {
  loginStart,
  loginSuccess,
  loginFailure,
} from "../../../redux/slices/userSlice";
import { RootState, AppDispatch } from "../../../redux/store";
import { loginUser } from "../../../services/authService";
import ForgotPasswordComponent from "../../../components/forgotPassword/ForgotPassword";
import {
  CircleButton,
  LeftColumn,
  LoginContainer,
  RightColumn,
  InputContainer,
  LoginButton,
  RemaindMeContainer,
  Social,
  SocialContainer,
  TextHr,
  AccountContainer,
} from "./LoginStyles";
import { setOrganization } from "../../../redux/slices/organizationSlice";
import { loadInitialNotifications } from "../../../redux/thunks/notificationThunk";
import { setLoading } from "../../../redux/slices/loadingSlice";

const slides = [
  {
    image: "https://via.placeholder.com/500x500.png",
    title: "Escribe a tus contactos por WhatsApp",
    text: "Crea conversaciones por WhatsApp y guarda todo el historial de tus contactos",
  },
  {
    image: "https://via.placeholder.com/500x500.png",
    title: "Crea Tareas para tus vendedores",
    text: "Crea conversaciones por WhatsApp y guarda todo el historial de tus contactos",
  },
  {
    image: "https://via.placeholder.com/500x500.png",
    title: "Lleva las ventas de tu empresa",
    text: "Crea conversaciones por WhatsApp y guarda todo el historial de tus contactos",
  },
];

const LoginPage: React.FC = () => {
  const [slideShowActive, setSlideShowActive] = useState(0);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [passIsHidden, setPassIsHidden] = useState(true);
  const [form, setForm] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { error, loading } = useSelector((state: RootState) => state.user);

  const handlerForm = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginStart());

    try {
      dispatch(setLoading(true));
      const response = await loginUser({
        email: form.email,
        password: form.password,
        rememberMe,
      });
      dispatch(loginSuccess(response));
      dispatch(setOrganization(response.organization));
      dispatch(loadInitialNotifications());
      dispatch(setLoading(false));

      navigate("/");
    } catch (err: any) {
      dispatch(loginFailure(err.message));
      console.log("Error en el inicio de sesión");
    }
  };

  return (
    <LoginContainer>
      <LeftColumn>
        {forgotPassword ? (
          <ForgotPasswordComponent setForgotPassword={setForgotPassword} />
        ) : (
          <>
            <img src="/images/logo/logo-rosado.png" alt="Logo" />
            <h1>Inicia Sesión en tu cuenta</h1>
            <p>¡Bienvenido! Selecciona un método para iniciar sesión</p>
            <SocialContainer>
              <Social>
                <FaFacebook />
                <p>Facebook</p>
              </Social>
              <Social>
                <FaGoogle />
                <p>Google</p>
              </Social>
            </SocialContainer>
            <TextHr>
              <hr />
              <p>O ingresa con tu correo</p>
              <hr />
            </TextHr>
            <form onSubmit={handlerForm}>
              <InputContainer>
                <FaEnvelope />
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(e) =>
                    setForm({ ...form, [e.target.name]: e.target.value })
                  }
                />
              </InputContainer>
              <InputContainer>
                <FaLock />
                <input
                  type={passIsHidden ? "password" : "text"}
                  name="password"
                  id="password"
                  placeholder="Password"
                  onChange={(e) =>
                    setForm({ ...form, [e.target.name]: e.target.value })
                  }
                />
                {passIsHidden ? (
                  <FaEye onClick={() => setPassIsHidden(!passIsHidden)} />
                ) : (
                  <FaEyeSlash onClick={() => setPassIsHidden(!passIsHidden)} />
                )}
              </InputContainer>
              {error && <p style={{ color: "red" }}>{error}</p>}
              <RemaindMeContainer>
                <div>
                  <input
                    type="checkbox"
                    name="rememberMe"
                    id="rememberMe"
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label htmlFor="rememberMe">Recuérdame</label>
                </div>
                <p onClick={() => setForgotPassword(true)}>
                  ¿Olvidaste la contraseña?
                </p>
              </RemaindMeContainer>
              <LoginButton type="submit" disabled={loading}>
                {loading ? "Cargando..." : "Iniciar Sesión"}
              </LoginButton>
            </form>
            <AccountContainer>
              <p>
                ¿No tienes una cuenta? <a href="/register">Crear una</a>
              </p>
            </AccountContainer>
          </>
        )}
      </LeftColumn>
      <RightColumn>
        <div style={{ textAlign: "center" }}>
          <img src={slides[slideShowActive].image} alt="" />
          <h2>{slides[slideShowActive].title}</h2>
          <p>{slides[slideShowActive].text}</p>
        </div>
        <div style={{ display: "flex" }}>
          {slides.map((_, index) => (
            <CircleButton
              key={index}
              $active={slideShowActive === index ? "true" : undefined}
              onClick={() => setSlideShowActive(index)}
            ></CircleButton>
          ))}
        </div>
      </RightColumn>
    </LoginContainer>
  );
};

export default LoginPage;
