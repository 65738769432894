import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f1f1f1;
`;

export const Nav = styled.div`
  width: 15%;
  padding: 20px;

  ul {
    display: flex;
    align-items: center;
    list-style: none;
  }

  li {
    margin: 10px 0px;
  }
`;

export const Content = styled.div`
  width: 85%;
  padding: 20px;
`;
