import { createSlice } from "@reduxjs/toolkit";
import { AlertNotificationProps } from "../../types/types";

const initialState: AlertNotificationProps = {
  title: "",
  message: "",
  show: false,
  type: "info",
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.show = action.payload.show;
      state.type = action.payload.type;
    },
  },
});

export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
