import axiosInstance from "./axiosConfig";

export const getOrganization = async (OrganizationId: string) => {
  try {
    const res = await axiosInstance.get(`/organizations/${OrganizationId}`);
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error fetching organization"
    );
    throw new Error(
      error.response?.data?.message || "Error fetching organization"
    );
  }
};

export const updateOrganization = async (form: any) => {
  try {
    const token = localStorage.getItem("token");
    const organizationId = localStorage.getItem("organizationId");
    const res = await axiosInstance.put(
      "/organizations/",
      { form: form, organizationId: organizationId },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error updating organization"
    );
    throw new Error(
      error.response?.data?.message || "Error updating organization"
    );
  }
};

export const uploadLogo = async (file: File): Promise<any> => {
  const formData = new FormData();
  formData.append("logo", file);

  try {
    const response = await axiosInstance.post<any>(
      `/organizations/uploadLogo`,
      formData
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.error || "Error al subir el logo");
  }
};
