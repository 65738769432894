export const formatCurrency = (value: string): string => {
  // Elimina cualquier caracter que no sea dígito
  const cleanedValue = value.replace(/\D/g, "");

  // Formatea el valor con puntos de miles
  const formattedValue = cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Agrega el signo de dólar al principio
  return `$${formattedValue}`;
};
