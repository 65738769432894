import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getContactDeals } from "services/dealService";
import { DealsTable } from "./ContactDetailStyles";
import { formatDate } from "utils/formatDate";
import { formatCurrency } from "utils/formatCurrency";

const ContactDeals = () => {
  const [deals, setDeals] = useState<any>([]);
  const { id } = useParams();
  console.log(id);
  const fetchContactDeals = async () => {
    const response = await getContactDeals(id as string);
    setDeals(response);
    console.log(response);
  };

  useEffect(() => {
    fetchContactDeals();
  }, []);

  const dealsTotal = () => {
    let total = 0;
    deals.map((deal: any) => {
      total += parseInt(deal.amount);
    });
    return total.toString();
  };

  return (
    <div>
      <h1
        style={{
          fontSize: "25px",
          margin: "20px 10px",
        }}
      >
        Total Ingreso:{formatCurrency(dealsTotal() as string)}{" "}
      </h1>
      <DealsTable>
        <thead>
          <tr>
            <th>Deal Name</th>
            <th>Pipeline</th>
            <th>Deal Value</th>
            <th>Deal Close Date</th>
            <th>Status</th>
            <th>Contacto Asociado</th>
          </tr>
        </thead>
        <tbody>
          {deals.map((deal: any) => (
            <tr key={deal._id}>
              <td>{deal.title}</td>
              <td>{deal.pipeline.title}</td>
              <td>{formatCurrency(deal.amount.toString())}</td>
              <td>{formatDate(new Date(deal.closingDate))}</td>
              <td>{deal.status.name}</td>
              <td>
                {
                  deal.associatedContactId.properties.find(
                    (prop: any) => prop.key === "firstName"
                  ).value
                }
              </td>
            </tr>
          ))}
        </tbody>
      </DealsTable>
    </div>
  );
};

export default ContactDeals;
