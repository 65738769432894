import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  padding: 10px;

  border-radius: 10px;
`;

export const MessageBody = styled.div`
  max-height: calc(100vh - 600px);
  overflow: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
  }
`;

export const Tools = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  gap: 20px;
  color: grey;
  svg {
    margin-right: 5px;
  }
`;

export const Messages = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 50px;
`;

export const Title = styled.h2`
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const MessageHeader = styled.div`
  margin-bottom: 20px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #000;
  background-color: white;
  border-top: 1px solid #000;
  gap: 20px;
`;
