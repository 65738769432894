import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  getPurchaseOrder,
  getPurchaseOrders,
  updatePurchaseOrder,
  deletePurchaseOrder,
  searchPurchaseOrder,
  advancedFilterPurchaseOrders,
  createPurchase,
} from "../../../services/purchaseService"; // Asegúrate de ajustar la ruta del servicio
import { IProduct, PurchaseOrderForm, Supplier } from "types";
import { getProducts } from "services/productService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLoading } from "redux/slices/loadingSlice";
import { setAlert } from "redux/slices/alertSlice";

interface PurchaseContextType {
  error: string | null;
  createPurchaseOrder: () => Promise<void>;
  getPurchaseOrder: (purchaseOrderId: string) => Promise<any>;
  getPurchaseOrders: (limit?: number, page?: number) => Promise<any>;
  updatePurchaseOrder: (
    purchaseOrderId: string,
    formPurchaseOrder: any
  ) => Promise<void>;
  deletePurchaseOrder: (purchaseOrderId: string) => Promise<void>;
  searchPurchaseOrder: (term: string) => Promise<any>;
  advancedFilterPurchaseOrders: (filters: any) => Promise<any>;
  purchases: any[];
  purchaseOrderForm: PurchaseOrderForm;
  updateField: (field: keyof PurchaseOrderForm, value: any) => void;
  addItem: (product: IProduct) => void;
  removeItem: (uniqueId: number) => void;
  updateItem: (
    uniqueId: number,
    field: "quantity" | "unitPrice" | "tax",
    value: number
  ) => void;
  products: IProduct[];
  suppliers: Supplier[];
}

const PurchaseContext = createContext<PurchaseContextType | undefined>(
  undefined
);

export const usePurchase = () => {
  const context = useContext(PurchaseContext);
  if (!context) {
    throw new Error("usePurchase must be used within a PurchaseProvider");
  }
  return context;
};

export const PurchaseProvider = ({ children }: { children: ReactNode }) => {
  const purchaseOrderNumber = useSelector(
    (state: any) => state.organization.purchaseSettings.purchaseNumber
  );
  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);
  const [purchases, setPurchases] = useState([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);

  const [purchaseOrderForm, setPurchaseOrderForm] = useState<PurchaseOrderForm>(
    {
      supplierId: "",
      purchaseDate: new Date(),
      paymentMethod: "",
      items: [],
      subtotal: 0,
      tax: 0,
      total: 0,
      description: "",
      orderNumber: purchaseOrderNumber,
    }
  );

  const handleError = (err: any) => {
    const errorMessage = err.response?.data?.message || "An error occurred";

    setError(errorMessage);
    dispatch(
      setAlert({
        title: "Error!",
        message: errorMessage,
        type: "error",
        show: true,
      })
    );
    dispatch(setLoading(false));
  };

  const fetchProducts = async () => {
    dispatch(setLoading(true));
    setError(null);
    try {
      const products = await getProducts();

      setProducts(products);
      dispatch(setLoading(false));
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const updateField = (field: keyof PurchaseOrderForm, value: any) => {
    setPurchaseOrderForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const addItem = (product: IProduct) => {
    const uniqueId = Date.now(); // Usar un identificador único
    setPurchaseOrderForm((prev: any) => ({
      ...prev,
      items: [
        ...prev.items,
        {
          uniqueId, // Agregamos el identificador único
          productId: product._id,
          name: product.name,
          quantity: 1,
          unitPrice: product.unitPrice,
          tax: product.taxes || 0, // Asignar el tax del producto (o 0 si no tiene)
          total:
            product.unitPrice +
            (product.unitPrice * (product.taxes || 0)) / 100,
        },
      ],
    }));
  };

  const removeItem = (uniqueId: number) => {
    setPurchaseOrderForm((prev) => ({
      ...prev,
      items: prev.items.filter((item) => item.uniqueId !== uniqueId),
    }));
  };

  const updateItem = (
    uniqueId: number,
    field: "quantity" | "unitPrice" | "tax",
    value: number
  ) => {
    setPurchaseOrderForm((prev) => ({
      ...prev,
      items: prev.items.map((item) =>
        item.uniqueId === uniqueId
          ? {
              ...item,
              [field]: value,
              total:
                (field === "quantity" ? value : item.quantity) *
                  (field === "unitPrice" ? value : item.unitPrice) +
                (field === "quantity" ? value : item.quantity) *
                  (field === "unitPrice" ? value : item.unitPrice) *
                  ((field === "tax" ? value : item.tax) / 100),
            }
          : item
      ),
    }));
  };

  // Recalcular totales cuando los items cambian
  useEffect(() => {
    const subtotal = purchaseOrderForm.items.reduce(
      (sum, item) => sum + item.unitPrice * item.quantity,
      0
    );
    const tax = purchaseOrderForm.items.reduce(
      (sum, item) => sum + item.unitPrice * item.quantity * (item.tax / 100),
      0
    );
    const total = subtotal + tax;

    setPurchaseOrderForm((prev) => ({
      ...prev,
      subtotal,
      tax,
      total,
    }));
  }, [purchaseOrderForm.items]);

  // Función para crear una nueva orden de compra
  const createPurchaseOrder = async () => {
    dispatch(setLoading(true));

    setError(null);
    try {
      await createPurchase(purchaseOrderForm);

      dispatch(
        setAlert({
          title: "Exito!",
          message: "Orden de compra creada correctamente",
          type: "success",
          show: true,
        })
      );

      // Reiniciar el formulario después de guardar
      setPurchaseOrderForm({
        supplierId: "",
        purchaseDate: new Date(),
        paymentMethod: "",
        items: [],
        subtotal: 0,
        tax: 0,
        total: 0,
        description: "",
        orderNumber: purchaseOrderNumber,
      });
    } catch (err) {
      handleError(err);
    }
  };

  const fetchPurchaseOrder = async (purchaseOrderId: string) => {
    dispatch(setLoading(true));
    setError(null);
    try {
      const data = await getPurchaseOrder(purchaseOrderId);
      dispatch(setLoading(false));
      return data;
    } catch (err) {
      handleError(err);
    }
  };

  const fetchPurchaseOrders = async (limit = 10, page = 0) => {
    dispatch(setLoading(true));
    setError(null);
    try {
      const data = await getPurchaseOrders(limit, page);
      setPurchases(data);

      dispatch(setLoading(false));
      return data;
    } catch (err) {
      handleError(err);
    }
  };

  const modifyPurchaseOrder = async (
    purchaseOrderId: string,
    formPurchaseOrder: any
  ) => {
    dispatch(setLoading(true));
    setError(null);
    try {
      await updatePurchaseOrder(purchaseOrderId, formPurchaseOrder);

      dispatch(
        setAlert({
          title: "Exito!",
          message: "Orden de compra actualizada correctamente",
          type: "success",
          show: true,
        })
      );
      dispatch(setLoading(false));
    } catch (err) {
      handleError(err);
    }
  };

  const removePurchaseOrder = async (purchaseOrderId: string) => {
    dispatch(setLoading(true));
    setError(null);
    try {
      await deletePurchaseOrder(purchaseOrderId);
      dispatch(
        setAlert({
          title: "Exito!",
          message: "Orden de compra eliminada correctamente",
          type: "success",
          show: true,
        })
      );

      dispatch(setLoading(false));
    } catch (err) {
      handleError(err);
    }
  };

  const searchOrder = async (term: string) => {
    setLoading(true);
    setError(null);
    try {
      const data = await searchPurchaseOrder(term);
      setLoading(false);
      return data;
    } catch (err) {
      handleError(err);
    }
  };

  const advancedFilterOrders = async (filters: any) => {
    setLoading(true);
    setError(null);
    try {
      const data = await advancedFilterPurchaseOrders(filters);
      setLoading(false);
      return data;
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    setPurchaseOrderForm((prevForm) => ({
      ...prevForm,
      orderNumber: purchaseOrderNumber,
    }));
  }, [purchaseOrderNumber]);

  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  return (
    <PurchaseContext.Provider
      value={{
        error,
        createPurchaseOrder,
        getPurchaseOrder: fetchPurchaseOrder,
        getPurchaseOrders: fetchPurchaseOrders,
        updatePurchaseOrder: modifyPurchaseOrder,
        deletePurchaseOrder: removePurchaseOrder,
        searchPurchaseOrder: searchOrder,
        advancedFilterPurchaseOrders: advancedFilterOrders,
        purchases,
        purchaseOrderForm,
        updateField,
        addItem,
        removeItem,
        updateItem,
        products,
        suppliers,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};
