import React, { useState } from "react";
import formsService from "../../../services/formsService";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/slices/alertSlice";
import { FormGroup, Table, AddFields } from "./CreateFormStyles";
import { Button } from "./FormsStyles";
import { FaTrash } from "react-icons/fa";
import Modal from "./Modal"; // Suponemos que tienes un componente Modal
import { useNavigate } from "react-router-dom";

const CreateForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [createContact, setCreateContact] = useState("");
  const [fields, setFields] = useState<
    { fieldName: string; fieldType: string; required: boolean }[]
  >([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newField, setNewField] = useState({
    fieldName: "",
    fieldType: "",
    required: false,
  });

  // Handler para manejar el cambio de los campos del formulario
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setNewField((prev) => ({
      ...prev,
      [name]: name === "required" ? value === "true" : value,
    }));
  };

  // Handler para agregar un nuevo campo
  const addField = () => {
    setFields([...fields, newField]);
    setShowModal(false);
    setNewField({ fieldName: "", fieldType: "", required: false }); // Limpiar el campo después de agregar
  };

  // Handler para eliminar un campo
  const removeField = (index: number) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  // Handler para manejar el submit del formulario
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log(createContact);

    const formData = {
      name,
      createContact,
      fields,
    };

    if (!name) {
      dispatch(
        setAlert({
          title: "Hubo un problema",
          message: "El nombre del formulario es requerido",
          type: "error",
          show: true,
        })
      );
      return;
    }

    if (createContact === "") {
      dispatch(
        setAlert({
          title: "Hubo un problema",
          message: "Debes seleccionar si deseas crear un contacto",
          type: "error",
          show: true,
        })
      );
      return;
    }

    if (fields.length === 0) {
      dispatch(
        setAlert({
          title: "Hubo un problema",
          message: "Debes agregar al menos un campo al formulario",
          type: "error",
          show: true,
        })
      );
      return;
    }

    const response = await formsService.createForm({
      ...formData,
      createdAt: new Date(),
    });

    if (response.status !== 201) {
      dispatch(
        setAlert({
          title: "Hubo un problema",
          message: response.data,
          type: "error",
          show: true,
        })
      );
      return;
    }

    dispatch(
      setAlert({
        title: "¡Éxito!",
        message: "Formulario creado exitosamente",
        type: "success",
        show: true,
      })
    );

    setName("");
    setFields([]);
    navigate("/formularios");
  };

  return (
    <div>
      <h1
        style={{
          marginBottom: "30px",
        }}
      >
        Crear Formulario
      </h1>

      <form onSubmit={submitHandler}>
        <FormGroup>
          <label htmlFor="name">Nombre del formulario</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Debe crear un contacto con las respuestas?</label>
          <select
            name="contact"
            onChange={(e) => setCreateContact(e.target.value)}
            required
          >
            <option value="">- Seleccione una opción -</option>
            <option value="true">Sí</option>
            <option value="false">No</option>
          </select>
        </FormGroup>
        <h3>Campos</h3>
        <h4
          style={{
            cursor: "pointer",
            color: "blue",
            marginTop: "20px",
            marginBottom: "20px",
            fontWeight: "500",
          }}
          onClick={() => setShowModal(true)}
        >
          + Agregar Campo
        </h4>
        <Table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Requerido</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {fields.length > 0 ? (
              fields.map((field, index) => (
                <tr key={index}>
                  <td>{field.fieldName}</td>
                  <td>{field.fieldType}</td>
                  <td>{field.required ? "Sí" : "No"}</td>
                  <td>
                    <FaTrash
                      onClick={() => removeField(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No hay campos en tu formulario</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Button type="submit">Crear Formulario</Button>
      </form>

      {showModal && (
        <Modal onClose={() => setShowModal(false)} title="Agregar Campo">
          <AddFields>
            <div>
              <input
                type="text"
                name="fieldName"
                placeholder="Nombre"
                value={newField.fieldName}
                onChange={handleFieldChange}
                required
              />
            </div>
            <div>
              <select
                name="fieldType"
                value={newField.fieldType}
                onChange={handleFieldChange}
                required
              >
                <option value="">- Tipo de campo -</option>
                <option value="text">Texto</option>
                <option value="number">Número</option>
                <option value="email">Email</option>
                <option value="date">Fecha</option>
              </select>
            </div>
            <div>
              <select
                name="required"
                value={String(newField.required)}
                onChange={handleFieldChange}
                required
              >
                <option value="">- ¿Es Requerido? -</option>
                <option value="true">Sí</option>
                <option value="false">No</option>
              </select>
            </div>
            <Button type="button" onClick={addField}>
              Agregar Campo
            </Button>
          </AddFields>
        </Modal>
      )}
    </div>
  );
};

export default CreateForm;
