import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Navbar = styled.div`
  background-color: #1f2a48;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  min-height: 80px;
  box-shadow: 3px 0px 20px rgba(0, 0, 0, 0.8);
`;

export const MainMenu = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;

  li {
    color: white;
    margin: 0px 10px;
  }

  li:hover {
    color: #d1345b;
    cursor: pointer;
  }

  path {
    color: #d1345b;
  }
`;

export const MenuItem = styled.li`
  color: white;
  margin: 0px 10px;
  cursor: pointer;

  &:hover {
    color: #d1345b;
  }

  &.active {
    color: #d1345b;
  }
`;

export const CircleActions = styled.div`
  width: 30px;
  height: 30px;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`;

export const SubMenu = styled.ul`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px;
  background-color: #1f2a48;
  list-style: none;
  border-radius: 10px;
  min-width: 250px;

  li {
    border-bottom: 1px solid #d1345b;
    padding: 10px 10px;
  }
`;

export const SubMenuContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover > ul {
    display: block;
  }
`;

export const Notifications = styled.div`
  display: flex;
  position: relative;

  svg {
    font-size: 20px;
  }

  span {
    background-color: ${theme.colors.rosado};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; // Cambié a 50% para asegurarnos de que sea perfectamente redondo
    font-size: 12px;
    padding: 2px;
    font-weight: 400;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -15px; // Ajusta este valor para mover verticalmente
    right: -15px; // Ajusta este valor para mover horizontalmente
  }
`;
