import React from "react";

interface ITotals {
  subtotal: number;
  taxes: { [key: number]: number };
  total: number;
}

interface TotalsProps {
  totals: ITotals;
}

const Totals: React.FC<TotalsProps> = ({ totals }) => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Importe sin impuestos:</td>
            <td>{totals.subtotal.toFixed(2)}</td>
          </tr>
          {Object.keys(totals.taxes).map((tax) => (
            <tr key={tax}>
              <td>{`IVA ${tax}%`}</td>
              <td>{totals.taxes[parseFloat(tax)].toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>
              <hr />
            </td>
            <td>
              <hr />
            </td>
          </tr>
          <tr>
            <td>Total:</td>
            <td>{totals.total.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Totals;
