import styled from "styled-components";

export const Item = styled.td`
  input,
  select {
    width: 100%;
    padding: 5px;
    border: 0.5px solid grey;
    outline: none;
  }
`;
