import axiosInstance from "./axiosConfig";

// Service functions

const getCampana = async (id: string) => {
  if (!id) {
    console.log("id is missing");
    return;
  }
  try {
    const res = await axiosInstance.get(`/campanas/${id}`);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching campaign");
    throw new Error(error.response?.data?.message || "Error fetching campaign");
  }
};

const getCampanas = async () => {
  try {
    const res = await axiosInstance.get("/campanas");
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching campaigns");
    throw new Error(
      error.response?.data?.message || "Error fetching campaigns"
    );
  }
};

const createCampana = async (campana: any) => {
  try {
    const res = await axiosInstance.post("/campanas", campana);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating campaign");
    throw new Error(error.response?.data?.message || "Error creating campaign");
  }
};

const updateCampana = async (id: string, campana: any) => {
  if (!id) {
    console.log("id is missing");
    return;
  }
  try {
    const res = await axiosInstance.put(`/campanas/${id}`, campana);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating campaign");
    throw new Error(error.response?.data?.message || "Error updating campaign");
  }
};

const deleteCampana = async (id: string) => {
  if (!id) {
    console.log("id is missing");
    return;
  }
  try {
    await axiosInstance.delete(`/campanas/${id}`);
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting campaign");
    throw new Error(error.response?.data?.message || "Error deleting campaign");
  }
};

export const campanaService = {
  getCampana,
  getCampanas,
  createCampana,
  updateCampana,
  deleteCampana,
};
