// src/context/SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const SocketContext = createContext<Socket | null>(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SERVER_SOCKET!, {
      transports: ["websocket"],
      auth: {
        token: `${localStorage.getItem("token")}`,
      },
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Socket connected:", newSocket.id);
    });

    newSocket.on("connect_error", (err) => {
      if (err.message === "Invalid token") {
        // Manejar token inválido, posiblemente redirigir al login
        console.error("Token inválido, redirigiendo al login");
        // Implementa la lógica para cerrar sesión o solicitar al usuario que inicie sesión de nuevo
      } else {
        console.error("Error de conexión del socket:", err.message);
      }
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
