import React, { useState } from "react";
import { FaLink, FaList, FaLockOpen, FaTable, FaUser } from "react-icons/fa";
import PerfilComponent from "./PerfilComponent";
import UsuariosComponent from "./UsuariosComponent";
import SeguridadComponent from "./SeguridadComponent";
import ConexionApi from "./ConexionApi";
import { Content, Layout, Nav } from "./PerfilStyles";
import ContactFields from "./ContactFields";

const PerfilPage = () => {
  const [isVisible, setIsVisible] = useState("Perfil");

  const menuItems = [
    {
      label: "Perfil",
      icon: <FaUser />,
      onClick: () => {
        setIsVisible("Perfil");
      },
    },
    {
      label: "Usuarios",
      icon: <FaList />,
      onClick: () => {
        setIsVisible("Usuarios");
      },
    },
    {
      label: "Campos de formularios",
      icon: <FaTable />,
      onClick: () => {
        setIsVisible("Campos de formularios");
      },
    },
    {
      label: "Seguridad",
      icon: <FaLockOpen />,
      onClick: () => {
        setIsVisible("Seguridad");
      },
    },
    {
      label: "Conexiones API",
      icon: <FaLink />,
      onClick: () => {
        setIsVisible("Conexiones API");
      },
    },
  ];

  return (
    <div>
      <h1>PerfilPage</h1>
      <Layout>
        <Nav>
          {menuItems.map((item, index) => (
            <ul key={index} onClick={item.onClick}>
              {item.icon}
              <li
                style={
                  isVisible === item.label
                    ? {
                        color: "#D1345B",
                        padding: "5px",
                        margin: "5px",
                        cursor: "pointer",
                      }
                    : {
                        padding: "5px",
                        margin: "5px",
                        cursor: "pointer",
                      }
                }
              >
                {item.label}
              </li>
            </ul>
          ))}
        </Nav>
        <Content>
          {isVisible === "Perfil" && <PerfilComponent />}
          {isVisible === "Usuarios" && <UsuariosComponent />}
          {isVisible === "Campos de formularios" && <ContactFields />}
          {isVisible === "Seguridad" && <SeguridadComponent />}
          {isVisible === "Conexiones API" && <ConexionApi />}
        </Content>
      </Layout>
    </div>
  );
};

export default PerfilPage;
