import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  background-color: #f1f1f1;
  position: absolute;
  top: 0;
  right: 0;
  width: 30vw;
  height: 100vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
  background-color: ${theme.colors.rosado};

  h1 {
    font-size: 22px;
    color: white;
  }

  svg {
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
  path {
    color: white;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid ${theme.colors.gris3};
  background-color: white;
  margin: 20px;
  border-radius: 20px;

  svg {
    font-size: 20px;
    color: #757575;
  }

  input {
    border: none;
    outline: none;
    padding: 10px;
    margin-left: 5px;
    width: 100%;
    background-color: none;
  }
`;

export const FileList = styled.div`
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  overflow: auto;
  height: 80%;
`;

export const FileItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gris3};
  }

  img {
    margin-right: 10px;
    max-height: 100px;
  }

  h3 {
    font-size: 18px;
  }
`;

export const ButtonContainer = styled.div`
  padding: 10px;
`;
