import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;
export const TableResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;

export const TableActionsStyles = styled.div`
  display: flex;
  margin-bottom: 1rem;

  span {
    padding: 0.5rem;
    color: ${theme.colors.grisOscuro};
    cursor: pointer;
    &:hover {
      color: ${theme.colors.rosado};

      path {
        color: ${theme.colors.rosado};
      }
    }
  }

  div:first-child {
    margin-left: 0px;
  }

  div {
    display: flex;
    /* align-items: center; */
    margin: 0px 10px;
  }
`;

export const TableTopActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px;
`;

export const TableSearch = styled.div`
  input {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    color: black;
    min-width: 400px;
  }
`;

export const TableFilterContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableFilter = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${theme.colors.rosado};
  background-color: transparent;
  color: ${theme.colors.rosado};
  cursor: pointer;
  margin-right: 1rem;
  width: 120px;
  justify-content: center;

  &:hover {
    background-color: ${theme.colors.rosado};
    color: white;
    transition: ease all 0.3s;

    path {
      color: white;
    }
  }

  svg {
    margin-right: 0.5rem;
    path {
      color: ${theme.colors.rosado};
    }
  }
`;

export const CreateContactoButton = styled.button`
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  background-color: ${theme.colors.rosado};
  color: white;
  cursor: pointer;
  width: 120px;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    transition: ease all 0.3s;
  }
`;

export const TableTag = styled.table`
  border-collapse: collapse;
  margin-bottom: 1rem;
  background-color: transparent;
  border-spacing: 0;
  border-color: ${theme.colors.gris3};
  min-height: 100%;
  width: 100%;
  table-layout: fixed;

  th,
  td {
    overflow: hidden;
    white-space: nowrap;
  }

  th:first-child,
  td:first-child {
    width: 50px; /* Establece el ancho de la primera columna a 50px */
    text-align: center; /* Opcional: Centra el contenido para que quede visualmente mejor */
  }

  thead {
    background-color: ${theme.colors.gris3};
    color: black;
    th {
      text-align: center;
      text-transform: capitalize;
    }
  }

  th {
    text-align: left;
    padding: 8px;
    border: 1px solid ${theme.colors.gris3};
  }

  td {
    text-align: left;
    padding: 10px;
    border: 1px solid ${theme.colors.gris3};
  }
`;

export const TablePagination = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  button {
    margin-right: 1rem;
    margin-left: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid ${theme.colors.gris3};
    background-color: #f2f2f2;
    color: black;
    cursor: pointer;
  }

  span {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  select {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    background-color: #f2f2f2;
    color: black;
    cursor: pointer;
  }
`;

/////////////////// TOOLS  //////////////////

export const ToolsContainer = styled.div`
  display: flex;
  div {
    margin: 0px 10px;
    display: flex;

    p {
      &:hover {
        color: ${theme.colors.rosado};
        cursor: pointer;
      }
    }

    svg {
      margin-right: 5px;
    }
  }
`;

export const ListItemDetail = styled.td`
  a {
    color: #d1345b;
    &:hover {
      color: #64162a;
    }
  }
`;
