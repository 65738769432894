import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
`;

export const Content = styled.div`
  width: 50%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 20px;

  input {
    border: none;
    outline: none;
    margin-left: 10px;
    width: 100%;
    padding: 5px 10px;
  }
`;

export const Results = styled.div`
  width: 100%;
  margin-top: 30px;
  overflow: auto;
  height: 150px;
`;

export const ResultsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  td:last-child {
    color: ${theme.colors.rosado};
    cursor: pointer;
  }
`;
