import React, { useEffect, useState } from "react";
import { ContainerModal, Modal } from "./SendWhatsappStyles";
import {
  getTemplates,
  sendMessageTemplate,
} from "../../../services/chatService";
import { Template } from "../../../types/types";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/slices/alertSlice";

interface SendWhatsappProps {
  to: string;
  setShowCreateChat: (value: boolean) => void;
}

interface SelectedTemplate {
  name: string;
  status: string;
  category: string;
  components: any[]; // Updated to any[] to handle the dynamic structure
  id: string;
  language: string;
}

const SendWhatsapp = ({ to, setShowCreateChat }: SendWhatsappProps) => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<SelectedTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<SelectedTemplate | null>(null);
  const [formValues, setFormValues] = useState<string[]>([]);

  const fetchTemplates = async () => {
    const response = await getTemplates();
    setTemplates(response);
  };

  const handleInputChange = (index: number, value: string) => {
    const updatedValues = [...formValues];
    updatedValues[index] = value;
    setFormValues(updatedValues);
  };

  const handleSend = async () => {
    if (!selectedTemplate) {
      alert("Selecciona una plantilla");
      return;
    }

    // Verificar si la plantilla contiene un componente dinámico
    const component = selectedTemplate.components.find(
      (item: any) => item.example
    );

    const isComponent = Boolean(component);
    const componentsPayload = isComponent
      ? [
          {
            type: "body",
            parameters: formValues.map((value) => ({
              type: "text",
              text: value,
            })),
          },
        ]
      : [];

    console.log(selectedTemplate, "selectedTemplate");

    const payload = {
      messaging_product: "whatsapp",
      to,
      type: "template",
      template: {
        name: selectedTemplate.name,
        language: {
          code: selectedTemplate.language,
        },
        components: componentsPayload,
      },
      message: selectedTemplate.components[0].text,
    };

    try {
      const response = await sendMessageTemplate(payload);

      const isSuccess = response?.messages?.[0]?.message_status === "accepted";
      dispatch(
        setAlert({
          title: isSuccess ? "Mensaje enviado" : "Error",
          message: isSuccess
            ? "Mensaje enviado correctamente"
            : "Error al enviar el mensaje",
          type: isSuccess ? "success" : "error",
          show: true,
        })
      );
      setShowCreateChat(false);
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al enviar el mensaje",
          type: "error",
          show: true,
        })
      );
      setShowCreateChat(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    // Reset form values when the template changes
    if (selectedTemplate) {
      const component = selectedTemplate.components.find(
        (item) => item.example
      );
      if (component && component.example) {
        setFormValues(component.example.body_text[0] || []);
      } else {
        setFormValues([]);
      }
    }
  }, [selectedTemplate]);

  return (
    <ContainerModal>
      <Modal>
        <h3>Inicia una nueva conversación</h3>

        <select
          name="plantilla"
          id="plantilla"
          onChange={(e) => {
            const selectedItem = templates.find(
              (item) => item.name === e.target.value
            );
            setSelectedTemplate(selectedItem || null);
          }}
        >
          <option value="">Selecciona una plantilla</option>
          {templates.map((item) => (
            <option key={item.name} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>

        {selectedTemplate &&
          selectedTemplate.components.map((component, componentIndex) =>
            component.example
              ? component.example.body_text[0].map(
                  (exampleText: string, index: number) => (
                    <div key={index}>
                      <label htmlFor={`input-${index}`}>
                        Parámetro {index + 1}
                      </label>
                      <input
                        type="text"
                        id={`input-${index}`}
                        value={formValues[index] || ""}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                    </div>
                  )
                )
              : null
          )}

        <button onClick={handleSend}>Enviar Mensaje</button>
      </Modal>
    </ContainerModal>
  );
};

export default SendWhatsapp;
