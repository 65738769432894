import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { IQuotation } from "../types/types";

const bgImage = "/images/quotations/bg.jpg";

const QuotationPdfDocument = ({ quotation }: any) => {
  const clientInfo = quotation.contactId?.properties.reduce(
    (acc: any, prop: any) => {
      acc[prop.key] = prop.value;
      return acc;
    },
    {}
  );

  const organizationInfo = quotation.organizationId as any;

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <Image style={styles.backgroundImage} src={bgImage} fixed />
        {/* Header with company info */}

        <View style={styles.header}>
          <Image src={organizationInfo?.logoUrl || ""} style={{ width: 100 }} />
          <View>
            <Text style={styles.companyName}>
              {organizationInfo?.companyName || "Company Name Placeholder"}
            </Text>
            {organizationInfo?.address?.street &&
              organizationInfo?.address?.city && (
                <Text style={styles.companyContact}>
                  {organizationInfo.address.street}{" "}
                  {organizationInfo.address.city}
                </Text>
              )}
            {organizationInfo?.phone && (
              <Text style={styles.companyContact}>
                {organizationInfo.phone}
              </Text>
            )}
          </View>
        </View>

        <View style={styles.clientHead}>
          <View> </View>

          <View>
            <Text style={styles.clientName}>{clientInfo?.companyName}</Text>
            <Text style={styles.clientName}>
              {clientInfo.firstName} {clientInfo.lastName}
            </Text>

            {clientInfo?.address && clientInfo?.city && (
              <Text style={styles.clientContact}>
                {clientInfo.address} {clientInfo.city}
              </Text>
            )}
            {clientInfo?.idNumber && (
              <Text style={styles.clientContact}>
                NIT: {clientInfo.idNumber}
              </Text>
            )}
            {clientInfo?.mobile && (
              <Text style={styles.clientContact}>
                Teléfono: {clientInfo.mobile}
              </Text>
            )}
          </View>
        </View>

        {/* Title */}
        <View style={styles.section}>
          <Text style={styles.title}>
            Número de cotización CO{quotation.quotationNumber}
          </Text>
        </View>

        {/* Quotation Details */}
        <View style={styles.section}>
          <View>
            <Text style={styles.label}>Fecha de cotización:</Text>
            <Text>{new Date(quotation.creationDate).toLocaleDateString()}</Text>
          </View>
          <View>
            <Text style={styles.label}>Vencimiento:</Text>
            <Text>
              {new Date(quotation.expirationDate).toLocaleDateString()}
            </Text>
          </View>
          <View>
            <Text style={styles.label}>Vendedor:</Text>
            <Text>
              {quotation.userId?.firstName} {quotation.userId?.lastName}
            </Text>
          </View>
        </View>

        {/* Items Table */}
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={{ width: "40%", color: "#bf0000" }}>DESCRIPCIÓN</Text>
            <Text style={{ width: "20%", color: "#bf0000" }}>CANTIDAD</Text>
            <Text style={{ width: "20%", color: "#bf0000" }}>PRECIO </Text>
            <Text style={{ width: "20%", color: "#bf0000" }}>IMPUESTOS</Text>
            <Text style={{ width: "20%", color: "#bf0000" }}>
              SUBTOTAL SIN IVA
            </Text>
          </View>
          {quotation.items.map((item: any) => (
            <View style={styles.tableRow} key={item.name}>
              {item.name && (
                <View style={{ width: "40%" }}>
                  <Text
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      color: "#666666",
                      fontSize: 10,
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text style={{ width: "100%", color: "#666666" }}>
                    {item.description}
                  </Text>
                </View>
              )}
              {item.quantity && (
                <Text style={styles.tableCell}>
                  {item.quantity.toLocaleString("en-US", {
                    minimumFractionDigits: 0, // No se mostrarán decimales
                    maximumFractionDigits: 0, // Limita a 0 decimales
                  })}
                </Text>
              )}
              {item.unitPrice && (
                <Text style={styles.tableCell}>
                  $
                  {item.unitPrice.toLocaleString("en-US", {
                    minimumFractionDigits: 0, // No se mostrarán decimales
                    maximumFractionDigits: 0, // Limita a 0 decimales
                  })}
                </Text>
              )}
              {item.taxes ? (
                <Text style={styles.tableCell}>
                  $
                  {((item.taxes / 100) * item.total).toLocaleString("en-US", {
                    minimumFractionDigits: 0, // No se mostrarán decimales
                    maximumFractionDigits: 0, // Limita a 0 decimales
                  })}{" "}
                  ({item.taxes}%)
                </Text>
              ) : (
                <Text style={styles.tableCell}>$0</Text>
              )}
              {item.total && (
                <Text style={styles.tableCell}>
                  $
                  {item.total.toLocaleString("en-US", {
                    minimumFractionDigits: 0, // No se mostrarán decimales
                    maximumFractionDigits: 0, // Limita a 0 decimales
                  })}
                </Text>
              )}
            </View>
          ))}
        </View>

        {/* Totals */}
        <View style={styles.totalsContainer}>
          <View style={styles.subtotalRow}>
            <Text style={styles.subtotalRowLabel}>Subtotal</Text>
            <Text style={styles.subtotalRowValue}>
              $
              {quotation.subtotal.toLocaleString("en-US", {
                minimumFractionDigits: 0, // No se mostrarán decimales
                maximumFractionDigits: 0, // Limita a 0 decimales
              })}
            </Text>
          </View>
          <View style={styles.subtotalRow}>
            <Text style={styles.ivaRowLabel}>IVA</Text>
            <Text style={styles.subtotalRowValue}>
              $
              {quotation.taxes.toLocaleString("en-US", {
                minimumFractionDigits: 0, // No se mostrarán decimales
                maximumFractionDigits: 0, // Limita a 0 decimales
              })}
            </Text>
          </View>
          <View style={styles.totalRowBg}>
            <Text style={styles.totalRowLabel}>Total</Text>
            <Text style={styles.totalRowLabel}>
              $
              {quotation.total.toLocaleString("en-US", {
                minimumFractionDigits: 0, // No se mostrarán decimales
                maximumFractionDigits: 0, // Limita a 0 decimales
              })}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.condicionesComerciales}>
            Condiciones Comerciales:
          </Text>
          <Text style={styles.texto}>
            Forma de pago: {quotation.paymentTerms || "A Convenir"}
          </Text>
          <Text style={styles.texto}>
            Tiempo de entrega: {quotation.shippingTerms || "A Convenir"}
          </Text>
        </View>
        <View>
          <Text style={styles.observaciones}>Observaciones:</Text>
          <Text style={styles.texto}>
            {quotation.observaciones || "Sin observaciones"}
          </Text>
        </View>

        {/* Optional Products */}
        {quotation.optionalItems.length > 0 && (
          <View>
            <Text style={styles.opcionales}>Productos Opcionales:</Text>
            <View style={styles.tableHeaderOpt}>
              <Text style={styles.tableCellDescription}>Nombre</Text>
              <Text style={styles.tableCell}>Cantidad</Text>
              <Text style={styles.tableCell}>Precio Unitario (Sin Iva) </Text>
            </View>
            {quotation.optionalItems.map((item: any) => (
              <View style={styles.tableRow} key={item.name}>
                {item.description && (
                  <View style={styles.tableCellDescription}>
                    <Text style={{ fontWeight: "bold", fontSize: "12" }}>
                      {item.name}
                    </Text>
                    <Text>{item.description}</Text>
                  </View>
                )}
                {item.quantity && (
                  <Text style={styles.tableCell}>
                    {item.quantity.toLocaleString("en-US", {
                      minimumFractionDigits: 0, // No se mostrarán decimales
                      maximumFractionDigits: 0, // Limita a 0 decimales
                    })}
                  </Text>
                )}
                {item.unitPrice && (
                  <Text style={styles.tableCell}>
                    $
                    {item.unitPrice.toLocaleString("en-US", {
                      minimumFractionDigits: 0, // No se mostrarán decimales
                      maximumFractionDigits: 0, // Limita a 0 decimales
                    })}
                  </Text>
                )}
              </View>
            ))}
          </View>
        )}

        {/* Footer */}
        <View style={styles.footer}>
          <Text>
            {organizationInfo?.companyName} - {organizationInfo?.idType}:{" "}
            {organizationInfo?.idNumber} - Tel: {organizationInfo?.phone} -
            Email: {organizationInfo?.email}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default QuotationPdfDocument;

const styles = StyleSheet.create({
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1, // Ensure the background is behind other content
  },
  table: {
    border: "1px solid #666666",
  },
  condicionesComerciales: {
    marginTop: 20,
    fontSize: 13,
    color: "#404040",
  },
  texto: {
    fontSize: 10,
    color: "#404040",
    marginTop: 5,
    marginBottom: 10,
    width: "300px",
  },
  observaciones: {
    marginTop: 15,
    fontSize: 13,
    color: "#404040",
    width: "200px",
  },
  totalsContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  subtotalRow: {
    border: "0.5px solid grey",
    padding: 3,
    width: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subtotalRowLabel: {
    color: "#666666",
    fontSize: 10,
  },
  ivaRowLabel: {
    color: "#666666",
    fontSize: 10,
  },
  totalRowLabel: {
    color: "white",
    fontSize: 10,
  },
  subtotalRowValue: {
    color: "#666666",
    marginLeft: 10,
    textAlign: "right",
    fontSize: 10,
  },
  totalRowBg: {
    display: "flex",
    color: "white",
    backgroundColor: "#1d6094",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "150px",
    padding: 3,
  },
  page: {
    padding: 20,
    fontFamily: "Helvetica",
    fontSize: 12,
    lineHeight: 1.5,
  },
  clientHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
    marginBottom: 10,
    marginTop: 0,
    alignItems: "center",
    minWidth: "60%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 0,
    marginBottom: 20,
    marginTop: 0,
    alignItems: "center",
    minWidth: "60%",
    borderBottom: "1px solid grey",
  },
  companyName: {
    fontSize: 10,
    color: "#666666",
    fontWeight: "bold",
  },
  companyContact: {
    fontSize: 10,
    color: "#666666",
    marginTop: 2,
  },
  clientInfo: {
    textAlign: "left",
    marginBottom: 20,
  },
  clientName: {
    fontSize: 12,
    fontWeight: "bold",
  },
  clientContact: {
    fontSize: 10,
    marginTop: 2,
  },
  title: {
    fontSize: 16,
    color: "#1d6094",
    marginBottom: 10,
  },
  section: {
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: "30px",
    paddingLeft: "30px",
  },
  label: {
    fontSize: 9,
    color: "#bf0000",
    fontWeight: "bold",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottom: 1,
    paddingBottom: 5,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 9,
    fontWeight: "bold",
  },
  tableHeaderOpt: {
    flexDirection: "row",
    borderBottom: 1,
    paddingBottom: 5,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 10,
    fontWeight: "bold",
    color: "#787878",
  },
  tableRow: {
    flexDirection: "row",
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 9,
  },
  tableCell: {
    // Asegurar que el ancho es consistente entre header y rows
    width: "20%", // Igual para las columnas de cantidad, precio, impuestos y subtotal
    color: "#666666",
  },
  tableCellDescription: {
    width: "40%",
    color: "#666666",
    fontWeight: "bold",
  },
  totalRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 20,
    lineHeight: 1,
  },
  totalLabel: {
    width: "80%",
    textAlign: "right",
    fontWeight: "bold",
    lineHeight: 1,
  },
  totalValue: {
    width: "20%",
    textAlign: "left",
    fontWeight: "bold",
    lineHeight: 1,
    marginLeft: 10,
    fontSize: 10,
  },
  opcionales: {
    fontSize: 12,
    marginTop: 10,
    color: "#ba0000",
  },
  footer: {
    position: "absolute",
    bottom: 10, // Adjust this to move the footer closer or farther from the bottom
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    borderTop: 1,
    paddingTop: 10,
    color: "#666666",
    marginHorizontal: 40, // Ensures the footer is within the page padding
  },
});
