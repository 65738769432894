import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  position: fixed;
  top: 10; /* Se posiciona justo debajo del botón */
  left: 250;
  z-index: 99999999; /* Asegúrate de que este valor sea mayor que el de otros elementos cercanos */
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Content = styled.div`
  min-width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  li {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.gris3};
      color: #333;
    }

    svg {
      margin-right: 10px;
    }
  }

  li:last-child {
    border-bottom: none;
  }
`;
