import { useState, useEffect } from "react";
import { campanaService } from "services/campanaService";
import { ICampana } from "types";

export const useCampana = () => {
  const [activeView, setActiveView] = useState("VerCampanas");
  const [activeStep, setActiveStep] = useState("Informacion");
  const [campanas, setCampanas] = useState<ICampana[]>([]); // Estado para almacenar la lista de campañas
  const [campana, setCampana] = useState<ICampana>(); // Estado para almacenar una campaña específica
  const [loading, setLoading] = useState(false); // Estado de carga
  const [error, setError] = useState<string | null>(null); // Estado para manejar errores
  const [newCampana, setNewCampana] = useState();

  // Handler para actualizar el estado de la nueva campaña
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewCampana((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Función para obtener todas las campañas
  const fetchCampanas = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await campanaService.getCampanas();
      setCampanas(data);
    } catch (error: any) {
      setError(error.message || "Error fetching campaigns");
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener una campaña por ID
  const fetchCampana = async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      const data = await campanaService.getCampana(id);
      setCampana(data);
    } catch (error: any) {
      setError(error.message || "Error fetching campaign");
    } finally {
      setLoading(false);
    }
  };

  // Función para crear una campaña
  const createCampana = async (newCampana: ICampana) => {
    setLoading(true);
    setError(null);
    try {
      await campanaService.createCampana(newCampana);
      setCampanas((prevCampanas) => [...prevCampanas, newCampana]);
      setActiveView("VerCampanas");
    } catch (error: any) {
      setError(error.message || "Error creating campaign");
    } finally {
      setLoading(false);
    }
  };

  // Función para actualizar una campaña
  const updateCampana = async (id: string, updatedCampana: any) => {
    setLoading(true);
    setError(null);
    try {
      const data = await campanaService.updateCampana(id, updatedCampana);
      setCampanas((prevCampanas) =>
        prevCampanas.map((camp) => (camp._id === id ? data : camp))
      );
      setActiveView("VerCampanas");
    } catch (error: any) {
      setError(error.message || "Error updating campaign");
    } finally {
      setLoading(false);
    }
  };

  // Función para eliminar una campaña
  const deleteCampana = async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      await campanaService.deleteCampana(id);
      setCampanas((prevCampanas) =>
        prevCampanas.filter((camp) => camp._id !== id)
      );
      setActiveView("VerCampanas");
    } catch (error: any) {
      setError(error.message || "Error deleting campaign");
    } finally {
      setLoading(false);
    }
  };

  // Llamada inicial para obtener campañas (opcional, puede ser llamada externamente)
  useEffect(() => {
    fetchCampanas();
  }, []);

  return {
    activeView,
    setActiveView,
    activeStep,
    setActiveStep,
    campanas,
    campana,
    fetchCampana,
    fetchCampanas,
    createCampana,
    updateCampana,
    deleteCampana,
    loading,
    error,
    newCampana,
    setNewCampana,
    handleChange,
  };
};
