// src/components/common/Layout/Layout.tsx
import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import AlertNotification from "../AlertNotification/AlertNotification";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import SearchModal from "../SearchModal/SearchModal";

const Layout: React.FC = () => {
  const location = useLocation();
  const noNavbarRoutes = ["/login", "/register"];
  const shouldRenderNavbar = !noNavbarRoutes.includes(location.pathname);
  const showAlert = useSelector((state: any) => state.alert.show);
  const showLoading = useSelector((state: any) => state.loading.isLoading);
  const showSearch = useSelector(
    (state: any) => state.search.status === "loading"
  );

  return (
    <>
      {showAlert && <AlertNotification />}
      {shouldRenderNavbar && <Navbar />}
      {showLoading && <Loading />}
      {showSearch && <SearchModal />}

      <main style={{ padding: "30px" }}>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
