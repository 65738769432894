import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setAlert } from "redux/slices/alertSlice";
import { projectService } from "services/projectService";
import styled from "styled-components";
import { theme } from "styles/theme";
import { ITask } from "types";

const initialState: ITask = {
  title: "",
  dueDate: new Date(),
  priority: "High",
  contactId: "",
  status: "No Iniciado",
  responsibleId: "",
  projectId: "66e9e1a8af5cf526fd6d243b",
};

const CreateTask = ({ setShowCreateTask }: any) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const organization = useSelector((state: any) => state.organization);

  const [task, setTask] = useState<ITask>(initialState);

  const createTask = async (e: any) => {
    e.preventDefault();

    try {
      if (!task.title || !task.dueDate || !task.priority) {
        dispatch(
          setAlert({
            title: "Error",
            message: "Todos los campos son requeridos",
            type: "error",
            show: true,
          })
        );
        return;
      }

      const response = await projectService.createTask({
        ...task,
        contactId: id,
        responsibleId: organization.employees[0]._id,
      });
      console.log(response);

      dispatch(
        setAlert({
          title: "Tarea creada",
          message: "La tarea ha sido creada correctamente",
          type: "success",
          show: true,
        })
      );
      setTask(initialState);
      setShowCreateTask(false);
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Ocurrió un error al crear la tarea",
          type: "error",
          show: true,
        })
      );
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setTask({ ...task, [e.target.name]: e.target.value });
  };

  return (
    <Container>
      <Content>
        <Header>
          <h1>Crear tarea para el contacto</h1>
          <FaTimes onClick={() => setShowCreateTask(false)} />
        </Header>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            createTask(e);
          }}
        >
          <FormGroup>
            <label htmlFor="">Tarea:</label>
            <input
              type="text"
              name="title"
              value={task.title}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="">Vencimiento</label>
            <input
              type="date"
              name="dueDate"
              value={task.dueDate?.toString()}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="">Prioridad</label>
            <select
              id=""
              name="priority"
              value={task.priority}
              onChange={handleChange}
            >
              <option value="">- Seleccione --</option>
              <option value="Alta">Alta</option>
              <option value="Media">Media</option>
              <option value="Baja">Baja</option>
            </select>
          </FormGroup>
          <FormGroup>
            <label htmlFor="">Responsable</label>
            <select
              id=""
              name="responsibleId"
              value={task.responsibleId}
              onChange={handleChange}
            >
              <option value="">- Seleccione --</option>
              {organization.employees.map((employee: any) => (
                <option key={employee._id} value={employee._id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))}
            </select>
          </FormGroup>
          <button>Guardar</button>
        </form>
      </Content>
    </Container>
  );
};

export default CreateTask;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  width: 400px;

  h1 {
    font-size: 20px;
  }

  button {
    background-color: ${theme.colors.rosado};
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
    font-size: 20px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;
