import { Container } from "./FooterStyles";
import { useContext } from "react";
import { FaCog, FaCogs } from "react-icons/fa";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";

const Footer = () => {
  const { handleOpenEditStates, handleOpenEditDealsFields } =
    useContext(PipelineContext);

  return (
    <Container>
      <div onClick={handleOpenEditStates}>
        <FaCogs />
        <h3>Editar columnas</h3>
      </div>
      <div onClick={handleOpenEditDealsFields}>
        <FaCog />
        <h3>Configurar</h3>
      </div>
    </Container>
  );
};

export default Footer;
