import React, { useState } from "react";
import {
  Container,
  Content,
  Results,
  ResultsTable,
  SearchContainer,
  Title,
} from "./SearchModalStyles";
import { FaSearch, FaTimes } from "react-icons/fa";
import { advancedSearch } from "../../../services/advancedSearch";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setSearch } from "../../../redux/slices/searchSlice";

interface Contact {
  _id: string;
  properties: Array<{
    value: string;
    key: string;
    isVisible: boolean;
    _id: string;
  }>;
}

interface Deal {
  _id: string;
  title: string;
  associatedContactId: string;
}

const SearchModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const search = async () => {
    if (!searchValue.trim()) return;

    setIsLoading(true);
    setError(null);

    try {
      dispatch(setLoading(true));
      const response = await advancedSearch(searchValue);
      dispatch(setLoading(false));
      if (response) {
        setContacts(response.contacts || []);
        setDeals(response.deals || []);
      }
    } catch (err) {
      setError("Hubo un error al realizar la búsqueda. Inténtalo de nuevo.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title>Buscar</Title>
          <FaTimes
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setSearch({ value: "", status: "idle" }));
              setContacts([]);
              setDeals([]);
              setSearchValue("");
            }}
          />
        </div>
        <div>
          <SearchContainer>
            <FaSearch />
            <input
              type="text"
              placeholder="Buscador de contactos y negocios"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </SearchContainer>
          <button onClick={search} disabled={isLoading}>
            {isLoading ? "Buscando..." : "Buscar"}
          </button>
          {error && <p>{error}</p>}

          {/* Resultados de Contactos */}
          <Results>
            <h3>Contactos</h3>
            <ResultsTable>
              <tbody>
                {contacts.length > 0 ? (
                  contacts.map((contact) => {
                    const firstNameProperty = contact.properties.find(
                      (prop) => prop.key === "firstName"
                    );
                    const firstName = firstNameProperty
                      ? firstNameProperty.value
                      : "Nombre no disponible";

                    return (
                      <tr key={contact._id}>
                        <td>{firstName}</td>
                        <td
                          onClick={() => {
                            navigate(`/contactos/${contact._id}`);
                            dispatch(setSearch({ value: "", status: "idle" }));
                          }}
                        >
                          Ver Registro
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>No hay resultados para mostrar...</td>
                  </tr>
                )}
              </tbody>
            </ResultsTable>
          </Results>

          {/* Resultados de Negocios (Deals) */}
          <Results>
            <h3>Negocios</h3>
            <ResultsTable>
              <tbody>
                {deals.length > 0 ? (
                  deals.map((deal) => (
                    <tr key={deal._id}>
                      <td>{deal.title}</td>
                      <td
                        onClick={() => {
                          navigate(`/negocios/${deal._id}`);
                          dispatch(setSearch({ value: "", status: "idle" }));
                        }}
                      >
                        Ver Registro
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>No hay resultados para mostrar...</td>
                  </tr>
                )}
              </tbody>
            </ResultsTable>
          </Results>
        </div>
      </Content>
    </Container>
  );
};

export default SearchModal;
