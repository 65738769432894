import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import reportsService from "services/reportsService";

// Función para formatear los números en formato de miles
const formatNumber = (value: any) => {
  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  }).format(value);
};

// Función para calcular el promedio de ventas
const calculateAverage = (data: any) => {
  const total = data.reduce((sum: any, item: any) => sum + item.ventas, 0);
  return total / data.length;
};

const InformesPage = () => {
  const [report, setReport] = useState([]);

  const fetchData = async () => {
    const report = await reportsService.salesReports();
    setReport(report);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calcular el promedio de ventas
  const averageSales = report.length > 0 ? calculateAverage(report) : 0;

  return (
    <div>
      <h2>Ventas Mensuales (2024)</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Columna 1: Gráfica */}
        <div style={{ flex: 1, marginRight: "20px" }}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={report}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={formatNumber} />
              <Tooltip formatter={(value) => formatNumber(value)} />
              <Legend />
              <ReferenceLine
                y={averageSales}
                label={"Promedio" + " " + averageSales.toLocaleString()}
                stroke="red"
                strokeDasharray="3 3"
              />
              <Bar dataKey="ventas" fill="#d1345b42" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Columna 2: Información adicional */}
        <div style={{ flex: 1 }}>
          <h3>Información adicional</h3>
          <p>
            Aquí puedes agregar información adicional sobre los informes,
            estadísticas, o cualquier otro contenido relevante.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformesPage;
