import { useRef } from "react";
import { Container, Link } from "./AttachmentsStyles";

const Attachments = ({
  setShowFileSelector,
  setShowAttachments,
  handleFileUpload,
}: any) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTextClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Container>
      <Link
        onClick={() => {
          setShowAttachments(false);
          setShowFileSelector(true);
        }}
      >
        <h3>Seleccionar Archivo Existente</h3>
      </Link>
      <Link>
        <h3 onClick={handleTextClick}>Subir</h3>
        <input
          type="file"
          name="file"
          id="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              handleFileUpload(e.target.files[0], false);
            }
          }}
        />
      </Link>
    </Container>
  );
};

export default Attachments;
