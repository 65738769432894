import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const ConfirmationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  flex-direction: column !important;
  font-family: "Inter", sans-serif;

  div:first-child {
    path {
      color: "red";
    }
  }
`;

export const ConfirmationContent = styled.div`
  background-color: #fff;
  width: 50%;
  display: flex;
  flex-direction: column;
  /* height: 250px; */
  border-radius: 10px;
  padding: 20px;

  svg {
    font-size: 25px;
    path {
      &:hover {
        cursor: pointer;
        color: ${theme.colors.rosado};
        transition: ease-in-out 0.2s;
      }
    }
  }

  h1 {
    font-size: 1.3em;
    color: ${theme.colors.rosado};
    margin-top: 40px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
  }

  p {
    font-size: 1em;
    color: ${theme.colors.grisOscuro};
    text-align: center;
    font-weight: 400;
  }

  div:first-child {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  div:last-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;

    button {
      width: 50%;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      margin: 10px 15px;

      &:hover {
        opacity: 0.9;
        transition: ease-in-out 0.2s;
      }
    }

    button:nth-child(1) {
      background-color: ${theme.colors.rosado};
    }

    button:nth-child(2) {
      background-color: transparent;
      border: 1px solid ${theme.colors.rosado};
      color: ${theme.colors.rosado};

      &:hover {
        background-color: ${theme.colors.rosado};
        color: #fff;
        transition: ease-in-out 0.2s;
      }
    }
  }
`;
