import axiosInstance from "./axiosConfig";

const salesReports = async () => {
  try {
    const response = await axiosInstance.get("/reports/salesReports");
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default {
  salesReports,
};
