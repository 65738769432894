import React, { useState } from "react";
import { Head, Icon } from "./ContactDetailStyles";
import {
  FaCalendar,
  FaEdit,
  FaEnvelope,
  FaPhone,
  FaPrint,
  FaTasks,
  FaWhatsapp,
} from "react-icons/fa";
import PrintModal from "../../components/Contacts/Print/PrintModal";
import CreateTask from "./components/CreateTask";

const ActionsContact = ({
  setShowActivities,
  showActivities,
  contact,
  setShowCreateChat,
}: any) => {
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showCreateTask, setShowCreateTask] = useState(false);

  return (
    <>
      <Head>
        <div style={{ display: "flex" }}>
          <Icon onClick={() => setShowCreateChat(true)}>
            <div>
              <FaWhatsapp />
            </div>
            <small>WhatsApp</small>
          </Icon>

          <Icon>
            <div>
              <FaEnvelope />
            </div>
            <small>Correo</small>
          </Icon>

          <Icon
            onClick={() => {
              setShowCreateTask(true);
            }}
          >
            <div>
              <FaTasks />
            </div>
            <small>Tareas</small>
          </Icon>
          <div>
            <div
              style={{
                position: "relative",
                overflow: "visible",
                background: "white",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                width: "40px",
              }}
              onClick={() => setShowPrintModal(!showPrintModal)}
            >
              <div style={{ color: "white" }}>
                <FaPrint color="white !important" />
              </div>
            </div>
            <small>Imprimir</small>
            {showPrintModal && (
              <PrintModal
                setShowPrintModal={setShowPrintModal}
                contact={contact}
              />
            )}
          </div>

          <Icon onClick={() => setShowActivities(!showActivities)}>
            <div>
              <FaEdit />
            </div>
            <small>Notas</small>
          </Icon>
        </div>
      </Head>
      {showCreateTask && <CreateTask setShowCreateTask={setShowCreateTask} />}
    </>
  );
};

export default ActionsContact;
