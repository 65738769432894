import React, { useEffect, useState } from "react";

import Content from "./Content";
import List from "./List";
import { CorreoContainer } from "./CorreoStyles";
import { fetchEmails } from "../../../services/emailService";
import { IEmail } from "../../../types/types";

const CorreoPage = () => {
  const [emails, setEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState<IEmail>({
    _id: 0,
    attachments: [],
    body: "",
    createdAt: "",
    date: "",
    flags: [],
    folder: "",
    from: "",
    message: "",
    modseq: 0,
    subject: "",
    to: "",
    updatedAt: "",
    userId: 0,
    htmlBody: "",
  });

  const fetchEmail = async () => {
    const response = await fetchEmails();
    console.log(response, "response");
    setEmails(response);
    setSelectedEmail(response.length > 0 ? response[0] : []);
  };

  const handleSelect = (uid: any) => {
    setSelectedEmail(uid);
  };

  useEffect(() => {
    fetchEmail();
  }, []);

  return (
    <div>
      <CorreoContainer>
        <List
          emails={emails}
          handleSelect={handleSelect}
          selectedEmail={selectedEmail}
        />
        <Content selectedEmail={selectedEmail} />
      </CorreoContainer>
    </div>
  );
};

export default CorreoPage;
