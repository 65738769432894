import React, { useState } from "react";
import { FaEdit, FaPrint, FaTrash } from "react-icons/fa";
import { usePurchase } from "./context/PurchasesContext";
import {
  Button,
  Header,
  ProveedoresPageContainer,
  ProveedoresTable,
} from "./ProveedoresPageStyles";

const PurchasesTable = ({ setActiveView }: any) => {
  const { purchases, error, deletePurchaseOrder, getPurchaseOrders } =
    usePurchase();
  const [selectedPurchases, setSelectedPurchases] = useState<string[]>([]); // Estado para las compras seleccionadas

  // Handler para seleccionar/deseleccionar una compra
  const handleSelectPurchase = (purchaseId: string) => {
    setSelectedPurchases(
      (prevSelected) =>
        prevSelected.includes(purchaseId)
          ? prevSelected.filter((id) => id !== purchaseId) // Deselecciona si ya está seleccionado
          : [...prevSelected, purchaseId] // Selecciona si no lo está
    );
  };

  // Handler para seleccionar/deseleccionar todas las compras
  const handleSelectAll = () => {
    if (selectedPurchases.length === purchases.length) {
      setSelectedPurchases([]); // Deselecciona todo si ya están todas seleccionadas
    } else {
      setSelectedPurchases(purchases.map((purchase) => purchase._id)); // Selecciona todas
    }
  };

  // Handler para editar compras seleccionadas
  const handleEdit = () => {
    console.log("Editar compras seleccionadas:", selectedPurchases);
  };

  // Handler para eliminar compras seleccionadas
  const handleDelete = async () => {
    if (selectedPurchases.length === 0) {
      return alert("Selecciona al menos una compra para eliminar.");
    }

    try {
      for (const purchaseId of selectedPurchases) {
        await deletePurchaseOrder(purchaseId);
      }

      // Limpiar la selección después de eliminar
      setSelectedPurchases([]);

      // Actualiza la lista de compras después de eliminar
      getPurchaseOrders(); // Vuelve a cargar las compras
    } catch (error) {
      console.error("Error eliminando las compras:", error);
    }
  };

  // Handler para imprimir compras seleccionadas
  const handlePrint = () => {
    console.log("Imprimir compras seleccionadas:", selectedPurchases);
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <ProveedoresPageContainer>
      <h1>Lista de compras</h1>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button onClick={() => setActiveView("create")}>Crear compra</Button>
      </div>

      <Header>
        <div onClick={handleEdit}>
          <FaEdit />
          <p>Editar</p>
        </div>
        <div onClick={handleDelete}>
          <FaTrash />
          <p>Eliminar</p>
        </div>
        <div onClick={handlePrint}>
          <FaPrint />
          <p>Imprimir</p>
        </div>
      </Header>

      {
        <ProveedoresTable>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedPurchases.length === purchases.length} // Selecciona si todos están seleccionados
                  onChange={handleSelectAll}
                />
              </th>
              <th>ID</th>
              <th>Proveedor</th>
              <th>Fecha de Compra</th>
              <th>Total</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            {purchases.length > 0 ? (
              purchases.map((purchase) => (
                <tr key={purchase._id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedPurchases.includes(purchase._id)} // Verifica si está seleccionado
                      onChange={() => handleSelectPurchase(purchase._id)}
                    />
                  </td>
                  <td>{purchase.orderNumber}</td>
                  <td>
                    {purchase.supplierId.properties.find(
                      (prop: any) => prop.key === "firstName"
                    )?.value +
                      " " +
                      purchase.supplierId.properties.find(
                        (prop: any) => prop.key === "lastName"
                      )?.value || "Sin Nombre"}
                  </td>
                  <td>
                    {new Date(purchase.purchaseDate).toLocaleDateString()}
                  </td>
                  <td>{purchase.total}</td>
                  <td>{purchase.status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No hay compras registradas.</td>
              </tr>
            )}
          </tbody>
        </ProveedoresTable>
      }
    </ProveedoresPageContainer>
  );
};

export default PurchasesTable;
