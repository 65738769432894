import axios from "axios";

const endSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("organizationId");
  localStorage.removeItem("userId");
  window.location.href = "/login";
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      endSession();
      console.log("Error 401: Unauthorized");
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
