import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import {
  Container,
  Header,
  List,
  Title,
  ClearButton,
} from "./NotificationMenuStyles";
import { RootState } from "../../../redux/store";
import {
  hideNotifications,
  markAsRead,
  removeNotification,
  markAllAsRead,
} from "../../../redux/slices/notificationSlice";

const NotificationMenu: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );
  const isVisible = useSelector(
    (state: RootState) => state.notifications.isVisible
  );

  const handleClose = () => {
    dispatch(hideNotifications());
  };

  const handleClearAll = () => {
    dispatch(markAllAsRead());
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      <Header>
        <FaTimes onClick={handleClose} />
      </Header>
      <Title>
        Notificaciones
        {notifications.length > 0 && (
          <ClearButton onClick={handleClearAll}>
            Marcar todo como leído
          </ClearButton>
        )}
      </Title>
      <List>
        {notifications.length === 0 ? (
          <p>No tienes nuevas notificaciones.</p>
        ) : (
          notifications.map((notification) => (
            <li key={notification.id}>
              <div>
                <p>{notification.title}</p>
                <p>{notification.message}</p>
                <button onClick={() => dispatch(markAsRead(notification.id))}>
                  Marcar como leído
                </button>
                <button
                  onClick={() => dispatch(removeNotification(notification.id))}
                >
                  Eliminar
                </button>
              </div>
            </li>
          ))
        )}
      </List>
    </Container>
  );
};

export default NotificationMenu;
