import styled from "styled-components";
import { theme } from "styles/theme";

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: ${theme.colors.azulOscuro};

  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    color: white;
    margin: 0 10px;
    cursor: pointer;
    padding: 10px 15px;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }
`;
