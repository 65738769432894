import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const RawContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;

  div {
    min-width: 50%;
  }
  input,
  select {
    border: 1px solid ${theme.colors.gris2};
    padding: 5px;
    border-radius: 5px;
    min-width: 350px;
    max-width: 500px;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0px;
  div {
    background-color: ${theme.colors.rosado};
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 6px;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
      cursor: pointer;
      transition: 0.3s;
    }
  }

  svg {
    color: ${theme.colors.rosado};
  }
  path {
    color: ${theme.colors.blanco};
  }
  button {
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
    }
  }
`;

export const ProductTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const Observaciones = styled.div`
  margin-top: 20px;
  textarea {
    width: 100%;
    height: 100px;
    border: 1px solid ${theme.colors.gris2};
    border-radius: 5px;
    padding: 10px;
  }
`;

export const TotalsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
  table {
    width: 300px;
    border-collapse: collapse;
    th,
    td {
      padding: 8px;
      text-align: left;
    }
  }
`;
