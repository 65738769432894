import axiosInstance from "./axiosConfig";
import {
  DealDetails,
  DealsFields,
  DealsFieldsToSend,
  Pipeline,
  PipelineForm,
  Status,
  IContact,
  Deals,
} from "../types/types";

// Helper function to get token and organizationId
const getAuthData = () => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const organizationId =
    localStorage.getItem("organizationId") ||
    sessionStorage.getItem("organizationId");
  if (!token || !organizationId) {
    throw new Error("Authentication data is missing");
  }
  return { token, organizationId };
};

//  -------------------------- DEALS ------------------------------- //

export const createDeal = async (deal: any, fields: DealsFieldsToSend[]) => {
  const { token } = getAuthData();
  try {
    const res = await axiosInstance.post("/deals", {
      deal,
      fields,
    });
    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error creating deal");
  }
};

export const getDeals = async (
  pipelineId: string,
  name?: string,
  filterDates?: any
) => {
  const { organizationId } = getAuthData();
  try {
    const res = await axiosInstance.get<Deals[]>(
      `/deals?pipelineId=${pipelineId}${name ? "&name=" + name : ""}`,
      {
        params: {
          organizationId: organizationId,
          filterDates: filterDates,
        },
      }
    );

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error fetching deals");
  }
};

export const getDealsDetails = async (dealId: string) => {
  const { organizationId } = getAuthData();
  try {
    const res = await axiosInstance.get<DealDetails>(`/deals/${dealId}`, {
      params: {
        organizationId: organizationId,
      },
    });

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Error fetching deal details"
    );
  }
};

export const updateDeal = async (
  dealId: any,
  deal: any,
  fields: DealsFieldsToSend[]
) => {
  try {
    const res = await axiosInstance.put(`/deals/${dealId}`, {
      deal,
      fields,
    });

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error updating deal");
  }
};

export const getDealsFields = async (pipelineId: string) => {
  const { organizationId } = getAuthData();
  try {
    const res = await axiosInstance.get<DealsFields[]>(
      `/deals-fields?pipeline=${pipelineId}`,
      {
        params: {
          organizationId: organizationId,
        },
      }
    );

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Error fetching deal fields"
    );
  }
};

export const addDealsField = async (field: {
  key: string;
  name: string;
  pipeline: string;
}) => {
  try {
    const res = await axiosInstance.post("/deals-fields/create", { ...field });

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error adding deal field");
  }
};

export const removeDealsField = async (fieldId: string) => {
  try {
    const res = await axiosInstance.delete(`/deals-fields/delete/${fieldId}`);

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Error removing deal field"
    );
  }
};

export const editDealsField = async (
  fieldId: string,
  field: {
    key: string;
    name: string;
    pipeline: string;
    required: boolean;
  }
) => {
  try {
    const res = await axiosInstance.put(`/deals-fields/edit/${fieldId}`, {
      ...field,
    });

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Error editing deal field"
    );
  }
};

export const changeDealStatus = async (dealId: string, status: string) => {
  try {
    const res = await axiosInstance.put(`/deals/status/${dealId}`, { status });

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Error changing deal status"
    );
  }
};

export const createPipeline = async (form: PipelineForm) => {
  try {
    const res = await axiosInstance.post("/pipelines", form);

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error creating pipeline");
  }
};

export const getStatus = async (pipelineId: string) => {
  try {
    const res = await axiosInstance.get<Status[]>(
      `/status?pipelineId=${pipelineId}`
    );

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error fetching status");
  }
};

export const editStatusByPipelines = async (
  pipelineId: string,
  statesToEdit: Status[]
) => {
  try {
    const res = await axiosInstance.put<Status[]>(
      `/status/pipeline/${pipelineId}`,
      statesToEdit
    );

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error editing status");
  }
};

export const getPipelines = async () => {
  try {
    const res = await axiosInstance.get<Pipeline[]>("/pipelines");

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(
      error.response?.data?.message || "Error fetching pipelines"
    );
  }
};

export const getContacts = async (page?: number, limit?: number) => {
  const { organizationId } = getAuthData();
  try {
    const res = await axiosInstance.get<IContact[]>("/contacts", {
      params: {
        organizationId: organizationId,
        page: page,
        limit: limit,
      },
    });

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error fetching contacts");
  }
};

export const deleteDeal = async (dealId: string) => {
  try {
    const res = await axiosInstance.delete(`/deals/${dealId}`);

    return res.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error.response?.data?.message || "Error deleting deal");
  }
};
