import styled from "styled-components";

export const CorreoContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 250px);
  width: calc(100vw - 100px);
  gap: 50px;
  margin-top: 20px;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
`;
