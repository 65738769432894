import React from "react";

const WhatsAppMasivoPage = () => {
  return (
    <div>
      <h1>Campañas de WhatsApp Masivas</h1>
    </div>
  );
};

export default WhatsAppMasivoPage;
