import React, { useEffect, useState } from "react";
import { getOrganization } from "../../services/organizationService";
import { IOrganization, IUser } from "../../types/types";
import { Table } from "./UsuariosComponentStyles";

const UsuariosComponent = () => {
  const [organization, setOrganization] = useState<IOrganization>();

  const fetchOrganization = async () => {
    const organizationId =
      localStorage.getItem("organizationId") ||
      sessionStorage.getItem("organizationId");
    if (!organizationId) {
      return;
    }
    const organizacion = await getOrganization(organizationId);
    setOrganization(organizacion);
    console.log(organizacion);
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  return (
    <div>
      <h1>Usuarios de la organización</h1>
      <Table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {organization?.employees.map((employee: IUser) => (
            <tr key={employee._id}>
              <td>
                {employee.firstName} {employee.lastName}
              </td>
              <td>{employee.email}</td>
              <td
                style={
                  organization.employees.length === 1
                    ? { color: "grey" }
                    : {
                        color: "red",
                      }
                }
              >
                Eliminar
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UsuariosComponent;
