import { IProduct } from "../types/types";
import axiosInstance from "./axiosConfig";

// Create a new product
export const createProduct = async (formProduct: any) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.post(
      "/products",
      { ...formProduct, organizationId },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating product");
    throw new Error(error.response?.data?.message || "Error creating product");
  }
};

// Get a single product by ID
export const getProduct = async (productId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get(`/products/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching product");
    throw new Error(error.response?.data?.message || "Error fetching product");
  }
};

// Get all products with pagination
export const getProducts = async (
  limit: number = 10,
  page: number = 0
): Promise<IProduct[]> => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<IProduct[]>("/products", {
      headers: { Authorization: `Bearer ${token}` },
      params: { organizationId, limit, page },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching products");
    throw new Error(error.response?.data?.message || "Error fetching products");
  }
};

// Delete a product by ID
export const deleteProduct = async (productId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.delete(`/products/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting product");
    throw new Error(error.response?.data?.message || "Error deleting product");
  }
};

// Update a product by ID
export const updateProduct = async (productId: string, formProduct: any) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(`/products/${productId}`, formProduct, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating product");
    throw new Error(error.response?.data?.message || "Error updating product");
  }
};

// Search for products based on a term
export const searchProduct = async (search: string) => {
  try {
    const res = await axiosInstance.get("/products/search", {
      params: { search },
    });
    return res.data;
  } catch (error: any) {
    console.log(error.response);
    console.error(error.response?.data?.message || "Error searching product");
    throw new Error(error.response?.data?.message || "Error searching product");
  }
};
