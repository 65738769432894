import { AxiosResponse } from "axios";
import { IForm } from "../types/types";
import axiosInstance from "./axiosConfig";
import { ErrorResponse } from "react-router-dom";

interface IFormResponse {
  formId: string;
  message: string;
}

const getForms = async () => {
  try {
    const response = await axiosInstance.get("/forms");
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

const getForm = async (id: string) => {
  try {
    const response = await axiosInstance.get(`/forms/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

const createForm = async (
  form: IForm
): Promise<AxiosResponse<IFormResponse> | ErrorResponse> => {
  try {
    const response = await axiosInstance.post<IFormResponse>(
      "/forms/create",
      form
    );
    return response; // Aquí response es de tipo AxiosResponse<IFormResponse>
  } catch (error: any) {
    return error.response; // Aquí error.response es de tipo ErrorResponse
  }
};

const deleteForm = async (id: string) => {
  try {
    await axiosInstance.delete(`/forms/${id}`);
  } catch (error) {
    console.error(error);
  }
};

const deleteFormResponses = async (selected: string[]) => {
  try {
    await axiosInstance.delete(`/forms/form-responses`, {
      data: { responseIds: selected },
    });
  } catch (error) {
    console.error("Error al eliminar respuestas:", error);
  }
};

export default {
  getForm,
  getForms,
  createForm,
  deleteForm,
  deleteFormResponses,
};
