import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 430px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  flex-direction: column;
  display: flex;
  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: ${theme.colors.azulOscuro};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  div > h2 {
    font-size: 1.5rem;
    color: white;
  }

  div > a {
    font-size: 1rem;
    color: white;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: ${theme.colors.rosado};
    }
  }

  svg {
    cursor: pointer;
    font-size: 25px;
    path {
      color: ${theme.colors.rosado};

      &:hover {
        color: ${theme.colors.rosadoOscuro};
        transition: ease all 0.3s;
      }
    }
  }
`;

export const FormFooter = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${theme.colors.grisClaro};
  right: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  button:first-child {
    background-color: ${theme.colors.rosado};
    color: white;
  }
  button:last-child {
    background-color: #f1f1f1;

    color: ${theme.colors.rosado};
  }

  button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.rosado};
    margin: 0px 15px;
    font-weight: bold;
    cursor: pointer;
    min-width: 100px;
  }
`;

export const Form = styled.form`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  overflow: auto;
  padding-bottom: 100px;
  height: 90vh;
  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;

    label {
      font-size: 1rem;
      color: #666;
    }

    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
    }
    select {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
    }
  }
`;
