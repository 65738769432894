import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Layout = styled.div`
  display: flex;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;

  h1 {
    color: ${theme.colors.rosado};
  }

  svg {
    margin-right: 10px;
    font-size: 15px;
  }
  path {
    color: ${theme.colors.rosado};
  }
`;

export const FirstColumn = styled.div`
  width: 20%;
  background-color: #ededed;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100vh - 60px);
  padding: 5px;
  margin-right: 15px;
`;

export const FirstColumnHeader = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

export const FirstColumnSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  width: 100%;

  input {
    width: 100%;
    border: none;
    padding: 10px;
    background-color: #ededed;
    outline: none;
  }

  svg {
    color: grey;
    margin-left: 10px;
  }
`;

export const FirstColumnButton = styled.div`
  background-color: ${theme.colors.rosado};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 50px;
  margin-left: 10px;
  cursor: pointer;

  path {
    color: white;
  }
`;

export const FirstColumnProyectList = styled.div`
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }

    label {
      color: grey;
    }
  }
`;

export const SecondColumn = styled.div`
  width: 80%;
`;

export const Button = styled.button`
  background-color: ${theme.colors.rosado};
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const SecondColumnHeader = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid grey;

  div {
    display: flex;
    align-items: center;
  }

  input {
    border: none;
    padding: 10px;
    border-radius: 5px;
  }

  svg {
    margin-right: 10px;
    color: grey;
  }
`;

export const SecondColumnSearch = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid grey;
  width: 400px;
  border-radius: 10px;
  padding: 0px 10px;

  input {
    outline: none;
    width: 100%;
  }
`;

export const HeaderItem = styled.div<{ isActive: boolean }>`
  padding-bottom: 5px;
  margin: 0px;
  color: #767676;
  cursor: pointer;
  margin-right: 20px;
  border-bottom: 1px solid
    ${(props) => (props.isActive ? theme.colors.rosado : "transparent")};
`;

export const CreateProyectInput = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;

  input {
    border: none;
    border-bottom: 1px solid ${theme.colors.rosado};
    width: 100%;
    background-color: transparent;
    padding: 10px;
    outline: none;
  }
  svg {
    cursor: pointer;
  }

  svg:nth-child(3) > path {
    color: ${theme.colors.rosado};
  }
`;
