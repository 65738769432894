import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import formsService from "../../../services/formsService";
import { DeleteButton, Table } from "./FormsStyles";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setAlert } from "../../../redux/slices/alertSlice";
import { FaTrash } from "react-icons/fa";
import CreateContact from "../../Contactos/Contactos/CreateContact";

interface IResponse {
  _id: string;
  responses: {
    [key: string]: string;
  };
  receivedAt: string;
}

interface IFormField {
  fieldName: string;
  fieldType: string;
  required: boolean;
}

interface IForm {
  _id: string;
  fields: IFormField[];
}

const FormDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [responses, setResponses] = useState<IResponse[]>([]);
  const [fields, setFields] = useState<IFormField[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [createContact, setCreateContact] = useState(false);

  const deleteHandler = async () => {
    if (!selected || selected.length === 0) {
      console.log("seleccione");
      dispatch(
        setAlert({
          title: "Error",
          message: "Selecciona al menos una respuesta",
          show: true,
          type: "error",
        })
      );
      return;
    }
    try {
      dispatch(setLoading(true));
      await formsService.deleteFormResponses(selected);
      dispatch(setLoading(false));
      setResponses((prevResponses) =>
        prevResponses.filter((response) => !selected.includes(response._id))
      );
      setSelected([]); // Limpiar la selección después de la eliminación
      dispatch(
        setAlert({
          title: "Éxito",
          message: "Respuestas eliminadas",
          show: true,
          type: "success",
        })
      );
    } catch (error) {
      console.error("Error al eliminar respuestas:", error);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al eliminar respuestas",
          show: true,
          type: "error",
        })
      );
    }
  };

  const handleSelectAll = () => {
    if (selected.length === responses.length) {
      setSelected([]); // Desseleccionar todo
    } else {
      setSelected(responses.map((response) => response._id)); // Seleccionar todo
    }
  };

  const handleSelect = (id: string) => {
    setSelected(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((selectedId) => selectedId !== id) // Desseleccionar
          : [...prevSelected, id] // Seleccionar
    );
  };

  useEffect(() => {
    const getForm = async () => {
      const result = await formsService.getForm(id as string);
      if (result.length > 0) {
        setResponses(result);
        setFields(result[0].formId.fields); // Obtener los campos desde la respuesta
      }
    };
    getForm();
  }, [id]);

  return (
    <div>
      <h1>Respuestas recibidas</h1>
      <DeleteButton onClick={deleteHandler}>
        <FaTrash color="white" />
        Eliminar
      </DeleteButton>
      <button onClick={() => setCreateContact(!createContact)}>
        Crear Contacto
      </button>
      {responses.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <td
                style={{
                  width: "50px",
                }}
              >
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selected.length === responses.length}
                />
              </td>
              {fields.map((field) => (
                <th key={field.fieldName}>{field.fieldName}</th>
              ))}
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody>
            {responses.map((response) => (
              <tr key={response._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selected.includes(response._id)}
                    onChange={() => handleSelect(response._id)}
                  />
                </td>
                {fields.map((field) => (
                  <td key={field.fieldName}>
                    {response.responses[field.fieldName] || "N/A"}
                  </td>
                ))}
                <td>{new Date(response.receivedAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No se han recibido respuestas aún.</p>
      )}

      {createContact && (
        <CreateContact setShowCreateContact={setCreateContact} />
      )}
    </div>
  );
};

export default FormDetail;
