import { TopMenuStyle } from "./TopMenuStyles";
import { useContext } from "react";
import CreateDeals from "./sideComponents/CreateDeals";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";
import { theme } from "../../styles/theme";

const TopMenu = () => {
  const {
    pipelines,
    setSelectedPipeline,
    selectedPipeline,
    handleOpenCreatePipeline,
    debouncedSearch,
    isVisibleCreateDeal,
    setIsVisibleCreateDeal,
    setFilterDates,
    filterDates,
  } = useContext(PipelineContext);

  return (
    <TopMenuStyle>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
          padding: "16px",
          width: "100%",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "40%",
              minWidth: "375px",
              maxWidth: "410px",
              gap: "8px",
            }}
          >
            <label htmlFor="status">Pipeline</label>
            <select
              id="pipelines"
              onChange={(e) => {
                setSelectedPipeline(
                  pipelines.find((pipeline) => pipeline._id === e.target.value)
                );
              }}
              value={selectedPipeline ? selectedPipeline._id : ""}
            >
              <option value={""}>No hay pipelines disponibles</option>
              {pipelines.map((pipeline) => (
                <option key={pipeline._id} value={pipeline._id}>
                  {pipeline.title}
                </option>
              ))}
            </select>
            <span
              style={{
                color: theme.colors.rosado,
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={handleOpenCreatePipeline}
            >
              + Añadir pipeline
            </span>
          </div>
          <input
            type="text"
            name="name"
            id=""
            placeholder="Buscar negocios"
            onChange={debouncedSearch}
          />
          <div>
            <button
              onClick={() => setIsVisibleCreateDeal(!isVisibleCreateDeal)}
            >
              Crear Negocio
            </button>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <label htmlFor="">Fecha Inicial</label>
            <input
              type="date"
              name="startDate"
              id="startDate"
              onChange={(e) =>
                setFilterDates({
                  ...filterDates,
                  startDate: new Date(e.target.value),
                })
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "8px",
            }}
          >
            <label htmlFor="">Fecha Final</label>
            <input
              type="date"
              name="endDate"
              id="endDate"
              onChange={(e) =>
                setFilterDates({
                  ...filterDates,
                  endDate: new Date(e.target.value),
                })
              }
            />
          </div>
        </div>
      </div>
      {isVisibleCreateDeal && <CreateDeals />}
    </TopMenuStyle>
  );
};

export default TopMenu;
