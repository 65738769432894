import axiosInstance from "./axiosConfig";
import {
  DealsFieldsToSend,
  DealDetails,
  Deals,
  Status,
  DealsFields,
  PipelineForm,
  Pipeline,
} from "../types/types";

// Crear un nuevo negocio (deal)
export const createDeal = async (deal: any, fields: DealsFieldsToSend[]) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.post(
      "/deals",
      { deal, fields, organizationId },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating deal");
    throw new Error(error.response?.data?.message || "Error creating deal");
  }
};

// Obtener todos los negocios (deals)
export const getDeals = async (pipelineId: string, name?: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<Deals[]>(
      `/deals?pipelineId=${pipelineId}${name ? "&name=" + name : ""}`,
      {
        headers: { Authorization: `${token}` },
        params: { organizationId },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching deals");
    throw new Error(error.response?.data?.message || "Error fetching deals");
  }
};

// Obtener detalles de un negocio (deal)
export const getDealsDetails = async (dealId: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<DealDetails>(`/deals/${dealId}`, {
      headers: { Authorization: `${token}` },
      params: { organizationId },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error fetching deal details"
    );
    throw new Error(
      error.response?.data?.message || "Error fetching deal details"
    );
  }
};

export const getContactDeals = async (contactId: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<Deals[]>(
      `/deals/contact/${contactId}`,
      {
        headers: { Authorization: `${token}` },
        params: { organizationId },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching deals");
    throw new Error(error.response?.data?.message || "Error fetching deals");
  }
};

// Actualizar un negocio (deal)
export const updateDeal = async (
  dealId: string,
  deal: any,
  fields: DealsFieldsToSend[]
) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.put(
      `/deals/${dealId}`,
      { deal, fields, organizationId },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating deal");
    throw new Error(error.response?.data?.message || "Error updating deal");
  }
};

// Obtener los campos de los negocios (deal fields) de un pipeline
export const getDealsFields = async (pipelineId: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<DealsFields[]>(
      `/deals-fields?pipeline=${pipelineId}`,
      {
        headers: { Authorization: `${token}` },
        params: { organizationId },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error fetching deal fields"
    );
    throw new Error(
      error.response?.data?.message || "Error fetching deal fields"
    );
  }
};

// Añadir un nuevo campo a los negocios (deal field)
export const addDealsField = async (field: {
  key: string;
  name: string;
  pipeline: string;
}) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(
      "/deals-fields/create",
      { ...field, token },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error adding deal field");
    throw new Error(error.response?.data?.message || "Error adding deal field");
  }
};

// Eliminar un campo de los negocios (deal field)
export const removeDealsField = async (fieldId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.delete(`/deals-fields/delete/${fieldId}`, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting deal field");
    throw new Error(
      error.response?.data?.message || "Error deleting deal field"
    );
  }
};

// Editar un campo de los negocios (deal field)
export const editDealsField = async (
  fieldId: string,
  field: {
    key: string;
    name: string;
    pipeline: string;
  }
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(
      `/deals-fields/edit/${fieldId}`,
      { ...field, token },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error editing deal field");
    throw new Error(
      error.response?.data?.message || "Error editing deal field"
    );
  }
};

// Cambiar el estado de un negocio (deal status)
export const changeDealStatus = async (dealId: string, status: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(
      `/deals/status/${dealId}`,
      { status, token },
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error changing deal status"
    );
    throw new Error(
      error.response?.data?.message || "Error changing deal status"
    );
  }
};

// Crear un nuevo pipeline
export const createPipeline = async (form: PipelineForm) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post("/pipelines", form, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating pipeline");
    throw new Error(error.response?.data?.message || "Error creating pipeline");
  }
};

// Obtener los estados de un pipeline
export const getStatus = async (pipelineId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get<Status[]>(
      `/status?pipelineId=${pipelineId}`,
      {
        headers: { Authorization: `${token}` },
        params: { organizationId: localStorage.getItem("organizationId") },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching statuses");
    throw new Error(error.response?.data?.message || "Error fetching statuses");
  }
};

// Editar los estados por pipeline
export const editStatusByPipelines = async (
  pipelineId: string,
  statesToEdit: Status[]
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put<Status[]>(
      `/status/pipeline/${pipelineId}`,
      statesToEdit,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error editing statuses");
    throw new Error(error.response?.data?.message || "Error editing statuses");
  }
};

// Obtener todos los pipelines
export const getPipelines = async () => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get<Pipeline[]>("/pipelines", {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching pipelines");
    throw new Error(
      error.response?.data?.message || "Error fetching pipelines"
    );
  }
};
