import React, { useContext } from "react";
import {
  Button,
  DetailArea,
  FormGroup,
  Layout,
  TextAreaGroup,
} from "../common/CommonStyles";
import { CampanaContext } from "../context/CampanaContext";

const Publico = () => {
  const { setActiveStep, setNewCampana, newCampana } =
    useContext(CampanaContext);

  // Función para manejar cambios en los inputs
  const handleInputChange = (field: string, value: any) => {
    setNewCampana((prevCampana: any) => ({
      ...prevCampana,
      public: {
        ...prevCampana.public,
        [field]: value,
      },
    }));
  };

  return (
    <div>
      <Layout>
        <div
          style={{
            width: "40%",
          }}
        >
          <TextAreaGroup>
            <label>Demográficos (Edad, género, ingreso)</label>
            <textarea
              name="demographics"
              value={newCampana?.public?.demographics || ""}
              onChange={(e) =>
                handleInputChange("demographics", e.target.value)
              }
            ></textarea>
          </TextAreaGroup>
          <TextAreaGroup>
            <label>Intereses</label>
            <textarea
              name="interests"
              value={newCampana?.public?.interests || ""}
              onChange={(e) => handleInputChange("interests", e.target.value)}
            ></textarea>
          </TextAreaGroup>
          <FormGroup>
            <label>Ubicación</label>
            <input
              type="text"
              name="location"
              value={newCampana?.public?.location || ""}
              onChange={(e) => handleInputChange("location", e.target.value)}
            />
          </FormGroup>
          <div>
            <label htmlFor="">Origen de los contactos</label>

            <div>
              <input
                type="checkbox"
                name="contactSource"
                checked={newCampana?.public?.contactSource === "Sin Contactos"}
                onChange={(e) =>
                  handleInputChange(
                    "contactSource",
                    e.target.checked ? "Sin Contactos" : ""
                  )
                }
              />
              <label>Sin Contactos</label>
            </div>
            <div>
              <input
                type="checkbox"
                name="contactSource"
                checked={
                  newCampana?.public?.contactSource === "Lista de contactos"
                }
                onChange={(e) =>
                  handleInputChange(
                    "contactSource",
                    e.target.checked ? "Lista de contactos" : ""
                  )
                }
              />
              <label>Lista de contactos</label>
            </div>
          </div>
          <Button onClick={() => setActiveStep("Plataforma")}>Continuar</Button>
        </div>
        <DetailArea>
          <h2>Descripción del input</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatem, quod, quae, quas quos quia nesciunt voluptates
            repudiandae, quibusdam tempore doloremque.
          </p>
        </DetailArea>
      </Layout>
    </div>
  );
};

export default Publico;
