import { Modal, ModalContent } from "./CreatePipelineStyles";
import React, { useContext, useEffect, useState } from "react";
import { DealsFieldsTable, Icon } from "./DealsFieldsStyles";
import { FaCheck, FaPen, FaTrash } from "react-icons/fa";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";
import { theme } from "../../styles/theme";

export const EditDealsFields = () => {
  const formRef = React.useRef<HTMLDivElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const {
    dealsFieldsByPipeline,
    handleOpenEditDealsFields,
    handleAddDealField,
    handleDeleteField,
    handleEditDealField,
  } = useContext(PipelineContext);

  const [newField, setNewField] = useState<{ name: string }>({
    name: "",
  });
  const [fieldToEdit, setFieldToEdit] = useState<{
    name: string;
    key: string;
    _id: string;
    required: boolean; // Agregamos la propiedad 'required'
  }>({
    name: "",
    key: "",
    _id: "",
    required: false,
  });

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isAddingDealField, setIsAddingDealField] = useState<boolean>(false);

  const [dealFields, setDealFields] = useState<
    { name: string; key: string; _id: string; required: boolean }[]
  >(
    dealsFieldsByPipeline.map((field) => ({
      name: field.name,
      key: field.key,
      _id: field._id,
      required: field.required,
    }))
  );

  const handleAddState = () => {
    setIsAddingDealField(true);
  };

  const handleIsEditing = (field: {
    name: string;
    key: string;
    _id: string;
    required: boolean;
  }) => {
    setIsEditing(!isEditing);
    setFieldToEdit(field);
  };

  const handleCancelAddState = () => {
    setIsAddingDealField(false);
    setNewField({ name: "" });
  };

  const handleRequiredChange = () => {
    setFieldToEdit((prev) => ({ ...prev, required: !prev.required }));
  };

  useEffect(() => {
    const handleClickOutsideChild = (event: MouseEvent) => {
      const parentElement = modalRef.current;
      const childElement = formRef.current;

      if (parentElement && childElement) {
        if (
          parentElement.contains(event.target as Node) &&
          !childElement.contains(event.target as Node)
        ) {
          handleOpenEditDealsFields();
        }
      }
    };

    document.addEventListener("click", handleClickOutsideChild);

    return () => {
      document.removeEventListener("click", handleClickOutsideChild);
    };
  }, [handleOpenEditDealsFields]);

  useEffect(() => {
    setDealFields(
      dealsFieldsByPipeline.map((field) => ({
        name: field.name,
        key: field.key,
        _id: field._id,
        required: field.required,
      }))
    );
    setIsEditing(false);
    setIsAddingDealField(false);
    setNewField({ name: "" });
    setFieldToEdit({
      name: "",
      key: "",
      _id: "",
      required: false,
    });
  }, [dealsFieldsByPipeline]);

  return (
    <Modal ref={modalRef}>
      <ModalContent
        style={{
          width: "600px",
        }}
        ref={formRef}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            handleOpenEditDealsFields();
          }}
        >
          <h2
            style={{
              marginBottom: -20,
            }}
          >
            Propiedades personalizadas
          </h2>

          <div>
            <DealsFieldsTable>
              <thead>
                <tr>
                  <th
                    style={{
                      width: "200px",
                    }}
                  >
                    Nombre
                  </th>
                  <th
                    style={{
                      width: "200px",
                    }}
                  >
                    Key
                  </th>
                  <th
                    style={{
                      width: "200px",
                    }}
                  >
                    Required
                  </th>
                  <th
                    style={{
                      width: "50px",
                    }}
                  >
                    Editar
                  </th>
                  <th
                    style={{
                      width: "50px",
                    }}
                  >
                    Eliminar
                  </th>
                </tr>
              </thead>
              <tbody>
                {dealFields.map((field, index) => (
                  <tr key={index}>
                    <td>
                      {fieldToEdit._id === field._id && isEditing ? (
                        <input
                          type="text"
                          value={fieldToEdit.name}
                          onChange={(e) =>
                            setFieldToEdit({
                              ...fieldToEdit,
                              name: e.target.value,
                            })
                          }
                        />
                      ) : (
                        <span>{field.name}</span>
                      )}
                    </td>
                    <td>
                      {fieldToEdit._id === field._id && isEditing ? (
                        <input
                          type="text"
                          value={fieldToEdit.name
                            .toLowerCase()
                            .replace(/[^a-zA-Z0-9_]/g, "_")}
                          disabled
                        />
                      ) : (
                        <span>{field.key}</span>
                      )}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {fieldToEdit._id === field._id && isEditing ? (
                          <input
                            type="checkbox"
                            checked={fieldToEdit.required}
                            onChange={handleRequiredChange}
                          />
                        ) : (
                          <span>{field.required ? "true" : "false"}</span>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          cursor: isAddingDealField ? "default" : "pointer",
                        }}
                        onClick={
                          isAddingDealField || isEditing
                            ? fieldToEdit._id === field._id
                              ? () => {
                                  handleEditDealField({
                                    _id: field._id,
                                    name: fieldToEdit.name,
                                    key: fieldToEdit.name
                                      .toLowerCase()
                                      .replace(/[^a-zA-Z0-9_]/g, "_"),
                                    required: fieldToEdit.required,
                                  });
                                  setIsEditing(false); // Salir del modo de edición
                                }
                              : () => {}
                            : () => handleIsEditing(field)
                        }
                      >
                        {isAddingDealField || isEditing ? (
                          <>
                            {fieldToEdit._id === field._id && isEditing ? (
                              <Icon>
                                <FaCheck />
                              </Icon>
                            ) : (
                              <FaPen />
                            )}
                          </>
                        ) : (
                          <Icon>
                            <FaPen />
                          </Icon>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          cursor: isAddingDealField ? "default" : "pointer",
                        }}
                        onClick={
                          !isAddingDealField
                            ? () => handleDeleteField(field._id)
                            : () => {}
                        }
                      >
                        {isAddingDealField || isEditing ? (
                          <FaTrash />
                        ) : (
                          <Icon>
                            <FaTrash />
                          </Icon>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </DealsFieldsTable>
            {isAddingDealField && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "16px",
                }}
              >
                <input
                  type="text"
                  placeholder="Nombre"
                  style={{
                    width: "200px",
                    marginRight: "16px",
                  }}
                  onChange={(e) => setNewField({ name: e.target.value })}
                  value={newField.name}
                />
                <input
                  type="text"
                  placeholder="Key"
                  style={{
                    width: "200px",
                    marginRight: "16px",
                  }}
                  disabled
                  value={newField.name
                    .toLowerCase()
                    .replace(/[^a-zA-Z0-9_]/g, "_")}
                />
                <button
                  style={{
                    margin: 0,
                  }}
                  type="button"
                  onClick={() => {
                    handleAddDealField({
                      name: newField.name,
                      key: newField.name
                        .toLowerCase()
                        .replace(/[^a-zA-Z0-9_]/g, "_"),
                      required: false, // Por defecto no requerido
                    });
                    setIsAddingDealField(false);
                  }}
                >
                  Guardar
                </button>
                <button
                  style={{
                    marginLeft: 8,
                  }}
                  type="button"
                  onClick={handleCancelAddState}
                >
                  Cancelar
                </button>
              </div>
            )}
          </div>
          <span
            style={{
              color: theme.colors.rosado,
              cursor: "pointer",
              marginTop: "16px",
            }}
            onClick={handleAddState}
          >
            + Añadir propiedad
          </span>
          <button>Cerrar</button>
        </form>
      </ModalContent>
    </Modal>
  );
};
