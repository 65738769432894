import React, { useContext } from "react";
import { FaTrash } from "react-icons/fa";
import { MenuOptionsContainer } from "./MenuOptionsStyles";
import { ProjectContext } from "../context/ProjectContext";

const MenuOptions = ({ taskId }: any) => {
  const { deleteTask } = useContext(ProjectContext);

  const deleteHandler = () => {
    deleteTask(taskId);
  };

  return (
    <MenuOptionsContainer>
      <ul>
        <li
          onClick={() => {
            deleteHandler();
          }}
        >
          <FaTrash /> Eliminar
        </li>
      </ul>
    </MenuOptionsContainer>
  );
};

export default MenuOptions;
