import React from "react";
import { InputLabel, Properties } from "./ContactDetailStyles";
import { useSelector } from "react-redux";

interface Property {
  key: string;
  label: string;
  isVisible: boolean;
}

interface Contact {
  properties: { key: string; value: string }[];
}

interface ColumnDetailsProps {
  contact: Contact | null;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (key: string, value: string) => void;
  form: Record<string, string>;
}

const ColumnDetails: React.FC<ColumnDetailsProps> = ({
  contact,
  setIsEditing,
  handleChange,
  form,
}) => {
  const properties = useSelector(
    (state: any) => state.organization.contactProperties
  ) as Property[];

  if (!contact || !properties) {
    return <p>Cargando...</p>;
  }

  return (
    <div
      style={{
        marginTop: "30px",
        height: "100%",
        overflow: "auto",
      }}
    >
      <h4 style={{ fontWeight: "300", textAlign: "center" }}>
        Información del contacto
      </h4>
      <Properties>
        {properties
          .filter((property) => property.isVisible) // Solo mostrar propiedades visibles
          .map((property) => {
            const contactProperty = contact.properties.find(
              (props) => props.key === property.key
            );

            return (
              <InputLabel key={property.key}>
                <label htmlFor="">{property.label}</label>
                <input
                  type="text"
                  value={
                    form[property.key] !== undefined
                      ? form[property.key]
                      : contactProperty?.value || ""
                  }
                  onChange={(e) => {
                    setIsEditing(true);
                    handleChange(property.key, e.target.value);
                  }}
                />
              </InputLabel>
            );
          })}
      </Properties>
    </div>
  );
};

export default ColumnDetails;
