import React, { useContext } from "react";
import {
  Button,
  DetailArea,
  FormGroup,
  Layout,
  TextAreaGroup,
} from "../common/CommonStyles";
import { CampanaContext } from "../context/CampanaContext";

const Informacion = () => {
  const { setActiveStep, newCampana, handleChange } =
    useContext(CampanaContext);

  return (
    <Layout>
      <div style={{ width: "40%" }}>
        <FormGroup>
          <label htmlFor="name">Nombre de la Campaña</label>
          <input
            type="text"
            name="name"
            id="name"
            value={newCampana?.name || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Estado de la campaña</label>
          <select
            name="state"
            id="state"
            value={newCampana?.state || ""}
            onChange={handleChange}
          >
            <option value="">- Selecciona un estado -</option>
            <option value="En Planeacion">En Planeacion</option>
            <option value="En Progreso">En Progreso</option>
            <option value="Completada">Completada</option>
            <option value="Cancelada">Cancelada</option>
          </select>
        </FormGroup>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup>
            <label htmlFor="startDate">Fecha de Inicio</label>
            <input
              type="date"
              name="startDate"
              id="startDate"
              value={newCampana?.startDate || ""}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="endDate">Fecha de Fin</label>
            <input
              type="date"
              name="endDate"
              id="endDate"
              value={newCampana?.endDate || ""}
              onChange={handleChange}
            />
          </FormGroup>
        </div>
        <FormGroup>
          <label htmlFor="budget">Presupuesto de la campaña</label>
          <input
            type="text"
            name="budget"
            id="budget"
            value={newCampana?.budget || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <TextAreaGroup>
          <label htmlFor="objective">Objetivo</label>
          <textarea
            name="objective"
            id="objective"
            cols={30}
            rows={10}
            value={newCampana?.objective || ""}
            onChange={handleChange}
          ></textarea>
        </TextAreaGroup>
        <TextAreaGroup>
          <label htmlFor="notes">Notas</label>
          <textarea
            name="notes"
            id="notes"
            cols={30}
            rows={10}
            value={newCampana?.notes || ""}
            onChange={handleChange}
          ></textarea>
        </TextAreaGroup>
        <Button onClick={() => setActiveStep("Publico")}>Continuar</Button>
      </div>
      <DetailArea>
        <h2>Descripcion del input</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
          voluptatem, quod, quae, quas quos quia nesciunt voluptates
          repudiandae, quibusdam tempore doloremque. Quisquam voluptatem, quod,
          quae, quas quos quia nesciunt voluptates repudiandae, quibusdam
          tempore doloremque.
        </p>
      </DetailArea>
    </Layout>
  );
};

export default Informacion;
