import React, { useEffect, useState } from "react";
import { Form } from "./ConexionApiStyles";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/slices/alertSlice";
import {
  getOrganization,
  updateOrganization,
} from "../../services/organizationService";
import { IOrganization } from "../../types/types";

const ConexionApi = () => {
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState<IOrganization | null>(null);
  const [accessToken, setAccessToken] = useState("");
  const [numberIdIdentifier, setNumberIdIdentifier] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const fetchOrganization = async () => {
      const organizationId =
        localStorage.getItem("organizationId") ||
        sessionStorage.getItem("organizationId");
      if (!organizationId) {
        dispatch(
          setAlert({
            title: "Error",
            message: "ID de organización no encontrado",
            type: "error",
            show: true,
          })
        );
        return;
      }
      try {
        const organization = await getOrganization(organizationId);
        setOrganization(organization);
        setAccessToken(organization.settings?.whatsapp?.accessToken || "");
        setNumberIdIdentifier(
          organization.settings?.whatsapp?.numberIdIdentifier || ""
        );
        setPhoneNumber(organization.settings?.whatsapp?.phoneNumber || "");
      } catch (error) {
        console.error("Error al obtener la organización:", error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Error al obtener la organización",
            type: "error",
            show: true,
          })
        );
      }
    };

    fetchOrganization();
  }, [dispatch]);

  const validatePhoneNumber = (phoneNumber: string): boolean => {
    const phoneNumberRegex = /^[1-9][0-9]{9,14}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!accessToken || !numberIdIdentifier || !phoneNumber) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Todos los campos son obligatorios",
          type: "error",
          show: true,
        })
      );
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      dispatch(
        setAlert({
          title: "Error",
          message: "El formato del número de teléfono no es válido.",
          type: "error",
          show: true,
        })
      );
      return;
    }

    if (!organization) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Organización no encontrada",
          type: "error",
          show: true,
        })
      );
      return;
    }

    try {
      const updatedOrganization = {
        ...organization,
        settings: {
          ...organization.settings,
          whatsapp: {
            accessToken,
            numberIdIdentifier,
            phoneNumber,
          },
        },
      };

      await updateOrganization(updatedOrganization);
      dispatch(
        setAlert({
          title: "Éxito",
          message: "Datos de conexión actualizados con éxito",
          type: "success",
          show: true,
        })
      );
    } catch (error) {
      console.error("Error al actualizar los datos de conexión:", error);
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al actualizar los datos de conexión",
          type: "error",
          show: true,
        })
      );
    }
  };

  return (
    <div>
      <h1>Conexión Api</h1>

      <Form onSubmit={handleSubmit}>
        <h3>WhatsApp</h3>
        <hr />
        <div>
          <label htmlFor="accessToken">Access Token</label>
          <input
            type="text"
            name="accessToken"
            id="accessToken"
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="numberIdIdentifier">
            Number Id Identifier (Ej: 409865115537474)
          </label>
          <input
            type="text"
            name="numberIdIdentifier"
            id="numberIdIdentifier"
            value={numberIdIdentifier}
            onChange={(e) => setNumberIdIdentifier(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="phoneNumber">Phone Number (Ej: 573163452862)</label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <button type="submit">Actualizar</button>
      </Form>
    </div>
  );
};

export default ConexionApi;
