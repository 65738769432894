import React, { useContext, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import BusinessCard from "./Card";
import { FaBars, FaColumns } from "react-icons/fa";

import { DealsTable, TableActions } from "./NegociosStyles";
import { changeDealStatus } from "../../services/dealService";
import { theme } from "../../styles/theme";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";
import { formatCurrency } from "../../utils/formatCurrency";

export enum TableMode {
  Grid,
  List,
}

const TableWithDragAndDrop: React.FC = () => {
  const [_isDragging, setIsDragging] = useState<boolean>(false);
  const [tableMode, setTableMode] = useState<TableMode>(
    localStorage.getItem("tableMode") === TableMode.List.toString()
      ? TableMode.List
      : TableMode.Grid
  );

  const { deals, setDeals, states, getDealDetail } =
    useContext(PipelineContext);

  const onDragEnd = async (result: DropResult) => {
    setIsDragging(false);
    if (!result.destination) return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return;
    const { destination } = result;
    setDeals((prevDeals) => {
      const newDeals = Array.from(prevDeals);

      const dragIndex = prevDeals.findIndex(
        (deal) => deal._id === result.draggableId
      );

      newDeals[dragIndex].status = destination!.droppableId;

      return newDeals;
    });
    await changeDealStatus(result.draggableId, destination!.droppableId);
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const handleModeGrid = () => {
    setTableMode(TableMode.Grid);
    localStorage.setItem("tableMode", TableMode.Grid.toString());
  };

  const handleModeList = () => {
    setTableMode(TableMode.List);
    localStorage.setItem("tableMode", TableMode.List.toString());
  };

  return (
    <>
      <TableActions>
        <button onClick={handleModeGrid}>
          <FaColumns />
        </button>
        <button onClick={handleModeList}>
          <FaBars />
        </button>
      </TableActions>
      {tableMode === TableMode.Grid && (
        <>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <div
              style={{
                display: "flex",
                gap: "12px",
                flexDirection: "row",
              }}
            >
              {states.map((state) => {
                const stateDeals = deals.filter(
                  (deal) => deal.status === state._id
                );
                let totalAmount = 0;
                stateDeals.forEach((deal) => {
                  totalAmount += Number(deal.amount);
                });
                const totalDeals = stateDeals.length;
                return (
                  <div
                    key={state._id}
                    style={{
                      width: "100%",
                      borderLeft: "1px solid",
                      borderRight: "1px solid",
                      borderBottom: "1px solid",
                      borderColor: theme.colors.grisClaro,
                    }}
                  >
                    <h2
                      style={{
                        boxShadow: "0px 4px 4px 0px #00000040",
                        backgroundColor: theme.colors.grisClaro,
                        height: "67px",
                        fontSize: "17px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        padding: "0 10px",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <span>{state.name}</span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          fontWeight: "lighter",
                        }}
                      >
                        <span>{formatCurrency(totalAmount.toString())}</span>
                        <span>{totalDeals} Neg</span>
                      </div>
                    </h2>
                    <Droppable droppableId={state._id!} key={state._id}>
                      {(droppableProvided) => (
                        <div
                          {...droppableProvided.droppableProps}
                          ref={droppableProvided.innerRef}
                          style={{
                            minWidth: "200px",
                            padding: "8px",
                            width: "100%",
                            height: "500px",
                            overflowY: "auto",
                          }}
                        >
                          {deals
                            .filter((deal) => deal.status === state._id)
                            .map((deal, i) => (
                              <Draggable
                                draggableId={deal._id}
                                index={i}
                                key={deal._id}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    onClick={() => getDealDetail(deal._id)}
                                    {...provided.draggableProps}
                                    style={{
                                      userSelect: "none",
                                      padding: "8px",
                                      margin: "0px 0px 8px 0px",
                                      background: snapshot.isDragging
                                        ? theme.colors.rosado
                                        : "white", // Cambia el color durante el arrastre
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <div {...provided.dragHandleProps}>
                                      <BusinessCard
                                        tableMode={tableMode}
                                        business={deal}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {droppableProvided?.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                );
              })}
            </div>
          </DragDropContext>
        </>
      )}
      {tableMode === TableMode.List && (
        <>
          <DealsTable>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Monto</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {deals.map((deal) => {
                return (
                  <tr
                    key={deal._id}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => getDealDetail(deal._id)}
                  >
                    <td>{deal.title}</td>
                    <td>{deal.amount}</td>
                    <td>
                      {states.find((state) => state._id === deal.status)?.name}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </DealsTable>
        </>
      )}
    </>
  );
};

export default TableWithDragAndDrop;
