import React, { useState, useEffect } from "react";
import {
  createFragment,
  updateFragment,
} from "../../../services/fragmentService";
import {
  Button,
  ButtonContainer,
  CloseButton,
  Form,
  Input,
  Label,
  ModalBackground,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  TextArea,
} from "./FragmentStyles";

interface CreateFragmentProps {
  setShowCreateFragment: React.Dispatch<React.SetStateAction<boolean>>;
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  affected: boolean;
  selectedFragment?: any; // Prop para fragmento seleccionado
}

const CreateFragment: React.FC<CreateFragmentProps> = ({
  setShowCreateFragment,
  setAffected,
  affected,
  selectedFragment,
}) => {
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [atajo, setAtajo] = useState("");

  useEffect(() => {
    if (selectedFragment) {
      setName(selectedFragment.name);
      setText(selectedFragment.text);
      setAtajo(selectedFragment.atajo);
    }
  }, [selectedFragment]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (selectedFragment) {
        // Si hay un fragmento seleccionado, actualizamos
        await updateFragment(selectedFragment._id, {
          name,
          text,
          atajo,
        });
      } else {
        // Si no hay fragmento seleccionado, creamos uno nuevo
        await createFragment({
          name,
          text,
          atajo,
          userId: "",
          organizationId: "",
        });
      }
      setAffected(!affected);
      setShowCreateFragment(false);
    } catch (error) {
      console.error("Error creating/updating fragment:", error);
    }
  };

  return (
    <ModalBackground>
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>
            {selectedFragment ? "Editar Fragmento" : "Crear Fragmento"}
          </ModalTitle>
          <CloseButton onClick={() => setShowCreateFragment(false)}>
            ×
          </CloseButton>
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <div>
            <Label htmlFor="name">Nombre</Label>
            <Input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="text">Texto</Label>
            <TextArea
              id="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="atajo">Atajo</Label>
            <p style={{ fontSize: "12px" }}>
              Para usar un fragmento, escribe el símbolo de numeral (#) seguido
              del atajo que introdujiste arriba. El fragmento aparecerá en el
              editor de texto.
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "15px 0px",
                border: "1px solid #ccc",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <span style={{ fontSize: "20px", margin: "0" }}>#</span>
              <input
                type="text"
                id="atajo"
                value={atajo}
                onChange={(e) => setAtajo(e.target.value)}
                style={{
                  padding: "8px",
                  width: "100%",
                  border: "none",
                  outline: "none",
                }}
              />
            </div>
          </div>
          <ButtonContainer>
            <Button type="submit">
              {selectedFragment ? "Actualizar" : "Crear"}
            </Button>
          </ButtonContainer>
        </Form>
      </ModalContainer>
    </ModalBackground>
  );
};

export default CreateFragment;
