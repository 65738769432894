import React, { useState } from "react";
import { updateUserPassword } from "../../services/userService";
import { Button, Form } from "./SeguridadComponentStyles";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/slices/alertSlice";

const SeguridadComponent = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();

  const validarFormulario = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      dispatch(
        setAlert({
          title: "Ups! hay un problema",
          message: "Todos los campos son obligatorios",
          type: "error",
          show: true,
        })
      );
      return false;
    }

    if (newPassword !== confirmPassword) {
      dispatch(
        setAlert({
          title: "Ups! hay un problema",
          message: "Las contraseñas no coinciden",
          type: "error",
          show: true,
        })
      );
      return false;
    }

    return true;
  };

  const actualizarUsuario = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validarFormulario()) {
      return;
    }

    try {
      await updateUserPassword({ oldPassword, newPassword });
      dispatch(
        setAlert({
          title: "Contraseña actualizada",
          message: "Contraseña actualizada con éxito",
          type: "success",
          show: true,
        })
      );
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      dispatch(
        setAlert({
          title: "Ups! hay un problema",
          message: "Verifica tu contraseña anterior",
          type: "error",
          show: true,
        })
      );
    }
  };

  return (
    <div>
      <h1>Seguridad</h1>
      <Form onSubmit={actualizarUsuario}>
        <div>
          <label htmlFor="oldPassword">Contraseña Anterior</label>
          <input
            type="password"
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="newPassword">Nueva Contraseña</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="confirmPassword">Confirmar Contraseña</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <Button type="submit">Cambiar Contraseña</Button>
      </Form>
    </div>
  );
};

export default SeguridadComponent;
