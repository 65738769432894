import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { PipelineForm } from "./CreatePipeline";
import { theme } from "../../styles/theme";

export interface States {
  name: string;
  order: number;
}

interface Props {
  setForm: React.Dispatch<React.SetStateAction<PipelineForm>>;
}

export const StatesTable = ({ setForm }: Props) => {
  const [isAddingState, setIsAddingState] = useState<boolean>(false);
  const [states, setStates] = useState<States[]>([
    {
      name: "Por hacer",
      order: 1,
    },
    {
      name: "En proceso",
      order: 2,
    },
    {
      name: "Finalizado",
      order: 3,
    },
  ]);
  const [newStateName, setNewStateName] = useState<string>("");

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return;
    const { source, destination } = result;

    const newStates = Array.from(states);
    const sourceState = newStates[source.index];

    newStates.splice(source.index, 1);
    newStates.splice(destination.index, 0, sourceState);

    setStates(
      newStates.map((state, index) => {
        return {
          ...state,
          order: index + 1,
        };
      })
    );
  };

  const onDragStart = () => {
    console.log("dragging");
  };

  const handleAddState = () => {
    setIsAddingState(true);
  };

  const handleAddStateSubmit = () => {
    setStates((prevStates) => {
      return [
        ...prevStates,
        {
          name: newStateName,
          order: prevStates.length + 1,
        },
      ];
    });
    setIsAddingState(false);
  };

  const handleRemoveState = (index: number) => {
    setStates((prevStates) => {
      return prevStates
        .filter((_, i) => i !== index)
        .map((state, index) => {
          return {
            ...state,
            order: index + 1,
          };
        });
    });
  };

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        states: states,
      };
    });
  }, [states, setForm]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div style={{ display: "flex", gap: "4px", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            gap: 30,
          }}
        >
          <h4>Orden</h4>
          <h4>Estado</h4>
        </div>
        <Droppable droppableId={"list"} key={"list"}>
          {(droppableProvided) => (
            <div
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={{ width: "100%" }}
            >
              {states.map((state, index) => (
                <Draggable
                  draggableId={state.order.toString()}
                  index={index}
                  key={state.order.toString()}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        border: "1px solid black",
                        backgroundColor: snapshot.isDragging
                          ? "lightgreen"
                          : "white",
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        ...provided.draggableProps.style,
                      }}
                      {...provided.dragHandleProps}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          marginRight: "4px",
                          borderRight: "1px solid black",
                          height: "40px",
                          width: "100px",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {index + 1}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        {state.name}
                      </div>
                      <button
                        style={{
                          width: "40px",
                          minWidth: "40px",
                          padding: 0,
                          margin: 0,
                          borderRadius: 0,
                        }}
                        type="button"
                        onClick={() => handleRemoveState(index)}
                      >
                        X
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided?.placeholder}
            </div>
          )}
        </Droppable>
        {!isAddingState && (
          <span
            style={{
              color: theme.colors.rosado,
              cursor: "pointer",
              marginTop: "16px",
            }}
            onClick={handleAddState}
          >
            + Añadir estado
          </span>
        )}
        {isAddingState && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "16px",
            }}
          >
            <input
              type="text"
              placeholder="Nombre del estado"
              onChange={(e) => {
                setNewStateName(e.target.value);
              }}
            />
            <button
              disabled={!newStateName.length}
              onClick={handleAddStateSubmit}
            >
              Añadir
            </button>
          </div>
        )}
      </div>
    </DragDropContext>
  );
};
