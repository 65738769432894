import styled from "styled-components";
import { theme } from "styles/theme";

export const FormGroup = styled.div`
  width: 100%;

  label {
    font-weight: 300;
    margin-bottom: 5px;
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
`;

export const Layout = styled.div`
  display: flex;
  height: calc(100vh - 400px);
  margin-top: 20px;
  overflow: auto;
`;

export const DetailArea = styled.div`
  background-color: ${theme.colors.gris3};
  padding: 20px;
  border-radius: 5px;
  margin-left: 20px;
  height: 100%;
  width: 60%;
`;

export const TextAreaGroup = styled.div`
  label {
    font-weight: 300;
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100px;
  }
`;

export const Button = styled.button`
  background-color: ${theme.colors.rosado};
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 20px;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    cursor: pointer;
  }
`;

export const ButtonOutline = styled.button`
  background-color: white;
  color: ${theme.colors.rosado};
  padding: 10px 15px;
  border: 1px solid ${theme.colors.rosado};
  border-radius: 5px;
  margin-right: 10px;

  &:hover {
    background-color: ${theme.colors.rosado};
    color: white;
    cursor: pointer;
  }
`;

export const CheckboxGroup = styled.div`
  background-color: white;
  padding: 20px;
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 20px;
  margin-top: 20px;

  h3 {
    margin: 20px 0;
  }
  label {
    font-weight: 300;
    margin-bottom: 5px;
  }

  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    input {
      margin-right: 10px;
    }
  }
`;
