import {
  addDealsField,
  createDeal,
  deleteDeal,
  editDealsField,
  editStatusByPipelines,
  getContacts,
  getDeals,
  getDealsDetails,
  getDealsFields,
  getPipelines,
  getStatus,
  removeDealsField,
  updateDeal,
} from "../../../../services/pipelineService";
import React, { useCallback, useEffect, useState } from "react";
import { IPipelineContext, Pipeline } from "../context/PipelineContext";
import debounce from "debounce";
import {
  DealForm,
  Deals,
  DealsFields,
  DealsFieldsToSend,
  IContact,
  Status,
} from "../../../../types/types";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices/loadingSlice";
import { setAlert } from "../../../../redux/slices/alertSlice";

export const usePipelines = (
  shouldFetchDeals = true,
  shouldFetchPipelines = true
): IPipelineContext => {
  const dispatch = useDispatch();
  const [states, setStates] = useState<Status[]>([]);
  const [deals, setDeals] = useState<Deals[]>([]);
  const [pipelines, setPipelines] = useState<Pipeline[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<Pipeline>();
  const [openCreatePipeline, setOpenCreatePipeline] = useState<boolean>(false);
  const [statesToEdit, setStatesToEdit] = useState<Status[]>([]);
  const [openEditStates, setOpenEditStates] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [isVisibleCreateDeal, setIsVisibleCreateDeal] = useState(false);
  const [filterDates, setFilterDates] = useState<any>({
    startDate: "",
    endDate: "",
  });
  const [form, setForm] = useState<DealForm>({
    title: "",
    amount: 0,
    closingDate: "",
    pipeline: "",
    status: "",
    associatedContactId: {
      _id: "",
      properties: [],
      organizationId: "",
      createdAt: "",
      updatedAt: "",
      name: "",
      EmployeeOwner: [],
    },
  });
  const [dealsFieldsByPipeline, setDealsFieldsByPipeline] = useState<
    DealsFields[]
  >([]);
  const [fields, setFields] = useState<DealsFieldsToSend[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [dealId, setDealId] = useState<string>("");
  const [openEditDealsFields, setOpenEditDealsFields] =
    useState<boolean>(false);
  const [contacts, setContacts] = useState<IContact[]>([]);

  const handleOpenCreatePipeline = () => {
    setOpenCreatePipeline(!openCreatePipeline);
  };

  const handleOpenEditDealsFields = () => {
    setOpenEditDealsFields(!openEditDealsFields);
  };

  const handleOpenEditStates = useCallback(() => {
    setStatesToEdit(states);
    setOpenEditStates(!openEditStates);
  }, [openEditStates, states]);

  const getStatusState = useCallback(
    async (loading: boolean = false) => {
      if (!selectedPipeline) return;
      dispatch(setLoading(true));

      try {
        const status: Status[] = await getStatus(selectedPipeline?._id);
        status.sort((a, b) => a.order - b.order);
        setStates(status);
        setStatesToEdit(status);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [selectedPipeline]
  );

  const getDealsState = useCallback(
    async (loading: boolean = false, name?: string) => {
      if (!selectedPipeline) return;
      dispatch(setLoading(true));

      try {
        const deals = await getDeals(selectedPipeline?._id, name, filterDates);

        setDeals(deals);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [selectedPipeline, filterDates]
  );

  const editStatesByPipeline = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      await editStatusByPipelines(selectedPipeline?._id!, statesToEdit);
      await getStatusState(true);
      await getDealsState(true);
      handleOpenEditStates();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [
    selectedPipeline,
    statesToEdit,
    getStatusState,
    handleOpenEditStates,
    getDealsState,
  ]);

  const getDealsFieldsByPipeline = useCallback(async () => {
    if (!selectedPipeline) return;
    dispatch(setLoading(true));
    try {
      const deals = await getDealsFields(selectedPipeline?._id);
      setDealsFieldsByPipeline(deals);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [selectedPipeline]);

  const getDealDetail = useCallback(
    async (id: string) => {
      if (!selectedPipeline) return;
      setDealId(id);
      try {
        const dealResp = await getDealsDetails(id);

        const fields = dealResp.fields.map((field: any) => {
          return {
            value: field.value,
            fieldId: field.field._id,
          };
        });

        setFields(fields);

        setForm({
          _id: dealResp.deal._id,
          title: dealResp.deal.title,
          amount: dealResp.deal.amount,
          closingDate: dealResp.deal.closingDate.split("T")[0],
          pipeline: dealResp.deal.pipeline,
          status: dealResp.deal.status,
          associatedContactId: dealResp.deal?.associatedContactId,
        });

        setIsVisibleCreateDeal(true);
        setIsEditing(true);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [selectedPipeline, setForm, setIsVisibleCreateDeal, setFields]
  );

  const getPipelinesState = useCallback(async (loading: boolean = false) => {
    if (!shouldFetchPipelines) return;
    dispatch(setLoading(true));
    try {
      const pipelines = await getPipelines();
      setPipelines(pipelines as any);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoading(false));
    }
  }, []);

  const crateDeal = useCallback(async () => {
    try {
      if (form?.title === "") {
        dispatch(
          setAlert({
            title: "Error",
            message: "Debe agregar un título",
            type: "error",
            show: true,
          })
        );
        return;
      }

      if (form?.amount === 0 || form?.amount === undefined) {
        dispatch(
          setAlert({
            title: "Error",
            message: "Debe agregar un monto",
            type: "error",
            show: true,
          })
        );
        return;
      }

      if (form?.status === "") {
        dispatch(
          setAlert({
            title: "Error",
            message: "Debe seleccionar un estado",
            type: "error",
            show: true,
          })
        );
        return;
      }

      if (form?.associatedContactId?._id === "") {
        dispatch(
          setAlert({
            title: "Error",
            message: "Debe seleccionar un contacto",
            type: "error",
            show: true,
          })
        );

        return;
      }

      // traer los campos posibles
      for (const field of dealsFieldsByPipeline) {
        const currentField = fields.find((f) => f.fieldId === field._id);
        console.log(field.required, currentField);

        if (field.required === true && currentField === undefined) {
          dispatch(
            setAlert({
              title: "Error",
              message: `El campo ${field.name} es requerido`,
              type: "error",
              show: true,
            })
          );
          return;
        }
      }

      dispatch(setLoading(true));
      await createDeal({ ...form, pipeline: selectedPipeline?._id }, fields);
      getDealsState(true);
      setIsVisibleCreateDeal(false);
      setForm({
        title: "",
        amount: 0,
        closingDate: "",
        pipeline: "",
        status: "",
      });
      setFields([]);
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          title: "Negocio creado",
          message: "Negocio creado correctamente",
          type: "success",
          show: true,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [form, getDealsState, setIsVisibleCreateDeal, selectedPipeline, fields]);

  const onClickUpdateDeal = useCallback(
    async (resetState = true) => {
      try {
        dispatch(setLoading(true));
        await updateDeal(
          dealId,
          { ...form, pipeline: selectedPipeline?._id },
          fields
        );
        getDealsState(true);
        dispatch(setLoading(false));
        dispatch(
          setAlert({
            title: "Negocio actualizado",
            message: "Negocio actualizado correctamente",
            type: "success",
            show: true,
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(
          setAlert({
            title: "Error",
            message: "Error actualizando el negocio",
            type: "error",
            show: true,
          })
        );
      } finally {
        if (resetState) {
          setIsVisibleCreateDeal(false);
          setIsEditing(false);
          setForm({
            title: "",
            amount: 0,
            closingDate: "",
            pipeline: "",
            status: "",
          });
          setFields([]);
        }
      }
    },
    [
      dealId,
      form,
      getDealsState,
      setIsVisibleCreateDeal,
      selectedPipeline,
      fields,
    ]
  );

  const handleAddDealField = useCallback(
    async (dealField: { name: string; key: string }) => {
      try {
        await addDealsField({
          name: dealField.name,
          key: dealField.key,
          pipeline: selectedPipeline?._id!,
        });
        await getDealsFieldsByPipeline();
      } catch (error) {
        console.log(error);
      }
    },
    [selectedPipeline, getDealsFieldsByPipeline]
  );

  const handleDeleteField = useCallback(async (fieldId: string) => {
    try {
      await removeDealsField(fieldId);
      await getDealsFieldsByPipeline();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleDeleteDeal = useCallback(
    async (dealId: string) => {
      try {
        dispatch(setLoading(true));
        await deleteDeal(dealId);
        dispatch(
          setAlert({
            title: "Negocio eliminado",
            message: "Negocio eliminado correctamente",
            type: "success",
            show: true,
          })
        );
        getDealsState(true);
        setIsVisibleCreateDeal(false);
        setIsEditing(false);
        setForm({
          title: "",
          amount: 0,
          closingDate: "",
          pipeline: "",
          status: "",
        });
        setFields([]);

        dispatch(setLoading(false));
      } catch (error) {
        dispatch(
          setAlert({
            title: "Error",
            message: "Error eliminando el negocio",
            type: "error",
            show: true,
          })
        );
        console.log(error);
      }
    },
    [getDeals]
  );

  const handleEditDealField = useCallback(
    async (dealField: {
      name: string;
      key: string;
      _id: string;
      required: boolean;
    }) => {
      try {
        await editDealsField(dealField._id, {
          name: dealField.name,
          key: dealField.key,
          pipeline: selectedPipeline?._id!,
          required: dealField?.required,
        });
        await getDealsFieldsByPipeline();
      } catch (error) {
        console.log(error);
      }
    },
    [selectedPipeline, getDealsFieldsByPipeline]
  );

  const getContactsHandler = useCallback(async () => {
    try {
      const contacts = await getContacts();
      setContacts(contacts);
    } catch (error) {
      console.log(error);
    }
  }, [setContacts]);

  const handleCancel = useCallback(() => {
    setIsVisibleCreateDeal(false);
    setForm({
      _id: "",
      title: "",
      amount: 0,
      closingDate: "",
      pipeline: "",
      status: "",
      associatedContactId: {
        _id: "",
        properties: [],
        organizationId: "",
        createdAt: "",
        updatedAt: "",
        name: "",
        EmployeeOwner: [],
      },
    });
    setFields([]);
    setIsEditing(false);
    setDealId("");
  }, [setForm, setIsVisibleCreateDeal, setFields]);

  const handleChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const debouncedSearch = useCallback(debounce(handleChangeName, 500), []);

  useEffect(() => {
    getPipelinesState(true);
  }, [getPipelinesState]);

  useEffect(() => {
    setSelectedPipeline(pipelines[0]);
  }, [pipelines]);

  useEffect(() => {
    if (selectedPipeline && shouldFetchDeals) {
      getStatusState(true);
      getDealsState(true);
      getDealsFieldsByPipeline();
      getContactsHandler();
    }
  }, [
    selectedPipeline,
    getStatusState,
    getDealsState,
    getDealsFieldsByPipeline,
    getContactsHandler,
    shouldFetchDeals,
  ]);

  useEffect(() => {
    if (shouldFetchDeals) {
      getDealsState(true, search);
    }
  }, [search, getDealsState, shouldFetchDeals]);

  return {
    states,
    deals,
    getStatusState,
    getDealsState,
    setDeals,
    setStates,
    pipelines,
    setPipelines,
    selectedPipeline,
    setSelectedPipeline,
    getPipelinesState,
    openCreatePipeline,
    setOpenCreatePipeline,
    handleOpenCreatePipeline,
    editStatesByPipeline,
    statesToEdit,
    setStatesToEdit,
    openEditStates,
    handleOpenEditStates,
    handleChangeName,
    debouncedSearch,
    isVisibleCreateDeal,
    crateDeal,
    form,
    setForm,
    setIsVisibleCreateDeal,
    dealsFieldsByPipeline,
    fields,
    setFields,
    getDealDetail,
    isEditing,
    onClickUpdateDeal,
    handleCancel,
    openEditDealsFields,
    setOpenEditDealsFields,
    handleOpenEditDealsFields,
    handleAddDealField,
    handleDeleteField,
    handleEditDealField,
    contacts,
    handleDeleteDeal,
    setFilterDates,
    filterDates,
    setDealId,
  };
};
