import React, { useState } from "react";
import { FaCogs, FaFileExport, FaFileImport } from "react-icons/fa";

import { ToolsContainer } from "./TableStyles";
import { Link } from "react-router-dom";

interface ToolsProps {
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  affected: boolean;
}

const Tools: React.FC<ToolsProps> = ({ setAffected, affected }) => {
  const [showEditColumns, setShowEditColumns] = useState<Boolean>(false);

  return (
    <ToolsContainer>
      <Link to="/importar">
        <FaFileImport />
        <p>Importar</p>
      </Link>
      <div>
        <FaFileExport />
        <p>Exportar</p>
      </div>
      <div onClick={() => setShowEditColumns(true)}>
        <FaCogs />
        <p>Editar Columnas</p>
      </div>
      {/* {showEditColumns && (
        <EditarColumnas
          setShowEditColumns={setShowEditColumns}
          setAffected={setAffected}
          affected={affected}
        />
      )} */}
    </ToolsContainer>
  );
};

export default Tools;
