import styled from "styled-components";
import { theme } from "../../../styles/theme";

// Contenedor del formulario principal
export const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

// Grupo de formulario para cada entrada
export const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="checkbox"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  input[type="checkbox"] {
    width: auto;
    margin-left: 5px;
  }
`;

// Encabezado del formulario
export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
`;

// Pie del formulario para los botones
export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button + button {
    margin-left: 10px;
  }
`;

// Estilo para los botones del formulario
export const FormButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px 0px;

  &:focus {
    outline: 1px solid #d1345b;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin: 10px 0px;
  &:focus {
    outline: 1px solid #d1345b;
  }
`;

export const FiltrosContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
`;

export const FilterDetail = styled.div`
  border: 1px solid ${theme.colors.gris1};
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h3 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  svg {
    cursor: pointer;
  }
  path {
    color: ${theme.colors.rosado};

    &:hover {
      color: ${theme.colors.rosadoOscuro};
    }
  }
`;
