import React from "react";
import {
  ListBody,
  ListChat,
  ListHeader,
  ListsChats,
  UnreadIndicator,
  UnreadCount,
} from "./ChatListStyles";
import { FaSearch } from "react-icons/fa";
import { useChat } from "./context/WhatsAppContext";
import { Chat } from "../../../types/types";

const ChatList: React.FC = () => {
  const { chatList, selectedChat, handleChatClick, handleSearchChat } =
    useChat();

  return (
    <ListsChats>
      <ChatListHeader onSearch={handleSearchChat} />
      <ChatListBody
        chatList={chatList}
        selectedChat={selectedChat}
        onChatClick={handleChatClick}
      />
    </ListsChats>
  );
};

const ChatListHeader: React.FC<{ onSearch: (query: string) => void }> = ({
  onSearch,
}) => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
    <ListHeader>
      <FaSearch />
      <input
        type="text"
        name="search"
        id="search"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Buscar..."
      />
    </ListHeader>
  );
};

const ChatListBody: React.FC<{
  chatList: Chat[];
  selectedChat: Chat | null;
  onChatClick: (chat: Chat) => void;
}> = ({ chatList, selectedChat, onChatClick }) => (
  <ListBody>
    {chatList.map((chat) => (
      <ChatListItem
        key={chat._id}
        chat={chat}
        isSelected={selectedChat?._id === chat._id}
        onClick={() => onChatClick(chat)}
      />
    ))}
  </ListBody>
);

const ChatListItem: React.FC<{
  chat: Chat;
  isSelected: boolean;
  onClick: () => void;
}> = ({ chat, isSelected, onClick }) => {
  const formatDate = (timestamp: string | undefined) => {
    if (!timestamp) return "";

    const messageDate = new Date(timestamp);
    const today = new Date();

    const isToday =
      messageDate.getDate() === today.getDate() &&
      messageDate.getMonth() === today.getMonth() &&
      messageDate.getFullYear() === today.getFullYear();

    return isToday
      ? messageDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      : messageDate.toLocaleDateString([], {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        });
  };

  const truncateMessage = (message: string, maxLength: number) => {
    return message.length > maxLength
      ? `${message.slice(0, maxLength)}...`
      : message;
  };

  return (
    <ListChat onClick={onClick} selected={isSelected}>
      <div className="indicator-container">
        <UnreadIndicator unreadCount={chat.unreadCount} />
      </div>
      <div className="content-container">
        <h4>{chat.name || chat.contact}</h4>
        <p
          style={{
            wordBreak: "break-word",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {truncateMessage(chat.lastMessage, 50)}
        </p>
      </div>
      <p>{formatDate(chat.lastMessageTime)}</p>
      {chat.unreadCount > 0 && (
        <div className="unread-count-container">
          <UnreadCount>
            <span>{chat.unreadCount} mensajes</span>
          </UnreadCount>
        </div>
      )}
    </ListChat>
  );
};

export default ChatList;
