import React, { useState } from "react";
import {
  ChatFooter as Footer,
  SelectedFiles,
  TextArea,
  Tools,
  ToolsContainer,
} from "./ChatFooterStyles";
import { FaPaperclip, FaSmile, FaTimes } from "react-icons/fa";
import EmojiPicker from "./EmojiPicker";
import Fragments from "../../../components/common/Fragments/Fragments";
import Attachments from "../../../components/common/Attachments/Attachments";
import FileSelector from "../../../components/common/FileSelector/FileSelector";
import { useDropzone } from "react-dropzone";
import { uploadFile } from "../../../services/fileService";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import SendWhatsapp from "../../../components/common/sendWhatsapp/SendWhatsapp";
import { useChat } from "./context/WhatsAppContext";

interface SelectedFileInterface {
  name?: string;
  mediaURL?: string;
  fileType?: string;
}

const ChatFooter: React.FC = () => {
  const { newMessage, setNewMessage, handleSendMessage, selectedChat } =
    useChat();
  const dispatch = useDispatch();
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [showFragments, setShowFragments] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [showFileSelector, setShowFileSelector] = useState(false);
  const [filesAffected, setFilesAffected] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFileInterface[]>(
    []
  );
  const [showCreateChat, setShowCreateChat] = useState(false);

  const handleEmojiClick = (emojiObject: any) => {
    setNewMessage(newMessage + emojiObject.emoji);
    setEmojiPickerVisible(false);
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setSelectedFiles([acceptedFiles[0]]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
  });

  const handleSend = () => {
    if (selectedFiles.length > 0) {
      if (selectedFiles[0].fileType === "application") {
        handleSendMessage("document", selectedFiles[0].mediaURL);
      } else {
        handleSendMessage("image", selectedFiles[0].mediaURL);
      }
      setSelectedFiles([]);
      return;
    }

    handleSendMessage("text");
  };

  const handleFileSelect = (file: File) => {
    setSelectedFiles([file]); // Limitar a un solo archivo
  };

  const handleRemoveFile = () => {
    setSelectedFiles([]);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Para evitar que se agregue una nueva línea
      handleSend();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const clipboardItems = event.clipboardData.items;
    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.type.startsWith("image/")) {
        const file = item.getAsFile();
        if (file) {
          handleFileUpload(file, false);
        }
      }
    }
  };

  const handleFileUpload = async (
    file: File,
    isVisible: boolean,
    isPrivate?: boolean
  ) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);

    try {
      const upd = await uploadFile(formData, isVisible);
      if (!isPrivate) {
        handleFileSelect(upd);
      }

      setShowAttachments(false);
      dispatch(setLoading(false));
      setFilesAffected(true);
    } catch (error: any) {
      console.error("Error uploading file:", error);
      dispatch(setLoading(false));
    }
  };

  const isMoreThan24Hours = () => {
    if (!selectedChat?.lastMessageTime) return true;

    const lastMessageDate = new Date(selectedChat.lastMessageTime);
    const now = new Date();

    const timeDifference = now.getTime() - lastMessageDate.getTime();
    return timeDifference > 24 * 60 * 60 * 1000; // 24 horas en milisegundos
  };

  return isMoreThan24Hours() ? (
    <div>
      <p>Debe iniciar Conversación</p>
      <button onClick={() => setShowCreateChat(true)}>Enviar Plantilla</button>
      {showCreateChat && selectedChat ? (
        <SendWhatsapp
          to={selectedChat.contact}
          setShowCreateChat={setShowCreateChat}
        />
      ) : null}
    </div>
  ) : (
    <Footer>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <input {...getInputProps()} />
        <TextArea
          placeholder="Escribe un mensaje o arrastra archivos aquí"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          style={{ flex: 1 }}
        />
        {selectedFiles.length > 0 && (
          <div>
            {selectedFiles.map((file, index) => (
              <SelectedFiles key={index}>
                <p>{file.name}</p>
                <FaTimes onClick={handleRemoveFile} />
              </SelectedFiles>
            ))}
          </div>
        )}
      </div>
      <ToolsContainer>
        <Tools>
          <div>
            <FaSmile
              onClick={() => setEmojiPickerVisible(!isEmojiPickerVisible)}
            />
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              isVisible={isEmojiPickerVisible}
            />
          </div>
          <div style={{ position: "relative", cursor: "pointer" }}>
            <FaPaperclip onClick={() => setShowAttachments(!showAttachments)} />
            {showAttachments && (
              <Attachments
                setShowFileSelector={setShowFileSelector}
                setShowAttachments={setShowAttachments}
                handleSendMessage={handleSendMessage}
                handleFileSelect={handleFileSelect}
                handleFileUpload={handleFileUpload}
              />
            )}
          </div>
          <div style={{ position: "relative", cursor: "pointer" }}>
            <p onClick={() => setShowFragments(!showFragments)}>Fragmentos</p>
            {showFragments && (
              <Fragments
                setNewMessage={setNewMessage}
                setShowFragments={setShowFragments}
              />
            )}
          </div>
        </Tools>
        <button onClick={handleSend}>Enviar</button>
      </ToolsContainer>
      {showFileSelector && (
        <FileSelector
          setShowFileSelector={setShowFileSelector}
          onFileSelect={handleFileSelect}
          handleFileUpload={handleFileUpload}
          setFilesAffected={setFilesAffected}
          filesAffected={filesAffected}
        />
      )}
    </Footer>
  );
};

export default ChatFooter;
