import styled from "styled-components";
import { theme } from "../../styles/theme";

export const DealsFieldsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  thead {
    th {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid #ccc;
      width: 140px;
    }
  }
  tbody {
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid #ccc;
      }
    }
  }
`;

export const Icon = styled.div`
  * {
    color: ${theme.colors.rosado};
  }
`;
