import React from "react";

const RedesSocialesPage = () => {
  return (
    <div>
      <h1>Redes Sociales</h1>
    </div>
  );
};

export default RedesSocialesPage;
