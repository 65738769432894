import styled, { css, keyframes } from "styled-components";
import { theme } from "../../../styles/theme";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

const getAlertStyles = (type: "error" | "success" | "warning" | "info") => {
  switch (type) {
    case "error":
      return css`
        background-color: #f8d7da;
        color: #721c24;
        border-color: #f5c6cb;
      `;
    case "success":
      return css`
        background-color: #d4edda;
        color: #155724;
        border-color: #c3e6cb;
      `;
    case "warning":
      return css`
        background-color: #fff3cd;
        color: #856404;
        border-color: #ffeeba;
      `;
    case "info":
      return css`
        background-color: #d1ecf1;
        color: #0c5460;
        border-color: #bee5eb;
      `;
    default:
      return css`
        background-color: rgba(255, 255, 255, 0.9);
        color: ${theme.colors.grisOscuro};
      `;
  }
};

export const AlertContainer = styled.div<{
  type: "error" | "success" | "warning" | "info";
  isexiting: boolean;
}>`
  width: 400px; /* Cambiar el ancho */
  position: fixed;
  top: 20px;
  right: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid;
  display: flex;
  flex-direction: column; /* Asegurar que el contenido esté alineado en la parte superior */

  ${({ type }) => getAlertStyles(type)}

  animation: ${({ isexiting }) =>
    isexiting ? slideOut : slideIn} 0.5s forwards;

  h1 {
    font-size: 18px;
    margin: 0 0 5px 0;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const AlertContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0px;
`;

export const AlertText = styled.div`
  margin-left: 10px;
  flex: 1; /* Asegura que el texto ocupe el espacio disponible */
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
