import axiosInstance from "./axiosConfig";

export const getUser = async (userId: string) => {
  try {
    const res = await axiosInstance.get(`/users/${userId}`);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching user");
    throw new Error(error.response?.data?.message || "Error fetching user");
  }
};

export const updateUser = async (form: any) => {
  try {
    const res = await axiosInstance.put("/users", { form: form });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating user");
    throw new Error(error.response?.data?.message || "Error updating user");
  }
};

interface IUserPasswordUpdate {
  oldPassword: string;
  newPassword: string;
}

export const updateUserPassword = async (form: IUserPasswordUpdate) => {
  try {
    const res = await axiosInstance.put("/users/update-password", form);
    return res.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || "Error updating user password";
    console.error(errorMessage);
    throw new Error(errorMessage);
  }
};
