import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import {
  Button,
  FirstNewListColumn,
  FormGroup,
  Header,
  NewListContainer,
  SecondNewListColumn,
} from "./ListStyles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { advancedFilterContacts } from "../../../services/contactService";
import {
  FilterDetail,
  FiltrosContainer,
  Input,
  Select,
} from "./CreateListStyles";
import {
  createDynamicList,
  createStaticList,
} from "../../../services/listService";
import { IListFilters } from "../../../types/types";

const conditionLabels: { [key: string]: string } = {
  equals: "Es igual a",
  not_equals: "No es igual a",
  contains: "Contiene",
  not_contains: "No contiene",
  is_empty: "Es vacío",
  is_not_empty: "No es vacío",
};

const CreateListPage: React.FC = () => {
  const [listName, setListName] = useState("");
  const [description, setDescription] = useState("");
  const [filters, setFilters] = useState<IListFilters[]>([]);
  const [selectedField, setSelectedField] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [contacts, setContacts] = useState<any[]>([]);
  const [listType, setListType] = useState("");
  const [query, setQuery] = useState([]);
  const navigate = useNavigate();

  const properties = useSelector(
    (state: any) => state.organization.contactProperties
  );

  const handleSave = async () => {
    if (
      !listType ||
      !listName ||
      !description ||
      filters.length === 0 ||
      query.length === 0
    ) {
      alert("Por favor complete todos los campos del filtro");
      return;
    }

    try {
      if (listType === "static") {
        await createStaticList({ name: listName, description, filters }, query);
      } else if (listType === "dynamic") {
        await createDynamicList(
          { name: listName, description, filters },
          query
        );
      }
      navigate("/listas");
    } catch (error) {
      console.error("Error saving list:", error);
      alert("Hubo un error al guardar la lista.");
    }
  };

  const handleAddFilter = () => {
    if (!selectedField || !selectedCondition || !filterValue) {
      alert("Por favor complete todos los campos del filtro");
      return;
    }

    const newFilter: IListFilters = {
      field: selectedField,
      condition: selectedCondition as any,
      value: filterValue,
    };
    const existingFilterIndex = filters.findIndex(
      (filter) => filter.field === selectedField
    );

    setFilters((prevFilters) =>
      existingFilterIndex !== -1
        ? prevFilters.map((filter, index) =>
            index === existingFilterIndex ? newFilter : filter
          )
        : [...prevFilters, newFilter]
    );

    setSelectedField("");
    setSelectedCondition("");
    setFilterValue("");
    setActiveStep(0);
  };

  useEffect(() => {
    if (filters.length > 0) {
      handleFetchResults();
    }
  }, [filters]);

  const handleFetchResults = async () => {
    try {
      const results = await advancedFilterContacts({
        organizationId: localStorage.getItem("organizationId"),
        filters,
      });
      const transformedResults = results.contacts.map((contact: any) => {
        const flattenedProperties: any = {};
        contact.properties.forEach((prop: any) => {
          flattenedProperties[prop.key] = prop.value;
        });
        return { ...contact, ...flattenedProperties };
      });
      setContacts(transformedResults);
      setQuery(results.query);
    } catch (error) {
      console.error("Error fetching results:", error);
    }
  };

  const handleRemoveFilter = (index: number) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const getPropertyLabel = (key: string) => {
    const property = properties.find((prop: any) => prop.key === key);
    return property ? property.label : key;
  };

  return (
    <div>
      <Header>
        <div>
          <h1>
            <FaArrowLeft onClick={() => navigate("/listas")} /> Crear Lista
          </h1>
        </div>
      </Header>
      <div>
        <FormGroup>
          <label htmlFor="listName">Nombre de la lista</label>
          <input
            type="text"
            name="listName"
            id="listName"
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="description">Descripción</label>
          <input
            type="text"
            name="description"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ marginBottom: "20px" }}
          />
        </FormGroup>
        <FormGroup>
          <label>Tipo de lista</label>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <input
                type="radio"
                name="type"
                id="static"
                style={{ marginRight: "5px" }}
                onChange={() => setListType("static")}
              />
              <label htmlFor="static">Estática</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <input
                type="radio"
                name="type"
                id="dynamic"
                style={{ marginRight: "5px" }}
                onChange={() => setListType("dynamic")}
              />
              <label htmlFor="dynamic">Dinámica</label>
            </div>
          </div>
          <Button
            onClick={handleSave}
            style={
              !listType ||
              !listName ||
              !description ||
              filters.length === 0 ||
              query.length === 0
                ? { backgroundColor: "grey" }
                : {}
            }
            disabled={
              !listType ||
              !listName ||
              !description ||
              filters.length === 0 ||
              query.length === 0
            }
          >
            Guardar Lista
          </Button>
        </FormGroup>
      </div>
      <NewListContainer>
        <FirstNewListColumn>
          {activeStep === 0 ? (
            <div style={{ width: "100%", padding: "20px" }}>
              {filters.length === 0 ? (
                <p>Esta lista no tiene ningún filtro</p>
              ) : (
                <FiltrosContainer>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3>Filtros activos</h3>
                    <hr style={{ width: "50%", marginTop: "10px" }} />
                  </div>
                  <div style={{ margin: "10px 0px" }}>
                    {filters.map((filter, index) => (
                      <FilterDetail key={index}>
                        <div>
                          <h3>{getPropertyLabel(filter.field)}</h3>
                          <p>
                            {conditionLabels[filter.condition]}: {filter.value}
                          </p>
                        </div>
                        <FaTimes onClick={() => handleRemoveFilter(index)} />
                      </FilterDetail>
                    ))}
                  </div>
                </FiltrosContainer>
              )}
              <button onClick={() => setActiveStep(1)}>Agregar filtro</button>
            </div>
          ) : (
            <div>
              <div>
                <label htmlFor="selectField">1. Seleccione un campo</label>
                <Select
                  id="selectField"
                  value={selectedField}
                  onChange={(e) => setSelectedField(e.target.value)}
                >
                  <option value="" disabled>
                    Seleccione Campo
                  </option>
                  {properties.map((property: any) => (
                    <option key={property.key} value={property.key}>
                      {property.label}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <label htmlFor="condition">2. Seleccione una condición</label>
                <Select
                  name="condition"
                  id="condition"
                  value={selectedCondition}
                  onChange={(e) => setSelectedCondition(e.target.value)}
                >
                  <option value="">Seleccione</option>
                  {Object.keys(conditionLabels).map((key) => (
                    <option key={key} value={key}>
                      {conditionLabels[key]}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <label htmlFor="filterValue">3. Agregar valor</label>
                <Input
                  type="text"
                  name="filterValue"
                  id="filterValue"
                  placeholder="Agregar valor"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  style={{
                    width: "100%",
                    padding: "8px",
                    borderRadius: "4px",
                    border: "none",
                  }}
                />
              </div>
              <button
                onClick={() => setActiveStep(0)}
                style={{ backgroundColor: "grey" }}
              >
                Regresar
              </button>
              <Button onClick={handleAddFilter}>Agregar</Button>
            </div>
          )}
        </FirstNewListColumn>
        <SecondNewListColumn>
          {filters.length === 0 ? (
            <div>
              <h3>Agrega filtros para comenzar a crear la lista</h3>
              <p>Los resultados filtrados aparecerán aquí.</p>
            </div>
          ) : (
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    {properties.map((property: any) => (
                      <th key={property.key}>{property.label}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {contacts.length === 0 ? (
                    <tr>
                      <td colSpan={properties.length}>
                        No hay resultados para la consulta
                      </td>
                    </tr>
                  ) : (
                    contacts.map((contact: any) => (
                      <tr key={contact._id}>
                        {properties.map((property: any) => (
                          <td key={property.key}>{contact[property.key]}</td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </SecondNewListColumn>
      </NewListContainer>
    </div>
  );
};

export default CreateListPage;
