import React from "react";
import Picker from "emoji-picker-react";
import styled from "styled-components";

const EmojiPickerWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 50px;
  right: 10px;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  z-index: 10;
`;

interface EmojiPickerProps {
  onEmojiClick: (emojiObject: any) => void;
  isVisible: boolean;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onEmojiClick,
  isVisible,
}) => {
  return (
    <EmojiPickerWrapper isVisible={isVisible}>
      <Picker onEmojiClick={onEmojiClick} />
    </EmojiPickerWrapper>
  );
};

export default EmojiPicker;
