import React, { useContext, useState } from "react";
import {
  AddTaskButton,
  AddTaskContent,
  AddTaskModalContainer,
} from "./AddTaskStyles";
import { ProjectContext } from "../context/ProjectContext";
import { useSelector } from "react-redux";
import { ITask } from "../../../../types";

const AddTask = () => {
  const { setAddTaskIsVisible, createTask } = useContext(ProjectContext);
  const employees = useSelector((state: any) => state.organization.employees);
  const [newTask, setNewTask] = useState<ITask>({
    title: "",
    responsibleId: "",
    status: "",
    projectId: "",
    dueDate: new Date(),
  });

  // Handler para actualizar los campos de la nueva tarea
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  const handleCreateTask = () => {
    if (
      newTask.title &&
      newTask.responsibleId &&
      newTask.status &&
      newTask.dueDate
    ) {
      createTask(newTask);
      setAddTaskIsVisible(false);
    } else {
      // Opcional: Muestra una alerta si faltan campos por completar
      alert("Por favor, completa todos los campos.");
    }
  };

  return (
    <AddTaskModalContainer>
      <AddTaskContent>
        <h1>Agregar Tarea</h1>

        <div>
          <label htmlFor="title">Nombre de la tarea</label>
          <input
            type="text"
            name="title"
            id="title"
            value={newTask.title}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="responsibleId">Responsable</label>
          <select
            name="responsibleId"
            id="responsibleId"
            value={newTask.responsibleId}
            onChange={handleSelectChange}
          >
            <option value="">-- Seleccione --</option>
            {employees.map((employee: any) => (
              <option key={employee._id} value={employee._id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="status">Estado</label>
          <select
            name="status"
            id="status"
            value={newTask.status}
            onChange={handleSelectChange}
          >
            <option value="">Selecciona un estado</option>
            <option value="No Iniciado">No Iniciado</option>
            <option value="En Progreso">En Progreso</option>
            <option value="Atrasado">Atrasado</option>
            <option value="Completado">Completado</option>
          </select>
        </div>
        <div>
          <label htmlFor="dueDate">Vencimiento</label>
          <input
            type="date"
            name="dueDate"
            id="dueDate"
            onChange={handleInputChange}
            defaultValue={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <AddTaskButton
            onClick={() => {
              handleCreateTask();
            }}
          >
            Guardar
          </AddTaskButton>
          <AddTaskButton
            style={{ background: "grey" }}
            onClick={() => {
              setAddTaskIsVisible(false);
            }}
          >
            Cancelar
          </AddTaskButton>
        </div>
      </AddTaskContent>
    </AddTaskModalContainer>
  );
};

export default AddTask;
