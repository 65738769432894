import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaArrowLeft, FaUser } from "react-icons/fa";
import {
  Container,
  FirstColumn,
  Layout,
  SecondColumn,
  Title,
  Body,
  Card,
  Header,
  MenuList,
} from "./ContactDetailStyles";
import ColumnDetails from "./ColumnDetails";
import ConfirmationBar from "./ConfirmationBar";
import { getContact, updateContact } from "../../services/contactService";
import ActionsContact from "./ActionsContact";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/slices/alertSlice";
import SendWhatsapp from "../../components/common/sendWhatsapp/SendWhatsapp";
import WhatsappMessages from "../../components/common/WhatsappMessages/WhatsappMessages";
import ContactDeals from "./ContactDeals";

interface Property {
  key: string;
  value: string;
  _id: { $oid: string };
}

interface Contact {
  properties: Property[];
}

const ContactDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [contact, setContact] = useState<Contact | null>(null);
  const [activities, setActivities] = useState<any[]>([]);
  const [activeView, setActiveView] = useState(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [isEditingActivity, setIsEditingActivity] = useState(false);
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState<string>("");
  const [showCreateChat, setShowCreateChat] = useState(false);
  const [form, setForm] = useState<Record<string, string>>({});

  const getData = async () => {
    try {
      const contactsResponse = await getContact(id as string);
      setContact(contactsResponse);

      const initialForm = contactsResponse.properties.reduce(
        (acc: any, prop: any) => ({ ...acc, [prop.key]: prop.value }),
        {}
      );

      setForm(initialForm);

      setMobile(
        contactsResponse.properties.find(
          (prop: Property) => prop.key === "mobile" || prop.key === "phone"
        )?.value || ""
      );
    } catch (error) {
      console.error("Error fetching contact:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleEditSubmit = async () => {
    if (!contact) return;

    try {
      // Actualiza las propiedades existentes
      const updatedProperties = contact.properties.map((prop) => ({
        ...prop,
        value: form[prop.key] !== undefined ? form[prop.key] : prop.value,
      }));

      // Añade propiedades nuevas que no estaban en contact.properties
      const newProperties = Object.keys(form)
        .filter((key) => !contact.properties.some((prop) => prop.key === key))
        .map((key) => ({
          key,
          value: form[key],
        }));

      // Combina propiedades actualizadas con nuevas propiedades
      const allProperties = [...updatedProperties, ...newProperties];

      await updateContact(id as string, { properties: allProperties });
      setIsEditing(false);
      getData(); // Refresh the data after updating
      dispatch(
        setAlert({
          title: "Éxito",
          message: "Contacto actualizado correctamente",
          type: "success",
          show: true,
        })
      );
    } catch (error) {
      console.error("Error updating contact:", error);
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al actualizar el contacto",
          type: "error",
          show: true,
        })
      );
    }
  };

  const handleChange = (key: string, value: string) => {
    setForm((prevForm) => ({
      ...prevForm,
      [key]: value,
    }));
  };

  return (
    <>
      <Container>
        <FaArrowLeft
          onClick={() => {
            window.history.back();
          }}
          style={{
            fontSize: "30px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        />
        <Title>
          <FaUser />
          <h1>Detalle de Contacto</h1>
        </Title>

        <Layout>
          <FirstColumn>
            <ActionsContact
              contact={contact}
              setShowCreateChat={setShowCreateChat}
            />
            <ColumnDetails
              contact={contact}
              setIsEditing={setIsEditing}
              handleChange={handleChange}
              form={form}
            />
          </FirstColumn>
          <SecondColumn>
            <MenuList>
              <h4
                style={{
                  borderBottom: activeView === 0 ? "5px solid pink" : "none",
                }}
                onClick={() => setActiveView(0)}
              >
                Actividad
              </h4>
              <h4
                onClick={() => setActiveView(1)}
                style={{
                  borderBottom: activeView === 1 ? "5px solid pink" : "none",
                }}
              >
                Notas
              </h4>
              <h4
                onClick={() => setActiveView(2)}
                style={{
                  borderBottom: activeView === 2 ? "5px solid pink" : "none",
                }}
              >
                Chat de WhatsApp
              </h4>
              <h4
                onClick={() => setActiveView(3)}
                style={{
                  borderBottom: activeView === 2 ? "5px solid pink" : "none",
                }}
              >
                Negocios
              </h4>
            </MenuList>
            {activities?.map((item: any, key: any) => (
              <Card key={key}>
                <Header>
                  <h3
                    onClick={() => {
                      setIsEditingActivity(item._id);
                    }}
                  >
                    TEST
                  </h3>
                  <h3 style={{ fontSize: "15px" }}>12/12/21</h3>
                </Header>
                <Body>
                  <p>Notas</p>
                </Body>
              </Card>
            ))}
            {activeView === 2 && (
              <Card>
                <Header>
                  <h3>Chat de WhatsApp</h3>
                </Header>
                <Body>
                  <WhatsappMessages mobile={form.mobile} />
                </Body>
              </Card>
            )}

            {activeView === 3 && (
              <Card>
                <Header>
                  <h3>Negocios</h3>
                </Header>
                <Body>
                  <ContactDeals />
                </Body>
              </Card>
            )}
          </SecondColumn>
        </Layout>

        {isEditing && (
          <ConfirmationBar
            setIsEditing={setIsEditing}
            handleEditSubmit={handleEditSubmit}
            contact={contact}
          />
        )}
      </Container>
      {showCreateChat && (
        <SendWhatsapp to={mobile} setShowCreateChat={setShowCreateChat} />
      )}
    </>
  );
};

export default ContactDetail;
