import styled from "styled-components";

export const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="checkbox"],
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  input[type="checkbox"] {
    width: auto;
    margin-left: 5px;
  }
`;

export const Table = styled.table`
  width: 100%;
`;

export const AddFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;

  div {
    width: 100%;
  }

  input,
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
`;
