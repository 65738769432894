import React, { useState } from "react";
import { FaEdit, FaHeadset, FaTrash, FaPrint } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { TableActionsStyles } from "./TableStyles";
import { setAlert } from "../../../redux/slices/alertSlice";
import Confirmation from "../Modals/Confirmation/Confirmation";
import EditContact from "../../../pages/Contactos/Contactos/EditContact";

interface TableActionsProps {
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  selected: string[];
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  affected: boolean;
  deleteHandler: (ids: string[]) => Promise<void>;
  editHandler: (id: string) => void;
  printHandler?: (id: string) => void; // Nuevo prop para imprimir
  asignHandler?: (id: string) => void; // Nuevo prop para asignar
}

const TableActions: React.FC<TableActionsProps> = ({
  setSelected,
  selected,
  setAffected,
  affected,
  deleteHandler,
  editHandler,
  printHandler, // Nuevo prop para imprimir
  asignHandler, // Nuevo prop para asignar
}) => {
  const [confirm, setConfirm] = useState(false);
  const [isEditting, setIsEditting] = useState(false);

  const dispatch = useDispatch();

  const handlerDelete = () => {
    if (!selected.length) {
      dispatch(
        setAlert({
          title: "Selecciona un registro",
          text: "Debes seleccionar un registro para poder eliminar",
          show: true,
        })
      );
      return;
    }
    setConfirm(true);
  };

  const confirmDelete = async () => {
    await deleteHandler(selected);
    setAffected(!affected);
    setConfirm(false);
    setSelected([]);
  };

  const editHandlerInternal = () => {
    if (selected.length !== 1) {
      dispatch(
        setAlert({
          title: "Selecciona un registro",
          text: "Debes seleccionar un único registro para poder editar",
          show: true,
        })
      );
      return;
    }
    editHandler(selected[0]);
    setIsEditting(true);
  };

  const asignHandlerInternal = () => {
    if (selected.length !== 1) {
      dispatch(
        setAlert({
          title: "Selecciona un registro",
          text: "Debes seleccionar un único registro para poder asignar",
          show: true,
        })
      );
      return;
    }
    if (asignHandler) asignHandler(selected[0]);
  };

  const printHandlerInternal = () => {
    if (selected.length !== 1) {
      dispatch(
        setAlert({
          title: "Selecciona un registro",
          text: "Debes seleccionar un único registro para poder imprimir",
          show: true,
        })
      );
      return;
    }
    if (printHandler) printHandler(selected[0]);
  };

  return (
    <TableActionsStyles>
      <div onClick={handlerDelete} style={{ alignItems: "center" }}>
        <FaTrash />
        <span>Eliminar</span>
      </div>
      <div onClick={editHandlerInternal} style={{ alignItems: "center" }}>
        <FaEdit />
        <span>Editar</span>
      </div>
      {printHandler && (
        <div onClick={printHandlerInternal} style={{ alignItems: "center" }}>
          <FaPrint />
          <span>Imprimir</span>
        </div>
      )}
      {asignHandler && (
        <div onClick={asignHandlerInternal} style={{ alignItems: "center" }}>
          <FaHeadset />
          <span>Asignar</span>
        </div>
      )}
      {isEditting && (
        <EditContact
          contactId={selected[0]}
          affected={affected}
          setAffected={setAffected}
          setIsEditting={setIsEditting}
        />
      )}
      {confirm && (
        <Confirmation
          title={`Va a eliminar ${selected.length} registro(s)`}
          message="Esta acción es irreversible"
          confirmation={confirmDelete}
          setConfirm={setConfirm}
        />
      )}
      {/* {asignEmployee && (
        <Asign setAsignEmployee={setAsignEmployee} contactId={selected[0]} />
      )} */}
    </TableActionsStyles>
  );
};

export default TableActions;
