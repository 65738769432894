import axiosInstance from "./axiosConfig";
import { IFragment } from "../types/types";

// Crear un Fragment
export const createFragment = async (fragment: {
  name: string;
  text: string;
  atajo: string;
  userId: string;
  organizationId: string;
}) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(
      "/fragments",
      { ...fragment },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error creando el fragmento"
    );
    throw new Error(
      error.response?.data?.message || "Error creando el fragmento"
    );
  }
};

// Obtener todos los Fragments
export const getFragments = async () => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<IFragment[]>("/fragments", {
      headers: { Authorization: `${token}` },
      params: { organizationId },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error obteniendo los fragmentos"
    );
    throw new Error(
      error.response?.data?.message || "Error obteniendo los fragmentos"
    );
  }
};

// Obtener un Fragment por ID
export const getFragmentById = async (id: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get<IFragment>(`/fragments/${id}`, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error obteniendo el fragmento"
    );
    throw new Error(
      error.response?.data?.message || "Error obteniendo el fragmento"
    );
  }
};

// Actualizar un Fragment
export const updateFragment = async (
  id: string,
  updatedFragment: { name?: string; text?: string; atajo?: string }
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(`/fragments/${id}`, updatedFragment, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error actualizando el fragmento"
    );
    throw new Error(
      error.response?.data?.message || "Error actualizando el fragmento"
    );
  }
};

// Eliminar un Fragment
export const deleteFragment = async (id: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.delete(`/fragments/${id}`, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error eliminando el fragmento"
    );
    throw new Error(
      error.response?.data?.message || "Error eliminando el fragmento"
    );
  }
};

// Buscar fragmentos
export const searchFragments = async (searchTerm: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<IFragment[]>(`/fragments/search`, {
      headers: { Authorization: `${token}` },
      params: { organizationId, searchTerm },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error buscando fragmentos");
    throw new Error(
      error.response?.data?.message || "Error buscando fragmentos"
    );
  }
};
