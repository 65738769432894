import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Table from "../../../components/common/Table/Table";
import { IQuotation, IHeader } from "../../../types/types";
import {
  getQuotations,
  searchQuotation,
  deleteQuotation,
} from "../../../services/quotationService";
import { theme } from "../../../styles/theme";
import QuotationPdfDocument from "../../../pdfs/QuotationPdfDocument";
import { pdf } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { setAlert } from "../../../redux/slices/alertSlice";

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;

  button {
    padding: 10px 20px;
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
      cursor: pointer;
      transition: 0.3s;
    }
  }

  h1 {
    margin-left: 10px;
  }

  small {
    margin-left: 10px;
    color: grey;
  }
`;

const QuotationsPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [quotations, setQuotations] = useState<IQuotation[]>([]);
  const [affected, setAffected] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedQuotation, setSelectedQuotation] = useState<IQuotation | null>(
    null
  );
  const navigate = useNavigate();

  const headers: IHeader[] = [
    {
      label: "Quotation Number",
      isVisible: true,
      key: "quotationNumber",
      getValue: (item: IQuotation) => item.quotationNumber.toString(),
    },
    {
      label: "Creation Date",
      isVisible: true,
      key: "creationDate",
      getValue: (item: IQuotation) =>
        new Date(item.creationDate).toLocaleDateString(),
    },
    {
      label: "Customer",
      isVisible: true,
      key: "name",
      getValue: (item: IQuotation) => item.name,
    },
    {
      label: "Created By",
      isVisible: true,
      key: "createdBy",
      getValue: (item: IQuotation) => "Created by Placeholder",
    },
    {
      label: "Total",
      isVisible: true,
      key: "total",
      getValue: (item: IQuotation) => `$${item.total.toFixed(2)}`,
    },
    {
      label: "State",
      isVisible: true,
      key: "status",
      getValue: (item: IQuotation) => item.status,
    },
  ];

  const fetchQuotations = useCallback(async () => {
    const quotations = await getQuotations(limit, page);

    setQuotations(quotations);
    return quotations;
  }, [limit, page]);

  useEffect(() => {
    fetchQuotations();
  }, [affected, limit, page, fetchQuotations]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const shouldPrint = queryParams.get("printQuotation");
    const quotationId = queryParams.get("id");

    if (shouldPrint && quotationId) {
      const quotation = quotations.find(
        (q) => q.quotationNumber === Number(quotationId)
      );
      if (quotation) {
        printHandler(quotation._id);
      }
    }
  }, [location.search, quotations]);

  const deleteHandler = async (ids: string[]) => {
    try {
      dispatch(setLoading(true));
      await Promise.all(ids.map((id) => deleteQuotation(id)));
      dispatch(setLoading(false));
      setAffected(!affected);
      dispatch(
        setAlert({
          title: "Cotizaciones eliminadas",
          message: "Las cotizaciones han sido eliminadas exitosamente.",
          show: true,
          type: "success",
        })
      );
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          title: "Error al eliminar cotizaciones",
          message:
            "Ocurrió un error al eliminar las cotizaciones. Por favor, intenta de nuevo.",
          show: true,
          type: "error",
        })
      );

      console.error("Error al eliminar las cotizaciones:", err);
    }
  };

  const editHandler = (id: string) => {
    navigate(`/cotizaciones/editar/${id}`);
  };

  const printHandler = async (id: string) => {
    const quotation = quotations.find((q) => q._id === id);
    if (quotation) {
      setSelectedQuotation(quotation);
      const blob = await pdf(
        <>
          <QuotationPdfDocument quotation={quotation} />
        </>
      ).toBlob();
      const blobURL = URL.createObjectURL(blob);
      const newWindow = window.open(blobURL, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
          setSelectedQuotation(null); // Reset the selection after printing
        };
      } else {
        setSelectedQuotation(null); // Ensure selection is reset if the window fails to open
      }
    }
  };

  return (
    <div>
      <Title>
        <div>
          <h1>Cotizaciones</h1>
          <small>{quotations.length} Registros</small>
        </div>
        <button onClick={() => navigate(`/cotizaciones/crear`)}>
          Crear Cotización
        </button>
      </Title>
      <Table<IQuotation>
        headers={headers}
        data={quotations}
        setData={setQuotations}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        page={page}
        setAffected={setAffected}
        affected={affected}
        fetchData={fetchQuotations}
        searchFunction={searchQuotation}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        printHandler={printHandler} // Handler para imprimir
        tableType="quotations" // Indicador de tipo de tabla
      />
    </div>
  );
};

export default QuotationsPage;
