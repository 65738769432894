import { useState } from "react";
import { PurchaseProvider } from "./context/PurchasesContext";

import PurchasesTable from "./PurchasesTable";
import CreatePurchase from "./CreatePurchase";

const App = () => {
  const [activeView, setActiveView] = useState("table");
  return (
    <PurchaseProvider>
      {activeView === "table" ? (
        <PurchasesTable setActiveView={setActiveView} />
      ) : activeView === "create" ? (
        <CreatePurchase setActiveView={setActiveView} />
      ) : null}
    </PurchaseProvider>
  );
};

export default App;
