import React from "react";
import { TablePagination } from "./TableStyles";

interface PaginationsProps {
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  affected: boolean;
  limit?: number;
  page?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Paginations: React.FC<PaginationsProps> = ({
  setLimit,
  setAffected,
  affected,
  limit = 10,
  page = 1,
  setPage,
}) => {
  return (
    <TablePagination>
      <button
        onClick={() => {
          setPage(page - 1);
          setAffected(!affected);
        }}
      >
        Anterior
      </button>
      <span>{page}</span>
      <button
        onClick={() => {
          setPage(page + 1);
          setAffected(!affected);
        }}
      >
        Siguiente
      </button>
      <select
        name="limit"
        id="limit"
        value={limit}
        onChange={(e) => {
          setLimit(Number(e.target.value));
          setAffected(!affected);
        }}
      >
        <option value="10">10 por página</option>
        <option value="30">30 por página</option>
        <option value="50">50 por página</option>
        <option value="100">100 por página</option>
      </select>
    </TablePagination>
  );
};

export default Paginations;
