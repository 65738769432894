import styled from "styled-components";

export const OptionsMenuContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 20;
  right: 200;
  width: 200px;
  background: #f1f1f1;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
`;
