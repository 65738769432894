import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  DealsDate,
  DealsList,
  DealsListTotal,
  EditContactContainer,
  Form,
  FormFooter,
  FormHeader,
} from "./EditContactStyles";
import { IContactProperty } from "../../../types/types";
import { getContact, updateContact } from "../../../services/contactService";
import { Link } from "react-router-dom";
import { getContactDeals } from "../../../services/dealService";
import { formatDate } from "../../../utils/formatDate";

interface IEditContact {
  contactId: string;
  affected?: boolean;
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditting: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IContact {
  EmployeeOwner: string;
  organizationId: string;
  properties: Array<{ key: string; value: string }>;
}

const EditContact: React.FC<IEditContact> = ({
  contactId,
  affected,
  setAffected,
  setIsEditting,
}) => {
  const [contact, setContact] = useState<IContact>({
    EmployeeOwner: "",
    organizationId: "",
    properties: [],
  });

  const [form, setForm] = useState<Record<string, string>>({});
  const [deals, setDeals] = useState<any[]>([]);

  const properties = useSelector((state: RootState) => {
    return state.organization.contactProperties as IContactProperty[];
  });

  const getContactDb = async () => {
    try {
      const contactData = await getContact(contactId);
      setContact(contactData);

      const initialForm = contactData.properties.reduce(
        (acc: any, prop: any) => ({ ...acc, [prop.key]: prop.value }),
        {}
      );

      setForm(initialForm);
    } catch (error) {
      console.error("Error fetching contact:", error);
    }
  };

  const submitHandler = async () => {
    try {
      // Actualiza las propiedades existentes
      const updatedProperties = contact.properties.map((prop) => ({
        ...prop,
        value: form[prop.key] !== undefined ? form[prop.key] : prop.value,
      }));

      // Añade propiedades nuevas que no estaban en contact.properties
      const newProperties = Object.keys(form)
        .filter((key) => !contact.properties.some((prop) => prop.key === key))
        .map((key) => ({
          key,
          value: form[key],
        }));

      // Combina propiedades actualizadas con nuevas propiedades
      const allProperties = [...updatedProperties, ...newProperties];

      await updateContact(contactId, { properties: allProperties });
      setAffected(!affected);
      setIsEditting(false);
      setContact({
        EmployeeOwner: "",
        organizationId: "",
        properties: [],
      }); // Reinicia el contacto
    } catch (error) {
      console.error("Error updating contact:", error);
    }
  };

  const fetchDeals = async () => {
    const deals = await getContactDeals(contactId);
    console.log(deals);
    setDeals(deals);
  };

  useEffect(() => {
    getContactDb();
    fetchDeals();
  }, [contactId]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  return (
    <EditContactContainer>
      <FormHeader>
        <div>
          <h2>Editar Contacto</h2>
          <Link to={`/contactos/${contactId}`}>
            <span
              style={{
                color: "#007bff",
                cursor: "pointer",
              }}
            >
              Ver Registro
            </span>
          </Link>
        </div>

        <FaTimes onClick={() => setIsEditting(false)} />
      </FormHeader>

      <Form>
        {properties.map((input: IContactProperty) => (
          <div key={input.key}>
            <label htmlFor={input.key}>{input.label}</label>
            <input
              type="text"
              name={input.key}
              id={input.key}
              value={form[input.key] || ""}
              onChange={handleInputChange}
            />
          </div>
        ))}
      </Form>
      <div
        style={{
          marginTop: "20px",
        }}
      >
        <h3>Negocios</h3>
        {deals.length > 0 ? (
          deals.map((deal) => (
            <DealsList key={deal.id}>
              <div>
                <Link to={`/negocios/${deal._id}`}>
                  <h3>{deal.title}</h3>
                </Link>
                <DealsDate>{formatDate(new Date(deal.closingDate))}</DealsDate>
              </div>
              <DealsListTotal>${deal.amount.toLocaleString()}</DealsListTotal>
            </DealsList>
          ))
        ) : (
          <p>No tiene negocios creados</p>
        )}
      </div>
      <FormFooter>
        <button onClick={submitHandler}>Modificar</button>
        <button onClick={() => setIsEditting(false)}>Cancelar</button>
      </FormFooter>
    </EditContactContainer>
  );
};

export default EditContact;
