import { createAsyncThunk } from "@reduxjs/toolkit";
import notificationService from "../../services/notificationService";

export const loadInitialNotifications = createAsyncThunk(
  "notifications/loadInitialNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await notificationService.fetchNotifications();
      return response; // Asegúrate de que retorna los datos correctos del servicio
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
