import styled from "styled-components";
import { theme } from "styles/theme";

export const ProveedoresPageContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
`;

export const ProveedoresTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th {
    background-color: #f2f2f2;
    color: #333;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 30px;
  margin: 20px 0px;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;

    p {
      font-size: 16px;
    }
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    cursor: pointer;
    transition: 0.3s;
  }
`;

export const RawContainer = styled.div`
  div {
    min-width: 50%;
  }
  input,
  select {
    border: 1px solid ${theme.colors.gris2};
    padding: 5px;
    border-radius: 5px;
    min-width: 350px;
    max-width: 500px;
  }
`;
