import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Form = styled.form`
  margin: 20px 0px;
  background-color: ${theme.colors.gris3};
  padding: 20px;
  border-radius: 20px;
  hr {
    margin: 20px 0px;
  }
  input {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: white;
  }
  button {
    padding: 10px;
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
      transition: ease all 0.3s;
      cursor: pointer;
    }
  }
`;
