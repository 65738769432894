import axiosInstance from "./axiosConfig";

const token = localStorage.getItem("token");

export const fetchFiles = async () => {
  try {
    const response = await axiosInstance.get("/files", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error fetching files:", error);
    throw new Error(error.response?.data?.message || "Error fetching files");
  }
};

export const uploadFile = async (
  formData: FormData,
  isVisible: any = false
) => {
  try {
    const response = await axiosInstance.post(
      `/files/upload?isVisible=${isVisible}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Error uploading file:", error);
    throw new Error(error.response?.data?.message || "Error uploading file");
  }
};

export const deleteFile = async (fileId: string) => {
  try {
    const response = await axiosInstance.delete(`/files/${fileId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error deleting file:", error);
    throw new Error(error.response?.data?.message || "Error deleting file");
  }
};
