import React, { useState } from "react";
import {
  FormArea,
  Instrucciones,
  LayoutLeads,
  RadioOptions,
  ResultsCounter,
  Table,
  TextArea,
} from "./LeadsPageStyles";
import LeadsGenerationService from "services/LeadsGenerationService";
import { useDispatch } from "react-redux";
import { setLoading } from "redux/slices/loadingSlice";
import { setAlert } from "redux/slices/alertSlice";
import CreateContact from "../Contactos/CreateContact";

const LeadsPage = () => {
  const [form, setForm] = useState({
    textQuery: "",
    limit: 100,
  });

  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({
    displayName: {
      text: "",
    },
    primaryTypeDisplayName: {
      text: "",
    },
    nationalPhoneNumber: "",
    website: "",
    shortFormattedAddress: "",
  });
  const dispatch = useDispatch();

  const handleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const fetchData = async () => {
    if (!form.textQuery) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Debes ingresar una búsqueda",
          show: true,
          type: "error",
        })
      );
    }

    dispatch(setLoading(true));

    const response = await LeadsGenerationService.getGoogleMaps(form);
    dispatch(setLoading(false));
    setContacts(response);
  };

  const saveContact = () => {
    // Busca los inputs dentro del componente CreateContact por nombre
    const firstNameInput = document.getElementsByName(
      "firstName"
    )[0] as HTMLInputElement;
    const tipoInput = document.getElementsByName(
      "companyType"
    )[0] as HTMLInputElement;
    const mobileInput = document.getElementsByName(
      "mobile"
    )[0] as HTMLInputElement;
    const websiteInput = document.getElementsByName(
      "website"
    )[0] as HTMLInputElement;
    const addressInput = document.getElementsByName(
      "address"
    )[0] as HTMLInputElement;

    // Asigna los valores del contacto seleccionado
    if (firstNameInput) firstNameInput.value = selectedContact.displayName.text;
    if (tipoInput)
      tipoInput.value = selectedContact.primaryTypeDisplayName.text;
    if (mobileInput) mobileInput.value = selectedContact.nationalPhoneNumber;
    if (websiteInput) websiteInput.value = selectedContact.website;
    if (addressInput)
      addressInput.value = selectedContact.shortFormattedAddress;
  };

  return (
    <div>
      <h1>Generación de leads</h1>

      <LayoutLeads>
        <div
          style={{
            width: "80%",
          }}
        >
          <FormArea>
            <label htmlFor="">Selecciona una fuente</label>
            <RadioOptions>
              <input
                type="radio"
                name="googleMaps"
                id="googleMaps"
                defaultChecked
              />
              <label htmlFor="googleMaps">Google Maps</label>
            </RadioOptions>
            <TextArea>
              <label htmlFor="">Ingresa una búsqueda por línea</label>
              <textarea
                name="textQuery"
                id="textQuery"
                onChange={(e: any) => handleForm(e)}
              ></textarea>
            </TextArea>
            <ResultsCounter>
              <label htmlFor="">Cantidad de resultados</label>
              <input
                type="number"
                name="limit"
                id="limit"
                defaultValue={100}
                onChange={(e: any) => handleForm(e)}
              />
            </ResultsCounter>
            <button
              onClick={() => {
                fetchData();
              }}
            >
              Realizar Búsqueda
            </button>
          </FormArea>
          <Table>
            <thead>
              <tr>
                <th>Nombre del contacto</th>
                <th>Tipo</th>
                <th>Teléfono</th>
                <th>Sitio Web</th>
                <th>Ciudad</th>
                <th>Accion</th>
              </tr>
            </thead>
            <tbody>
              {contacts?.length > 0 ? (
                contacts.map((contact: any) => (
                  <tr key={contact.id}>
                    <td>{contact.displayName.text || ""}</td>
                    <td>{contact.primaryTypeDisplayName?.text || ""}</td>
                    <td>{contact.nationalPhoneNumber || ""}</td>
                    <td
                      style={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {(
                        <a
                          href={contact.websiteUri}
                          target="_blank"
                          style={{
                            color: "blue",
                          }}
                        >
                          {contact.websiteUri}
                        </a>
                      ) || ""}
                    </td>
                    <td>{contact.shortFormattedAddress}</td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedContact(contact);
                          saveContact();
                        }}
                      >
                        Guardar
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>No hay datos</td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>
                  <button>Exportar CSV</button>
                  <button>Guardar Todos</button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
        <Instrucciones>
          <h2>Instrucciones</h2>
        </Instrucciones>
      </LayoutLeads>
      <CreateContact setShowCreateContact={() => {}} />
    </div>
  );
};

export default LeadsPage;
