import React, { useContext } from "react";
import VerCampanas from "./VerCampanas/VerCampanas";

import { CampanaContext } from "./context/CampanaContext";
import { useCampana } from "./hooks/useCampana";
import CrearCampanas from "./CrearCampanas/CrearCampanas";
import Reportes from "./Reportes/Reportes";

const CampanasDeMarketingPage = () => {
  const { activeView } = useContext(CampanaContext);

  return activeView === "CrearCampanas" ? (
    <CrearCampanas />
  ) : activeView === "Reportes" ? (
    <Reportes />
  ) : (
    <VerCampanas />
  );
};

const App = () => {
  return (
    <CampanaContext.Provider value={useCampana()}>
      <CampanasDeMarketingPage />
    </CampanaContext.Provider>
  );
};

export default App;
