import { IContact } from "../types/types";
import axiosInstance from "./axiosConfig";

export const createContact = async (formContact: any) => {
  try {
    const res = await axiosInstance.post("/contacts", {
      properties: formContact,
    });
    return res.data;
  } catch (error: any) {
    console.log(error);
    console.error(error.response?.data?.message || "Error creating contact");
    throw new Error(error.response?.data?.message || "Error creating contact");
  }
};

export const getContact = async (userId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get("/contacts/single/", {
      headers: { Authorization: `${token}` },
      params: { id: userId },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching contact");
    throw new Error(error.response?.data?.message || "Error fetching contact");
  }
};

export const getContacts = async (
  limit: number = 10,
  page: number = 0
): Promise<IContact[]> => {
  try {
    const token = localStorage.getItem("token");
    const organizationId = localStorage.getItem("organization");

    const res = await axiosInstance.get<IContact[]>("/contacts", {
      headers: { Authorization: `${token}` },
      params: { organizationId, limit, page },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching contacts");
    throw new Error(error.response?.data?.message || "Error fetching contacts");
  }
};

export const deleteContact = async (userId: string[]) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axiosInstance.delete("/contacts", {
      headers: { Authorization: `${token}` },
      data: { userId: userId },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting contact");
    throw new Error(error.response?.data?.message || "Error deleting contact");
  }
};

export const updateContact = async (contactId: string, formContact: any) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put("/contacts", {
      ...formContact,
      _id: contactId,
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating contact");
    throw new Error(error.response?.data?.message || "Error updating contact");
  }
};

export const searchContact = async (search: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get("/contacts/search", {
      headers: { Authorization: `${token}` },
      params: { organizationId: organizationId, search: search },
    });

    return res.data;
  } catch (error: any) {
    console.log(error, "ERROR");
    console.error(error.response?.data?.message || "Error searching contact");
    throw new Error(error.response?.data?.message || "Error searching contact");
  }
};

// Filtros avanzados
export const advancedFilterContacts = async (filters: any) => {
  const token = localStorage.getItem("token");

  try {
    const res = await axiosInstance.post("/contacts/advanced-filter", filters, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error filtering contacts");
    throw new Error(
      error.response?.data?.message || "Error filtering contacts"
    );
  }
};
