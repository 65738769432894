import React, { useEffect } from "react";
import { FaAngleDown, FaBell, FaSearch } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import {
  Navbar,
  MainMenu,
  MenuItem,
  SubMenu,
  SubMenuContainer,
  CircleActions,
  Notifications,
} from "./NavbarStyles";
import { useSocket } from "../../../redux/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

import NotificationMenu from "../NotificationMenu/NotificationMenu"; // Importa el componente
import {
  addNotification,
  showNotifications,
} from "../../../redux/slices/notificationSlice";
import { setSearch } from "../../../redux/slices/searchSlice";

const NavbarComponent: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { firstName, handleLogout, isAuthenticated } = useAuth();
  const notificationsCount = useSelector(
    (state: RootState) => state.notifications.unreadCount
  );

  const socket = useSocket();

  const isActive = (path: string) => location.pathname === path;
  const isParentActive = (paths: string[]) => paths.some(isActive);

  useEffect(() => {
    if (!socket) {
      console.log("no hay socket");
      return;
    }

    socket.on("newNotification", (data) => {
      console.log("nueva notificacion recibida", data);
      dispatch(
        addNotification({
          id: Date.now().toString(),
          title: data.title || "Nueva notificación",
          message:
            data.type === "whatsapp"
              ? "Nuevo mensaje de WhatsApp"
              : "Nuevo formulario recibido",
          read: false,
        })
      );
    });

    return () => {
      socket.off("newNotification");
    };
  }, [socket, dispatch]);

  const handleShowNotifications = () => {
    dispatch(showNotifications());
  };

  return (
    <>
      <Navbar>
        <div className="logo">
          <img src="/images/logo/icono.png" alt="Logo" width={50} />
        </div>
        <MainMenu>
          <SubMenuContainer>
            <MenuItem
              className={
                isParentActive(["/contactos", "/listas", "/contactos/leads"])
                  ? "active"
                  : ""
              }
            >
              Contactos <FaAngleDown />
            </MenuItem>
            <SubMenu>
              <MenuItem className={isActive("/contactos") ? "active" : ""}>
                <Link to="/contactos">Contactos</Link>
              </MenuItem>
              <MenuItem className={isActive("/listas") ? "active" : ""}>
                <Link to="/listas">Lista de contactos</Link>
              </MenuItem>
              <MenuItem
                className={isActive("/contactos/leads") ? "active" : ""}
              >
                <Link to="/contactos/leads">Generacion de Leads</Link>
              </MenuItem>
            </SubMenu>
          </SubMenuContainer>
          <SubMenuContainer>
            <MenuItem
              className={
                isParentActive([
                  "/negocios",
                  "/negocios/actividades",
                  "/negocios/cotizaciones",
                ])
                  ? "active"
                  : ""
              }
            >
              Negocios <FaAngleDown />
            </MenuItem>
            <SubMenu>
              <MenuItem className={isActive("/negocios") ? "active" : ""}>
                <Link to="/negocios">Negocios</Link>
              </MenuItem>
              <MenuItem
                className={isActive("/negocios/actividades") ? "active" : ""}
              >
                <Link to="/informes">Informes</Link>
              </MenuItem>
              <MenuItem
                className={isActive("/negocios/cotizaciones") ? "active" : ""}
              >
                <Link to="/negocios/cotizaciones">Cotizaciones</Link>
              </MenuItem>
            </SubMenu>
          </SubMenuContainer>

          {/* MENU DE CONVERSACIONES  */}
          <SubMenuContainer>
            <MenuItem
              className={
                isParentActive([
                  "/conversaciones/conversaciones",
                  "/conversaciones/fragmentos",
                  "/conversaciones/whatsapp",
                  "/conversaciones/formularios",
                ])
                  ? "active"
                  : ""
              }
            >
              Conversaciones <FaAngleDown />
            </MenuItem>
            <SubMenu>
              <MenuItem
                className={isActive("/conversaciones/whatsapp") ? "active" : ""}
              >
                <Link to="/conversaciones/whatsapp">WhatsApp</Link>
              </MenuItem>
              <MenuItem
                className={
                  isActive("/conversaciones/fragmentos") ? "active" : ""
                }
              >
                <Link to="/conversaciones/fragmentos">Fragmentos</Link>
              </MenuItem>
              <MenuItem className={isActive("/correo") ? "active" : ""}>
                <Link to="/conversaciones/correo">Correo</Link>
              </MenuItem>
              <MenuItem className={isActive("/formularios") ? "active" : ""}>
                <Link to="/conversaciones/formularios">Formularios</Link>
              </MenuItem>
            </SubMenu>
          </SubMenuContainer>
          {/* MENU DE MARKETING  */}
          <SubMenuContainer>
            <MenuItem
              className={
                isParentActive([
                  "/marketing/correos-masivos",
                  "/marketing/whatsapp-masivo",
                ])
                  ? "active"
                  : ""
              }
            >
              Marketing <FaAngleDown />
            </MenuItem>
            <SubMenu>
              <MenuItem
                className={
                  isActive("/marketing/whatsapp-masivo") ? "active" : ""
                }
              >
                <Link to="/marketing/whatsapp-masivo">WhatsApp Masivo</Link>
              </MenuItem>
              <MenuItem
                className={
                  isActive("/marketing/correos-masivos") ? "active" : ""
                }
              >
                <Link to="/marketing/correos-masivos">
                  Correos electronicos
                </Link>
              </MenuItem>
            </SubMenu>
          </SubMenuContainer>
          {/* MENU DE PLANEACION  */}
          <SubMenuContainer>
            <MenuItem
              className={
                isParentActive([
                  "/planeacion/proyectos",
                  "/planeacion/campanas-de-marketing",
                  "/planeacion/redes-sociales",
                ])
                  ? "active"
                  : ""
              }
            >
              Planeación <FaAngleDown />
            </MenuItem>
            <SubMenu>
              <MenuItem
                className={
                  isActive("/planeacion/campanas-de-marketing") ? "active" : ""
                }
              >
                <Link to="/planeacion/campanas-de-marketing">
                  Campañas de marketing
                </Link>
              </MenuItem>
              <MenuItem
                className={isActive("/planeacion/proyectos") ? "active" : ""}
              >
                <Link to="/planeacion/proyectos">Proyectos y Tareas</Link>
              </MenuItem>
              <MenuItem
                className={
                  isActive("/planeacion/redes-sociales") ? "active" : ""
                }
              >
                <Link to="/planeacion/redes-sociales">Redes Sociales</Link>
              </MenuItem>
            </SubMenu>
          </SubMenuContainer>
          <li>
            <Notifications onClick={handleShowNotifications}>
              <FaBell />
              <span>{notificationsCount}</span>
            </Notifications>
          </li>
          <li>
            <Notifications
              onClick={() => {
                dispatch(setSearch({ value: "1", status: "loading" }));
              }}
            >
              <FaSearch />
            </Notifications>
          </li>
          {isAuthenticated && (
            <li>
              <SubMenuContainer>
                <CircleActions>
                  <span>{firstName.charAt(0)}</span>
                </CircleActions>
                <SubMenu style={{ left: "auto", right: "-40px" }}>
                  <MenuItem className={isActive("/perfil") ? "active" : ""}>
                    <Link to="/perfil">Configuracion</Link>
                  </MenuItem>
                  <MenuItem
                    className={isActive("/proveedores") ? "active" : ""}
                  >
                    <Link to="/proveedores">Proveedores</Link>
                  </MenuItem>
                  <MenuItem className={isActive("/productos") ? "active" : ""}>
                    <Link to="/productos">Productos</Link>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
                </SubMenu>
              </SubMenuContainer>
            </li>
          )}
        </MainMenu>
      </Navbar>
      <NotificationMenu /> {/* Agregamos el componente NotificationMenu */}
    </>
  );
};

export default NavbarComponent;
