import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    color: ${({ theme }) => theme.colors.rosado};
  }

  svg {
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.rosado};
    font-size: 20px;
  }

  path {
    color: ${({ theme }) => theme.colors.rosado};
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;

  button {
    background-color: ${({ theme }) => theme.colors.rosado};
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.rosadoOscuro};
      cursor: pointer;
    }
  }

  div {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.gris3};
    border-radius: 5px;
    padding: 5px;
    min-width: 500px;

    svg {
      margin-right: 10px;
    }

    path {
      color: ${({ theme }) => theme.colors.gris3};
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      padding: 5px;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.colors.gris3};

  th {
    border: 1px solid ${({ theme }) => theme.colors.gris3};
    padding: 10px;
  }

  tr {
    border: 1px solid ${({ theme }) => theme.colors.gris3};
  }

  td {
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.gris3};
  }
`;

export const LearnMore = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  div {
    background-color: ${({ theme }) => theme.colors.grisClaro};
    border-radius: 15px;
    margin: 10px;
    padding: 20px;
    color: white;
  }
`;
