import { FaBoxes, FaColumns, FaSearch, FaTable } from "react-icons/fa";
import {
  Button,
  HeaderItem,
  Layout,
  SecondColumn,
  SecondColumnHeader,
  SecondColumnSearch,
  Title,
} from "./TareasPageStyles";
import TaskTable from "./TaskTable";
import ProjectColumn from "./ProjectColumn";
import { ProjectContext } from "./context/ProjectContext";
import { useProject } from "./context/hooks/useProjects";
import TaskKanban from "./Kanban/Kanban";
import { useContext, useState } from "react";
import AddTask from "./menu/AddTask";

const TareasPage = () => {
  const [viewSelector, setViewSelector] = useState("kanban");

  const {
    addTaskIsVisible,
    setAddTaskIsVisible,
    selectedProject,
    queryTasks,
    setQueryTasks,
  } = useContext(ProjectContext);

  return (
    <>
      <Title>
        <FaBoxes />
        <h1>Proyectos y Tareas</h1>
      </Title>
      <Layout>
        <ProjectColumn />
        <SecondColumn>
          {selectedProject && (
            <Button onClick={() => setAddTaskIsVisible(true)}>
              {" "}
              + Crear Tarea
            </Button>
          )}
          {addTaskIsVisible && <AddTask />}
          <SecondColumnHeader>
            <div style={{ cursor: "pointer" }}>
              <HeaderItem
                isActive={viewSelector === "kanban" && true}
                onClick={() => setViewSelector("kanban")}
              >
                <FaColumns />
                <label htmlFor="">Kanban</label>
              </HeaderItem>
              <HeaderItem
                isActive={viewSelector === "table" && true}
                onClick={() => setViewSelector("table")}
              >
                <FaTable />
                <label htmlFor="">Tabla</label>
              </HeaderItem>
            </div>
            <SecondColumnSearch>
              <FaSearch />
              <input
                type="text"
                name=""
                id=""
                value={queryTasks}
                placeholder="Buscar Tarea..."
                onChange={(e) => {
                  setQueryTasks(e.target.value);
                }}
              />
            </SecondColumnSearch>
          </SecondColumnHeader>
          {viewSelector === "table" && <TaskTable />}
          {viewSelector === "kanban" && <TaskKanban />}
        </SecondColumn>
      </Layout>
    </>
  );
};

export const App = () => {
  return (
    <ProjectContext.Provider value={useProject()}>
      <TareasPage />
    </ProjectContext.Provider>
  );
};

export default App;
