import styled from "styled-components";
import { theme } from "styles/theme";

export const Title = styled.div`
  margin-bottom: 20px;
  h1 {
    color: ${theme.colors.rosado};
  }

  svg {
    font-size: 30px;

    &:hover {
      cursor: pointer;
    }
  }

  path {
    color: ${theme.colors.rosado};
  }
`;

export const Container = styled.div`
  background-color: ${theme.colors.grisClaro};
  padding: 20px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
  height: calc(100% - 40px);
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  height: calc(100% - 40px);

  div {
    background-color: ${theme.colors.rosadoClaro};
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Publico = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;

  img {
    border-radius: 50%;
  }

  text-area {
    width: 100%;
  }
`;

export const Plataformas = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  height: calc(100% - 40px);
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  tr {
    border-bottom: 1px solid ${theme.colors.gris2};
  }

  td {
    padding: 10px;
    text-align: center;
  }
`;

export const Funnel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
`;
