import {
  ContentContainer,
  Footer,
  MessageBody,
  MessageHeader,
  Messages,
  Title,
  Tools,
} from "./ContentStyles";
import {
  FaFile,
  FaForward,
  FaReply,
  FaReplyAll,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { fetchEmail } from "../../../services/emailService";
import { formatDate } from "../../../utils/formatDate";

interface ContentProps {
  selectedEmail: any;
}

interface Attachments {
  filename: string;
  content: string;
  contentType: string;
}

interface Email {
  attachments: Attachments[];
  date: string;
  from: string;
  html: string;
  subject: string;
  text: string;
  to: string;
  uid: number;
}

const Content = ({ selectedEmail }: ContentProps) => {
  const [email, setEmail] = useState<Email>();

  const fetchSelectedEmail = async () => {
    const response = await fetchEmail(selectedEmail);

    setEmail(response);
  };

  useEffect(() => {
    fetchSelectedEmail();
  }, [selectedEmail]);

  // Function to create a URL for the attachment
  const createAttachmentUrl = (attachment: Attachments) => {
    const binaryString = atob(attachment.content);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }

    const blob = new Blob([bytes], { type: attachment.contentType });
    return URL.createObjectURL(blob);
  };

  return (
    <ContentContainer>
      <Tools>
        <div>
          <FaFile />
          <label htmlFor="">Archivar</label>
        </div>
        <div>
          <FaTrash />
          <label htmlFor="">Eliminar</label>
        </div>
        <div>
          <FaStar />
          <label htmlFor="">Marcar como favorito</label>
        </div>
      </Tools>

      {!email ? (
        <p>Cargando...</p>
      ) : (
        <>
          <Title>{email.subject}</Title>
          <Messages>
            <MessageHeader>
              <p>De: {email.from}</p>
              <p>Para: {email.to}</p>
              <p>Fecha: {formatDate(new Date(email.date))}</p>
              {
                // map attachments and create links
                email.attachments.map((item: Attachments) => {
                  const attachmentUrl = createAttachmentUrl(item);
                  return (
                    <a
                      href={attachmentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={item.filename}
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        fontSize: "12px",
                      }}
                    >
                      {item.filename}
                    </a>
                  );
                })
              }
            </MessageHeader>
            <MessageBody>
              <div dangerouslySetInnerHTML={{ __html: email.html }}></div>
            </MessageBody>
          </Messages>
          <Footer>
            <FaReply />
            <FaReplyAll />
            <FaForward />
            <h4>Escribir una respuesta...</h4>
          </Footer>
        </>
      )}
    </ContentContainer>
  );
};

export default Content;
