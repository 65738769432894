import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  padding: 10px;
  background-color: white;
  position: absolute; // Cambiado a absolute para que se superponga sobre el contenido
  top: -350px; // Ajustado para posicionar encima del texto "Fragmentos"
  left: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 1000; // Asegura que esté por encima de otros elementos
  overflow: hidden;
  margin-top: 0;
  padding-top: 0;

  h1 {
    font-size: 18px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
  }

  svg {
    margin-right: 5px;
    font-size: 12px;
  }
  path {
    color: white;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  border: 1px solid ${theme.colors.gris1};
  border-radius: 10px;
  padding: 5px;

  input {
    padding: 10px;
    border: none !important;
    outline: none;
    height: 20px;
    margin: 0px;
  }

  svg {
    margin-left: 10px;
    font-size: 20px;
  }
`;

export const FragmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
`;

export const Fragment = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: start;
  justify-content: start;
  margin: 0px 0px;
  padding: 10px;

  h3 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }

  &:hover {
    background-color: ${theme.colors.rosadoClaro};
    border-radius: 5px;
    cursor: pointer;
  }
`;
