import styled from "styled-components";
import { theme } from "../../styles/theme";

export const TopMenuStyle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0px;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: row;
  select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
  }
  input {
    width: 40%;
    min-width: 375px;
    max-width: 410px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
  }
  button {
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: 150px;

    &hover {
      background-color: ${theme.colors.rosadoOscuro};
    }
  }
  div {
    display: flex;
  }

  button:first-child {
    background-color: transparent;
    color: ${theme.colors.rosado};
    border: 1px solid ${theme.colors.rosado};
    display: flex;
    justify-content: center;
    align-items: center;

    path {
      color: ${theme.colors.rosado};
    }
  }
`;
