import { createContext } from "react";
import { IProject, ITask } from "../../../../types";

interface IProjectWithTasks {
  project: IProject;
  tasks: ITask[];
}

export interface IProjectContext {
  // Funciones y estados relacionados con proyectos
  getProjects: () => void;
  projects: IProject[];
  setProjects: React.Dispatch<React.SetStateAction<IProject[]>>;
  selectedProject: IProject | null;
  setSelectedProject: React.Dispatch<React.SetStateAction<IProject | null>>;
  createProject: (project: IProject) => void;
  deleteProject: () => void;
  searchProjects: (q: string) => void;
  queryProjects: string;
  setQueryProjects: React.Dispatch<React.SetStateAction<string>>;

  // Funciones y estados relacionados con tareas
  getAllTasks: () => void;
  getProject: (id: string) => void;
  project: IProjectWithTasks;
  setProject: React.Dispatch<React.SetStateAction<IProjectWithTasks>>;
  createTask: (newTask: ITask) => void;
  updateTask: (task: ITask) => void;
  deleteTask: (taskId: string) => void;
  searchTasks: (q: string) => void;
  queryTasks: string;
  setQueryTasks: React.Dispatch<React.SetStateAction<string>>;

  // Estados y funciones para control de UI
  openCreateProject: boolean;
  setOpenCreateProject: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenCreateProject: () => void;
  isVisibleCreateTask: boolean;
  setIsVisibleCreateTask: React.Dispatch<React.SetStateAction<boolean>>;
  menuIsVisible: boolean;
  setMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  addTaskIsVisible: boolean;
  setAddTaskIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  form: any;
  setForm: React.Dispatch<React.SetStateAction<any>>;
}

export const ProjectContext = createContext<IProjectContext>({
  // Funciones y estados relacionados con proyectos
  getProjects: () => {},
  projects: [],
  setProjects: () => {},
  selectedProject: null,
  setSelectedProject: () => {},
  createProject: () => {},
  deleteProject: () => {},
  searchProjects: () => {},
  queryProjects: "",
  setQueryProjects: () => {},

  // Funciones y estados relacionados con tareas
  getAllTasks: () => {},
  getProject: () => {},
  project: { project: {} as IProject, tasks: [] as ITask[] },
  setProject: () => {},
  createTask: () => {},
  updateTask: () => {},
  deleteTask: () => {},
  searchTasks: () => {},
  queryTasks: "",
  setQueryTasks: () => {},

  // Estados y funciones para control de UI
  openCreateProject: false,
  setOpenCreateProject: () => {},
  handleOpenCreateProject: () => {},
  isVisibleCreateTask: false,
  setIsVisibleCreateTask: () => {},
  menuIsVisible: false,
  setMenuIsVisible: () => {},
  addTaskIsVisible: false,
  setAddTaskIsVisible: () => {},
  form: {},
  setForm: () => {},
});
