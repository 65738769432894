"use client";
import { Modal, ModalContent } from "./CreatePipelineStyles";
import React, { useContext, useEffect, useMemo } from "react";
import { States, StatesTable } from "./StatesTable";
import { createPipeline } from "../../services/pipelineService";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";

export interface PipelineForm {
  title: string;
  states: States[];
}

export const CreatePipeline = () => {
  const { handleOpenCreatePipeline, getPipelinesState } =
    useContext(PipelineContext);
  const formRef = React.useRef<HTMLDivElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const [form, setForm] = React.useState<PipelineForm>({
    title: "",
    states: [],
  });
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const isFormValid = useMemo(() => {
    return form.title.length > 3 && form.states.length >= 3;
  }, [form]);

  const postPipeline = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createPipeline(form as any);

      await getPipelinesState();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleOpenCreatePipeline();
    }
  };

  useEffect(() => {
    const handleClickOutsideChild = (event: MouseEvent) => {
      const parentElement = modalRef.current;
      const childElement = formRef.current;

      if (parentElement && childElement) {
        if (
          parentElement.contains(event.target as Node) &&
          !childElement.contains(event.target as Node)
        ) {
          handleOpenCreatePipeline();
        }
      }
    };

    document.addEventListener("click", handleClickOutsideChild);

    return () => {
      document.removeEventListener("click", handleClickOutsideChild);
    };
  }, [handleOpenCreatePipeline]);
  return (
    <Modal ref={modalRef}>
      <ModalContent ref={formRef}>
        <h1>Crear Pipeline</h1>
        <form onSubmit={postPipeline}>
          <input
            type="text"
            placeholder="Nombre del pipeline"
            name="title"
            onChange={handleChange}
            value={form.title}
          />
          <h2
            style={{
              marginBottom: -20,
            }}
          >
            Estados
          </h2>
          <span>(Un Pipeline debe tener mínimo tres estados)</span>
          <StatesTable setForm={setForm} />
          <button disabled={!isFormValid || loading}>Crear Pipeline</button>
        </form>
      </ModalContent>
    </Modal>
  );
};
