import React, { useState, useEffect } from "react";
import { ContainerBar } from "./ContactDetailStyles";

const ConfirmationBar = ({ setIsEditing, handleEditSubmit, contact }: any) => {
  const [formValues, setFormValues] = useState(contact);

  useEffect(() => {
    setFormValues(contact);
  }, [contact]);

  const handleSubmit = () => {
    handleEditSubmit(formValues);
  };

  return (
    <ContainerBar>
      <button onClick={handleSubmit}>Guardar</button>
      <button
        onClick={() => {
          setIsEditing(false);
        }}
      >
        Cancelar
      </button>
    </ContainerBar>
  );
};

export default ConfirmationBar;
