import React, { useContext } from "react";
import {
  Container,
  Funnel,
  Grid,
  Plataformas,
  Publico,
  Title,
} from "./ReportesStyles";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { TextAreaGroup } from "../common/CommonStyles";
import { CampanaContext } from "../context/CampanaContext";

const Reportes = () => {
  const { setActiveView } = useContext(CampanaContext);

  return (
    <div>
      <Title>
        <FaArrowAltCircleLeft onClick={() => setActiveView("VerCampanas")} />
        <h1>Reporte de la Campaña: Adiffe 2023</h1>
        <p>25 Junio de 2024 13:32pm</p>
      </Title>

      <Container>
        <div
          style={{
            width: "50%",
          }}
        >
          <h3>Resumen de la Campaña</h3>
          <Grid>
            <div>
              <h4>$100.000</h4>
              <p>Ventas Generadas</p>
            </div>
            <div>
              <h4>$100.000</h4>
              <p>Total Gastado</p>
            </div>
            <div>
              <h4>$100.000</h4>
              <p>Negocios Cerrados</p>
            </div>
            <div>
              <h4>$100.000</h4>
              <p>ROAS</p>
            </div>
            <div>
              <h4>$100.000</h4>
              <p>ROI</p>
            </div>
            <div>
              <h4>$100.000</h4>
              <p>LEADS</p>
            </div>
          </Grid>
        </div>
        <div
          style={{
            width: "45%",
          }}
        >
          <h3>Publico Objetivo</h3>
          <Publico>
            <div
              style={{
                width: "30%",
              }}
            >
              <img
                src="https://via.placeholder.com/200x200.png"
                alt=""
                width={150}
              />
            </div>
            <div
              style={{
                width: "70%",
              }}
            >
              <TextAreaGroup>
                <label htmlFor="">Perfil Demografico</label>
                <textarea name="" id=""></textarea>
              </TextAreaGroup>
              <TextAreaGroup>
                <label htmlFor="">Intereses</label>
                <textarea name="" id=""></textarea>
              </TextAreaGroup>
            </div>
          </Publico>
        </div>
        <div
          style={{
            width: "50%",
          }}
        >
          <h3>Plataformas</h3>
          <Plataformas>
            <table>
              <thead>
                <tr>
                  <th>Plataforma</th>
                  <th>Impresiones</th>
                  <th>Clicks</th>
                  <th>CTR</th>
                  <th>Conversiones</th>
                  <th>Costo</th>
                  <th>CPA</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Facebook Ads</td>
                  <td>1000</td>
                  <td>100</td>
                  <td>10%</td>
                  <td>10</td>
                  <td>$100</td>
                  <td>$10</td>
                </tr>
                <tr>
                  <td>Google Ads</td>
                  <td>1000</td>
                  <td>100</td>
                  <td>10%</td>
                  <td>10</td>
                  <td>$100</td>
                  <td>$10</td>
                </tr>
              </tbody>
            </table>
          </Plataformas>
        </div>
        <div
          style={{
            width: "45  %",
          }}
        >
          <h3>Funnel</h3>
          <Funnel>
            <img
              src="https://via.placeholder.com/200x200.png"
              alt=""
              width={150}
            />
          </Funnel>
        </div>
      </Container>
    </div>
  );
};

export default Reportes;
