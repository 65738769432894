import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadInitialNotifications } from "../thunks/notificationThunk";

interface Notification {
  id: string;
  title: string;
  message: string;
  read: boolean;
}

interface NotificationsState {
  unreadCount: number;
  notifications: Notification[];
  loading: boolean;
  error: string | null;
  isVisible: boolean; // Nueva propiedad para manejar la visibilidad
}

const initialState: NotificationsState = {
  unreadCount: 0,
  notifications: [],
  loading: false,
  error: null,
  isVisible: false, // Inicialmente el menú de notificaciones no es visible
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
      if (!action.payload.read) {
        state.unreadCount += 1;
      }
    },
    markAsRead: (state, action: PayloadAction<string>) => {
      const notification = state.notifications.find(
        (n) => n.id === action.payload
      );
      if (notification && !notification.read) {
        notification.read = true;
        state.unreadCount -= 1;
      }
    },
    markAllAsRead: (state) => {
      state.notifications = [];
      state.unreadCount = 0;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (n) => n.id !== action.payload
      );
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    resetNotifications: (state) => {
      state.notifications = [];
      state.unreadCount = 0;
      state.loading = false;
      state.error = null;
    },
    showNotifications: (state) => {
      state.isVisible = true;
    },
    hideNotifications: (state) => {
      state.isVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadInitialNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        loadInitialNotifications.fulfilled,
        (state, action: PayloadAction<Notification[]>) => {
          state.loading = false;
          if (Array.isArray(action.payload)) {
            state.notifications = action.payload;
            state.unreadCount = action.payload.filter(
              (notification) => !notification.read
            ).length;
          } else {
            state.error = "Error loading notifications";
          }
        }
      )
      .addCase(loadInitialNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const {
  addNotification,
  markAsRead,
  markAllAsRead,
  removeNotification,
  setLoading,
  setError,
  resetNotifications,
  showNotifications, // Acción para mostrar notificaciones
  hideNotifications, // Acción para ocultar notificaciones
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
