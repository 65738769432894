import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { createContact } from "../../../services/contactService";
import { RootState } from "../../../redux/store";
import { Container, Form, FormFooter, FormHeader } from "./CreateContactStyles";
import { IContactProperty } from "../../../types/types";

interface CreateContactProps {
  setShowCreateContact: React.Dispatch<React.SetStateAction<boolean>>;
  setAffected?: React.Dispatch<React.SetStateAction<boolean>>;
  affected?: boolean;
  contactNumber?: string;
}

const CreateContact: React.FC<CreateContactProps> = ({
  setShowCreateContact,
  setAffected,
  affected,
  contactNumber,
}) => {
  const [form, setForm] = useState<Record<string, string>>({
    ...(contactNumber ? { mobile: contactNumber } : {}),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const properties = useSelector(
    (state: RootState) =>
      state.organization.contactProperties as IContactProperty[]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const submitHandler = async () => {
    setLoading(true);
    setError(null);
    try {
      const contactProperties = Object.keys(form).map((key) => ({
        key,
        value: form[key],
      }));
      const newContact = await createContact(contactProperties);

      setForm({});
      setShowCreateContact(false);
      if (setAffected) {
        setAffected(!affected);
      }
    } catch (err) {
      setError("Error al crear el contacto.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FormHeader>
        <h2>Crear Contacto</h2>
        <FaTimes onClick={() => setShowCreateContact(false)} />
      </FormHeader>
      <Form>
        {properties ? (
          properties.map((property) => (
            <div key={property.key}>
              {property.isVisible && (
                <>
                  <label htmlFor={property.key}>{property.label}</label>
                  <input
                    type="text"
                    name={property.key}
                    id={property.key}
                    onChange={handleChange}
                    value={
                      property.key === "mobile"
                        ? contactNumber || form[property.key] || ""
                        : form[property.key] || ""
                    }
                  />
                </>
              )}
            </div>
          ))
        ) : (
          <div>Cargando propiedades...</div>
        )}
      </Form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <FormFooter>
        <button onClick={submitHandler} disabled={loading}>
          {loading ? "Creando..." : "Crear"}
        </button>
        <button onClick={() => setShowCreateContact(false)}>Cancelar</button>
      </FormFooter>
    </Container>
  );
};

export default CreateContact;
