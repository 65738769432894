import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const ProductLinesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
`;

export const TableHeader = styled.thead`
  background-color: #f1f1f1;
  tr {
    th {
      padding: 15px;
      text-align: left;
      color: #333;
      font-weight: bold;
      border-bottom: 2px solid #ddd;
    }
  }
`;

export const TableBody = styled.tbody`
  tr {
    &:hover {
      background-color: #f9f9f9;
    }
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    td {
      padding: 15px;
      border-bottom: 1px solid #ddd;
      &:last-child {
        text-align: right;
      }
    }
  }
`;

export const AddProductRow = styled.tr`
  td {
    cursor: pointer;
    text-align: center;
    padding: 10px;

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.rosado};
    }

    p {
      text-align: left;
      color: ${({ theme }) => theme.colors.rosado};
      font-weight: bold;
    }
  }
`;

export const Modal = styled.div<{ position: { top: number; left: number } }>`
  position: absolute;
  top: ${({ position }) => position.top}px;
  left: ${({ position }) => position.left}px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

export const ModalHeader = styled.h2`
  margin-top: 0;
  color: ${({ theme }) => theme.colors.rosado};
`;

export const ModalList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  li {
    padding: 10px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

export const ModalCloseButton = styled.button`
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.rosado};
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.rosadoHover};
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.rosado};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.rosado};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const Observaciones = styled.textarea`
  width: 20%;
  padding: 10px;
  border: 1px solid ${theme.colors.gris2};
  border-radius: 10px;
  resize: none;
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.rosado};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
`;
