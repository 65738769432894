import { PropertiesContainer } from "../FilterContactsStyles";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { IContactProperty } from "../../../../types/types";

interface PropertiesProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  step: { results: number; properties: number; filter: number };
  setLabel: React.Dispatch<React.SetStateAction<string>>;
}

const Properties: React.FC<PropertiesProps> = ({
  setActiveStep,
  step,
  setLabel,
}) => {
  const properties = useSelector(
    (state: RootState) =>
      state.organization.contactProperties as IContactProperty[]
  );

  return (
    <PropertiesContainer>
      <h3>Filtros avanzados (0)</h3>
      <input type="text" name="search" id="search" placeholder="Buscar" />
      <h3>Propiedades</h3>
      <ul>
        {properties.map((property: IContactProperty, index: number) => (
          <li
            key={index}
            onClick={() => {
              setLabel(property.label);
              setActiveStep(step.filter);
            }}
          >
            {property.label}
          </li>
        ))}
      </ul>
    </PropertiesContainer>
  );
};

export default Properties;
