import React, { useContext } from "react";
import Informacion from "./Informacion";
import Navbar from "../common/Navbar";
import { Button, ButtonOutline } from "../common/CommonStyles";
import Publico from "./Publico";
import { CampanaContext } from "../context/CampanaContext";
import { useCampana } from "../hooks/useCampana";
import Plataforma from "./Plataforma";
import Metricas from "./Metricas";
import Resultados from "./Resultados";

const CrearCampanas = () => {
  const { activeStep, setActiveView, createCampana, newCampana } =
    useContext(CampanaContext);

  const handleSave = async () => {
    console.log("Guardando borrador");
    console.log("Nueva Campaña", newCampana);

    await createCampana(newCampana);
  };

  return (
    <div>
      <h1>Crear Campaña</h1>
      <div
        style={{
          margin: "20px 0",
        }}
      >
        <ButtonOutline onClick={handleSave}>Guardar Borrador</ButtonOutline>
        <ButtonOutline>Eliminar</ButtonOutline>
        <ButtonOutline
          onClick={() => {
            setActiveView("Reportes");
          }}
        >
          Informe
        </ButtonOutline>
        <Button
          onClick={() => {
            setActiveView("Campanas");
          }}
        >
          Salir
        </Button>
      </div>
      <Navbar />
      <div
        style={{
          backgroundColor: "#F1f1f1",
          padding: "20px",
          height: "100%",
        }}
      >
        {activeStep === "Informacion" && <Informacion />}
        {activeStep === "Publico" && <Publico />}
        {activeStep === "Plataforma" && <Plataforma />}
        {activeStep === "Metricas" && <Metricas />}
        {activeStep === "Resultados" && <Resultados />}
      </div>
    </div>
  );
};

export default CrearCampanas;
