import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface OrganizationState {
  _id: string;
  companyName: string;
  address: {
    address: string;
    city: string;
    state: string;
    zipCode: string;
  };
  phone: string;
  whatsapp: string;
  email: string;
  employees: any[];
  contactProperties: { key: string; label: string }[];
  purchaseSettings: {
    purchaseNumber: number;
    paymentTerms: Array<string>;
    currency: string;
    notes: string;
  };
  quotationSettings: {
    quotationNumber: number;
    paymentTerms: Array<string>;
    currency: string;
    notes: string;
  };
  settings?: any;
}

const initialState: OrganizationState = {
  _id: "",
  companyName: "",
  address: {
    address: "",
    city: "",
    state: "",
    zipCode: "",
  },
  phone: "",
  whatsapp: "",
  email: "",
  employees: [],
  contactProperties: [
    {
      label: "Nombre",
      key: "firstName",
    },
  ],
  purchaseSettings: {
    purchaseNumber: 0,
    paymentTerms: [],
    currency: "",
    notes: "",
  },
  quotationSettings: {
    quotationNumber: 0,
    paymentTerms: [],
    currency: "",
    notes: "",
  },
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<OrganizationState>) => {
      localStorage.setItem("organizationId", action.payload._id);
      state._id = action.payload._id;
      state.companyName = action.payload.companyName;
      state.address = action.payload.address;
      state.phone = action.payload.phone;
      state.whatsapp = action.payload.whatsapp;
      state.email = action.payload.email;
      state.employees = action.payload.employees;
      state.contactProperties = action.payload.contactProperties;
      state.quotationSettings = action.payload.settings.quotations;
      state.purchaseSettings = action.payload.settings.purchases;
    },
    updateContactProperties: (
      state,
      action: PayloadAction<{ key: string; label: string }[]>
    ) => {
      state.contactProperties = action.payload;
    },
    updateQuotationNumber: (state) => {
      state.quotationSettings.quotationNumber += 1;
    },
    updatePurchaseNumber: (state) => {
      state.purchaseSettings.purchaseNumber += 1;
    },
  },
});

export const {
  setOrganization,
  updateContactProperties,
  updateQuotationNumber,
  updatePurchaseNumber,
} = organizationSlice.actions;

export default organizationSlice.reducer;
