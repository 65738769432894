import React, { useContext } from "react";
import { PipelineContext } from "../../../pages/Negocios/Negocios/context/PipelineContext";

export const DynamicForm = () => {
  const { setFields, fields, dealsFieldsByPipeline, form } =
    useContext(PipelineContext);

  return (
    <>
      {dealsFieldsByPipeline.map((field) => {
        const handleChange = (e: any) => {
          const currentField = fields.find((f: any) => f.fieldId === field._id); // Comparar con fieldId._id
          if (currentField) {
            setFields((prevFields) =>
              prevFields.map(
                (f: any) =>
                  f.fieldId === field._id ? { ...f, value: e.target.value } : f // Comparar con fieldId._id
              )
            );
          } else {
            setFields((prevFields) => [
              ...prevFields,
              {
                value: e.target.value,
                fieldId: field._id,
              },
            ]);
          }
        };

        return (
          <div key={field._id}>
            <label htmlFor={field.key}>
              {field.name}
              {field.required ? (
                <span
                  style={{
                    color: "red",
                  }}
                >
                  *
                </span>
              ) : null}
            </label>
            {field.type === "select" ? (
              <select
                name={field.key}
                id={field.key}
                value={
                  fields.find((f: any) => f.fieldId === field._id)?.value || ""
                } // Comparar con fieldId._id
                onChange={handleChange}
              >
                <option value="">Seleccione una opción</option>
                {field.options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="text"
                name={field.key}
                id={field.key}
                value={
                  fields.find((f: any) => f.fieldId === field._id)?.value || ""
                } // Comparar con fieldId._id
                onChange={handleChange}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
