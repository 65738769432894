import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FaTimes,
  FaCheckCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import {
  AlertContainer,
  AlertContent,
  AlertText,
  CloseIconContainer,
} from "./AlertNotificationStyles";
import { setAlert } from "../../../redux/slices/alertSlice";
import { AlertNotificationProps } from "../../../types/types";

const AlertNotification = () => {
  const [isExiting, setIsExiting] = useState<any>(false);
  const dispatch = useDispatch();
  const { title, message, show, type } = useSelector(
    (state: any) => state.alert
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 5000); // 5 segundos antes de iniciar la animación de salida

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isExiting) {
      const timer = setTimeout(() => {
        dispatch(setAlert({ title: "", text: "", show: false }));
      }, 500); // Duración de la animación de salida

      return () => clearTimeout(timer);
    }
  }, [isExiting, dispatch]);

  const renderIcon = () => {
    switch (type) {
      case "error":
        return <FaExclamationCircle />;
      case "success":
        return <FaCheckCircle />;
      case "warning":
        return <FaExclamationTriangle />;
      case "info":
        return <FaInfoCircle />;
      default:
        return null;
    }
  };

  return (
    <AlertContainer type={type} isexiting={isExiting ? isExiting : undefined}>
      <AlertContent>
        {renderIcon()}
        <AlertText>
          <h1>{title}</h1>
          <p>{message}</p>
        </AlertText>
        <FaTimes onClick={() => setIsExiting(true)} />
      </AlertContent>
    </AlertContainer>
  );
};

export default AlertNotification;
