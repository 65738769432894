import styled from "styled-components";

export const ListContainer = styled.div`
  background-color: white;
  border-right: 1px solid #000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 400px;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
`;

export const ListItem = styled.div`
  border-bottom: 1px solid #000;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
  min-height: 100px;
  overflow: hidden;
  margin-bottom: 10px;
  padding-top: 10px;
  width: 100%;
`;

export const SelectedListItem = styled(ListItem)`
  background-color: #e0e0e0; /* Color de fondo para el item seleccionado */
`;

export const ListHeader = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    width: 100%;
  }

  p {
    font-size: 12px;
    word-break: break-word;
    margin: 0;
  }

  p:first-child {
    font-weight: bold;
    font-size: 17px;
  }

  p:last-child {
    color: grey;
  }
`;

export const EmailBody = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  width: 100%;
  word-break: break-word;
`;

export const ListDate = styled.p`
  display: flex;
  /* width: 30%; */
  justify-content: end;
  align-items: center;
  font-size: 12px;
`;
