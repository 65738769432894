import React, { useState } from "react";
import { Button, ChatHeader as Header } from "./WhatsappStyles";
import { FaUser } from "react-icons/fa";
import CreateContact from "../../Contactos/Contactos/CreateContact";
import { deleteChat } from "../../../services/chatService";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/slices/alertSlice";
import EditContact from "../../Contactos/Contactos/EditContact";
import { useChat } from "./context/WhatsAppContext";

const ChatHeader: React.FC = () => {
  const { selectedChat, setAffected, affected, handleDeleteChat } = useChat();
  const dispatch = useDispatch();
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [showEditContact, setShowEditContact] = useState(false);

  if (!selectedChat) {
    return null; // Si no hay un chat seleccionado, no se muestra el header
  }

  return (
    <>
      <Header>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaUser />
          <div>
            {selectedChat.name ? (
              <p>
                {selectedChat.name}{" "}
                <span style={{ color: "grey" }}>
                  ({selectedChat.possibleName})
                </span>
              </p>
            ) : (
              <p>Sin Contacto</p>
            )}
            <p
              style={{
                color: "grey",
                fontSize: "16px",
                margin: 0,
              }}
            >
              {selectedChat.contact}
            </p>
          </div>
        </div>
        <div>
          <Button
            onClick={() =>
              selectedChat.contactId
                ? setShowEditContact(true)
                : setShowCreateContact(true)
            }
          >
            {selectedChat.contactId ? "Ver Contacto" : "Crear Contacto"}
          </Button>
          <Button onClick={handleDeleteChat}>Eliminar Chat</Button>
        </div>

        {showCreateContact && (
          <CreateContact
            setShowCreateContact={setShowCreateContact}
            setAffected={setAffected}
            affected={affected}
            contactNumber={selectedChat.contact}
          />
        )}

        {showEditContact && (
          <EditContact
            setAffected={setAffected}
            affected={affected}
            contactId={selectedChat.contactId}
            setIsEditting={setShowEditContact}
          />
        )}
      </Header>
    </>
  );
};

export default ChatHeader;
