import React, { useState, useEffect, useRef } from "react";
import { FaTrash } from "react-icons/fa";
import {
  Table,
  TableHeader,
  TableBody,
  AddProductRow,
  Modal,
  ModalList,
  Input,
} from "./ProductLinesStyles";
import { getProducts } from "../../../services/productService";
import { IProduct } from "../../../types/types";

interface IOptionalProductLine {
  product: IProduct | null;
  quantity: number;
  unitPrice: number;
  subtotal: number;
}

const initialOptionalProductLine: IOptionalProductLine = {
  product: null,
  quantity: 1,
  unitPrice: 0,
  subtotal: 0,
};

const OptionalProductLines: React.FC<{
  optionalProductLines: IOptionalProductLine[];
  setOptionalProductLines: React.Dispatch<
    React.SetStateAction<IOptionalProductLine[]>
  >;
}> = ({ optionalProductLines, setOptionalProductLines }) => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >(null);
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const rowRefs = useRef<HTMLTableRowElement[]>([]);

  const fetchProducts = async () => {
    const fetchedProducts = await getProducts();
    setProducts(fetchedProducts);
    setFilteredProducts(fetchedProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleProductSelect = (product: IProduct) => {
    if (selectedProductIndex === null) return;
    const updatedOptionalProductLines = [...optionalProductLines];
    updatedOptionalProductLines[selectedProductIndex] = {
      ...updatedOptionalProductLines[selectedProductIndex],
      product,
      unitPrice: product.unitPrice,
      subtotal:
        product.unitPrice *
        updatedOptionalProductLines[selectedProductIndex].quantity,
    };
    setOptionalProductLines(updatedOptionalProductLines);
    setShowProductModal(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    field: keyof IOptionalProductLine
  ) => {
    const updatedOptionalProductLines = [...optionalProductLines];
    const value = parseFloat(e.target.value);
    updatedOptionalProductLines[index] = {
      ...updatedOptionalProductLines[index],
      [field]: value,
      subtotal:
        field === "quantity" || field === "unitPrice"
          ? updatedOptionalProductLines[index].unitPrice * value
          : updatedOptionalProductLines[index].subtotal,
    };
    setOptionalProductLines(updatedOptionalProductLines);
  };

  const handleRowClick = (index: number) => {
    setSelectedProductIndex(index);
  };

  const handleProductInputFocus = (
    e: React.FocusEvent<HTMLInputElement>,
    index: number
  ) => {
    const rect = e.target.getBoundingClientRect();
    setModalPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setShowProductModal(true);
    setSelectedProductIndex(index);
  };

  const handleAddProductLine = () => {
    if (
      optionalProductLines.some((line: IOptionalProductLine) => !line.product)
    ) {
      alert("Debe seleccionar un producto antes de agregar uno nuevo.");
      return;
    }
    setOptionalProductLines([
      ...optionalProductLines,
      initialOptionalProductLine,
    ]);
  };

  const handleDeleteProductLine = (index: number) => {
    const updatedOptionalProductLines = optionalProductLines.filter(
      (_: any, i: any) => i !== index
    );
    setOptionalProductLines(updatedOptionalProductLines);
  };

  return (
    <div>
      <Table>
        <TableHeader>
          <tr>
            <th>Producto</th>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Precio unitario</th>
            <th>Subtotal</th>
            <th></th>
          </tr>
        </TableHeader>
        <TableBody>
          {optionalProductLines.map(
            (line: IOptionalProductLine, index: any) => (
              <tr
                key={index}
                ref={(el) => (rowRefs.current[index] = el!)}
                onClick={() => handleRowClick(index)}
              >
                <td>
                  <Input
                    type="text"
                    value={line.product?.name || ""}
                    onFocus={(e) => handleProductInputFocus(e, index)}
                    readOnly
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    value={line.product?.description || ""}
                    readOnly
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    value={line.quantity}
                    onChange={(e) => handleInputChange(e, index, "quantity")}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    value={line.unitPrice}
                    onChange={(e) => handleInputChange(e, index, "unitPrice")}
                  />
                </td>
                <td>{line.subtotal.toFixed(2)}</td>
                <td>
                  <FaTrash onClick={() => handleDeleteProductLine(index)} />
                </td>
              </tr>
            )
          )}
          <AddProductRow onClick={handleAddProductLine}>
            <td colSpan={6}>
              <p>Agregar un producto opcional</p>
            </td>
          </AddProductRow>
        </TableBody>
      </Table>

      {showProductModal && (
        <Modal position={modalPosition}>
          <ModalList>
            {filteredProducts.map((product) => (
              <li
                key={product._id}
                onClick={() => handleProductSelect(product)}
              >
                {product.name}
              </li>
            ))}
          </ModalList>
        </Modal>
      )}
    </div>
  );
};

export default OptionalProductLines;
