import React from "react";
import { ChatProvider } from "./context/WhatsAppContext";
import ChatList from "./ChatList";
import MessageList from "./MessageList";
import ChatHeader from "./ChatHeader";
import ChatFooter from "./ChatFooter";
import CreateDeals from "../../../components/negocios/sideComponents/CreateDeals";
import { PipelineContext } from "../../../pages/Negocios/Negocios/context/PipelineContext";

import { Container, Button } from "./WhatsappStyles";
import { usePipelines } from "../../../pages/Negocios/Negocios/hooks/usePipelines";

const WhatsappPage: React.FC = () => {
  const pipelineContextInstance = usePipelines();

  return (
    <ChatProvider>
      <PipelineContext.Provider value={pipelineContextInstance}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1>Conversaciones</h1>

          <Button
            onClick={() =>
              pipelineContextInstance.setIsVisibleCreateDeal(
                !pipelineContextInstance.isVisibleCreateDeal
              )
            }
          >
            Crear Negocio
          </Button>
        </div>
        {pipelineContextInstance.isVisibleCreateDeal && <CreateDeals />}
      </PipelineContext.Provider>

      <Container>
        <ChatList />
        <div
          style={{
            width: "70%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ChatHeader />
          <MessageList />
          <ChatFooter />
        </div>
      </Container>
    </ChatProvider>
  );
};

export default WhatsappPage;
