import { createContext } from "react";

export interface ICampanaContext {
  activeView: string;
  setActiveView: React.Dispatch<React.SetStateAction<string>>;
  activeStep: string;
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
  campanas: any[];
  campana: any | null;
  fetchCampanas: () => void;
  fetchCampana: (id: string) => void;
  createCampana: (newCampana: any) => void;
  updateCampana: (id: string, updatedCampana: any) => void;
  deleteCampana: (id: string) => void;
  loading: boolean;
  error: string | null;
  setNewCampana: React.Dispatch<React.SetStateAction<any>>;
  newCampana: any;
  handleChange: any;
}

export const CampanaContext = createContext<ICampanaContext>({
  activeView: "publico",
  setActiveView: () => {},
  activeStep: "Informacion",
  setActiveStep: () => {},
  campanas: [],
  campana: null,
  fetchCampanas: () => {},
  fetchCampana: () => {},
  createCampana: () => {},
  updateCampana: () => {},
  deleteCampana: () => {},
  loading: false,
  error: null,
  setNewCampana: () => {},
  newCampana: {},
  handleChange: () => {},
});
