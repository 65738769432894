import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEnvelope } from "react-icons/fa";
import {
  AccountContainer,
  InputContainer,
  LoginButton,
} from "../../pages/Auth/Login/LoginStyles";
import { theme } from "../../styles/theme";

const ForgotPasswordComponent: React.FC<{
  setForgotPassword: (value: boolean) => void;
}> = ({ setForgotPassword }) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    // Aquí puedes añadir la lógica para manejar la recuperación de contraseña.
    console.log("Recuperación de contraseña para:", email);
  };

  return (
    <>
      <img
        src="/images/logo/logo-rosado.png"
        alt="Logo"
        style={{ marginBottom: "50px" }}
      />
      <h1>Recupera tu contraseña</h1>
      <p>
        Ingrese la dirección de correo electrónico a la que desea que se envíe
        la información para restablecer su contraseña.
      </p>
      <InputContainer style={{ margin: "30px 0px" }}>
        <FaEnvelope />
        <input
          type="text"
          name="email"
          id="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </InputContainer>

      <LoginButton
        style={{ margin: "30px 0px" }}
        onClick={handleForgotPassword}
      >
        Recuperar Contraseña
      </LoginButton>
      <AccountContainer>
        <p>¿No tienes una cuenta?</p>
        <p>
          <a href="/register" style={{ color: theme.colors.rosado }}>
            Crear cuenta
          </a>{" "}
          o{" "}
          <a
            href="/login"
            style={{ color: theme.colors.rosado }}
            onClick={() => setForgotPassword(false)}
          >
            Iniciar Sesión
          </a>
        </p>
      </AccountContainer>
    </>
  );
};

export default ForgotPasswordComponent;
