import axios from "axios";
import axiosInstance from "./axiosConfig";

interface FormUser {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  password: string;
}
// Example of loginUser function
export const loginUser = async ({
  email,
  password,
  rememberMe,
}: {
  email: string;
  password: string;
  rememberMe: boolean;
}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/auth/login`,
      { email, password, rememberMe }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Example of newUser function
export const newUser = async (user: FormUser) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/auth/register`,
      user
    );
    // Assuming response.data contains { user, token }
    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Example of verifyToken function
export const verifyToken = async (token: string) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/auth/verify-token`,
      null,
      {
        headers: { Authorization: `${token}` },
      }
    );
    // Assuming response.data contains { user, token }

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};
