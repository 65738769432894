import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: transparent;
  border-spacing: 0;
  border-color: ${theme.colors.gris3};
  min-height: 100%;
  width: 100%;
  table-layout: fixed;

  th,
  td {
    overflow: hidden;
    white-space: nowrap;
  }

  thead {
    background-color: ${theme.colors.gris3};
    color: black;
    th {
      text-align: center;
      text-transform: capitalize;
    }
  }

  th {
    text-align: left;
    padding: 8px;
    border: 1px solid ${theme.colors.gris3};
  }

  td {
    text-align: left;
    padding: 10px;
    border: 1px solid ${theme.colors.gris3};
    overflow: auto;

    input {
      width: 100%;
      background-color: ${theme.colors.gris3};
      text-align: center;
      padding: 10px;
      border: none;
      border-radius: 10px;
    }
  }
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 20px 0px;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    transition: ease all 0.3s;
    cursor: pointer;
  }
`;

export const DeleteButton = styled.button`
  padding: 10px;
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 20px 0px;

  svg {
    margin-right: 10px;
  }

  path {
    color: white;
  }

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    transition: ease all 0.3s;
    cursor: pointer;
  }
`;
