import { createSlice } from "@reduxjs/toolkit";

interface SearchState {
  value: string;
  status: "idle" | "loading" | "failed";
}

const initialState: SearchState = {
  value: "",
  status: "idle",
};

export const searchSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.value = action.payload.value;
      state.status = action.payload.status;
    },
  },
});

export const { setSearch } = searchSlice.actions;

export default searchSlice.reducer;
