import React, { useEffect, useState } from "react";
import formsService from "../../../services/formsService";
import { IForm } from "../../../types/types";
import { Button, Table } from "./FormsStyles";
import { Link, useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/slices/alertSlice";

const FormsPage = () => {
  const [forms, setForms] = useState<IForm[]>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchForms = async () => {
    const response = await formsService.getForms();
    setForms(response);
  };

  const deleteHandler = async (id: string) => {
    await formsService.deleteForm(id);

    dispatch(
      setAlert({
        title: "Exito!",
        message: "Formulario eliminado",
        type: "success",
        show: true,
      })
    );
    fetchForms();
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      dispatch(
        setAlert({
          title: "Copiado!",
          message: "El endpoint ha sido copiado al portapapeles.",
          type: "success",
          show: true,
        })
      );
    });
  };

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <h1>Formularios</h1>
        <p>
          Con los formularios, puedes recibir la informacion desde tu sitio web
          directamente en tu CRM.
        </p>
      </div>
      <Link to="/formularios/crear">
        <Button>Crear Formulario</Button>
      </Link>
      <Table>
        <thead>
          <tr>
            <th>Nombre del formulario</th>
            <th>Fecha de creación</th>
            <th>Usuario</th>
            <th>Endpoint</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {forms?.length ? (
            forms.map((form: IForm) => (
              <tr key={form._id}>
                <td
                  onClick={() => {
                    navigate("/formularios/" + form._id);
                  }}
                  style={{ color: "#D1345B", cursor: "pointer" }}
                >
                  {form.name}
                </td>
                <td>{new Date(form.createdAt).toLocaleDateString()}</td>
                <td>{form.userId?.firstName + " " + form.userId?.lastName}</td>
                <td>
                  <input
                    type="text"
                    name="endpoint"
                    id="endpoint"
                    value={`${process.env.REACT_APP_SERVER}/forms/submit/${form._id}?token=${form.organizationId?._id}`}
                    onClick={() => {
                      handleCopyToClipboard(
                        `${process.env.REACT_APP_SERVER}/forms/submit/${form._id}?token=${form.organizationId?._id}`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    title="Click para copiar el endpoint"
                    readOnly
                  />
                </td>
                <td style={{ width: "10% !important" }}>
                  <FaTrash
                    style={{
                      cursor: "pointer",
                      color: "red",
                    }}
                    onClick={() => deleteHandler(form._id as string)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No hay formularios</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default FormsPage;
