import { FaBoxes, FaSearch } from "react-icons/fa";
import { Head, LearnMore, Table, Title } from "./VerCampanasStyles";
import { useContext } from "react";
import { CampanaContext } from "../context/CampanaContext";
import { ICampana } from "types";

const VerCampanas = () => {
  const { setActiveView, campanas } = useContext(CampanaContext);

  // Función para calcular el ROI
  const calculateROI = (
    totalRevenueGenerated: number,
    totalExpenses: number
  ) => {
    if (totalExpenses === 0) return 0; // Evitar división por cero
    return ((totalRevenueGenerated - totalExpenses) / totalExpenses) * 100;
  };

  // Función para calcular el ROAS
  const calculateROAS = (totalRevenueGenerated: number, budget: number) => {
    if (budget === 0) return 0; // Evitar división por cero
    return totalRevenueGenerated / budget;
  };

  return (
    <div>
      <Title>
        <FaBoxes />
        <h1>Campañas de Marketing</h1>
      </Title>

      <Head>
        <button onClick={() => setActiveView("CrearCampanas")}>
          Crear Campaña
        </button>
        <div>
          <FaSearch />
          <input type="text" placeholder="Buscar Campaña" />
        </div>
      </Head>
      <Table>
        <thead>
          <tr>
            <th>Nombre de la Campaña </th>
            <th>Fecha de Inicio</th>
            <th>Fecha de Fin</th>
            <th>Etapa</th>
            <th>Presupuesto</th>
            <th>Gasto</th>
            <th>ROI</th>
            <th>ROAS</th>
          </tr>
        </thead>
        <tbody>
          {campanas.length > 0 ? (
            campanas.map((campana: ICampana) => (
              <tr key={campana._id}>
                <td onClick={() => {}}>{campana.name}</td>
                <td>
                  {new Date(campana.startDate).toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </td>
                <td>
                  {new Date(campana.endDate).toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </td>
                <td>{campana.state}</td>
                <td>{campana.budget}</td>
                <td>{campana.totalExpenses}</td>
                <td>
                  {campana.totalRevenueGenerated !== undefined &&
                  campana.totalExpenses !== undefined
                    ? calculateROI(
                        campana.totalRevenueGenerated,
                        campana.totalExpenses
                      ).toFixed(2)
                    : "N/A"}
                  %
                </td>
                <td>
                  {campana.totalRevenueGenerated !== undefined &&
                  campana.budget !== undefined
                    ? calculateROAS(
                        campana.totalRevenueGenerated,
                        campana.budget
                      ).toFixed(2)
                    : "N/A"}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>No hay campañas creadas aún</td>
            </tr>
          )}
        </tbody>
      </Table>

      <div>
        <h2
          style={{
            marginTop: "80px",
          }}
        >
          Aprende sobre Campañas de Marketing
        </h2>
        <LearnMore>
          <div>
            <h3>¿Qué es una Campaña de Marketing?</h3>
            <p>
              Una campaña de marketing es una serie de acciones coordinadas que
              promocionan un producto o servicio a través de diferentes canales
              para lograr un objetivo específico.
            </p>
          </div>
          <div>
            <h3>¿Cómo crear una Campaña de Marketing?</h3>
            <p>
              Para crear una campaña de marketing efectiva, es importante
              definir el objetivo, el público objetivo, el presupuesto y las
              métricas de éxito.
            </p>
          </div>
          <div>
            <h3>¿Cómo crear una Campaña de Marketing?</h3>
            <p>
              Para crear una campaña de marketing efectiva, es importante
              definir el objetivo, el público objetivo, el presupuesto y las
              métricas de éxito.
            </p>
          </div>
          <div>
            <h3>¿Cómo crear una Campaña de Marketing?</h3>
            <p>
              Para crear una campaña de marketing efectiva, es importante
              definir el objetivo, el público objetivo, el presupuesto y las
              métricas de éxito.
            </p>
          </div>
        </LearnMore>
      </div>
    </div>
  );
};

export default VerCampanas;
