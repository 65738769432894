import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Card = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  h3 {
    font-size: 15px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3:last-child {
    font-size: 12px !important;
    font-weight: 300;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  path {
    color: ${theme.colors.rosado};
  }
  svg {
    font-size: 25px;
    color: ${theme.colors.rosado};
  }
  h1 {
    color: ${theme.colors.rosado};
    font-size: 25px;
    margin: 0px 10px;
  }
`;

export const Head = styled.div`
  display: flex;
  justify-content: center;

  div:first-child {
    display: flex;
    align-items: center;
    h1 {
      color: ${theme.colors.rosado};
      font-size: 25px;
    }

    path:first-child {
      font-size: 25px;
      color: ${theme.colors.rosado};
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.rosado};
    border-radius: 100%;
    height: 40px;
    width: 40px;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
      cursor: pointer;
      transition: 0.3s;
    }

    path {
      color: white !important;
    }
    svg {
      font-size: 18px;
      color: white;
    }
  }
`;

export const MenuList = styled.div`
  display: flex;
  margin-bottom: 15px;

  h4 {
    color: ${theme.colors.gris1};
    font-weight: 500;
    margin: 0px 10px;
    cursor: pointer;
  }
`;

export const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  gap: 20px;
`;

export const FirstColumn = styled.div`
  width: 20%;
  height: 75vh;
  background-color: ${theme.colors.grisClaro};
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 15px;
`;

export const SecondColumn = styled.div`
  width: 80%;
  height: 75vh;
  background-color: ${theme.colors.grisClaro};
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 15px;
  overflow: auto;
`;

export const Title2 = styled.div`
  text-align: center;
  margin-bottom: 30px;
  h3 {
    color: ${theme.colors.rosado};
    font-size: 20px;
    text-align: center;
  }
`;

export const InputLabel = styled.div`
  margin: 10px;

  label {
    font-weight: 600;
  }

  input {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 5px;
    font-size: 17px;
    border-bottom: 1px solid ${theme.colors.rosadoClaro};

    &:focus {
      outline: none;
      border-bottom: 1px solid ${theme.colors.rosado};
    }
  }
`;

export const Properties = styled.div`
  height: calc(100% - 30px);
  overflow: auto;
`;

export const ContainerBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background-color: ${theme.colors.gris3};
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 20px;

  button {
    border: none;
    margin: 0px 10px;
    color: white;
    min-width: 100px;
  }

  button:first-child {
    background-color: ${theme.colors.rosado};
    border-radius: 10px;
    padding: 10px 20px;
  }

  button:last-child {
    background-color: ${theme.colors.gris1};
    border-radius: 10px;
    padding: 10px 20px;
  }
`;

export const DealsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: ${theme.colors.gris3};
    color: white;
  }

  th,
  td {
    border: 1px solid ${theme.colors.gris3};
    padding: 10px;
    text-align: center;
  }

  tbody tr {
    &:hover {
      background-color: ${theme.colors.grisClaro};
    }
  }
`;
