import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  path {
    color: ${theme.colors.rosado};
  }
  svg {
    font-size: 25px;
    color: ${theme.colors.rosado};
  }
  h1 {
    color: ${theme.colors.rosado};
    font-size: 25px;
    margin: 0px 10px;
  }
`;

export const Layout = styled.div`
  display: flex;
  margin: 10px 0px;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  gap: 20px;
`;

export const FormColumn = styled.div`
  width: 20%;
  height: calc(100vh - 350px);
  overflow: auto;

  button {
    width: 100%;
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    padding: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 5px;
  }
`;

export const InputLabel = styled.div`
  margin: 10px;

  label {
    font-weight: 600;
  }

  input,
  select {
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 5px;
    font-size: 17px;
    border-bottom: 1px solid ${theme.colors.rosadoClaro};

    &:focus {
      outline: none;
      border-bottom: 1px solid ${theme.colors.rosado};
    }
  }
`;
