import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  thead {
    background-color: #f2f2f2;
  }

  th {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  tr:hover {
    background-color: #f2f2f2;
  }
`;
