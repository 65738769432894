import { FaTimes } from "react-icons/fa";
import { Container, FormFooter, Header } from "./CreateDealsStyles";
import FormDeal from "./FormDeal";
import { useContext, useEffect, useMemo } from "react";
import { PipelineContext } from "../../../pages/Negocios/Negocios/context/PipelineContext";
import { Link } from "react-router-dom";

const CreateDeals = () => {
  const {
    selectedPipeline,
    crateDeal,
    form,
    setForm,
    isEditing,
    handleCancel,
    onClickUpdateDeal,
    handleDeleteDeal,
  } = useContext(PipelineContext);

  const { states } = useContext(PipelineContext);

  const isFormValid = useMemo(() => {
    return (
      form?.title !== undefined &&
      form?.pipeline !== undefined &&
      form?.amount !== undefined &&
      form?.status !== undefined &&
      form?.closingDate !== undefined
    );
  }, [form]);

  useEffect(() => {
    if (selectedPipeline) {
      // @ts-ignore
      setForm((prevForm: Form) => {
        return {
          ...prevForm,
          pipeline: selectedPipeline?._id,
        };
      });
    }
  }, [selectedPipeline, setForm]);

  return (
    <Container>
      <Header>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "100%",
          }}
        >
          <h2>{isEditing ? "Detalles de un negocio" : "Crear Negocio"}</h2>

          <Link to={`/negocios/${form._id}`}>Ver Registro</Link>
        </div>
        <FaTimes onClick={handleCancel} />
      </Header>

      <FormDeal form={form} setForm={setForm} status={states} />
      <FormFooter>
        <button
          disabled={!isFormValid}
          onClick={isEditing ? onClickUpdateDeal : crateDeal}
        >
          {isEditing ? "Editar negocio" : "Crear negocio"}
        </button>
        <button onClick={() => handleDeleteDeal(form._id as string)}>
          Eliminar
        </button>
      </FormFooter>
    </Container>
  );
};

export default CreateDeals;
