import React, { useEffect, useState } from "react";
import { Button, FormuGroup } from "./PerfilComponentStyles";
import { IOrganization } from "../../types/types";
import {
  getOrganization,
  updateOrganization,
  uploadLogo,
} from "../../services/organizationService";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/slices/alertSlice";

const PerfilComponent = () => {
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState<IOrganization>();
  const [logoFile, setLogoFile] = useState<File | null>(null);

  const fetchOrganization = async () => {
    try {
      const organizationId =
        localStorage.getItem("organizationId") ||
        sessionStorage.getItem("organizationId");

      if (!organizationId) {
        dispatch(
          setAlert({
            message: "No se ha encontrado la organización",
            type: "error",
            show: true,
          })
        );
        return;
      }

      const org = await getOrganization(organizationId);
      setOrganization(org);
    } catch (error) {
      dispatch(
        setAlert({
          message: "Error al cargar la información de la organización",
          type: "error",
          show: true,
        })
      );
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!organization) return;
    const { name, value } = e.target;
    setOrganization({
      ...organization,
      [name]: value,
    });
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!organization) return;
    const { name, value } = e.target;
    setOrganization({
      ...organization,
      address: {
        ...organization.address,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!organization) return;

    try {
      await updateOrganization(organization);

      dispatch(
        setAlert({
          message: "Información actualizada con éxito",
          type: "success",
          show: true,
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          message: "Error al actualizar la información",
          type: "error",
          show: true,
        })
      );
    }
  };

  const updloadLogo = async () => {
    if (logoFile) {
      const uploadResult = await uploadLogo(logoFile);

      //reload
      fetchOrganization();
      dispatch(
        setAlert({
          message: "Logo actualizado con éxito",
          type: "success",
          show: true,
        })
      );
      return;
    }
  };

  return (
    <div>
      <h1>Información de la empresa</h1>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            gap: "50px",
          }}
        >
          <div
            style={{
              width: "80%",
            }}
          >
            <FormuGroup>
              <label>Nombre de la empresa</label>
              <input
                type="text"
                name="companyName"
                value={organization?.companyName || ""}
                onChange={handleInputChange}
              />
            </FormuGroup>
            <FormuGroup>
              <label>Correo de la empresa</label>
              <input
                type="email"
                name="email"
                value={organization?.email || ""}
                onChange={handleInputChange}
              />
            </FormuGroup>
            <FormuGroup>
              <label>Teléfono de la empresa</label>
              <input
                type="text"
                name="phone"
                value={organization?.phone || ""}
                onChange={handleInputChange}
              />
            </FormuGroup>
            <FormuGroup>
              <label>Teléfono de WhatsApp</label>
              <input
                type="text"
                name="whatsapp"
                value={organization?.whatsapp || ""}
                onChange={handleInputChange}
              />
            </FormuGroup>
          </div>
          <div>
            <img
              src={organization?.logoUrl || "https://via.placeholder.com/150"}
              alt="Logo"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <div>
              <input type="file" onChange={handleFileChange} />
            </div>
            <Button
              onClick={() => updloadLogo()}
              style={{
                marginTop: "10px",
              }}
            >
              Actualizar Logo
            </Button>
          </div>
        </div>
        <hr />
        <h3
          style={{
            margin: "10px 0px",
          }}
        >
          Dirección
        </h3>

        <div
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <FormuGroup>
            <label>Dirección</label>
            <input
              type="text"
              name="address"
              value={organization?.address?.address || ""}
              onChange={handleAddressChange}
            />
          </FormuGroup>
          <FormuGroup>
            <label>Ciudad</label>
            <input
              type="text"
              name="city"
              value={organization?.address?.city || ""}
              onChange={handleAddressChange}
            />
          </FormuGroup>
          <FormuGroup>
            <label htmlFor="">Departamento</label>
            <input
              type="text"
              name="state"
              value={organization?.address?.state || ""}
              onChange={handleAddressChange}
            />
          </FormuGroup>
          <FormuGroup>
            <label htmlFor="">País</label>
            <input
              type="text"
              name="country"
              value={organization?.address?.country || ""}
              onChange={handleAddressChange}
            />
          </FormuGroup>
        </div>
        <Button type="submit">Guardar Información</Button>
      </form>
    </div>
  );
};

export default PerfilComponent;
