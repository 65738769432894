import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: ${theme.colors.azulOscuro};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  h2 {
    font-size: 1.5rem;
    color: white;
  }

  svg {
    cursor: pointer;
    font-size: 25px;
    path {
      color: ${theme.colors.rosado};

      &:hover {
        color: ${theme.colors.rosadoOscuro};
        transition: ease all 0.3s;
      }
    }
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
`;

export const FormBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    padding: 10px 10px;
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;

    svg {
      font-size: 20px;
      margin-right: 10px;
    }

    path {
      color: white;
    }
  }
`;

export const PropertiesContainer = styled.div`
  input {
    padding: 10px;
    border: 1px solid ${theme.colors.grisClaro};
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    background-color: ${theme.colors.gris2};
    margin: 20px 0px;

    &:focus {
      background-color: white;
      transition: ease all 0.3s;
    }
  }

  h3 {
    font-size: 1em;
    font-weight: 600;
    color: ${theme.colors.grisOscuro};
    margin: 10px 0px;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 10px;
    &:hover {
      background-color: ${theme.colors.grisClaro};
      transition: ease all 0.3s;
    }
  }
`;

export const FilterContainer = styled.div`
  div:first-child {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 20px;
      margin-right: 3px;
      path {
        color: ${theme.colors.rosado};
      }
    }

    small {
      font-size: 0.8em;
      color: ${theme.colors.rosado};
    }
  }

  h3 {
    margin: 20px 0px;
  }

  input[type="radio"] {
    margin-right: 10px;
  }

  input[type="text"] {
    width: 100%;
    padding: 8px 12px;
    background-color: ${theme.colors.grisClaro};
    border: 1px solid ${theme.colors.gris1};
    border-radius: 5px;

    &:focus {
      background-color: white;
      transition: ease all 0.3s;
    }
  }
  button {
    background-color: ${theme.colors.rosado};
    padding: 10px 20px;
    width: 50%;
    border: none;
    border-radius: 5px;
    color: white;
    margin-top: 20px;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
      transition: ease all 0.3s;
    }
  }
`;

export const FilterGroup = styled.div`
  div {
    margin: 12px 0px;
  }
  margin: 12px 0px;
`;

export const ResultContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${theme.colors.grisClaro};

  span {
    color: ${theme.colors.rosado};
  }
  svg {
    path {
      color: ${theme.colors.rosado};
    }
  }
`;
