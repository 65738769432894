import React, { useState } from "react";
import styled from "styled-components";

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`;

const Tab = styled.button<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? "#fff" : "#f1f1f1")};
  border: 1px solid #ccc;
  border-bottom: none;
  padding: 10px 20px;
  cursor: pointer;
`;

const TabContent = styled.div`
  padding: 20px;
  border: 1px solid #ccc;
`;

const Tabs: React.FC<{ tabs: string[]; children: React.ReactNode }> = ({
  tabs,
  children,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <TabContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            isActive={index === activeTab}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </Tab>
        ))}
      </TabContainer>
      <TabContent>{React.Children.toArray(children)[activeTab]}</TabContent>
    </div>
  );
};

export default Tabs;
