import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Button = styled.button`
  padding: 10px;
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    transition: ease all 0.3s;
    cursor: pointer;
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  h3 {
    margin: 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }

  label {
    margin-bottom: 10px;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }

  select {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }
`;
