import { Modal, ModalContent } from "./CreatePipelineStyles";
import React, { useContext, useEffect, useMemo } from "react";
import { EditStatesTable } from "./EditStatesTable";
import { PipelineContext } from "../../pages/Negocios/Negocios/context/PipelineContext";

export const EditState = () => {
  const formRef = React.useRef<HTMLDivElement>(null);
  const modalRef = React.useRef<HTMLDivElement>(null);
  const { handleOpenEditStates, editStatesByPipeline, statesToEdit } =
    useContext(PipelineContext);

  useEffect(() => {
    const handleClickOutsideChild = (event: MouseEvent) => {
      const parentElement = modalRef.current;
      const childElement = formRef.current;

      if (parentElement && childElement) {
        if (
          parentElement.contains(event.target as Node) &&
          !childElement.contains(event.target as Node)
        ) {
          handleOpenEditStates();
        }
      }
    };

    document.addEventListener("click", handleClickOutsideChild);

    return () => {
      document.removeEventListener("click", handleClickOutsideChild);
    };
  }, [handleOpenEditStates]);

  const isFormValid = useMemo(() => {
    return statesToEdit.length >= 3;
  }, [statesToEdit]);

  return (
    <Modal ref={modalRef}>
      <ModalContent ref={formRef}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await editStatesByPipeline();
          }}
        >
          <h2
            style={{
              marginBottom: -20,
            }}
          >
            Estados
          </h2>
          <span>(Un Pipeline debe tener mínimo tres estados)</span>
          <EditStatesTable />
          <button disabled={!isFormValid}>Guardar cambios</button>
        </form>
      </ModalContent>
    </Modal>
  );
};
