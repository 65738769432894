import { FaArrowLeft, FaUser } from "react-icons/fa";
import {
  Container,
  FormColumn,
  InputLabel,
  Layout,
  Title,
} from "./NegociosDetailStyles";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { getDealsDetails } from "../../../../services/dealService";
import { PipelineContext } from "../../Negocios/context/PipelineContext";
import { usePipelines } from "../../Negocios/hooks/usePipelines";

const NegociosDetail = () => {
  const { id } = useParams<{ id: string }>();
  const [deal, setDeal] = useState<any | null>(null);

  const pipelineContextInstance = usePipelines();

  return (
    <PipelineContext.Provider value={pipelineContextInstance}>
      <NegociosDetailContent id={id} deal={deal} setDeal={setDeal} />
    </PipelineContext.Provider>
  );
};

const NegociosDetailContent = ({ id, deal, setDeal }: any) => {
  const {
    states,
    onClickUpdateDeal,
    form,
    setForm,
    dealsFieldsByPipeline,
    setDealId,
    fields,
    setFields,
  } = useContext(PipelineContext);

  useEffect(() => {
    const fetchDeal = async () => {
      if (!id) return;
      const response = await getDealsDetails(id);

      setDeal(response.deal);

      // Actualizar `fields` del contexto
      setFields(
        response.fields.map((field: any) => ({
          fieldId: field.field._id,
          value: field.value,
        }))
      );

      // Inicializa el formulario con los datos del deal
      setForm({
        _id: response.deal._id,
        title: response.deal.title,
        amount: response.deal.amount,
        closingDate: response.deal.closingDate.split("T")[0],
        pipeline: response.deal.pipeline,
        status: response.deal.status,
        associatedContactId: response.deal?.associatedContactId,
      });
    };

    fetchDeal();
  }, [id]);

  const getFieldValue = (fieldId: string) => {
    const field = fields.find((f: any) => f.fieldId === fieldId);
    return field ? field.value : "";
  };

  const editHandler = (key: string, value: string) => {
    setDealId(id);

    // Verificar si el key corresponde a un campo dinámico
    const isDynamicField = dealsFieldsByPipeline.some(
      (field: any) => field._id === key
    );

    if (isDynamicField) {
      setFields((prevFields: any) => {
        const existingField = prevFields.find((f: any) => f.fieldId === key);

        if (existingField) {
          // Actualizar el campo existente
          return prevFields.map((f: any) =>
            f.fieldId === key ? { ...f, value: value } : f
          );
        } else {
          // Agregar un nuevo campo
          return [...prevFields, { fieldId: key, value }];
        }
      });
    } else {
      // Actualizar el formulario estándar
      setForm((prevForm: any) => ({
        ...prevForm,
        [key]: value,
      }));
    }
  };

  const handleSave = () => {
    onClickUpdateDeal(false);
  };

  if (!deal) {
    return (
      <Container>
        <h1>Cargando...</h1>
      </Container>
    );
  }

  return (
    <Container>
      <FaArrowLeft
        onClick={() => window.history.back()}
        style={{
          fontSize: "30px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
      />
      <Title>
        <FaUser />
        <h1>Detalle de Negocio</h1>
      </Title>

      <Layout>
        <FormColumn>
          <InputLabel>
            <label>Nombre</label>
            <input
              type="text"
              value={form?.title || ""}
              onChange={(e) => editHandler("title", e.target.value)}
            />
          </InputLabel>
          <InputLabel>
            <label htmlFor="amount">Valor</label>
            <input
              type="text"
              value={form?.amount || ""}
              onChange={(e) => editHandler("amount", e.target.value)}
            />
          </InputLabel>
          <InputLabel>
            <label htmlFor="closingDate">Fecha de cierre</label>
            <input
              type="date"
              value={form?.closingDate || ""}
              onChange={(e) => editHandler("closingDate", e.target.value)}
            />
          </InputLabel>
          <InputLabel>
            <label htmlFor="status">Etapa</label>
            <select
              value={form?.status || ""}
              onChange={(e) => editHandler("status", e.target.value)}
            >
              {states.map((state: any) => (
                <option key={state._id} value={state._id}>
                  {state.name}
                </option>
              ))}
            </select>
          </InputLabel>
          <InputLabel>
            <label htmlFor="pipeline">Pipeline</label>
            <input
              type="text"
              value={deal?.pipeline.title || ""}
              onChange={(e) => editHandler("pipeline", e.target.value)}
            />
          </InputLabel>
          {dealsFieldsByPipeline.map((field: any) => (
            <InputLabel key={field._id}>
              <label>{field.name}</label>
              {field.type === "select" ? (
                <select
                  value={getFieldValue(field._id)}
                  onChange={(e) => editHandler(field._id, e.target.value)}
                >
                  <option value="">Seleccione una opción</option>
                  {field.options.map((option: any) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="text"
                  value={getFieldValue(field._id)}
                  onChange={(e) => editHandler(field._id, e.target.value)}
                />
              )}
            </InputLabel>
          ))}

          <button onClick={handleSave}>Guardar</button>
        </FormColumn>
        <div>
          <h1>Detalles del negocio</h1>
          <h3>Información del Contacto Asociado:</h3>
          {/* Aquí puedes añadir más detalles relacionados con el contacto o la organización */}
        </div>
      </Layout>
    </Container>
  );
};

export default NegociosDetail;
