import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 15px;
    cursor: pointer;

    h3 {
      font-weight: 500;
    }

    svg {
      margin: 0px 5px;
    }
  }
`;
