import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEnvelope, FaPrint, FaSave } from "react-icons/fa";
import Tabs from "./TabContainer";
import ProductLines from "./ProductLines";
import OptionalProductLines from "./OptionalProductLines";
import { Actions, RawContainer } from "./CreateQuotationStyles";
import { createQuotation } from "../../../services/quotationService";
import { IContact } from "../../../types/types";
import FormDealContactModal from "../../../components/negocios/sideComponents/FormDealContactModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/slices/alertSlice";
import { setLoading } from "../../../redux/slices/loadingSlice";
import { updateQuotationNumber } from "../../../redux/slices/organizationSlice";

const CreateQuotation: React.FC = () => {
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState<any>({
    properties: [{ key: "firstName", value: "" }],
  });
  const quotation = useSelector(
    (state: any) => state.organization.quotationSettings
  );

  const [expirationDate, setExpirationDate] = useState(
    new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]
  );
  const [quotationDate, setQuotationDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [paymentTerms, setPaymentTerms] = useState(
    quotation.paymentTerms && quotation.paymentTerms.length > 0
      ? quotation.paymentTerms[0]
      : ""
  );
  const [shippingTerms, setShippingTerms] = useState(
    quotation.shippingTerms && quotation.shippingTerms.length > 0
      ? quotation.shippingTerms[0]
      : ""
  );
  const [productLines, setProductLines] = useState<any[]>([]);
  const [optionalProductLines, setOptionalProductLines] = useState<any[]>([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [observations, setObservations] = useState(quotation.notes || "");
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const handleSaveQuotation = async () => {
    if (
      !customer ||
      !expirationDate ||
      !quotationDate ||
      !paymentTerms ||
      !shippingTerms
    ) {
      alert("Please fill all required fields.");
      return;
    }

    const quotationData = {
      quotationNumber: quotation.quotationNumber,
      name:
        customer.properties.find(
          (property: any) => property.key === "firstName"
        )?.value || "",
      observaciones: observations,
      contactId: customer._id,
      organizationId: localStorage.getItem("organizationId"),
      creationDate: new Date(quotationDate),
      expirationDate: new Date(expirationDate),
      paymentTerms: paymentTerms,
      shippingTerms: shippingTerms,
      items: productLines.map((line) => ({
        name: line.product?.name || "",
        description: line.product?.description || "",
        quantity: line.quantity,
        unitPrice: line.unitPrice,
        taxes: line.taxes,
        total: line.total,
      })),
      optionalItems: optionalProductLines.map((line: any) => ({
        name: line.product?.name || "",
        description: line.product?.description || "",
        quantity: line.quantity,
        unitPrice: line.unitPrice,
      })),
      subtotal: productLines.reduce(
        (sum, line) => sum + line.unitPrice * line.quantity,
        0
      ),
      taxes: productLines.reduce(
        (sum, line) =>
          sum + (line.unitPrice * line.quantity * line.taxes) / 100,
        0
      ),
      discounts: 0,
      total: productLines.reduce(
        (sum, line) =>
          sum +
          line.unitPrice * line.quantity +
          (line.unitPrice * line.quantity * line.taxes) / 100,
        0
      ),
      status: "pending",
      userId: localStorage.getItem("userId"),
      lastModified: new Date(),
    };

    try {
      dispatch(setLoading(true));
      await createQuotation(quotationData);
      dispatch(setLoading(false));
      //actualizar el contador de cotizaciones en el state
      dispatch(updateQuotationNumber());
      dispatch(
        setAlert({
          title: "Cotización creada",
          message: "La cotización ha sido creada exitosamente",
          show: true,
          type: "success",
        })
      );
      navigate(
        `/negocios/cotizaciones?printQuotation=true&id=${quotationData.quotationNumber}`
      );
    } catch (error) {
      console.error("Error creating quotation:", error);
      alert("Error creating quotation.");
    }
  };

  return (
    <div>
      <Actions>
        <div>
          <FaSave />
          <button onClick={handleSaveQuotation}>Guardar</button>
        </div>
        <div>
          <FaPrint />
          <button>Imprimir</button>
        </div>
        <div>
          <FaEnvelope />
          <button>Enviar por correo</button>
        </div>
      </Actions>
      <h1>Nueva Cotización</h1>
      <RawContainer>
        <div>
          <table>
            <tbody>
              <tr>
                <td>Cliente</td>
                <td>
                  <input
                    type="text"
                    id="searchValue"
                    name="searchValue"
                    value={
                      customer.properties.find(
                        (property: any) => property.key === "firstName"
                      )?.value || searchValue
                    }
                    onClick={() => setShowContactModal(!showContactModal)}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />

                  {showContactModal && (
                    <div
                      style={{
                        position: "relative",
                        width: "400px",
                        marginTop: "0px",
                      }}
                    >
                      <FormDealContactModal
                        searchValue={searchValue}
                        setShowContactModal={setShowContactModal}
                        setCustomer={setCustomer}
                      />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>Numero de cotización</td>
                <td>
                  <input
                    type="text"
                    name="quotationNumber"
                    id="quotationNumber"
                    value={quotation.quotationNumber}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>Fecha de cotización</td>
                <td>
                  <input
                    type="date"
                    value={quotationDate}
                    onChange={(e) => setQuotationDate(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Vencimiento</td>
                <td>
                  <input
                    type="date"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Términos de pago</td>
                {quotation.paymentTerms ? (
                  <td>
                    <select
                      value={paymentTerms}
                      onChange={(e) => setPaymentTerms(e.target.value)}
                    >
                      {quotation.paymentTerms.map((term: any) => (
                        <option key={term} value={term}>
                          {term}
                        </option>
                      ))}
                    </select>
                  </td>
                ) : (
                  <td>
                    <input
                      type="text"
                      value={paymentTerms}
                      onChange={(e) => setPaymentTerms(e.target.value)}
                    />
                  </td>
                )}
              </tr>
              <tr>
                <td>Tiempo de Entrega</td>
                {quotation.paymentTerms.length > 1 ? (
                  <td>
                    <select
                      value={shippingTerms || ""}
                      onChange={(e) => setShippingTerms(e.target.value)}
                    >
                      {quotation?.shippingTerms?.map((term: any) => (
                        <option key={term} value={term}>
                          {term}
                        </option>
                      ))}
                    </select>
                  </td>
                ) : (
                  <td>
                    <input
                      type="text"
                      value={shippingTerms || ""}
                      onChange={(e) => setShippingTerms(e.target.value)}
                    />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </RawContainer>
      <div>
        <Tabs
          tabs={[
            "Líneas de la orden",
            "Productos opcionales",
            "Otra información",
            "Firma del cliente",
          ]}
        >
          <ProductLines
            productLines={productLines}
            setProductLines={setProductLines}
            setObservations={setObservations}
            notes={quotation.notes}
          />
          <OptionalProductLines
            optionalProductLines={optionalProductLines}
            setOptionalProductLines={setOptionalProductLines}
          />
        </Tabs>
      </div>
    </div>
  );
};

export default CreateQuotation;
