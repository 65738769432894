import React, { useRef, useEffect } from "react";
import { Card, ModalContainer, ModalList } from "./ProductCardStyles";
import { IProduct } from "../../types/types";
import { FaEdit, FaEllipsisV, FaTrash } from "react-icons/fa";

interface ProductCardProps {
  product: IProduct;
  onDelete: (productId: string) => void;
  onEdit: (product: IProduct) => void;
  isActive: boolean;
  onMenuToggle: (productId: string) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onDelete,
  onEdit,
  isActive,
  onMenuToggle,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      onMenuToggle(""); // Cierra el menú si se hace clic fuera
    }
  };

  const handleEllipsisClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Evita la propagación del clic
    onMenuToggle(product._id as string); // Alterna el estado del menú
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isActive]);

  return (
    <Card>
      <div>
        <h3>{product.name}</h3>
        <p>Precio: {product?.unitPrice?.toLocaleString() || ""}</p>
      </div>
      <div>
        <img src={product.imageUrl} alt={product.name} width={150} />
      </div>
      <div style={{ position: "relative" }}>
        <FaEllipsisV onClick={handleEllipsisClick} />

        {isActive && (
          <Menu
            product={product}
            onDelete={onDelete}
            onEdit={onEdit}
            ref={menuRef}
            onMenuToggle={onMenuToggle}
          />
        )}
      </div>
    </Card>
  );
};

interface MenuProps {
  product: IProduct;
  onDelete: (productId: string) => void;
  onEdit: (product: IProduct) => void;
  onMenuToggle: (productId: string) => void;
}

const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  ({ product, onDelete, onEdit, onMenuToggle }, ref) => (
    <ModalContainer ref={ref}>
      <ModalList>
        <li
          onClick={() => {
            onEdit(product);
            onMenuToggle("");
          }}
        >
          <FaEdit /> Editar
        </li>
        <li onClick={() => onDelete(product._id as string)}>
          <FaTrash /> Eliminar
        </li>
      </ModalList>
    </ModalContainer>
  )
);

export default ProductCard;
