import React, { useEffect, useState } from "react";
import { FaList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Button, Header, TableContainer } from "./ListStyles";
import { IList, IHeader } from "../../../types/types";
import {
  getAllLists,
  searchLists,
  deleteList,
} from "../../../services/listService";
import Table from "../../../components/common/Table/Table";

const ListPage: React.FC = () => {
  const navigate = useNavigate();
  const [lists, setLists] = useState<IList[]>([]);
  const [affected, setAffected] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const headers: IHeader[] = [
    { key: "name", label: "Nombre de la lista", isVisible: true },
    { key: "size", label: "Tamaño", isVisible: true },
    { key: "creator", label: "Creador", isVisible: true },
  ];

  const fetchLists = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getAllLists();
      setLists(data);
    } catch (err) {
      setError("Error al cargar las listas.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLists();
  }, [affected, limit, page]);

  const handleCreateList = () => {
    navigate("/listas/crear");
  };

  const deleteHandler = async (ids: string[]) => {
    try {
      await Promise.all(ids.map((id) => deleteList(id)));
      setAffected(!affected);
    } catch (err) {
      setError("Error al eliminar las listas.");
    }
  };

  const editHandler = (id: string) => {
    navigate(`/listas/editar/${id}`);
  };

  return (
    <div>
      <Header>
        <h1>
          <FaList /> Listas
        </h1>
        <Button onClick={handleCreateList}>Crear Lista</Button>
      </Header>
      <TableContainer>
        <Table<IList>
          headers={headers}
          data={lists}
          setData={setLists}
          setShowCreateItem={undefined}
          setShowFilterItem={undefined}
          setLimit={setLimit}
          limit={limit}
          setPage={setPage}
          page={page}
          setAffected={setAffected}
          affected={affected}
          fetchData={getAllLists}
          searchFunction={searchLists}
          deleteHandler={deleteHandler}
          editHandler={editHandler}
          tableType="list"
        />
      </TableContainer>
      {loading && <p>Cargando...</p>}
      {error && <p>{error}</p>}
    </div>
  );
};

export default ListPage;
