// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import LoginPage from "./pages/Auth/Login/LoginPage";
import RegisterPage from "./pages/Auth/Register/RegisterPage";
import FragmentosPage from "./pages/Conversaciones/Fragmentos/FragmentPage";
import PrivateRoute from "./components/common/PrivateRoute";
import { theme } from "./styles/theme";
import "./styles/globalStyles.css";
import Layout from "./components/common/layout/Layout";
import { Provider } from "react-redux";
import store from "./redux/store";
import ContactPage from "./pages/Contactos/Contactos/ContactPage";
import ListPage from "./pages/Contactos/Listas/ListPage";
import CreateListPage from "./pages/Contactos/Listas/CreateList";
import WhatsappPage from "./pages/Conversaciones/Whatsapp/WhatsappPage";
import ContactDetail from "./pages/ContactDetail/ContactDetail";
import CotizacionesPage from "./pages/Negocios/Cotizaciones/CotizacionesPage";
import { SocketProvider } from "./redux/SocketContext";
import NegociosPage from "./pages/Negocios/Negocios/Negocios";
import ActividadesPage from "./pages/Actividades/ActividadesPage";
import InformesPage from "./pages/Negocios/Informes/InformesPage";
import ProductosPage from "./pages/Productos/ProductosPage";
import PerfilPage from "./pages/Perfil/PerfilPage";
import FormsPage from "./pages/Conversaciones/Forms/FormsPage";
import CreateForm from "./pages/Conversaciones/Forms/CreateForm";
import FormDetail from "./pages/Conversaciones/Forms/FormDetail";
import Importar from "./pages/Importar/Importar";
import Correo from "./pages/Conversaciones/Correo/CorreoPage";

import TareasPage from "./pages/Planeacion/Proyectos/TareasPage";
import RedesSocialesPage from "./pages/Planeacion/RedesSociales/RedesSocialesPage";
import CampanasDeMarketingPage from "./pages/Planeacion/CampanasDeMarketing/CampanasDeMarketingPage";
import WhatsAppMasivoPage from "./pages/Marketing/WhatsAppMasivo/WhatsAppMasivoPage";
import CorreosMasivos from "./pages/Marketing/CorreosMasivos/CorreosMasivos";
import NegociosDetail from "./pages/Negocios/Negocios/NegociosDetail/NegociosDetail";
import CreateQuotation from "./pages/Negocios/Cotizaciones/CreateQuotation";
import LeadsPage from "./pages/Contactos/Leads/LeadsPage";
import ProveedoresPage from "pages/Proveedores/ProveedoresPage";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <SocketProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/informes" element={<InformesPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/contactos" element={<ContactPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/contactos/leads" element={<LeadsPage />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/contactos/:id" element={<ContactDetail />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/listas" element={<ListPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/listas/crear" element={<CreateListPage />} />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route
                    path="/negocios/cotizaciones"
                    element={<CotizacionesPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/cotizaciones/crear"
                    element={<CreateQuotation />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/negocios" element={<NegociosPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/negocios/:id" element={<NegociosDetail />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/negocios/actividades"
                    element={<ActividadesPage />}
                  />
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="/productos" element={<ProductosPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/perfil" element={<PerfilPage />} />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/importar" element={<Importar />} />
                </Route>

                {/* MENU DE CONVERSACIONES  */}
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/conversaciones/whatsapp"
                    element={<WhatsappPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/conversaciones/fragmentos"
                    element={<FragmentosPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/conversaciones/formularios"
                    element={<FormsPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/conversaciones/formularios/crear"
                    element={<CreateForm />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/conversaciones/formularios/:id"
                    element={<FormDetail />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route path="/conversaciones/correo" element={<Correo />} />
                </Route>

                {/* MENU DE MARKETING  */}
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/marketing/whatsapp-masivo"
                    element={<WhatsAppMasivoPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/marketing/correos-masivos"
                    element={<CorreosMasivos />}
                  />
                </Route>

                {/* MENU DE PLANEACION  */}
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/planeacion/campanas-de-marketing"
                    element={<CampanasDeMarketingPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/planeacion/proyectos"
                    element={<TareasPage />}
                  />
                </Route>
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/planeacion/redes-sociales"
                    element={<RedesSocialesPage />}
                  />
                </Route>
                <Route element={<ProveedoresPage />}>
                  <Route path="/proveedores" element={<ProveedoresPage />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </ThemeProvider>
      </SocketProvider>
    </Provider>
  );
};

export default App;
