import React, { useContext, useEffect } from "react";
import { Button, DetailArea, Layout } from "../common/CommonStyles";
import { CampanaContext } from "../context/CampanaContext";

const Metricas = () => {
  const { setActiveStep, newCampana, setNewCampana } =
    useContext(CampanaContext);

  // Inicializar las métricas si el usuario añade más plataformas o métricas
  useEffect(() => {
    const initializeMetrics = () => {
      const updatedMetrics = [...(newCampana?.conversionMetrics || [])];

      newCampana?.platforms?.platformName?.forEach((platform: string) => {
        newCampana?.platforms?.metricsName?.forEach((metric: string) => {
          // Verificar si la métrica ya existe, si no, agregarla
          if (
            !updatedMetrics.some(
              (m) => m.platformName === platform && m.metric === metric
            )
          ) {
            updatedMetrics.push({
              platformName: platform,
              metric: metric,
              value: 0, // Valor por defecto
            });
          }
        });

        // Verificar y agregar el gasto si no existe
        if (
          !updatedMetrics.some(
            (m) => m.platformName === platform && m.metric === "Gasto"
          )
        ) {
          updatedMetrics.push({
            platformName: platform,
            metric: "Gasto",
            value: 0, // Valor por defecto
          });
        }
      });

      setNewCampana((prev: any) => ({
        ...prev,
        conversionMetrics: updatedMetrics,
      }));
    };

    initializeMetrics();
  }, [newCampana?.platforms, setNewCampana]);

  // Manejar cambios en los inputs de métricas
  const handleMetricChange = (
    platformName: string,
    metric: string,
    value: string
  ) => {
    setNewCampana((prev: any) => {
      const updatedMetrics = prev.conversionMetrics.map((metricObj: any) =>
        metricObj.platformName === platformName && metricObj.metric === metric
          ? { ...metricObj, value: parseFloat(value) || 0 }
          : metricObj
      );
      return { ...prev, conversionMetrics: updatedMetrics };
    });
  };

  // Filtrar las métricas seleccionadas
  const selectedPlatforms = newCampana?.platforms?.platformName || [];
  const selectedMetrics = newCampana?.platforms?.metricsName || [];

  return (
    <Layout>
      <div
        style={{
          width: "40%",
        }}
      >
        <h2>Métricas Obtenidas</h2>

        {selectedPlatforms.length > 0 && selectedMetrics.length > 0 ? (
          <div
            style={{
              overflowX: "auto",
            }}
          >
            <table>
              <thead>
                <tr>
                  <th>Plataforma</th>
                  {selectedMetrics.map((metric: any) => (
                    <th key={metric}>{metric}</th>
                  ))}
                  <th>Gasto en la plataforma</th>
                </tr>
              </thead>
              <tbody>
                {selectedPlatforms.map((platform: any) => (
                  <tr key={platform}>
                    <td>{platform}</td>
                    {selectedMetrics.map((metric: any) => (
                      <td key={metric}>
                        <input
                          type="text"
                          value={
                            newCampana?.conversionMetrics?.find(
                              (m: any) =>
                                m.platformName === platform &&
                                m.metric === metric
                            )?.value || ""
                          }
                          onChange={(e) =>
                            handleMetricChange(platform, metric, e.target.value)
                          }
                        />
                      </td>
                    ))}
                    <td>
                      <input
                        type="text"
                        value={
                          newCampana?.conversionMetrics?.find(
                            (m: any) =>
                              m.platformName === platform &&
                              m.metric === "Gasto"
                          )?.value || ""
                        }
                        onChange={(e) =>
                          handleMetricChange(platform, "Gasto", e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No hay plataformas o métricas seleccionadas.</p>
        )}
        <Button
          onClick={() => {
            setActiveStep("Resultados");
          }}
        >
          Continuar
        </Button>
      </div>
      <DetailArea>
        <h2>Descripción del input</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
          voluptatem, quod, quae, quas quos quia nesciunt voluptates
          repudiandae, quibusdam tempore doloremque.
        </p>
      </DetailArea>
    </Layout>
  );
};

export default Metricas;
