import styled from "styled-components";
import { theme } from "../../../../styles/theme";

export const AddTaskModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 10000000;
`;

export const AddTaskContent = styled.div`
  background-color: white;
  min-width: 500px;
  border-radius: 10px;
  padding: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-bottom: 20px;
  }

  div {
    width: 100%;
    margin-bottom: 20px;
  }

  input,
  select {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: ${theme.colors.gris3};
    border: none;
  }
`;

export const AddTaskButton = styled.button`
  background-color: #f50057;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
`;
