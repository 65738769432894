import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
`;

export const LeftColumn = styled.div`
  height: 100vh;
  width: 40vw;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 6%;

  img {
    width: 300px;
    height: 150px;
    margin-bottom: 20px;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0px;

  :hover {
    cursor: pointer;
  }
`;

export const Social = styled.div`
  color: ${theme.colors.grisOscuro};
  background-color: ${theme.colors.gris3};
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0px 20px;
  border: none;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.25);
  text-align: center;

  svg {
    margin: 0px 10px;
  }

  p {
    text-align: center;
    background-color: transparent;
  }
`;

export const TextHr = styled.div`
  hr {
    border: 1px solid ${theme.colors.gris3};
    width: 35%;
  }

  p {
    color: ${theme.colors.grisOscuro};
    font-size: 14px;
    margin: 0px 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;
  border: 1px solid ${theme.colors.gris3};
  border-radius: 5px;
  background-color: ${theme.colors.gris3};
  svg {
    margin: 0px 15px;
    background-color: transparent;
  }

  input {
    border: none;
    outline: none;
    background-color: ${theme.colors.gris3};
    width: 100%;
    padding: 15px 10px;
  }
`;

export const RemaindMeContainer = styled.div`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    input {
      margin: 0px 10px;
    }
  }

  p {
    color: ${theme.colors.rosado};
    font-size: 14px;
    margin: 0px 10px;
    &:hover {
      cursor: pointer;
      font-weight: 500;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
`;

export const LoginButton = styled.button`
  background-color: ${theme.colors.rosado};
  color: ${theme.colors.blanco};
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.azulOscuro};
    transition: ease all 0.3s;
  }
`;

export const AccountContainer = styled.div`
  p {
    color: ${theme.colors.grisOscuro};
    font-size: 14px;
    margin: 0px 10px;
  }

  a {
    color: ${theme.colors.rosado};
    &:hover {
      cursor: pointer;
      font-weight: 500;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;

export const RightColumn = styled.div`
  background-color: ${theme.colors.azulOscuro};
  height: 100vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2,
  p {
    color: white;
    margin: 5px 0px;
  }

  img {
    border-radius: 20px;
    margin: 20px 0px;
  }
`;

export const CircleButton = styled.div<{ $active: any }>`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 1px solid ${theme.colors.gris3};
  background-color: ${(props) => (props.$active ? "white" : "transparent")};
  outline: none;
  margin: 10px 10px;
  text-align: center;

  h2,
  p {
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
`;
