import styled from "styled-components";
import { theme } from "../../styles/theme";

export const FormuGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;

  label {
    margin-bottom: 10px;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
  }
`;

export const Button = styled.button`
  padding: 10px;
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
    transition: ease all 0.3s;
    cursor: pointer;
  }
`;
