import React, { useEffect, useState } from "react";
import {
  getOrganization,
  updateOrganization,
} from "../../services/organizationService";
import { IContactProperty, IOrganization } from "../../types/types";
import {
  Button,
  Form,
  ModalContainer,
  ModalContent,
} from "./ContactFieldsStyles";
import { FaPen, FaTimes, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../redux/slices/alertSlice";
import {
  setOrganization,
  updateContactProperties,
} from "../../redux/slices/organizationSlice"; // Import the action
import { Table } from "./UsuariosComponentStyles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ContactFieldsModal from "./ContactFieldsModal";

const ContactFields = () => {
  const organization = useSelector(
    (state: any) => state.organization
  ) as IOrganization;
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [form, setForm] = useState<IContactProperty | null>(null);
  const [addIsVisible, setAddIsVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrganization = async () => {
      const organizationId =
        localStorage.getItem("organizationId") ||
        sessionStorage.getItem("organizationId");
      if (!organizationId) {
        dispatch(
          setAlert({
            message: "ID de organización no encontrado",
            type: "error",
          })
        );
        return;
      }
      try {
        const organization = await getOrganization(organizationId);
        dispatch(setOrganization(organization));
      } catch (error) {
        console.error("Error al obtener la organización:", error);
        dispatch(
          setAlert({
            message: "Error al obtener la organización",
            type: "error",
          })
        );
      }
    };

    fetchOrganization();
  }, [dispatch]);

  const handleDoubleClick = (index: number) => {
    setEditIndex(index);
    setForm(organization.contactProperties[index] || null);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (form) {
      setForm({
        ...form,
        [name]: name === "isVisible" ? value === "true" : value,
      });
    }
  };

  const handleSave = async () => {
    if (!organization || editIndex === null || !form) return;

    const updatedProperties = [...organization.contactProperties];
    updatedProperties[editIndex] = form;

    try {
      const updatedOrganization = await updateOrganization({
        contactProperties: updatedProperties,
      });

      // Update the global state with the new properties
      dispatch(updateContactProperties(updatedProperties));
      setEditIndex(null);
      dispatch(
        setAlert({ message: "Campo actualizado con éxito", type: "success" })
      );
    } catch (error) {
      console.error("Error al actualizar la organización:", error);
      dispatch(
        setAlert({
          message: "Error al actualizar la organización",
          type: "error",
        })
      );
    }
  };

  const handleDragEnd = async (result: any) => {
    if (!organization) return;
    if (!result.destination) return;

    const items = Array.from(organization.contactProperties);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    try {
      const updatedOrganization = await updateOrganization({
        contactProperties: items,
      });

      // Update the global state with the new properties
      dispatch(updateContactProperties(items));
      dispatch(
        setAlert({ message: "Orden actualizado con éxito", type: "success" })
      );
    } catch (error) {
      console.error("Error al actualizar la organización:", error);
      dispatch(
        setAlert({
          message: "Error al actualizar la organización",
          type: "error",
        })
      );
    }
  };

  return (
    <div>
      <h1>Campos de los contactos</h1>
      <Button onClick={() => setAddIsVisible(true)}>Agregar Campo</Button>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="properties">
          {(provided) => (
            <Table {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Key</th>
                  <th>isVisible</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {organization?.contactProperties.map((property, index) => (
                  <Draggable
                    key={property.key}
                    draggableId={property.key}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onDoubleClick={() => handleDoubleClick(index)}
                      >
                        {editIndex === index ? (
                          <>
                            <td>
                              <input
                                type="text"
                                name="label"
                                value={form?.label || ""}
                                onChange={handleInputChange}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="key"
                                value={form?.key || ""}
                                onChange={handleInputChange}
                              />
                            </td>
                            <td>
                              <select
                                name="isVisible"
                                value={form?.isVisible.toString() || ""}
                                onChange={handleInputChange}
                              >
                                <option value="true">true</option>
                                <option value="false">false</option>
                              </select>
                            </td>
                            <td>
                              <Button onClick={handleSave}>Guardar</Button>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{property.label}</td>
                            <td>{property.key}</td>
                            <td>{property.isVisible ? "true" : "false"}</td>
                            <td>
                              <FaPen
                                onClick={() => handleDoubleClick(index)}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </>
                        )}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      {addIsVisible && (
        <ContactFieldsModal
          organization={organization}
          setAddIsVisible={setAddIsVisible}
        />
      )}
    </div>
  );
};

export default ContactFields;
