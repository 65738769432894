import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaFileAlt } from "react-icons/fa";
import styled from "styled-components";
import { IFragment, IHeader } from "../../../types/types";
import {
  getFragments,
  searchFragments,
  deleteFragment,
} from "../../../services/fragmentService";
import CreateFragment from "./CreateFragment";
import { theme } from "../../../styles/theme";

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin-left: 10px;
  }

  small {
    margin-left: 10px;
    color: grey;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    display: flex;
    align-items: center;
    font-size: 24px;
    svg {
      margin-right: 10px;
    }
  }
`;

const Button = styled.button`
  background-color: ${theme.colors.rosado};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.rosadoOscuro};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  th,
  td {
    border: 1px solid ${theme.colors.grisClaro};
    padding: 10px;
    text-align: left;
  }

  th {
    background-color: ${theme.colors.rosado};
    color: white;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  tr:hover {
    background-color: ${theme.colors.grisClaro};
  }
`;

const FragmentosPage: React.FC = () => {
  const [showCreateFragment, setShowCreateFragment] = useState(false);
  const [fragments, setFragments] = useState<IFragment[]>([]);
  const [selectedFragment, setSelectedFragment] = useState<IFragment | null>(
    null
  ); // Estado para fragmento seleccionado
  const [affected, setAffected] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const headers: IHeader[] = [
    { key: "name", label: "Nombre del Fragmento", isVisible: true },
    { key: "atajo", label: "Atajo", isVisible: true },
    { key: "createdAt", label: "Fecha de Creación", isVisible: true },
    { key: "updatedAt", label: "Fecha de Actualización", isVisible: true },
  ];

  const fetchFragments = useCallback(async () => {
    const fragmentos = await getFragments();
    setFragments(fragmentos);
    return fragmentos;
  }, []);

  useEffect(() => {
    fetchFragments();
  }, [affected, limit, page, fetchFragments]);

  const deleteHandler = async (id: string) => {
    try {
      await deleteFragment(id);
      setAffected(!affected);
    } catch (err) {
      console.error("Error al eliminar el fragmento:", err);
    }
  };

  const editHandler = (fragment: IFragment) => {
    setSelectedFragment(fragment); // Seleccionar el fragmento para editar
    setShowCreateFragment(true); // Abrir el modal
  };

  return (
    <div>
      <Header>
        <h1>
          <FaFileAlt size={32} />
          Fragmentos
        </h1>
        <Button onClick={() => setShowCreateFragment(true)}>
          Agregar Fragmento
        </Button>
      </Header>

      {/* Tabla manual */}
      <Table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.key}>{header.label}</th>
            ))}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {fragments.map((fragment) => (
            <tr key={fragment._id}>
              <td>{fragment.name}</td>
              <td>{fragment.atajo}</td>
              <td>{new Date(fragment.createdAt).toLocaleDateString()}</td>
              <td>{new Date(fragment.updatedAt).toLocaleDateString()}</td>
              <td>
                <Button
                  onClick={() => editHandler(fragment)}
                  style={{ marginRight: "10px" }}
                >
                  Editar
                </Button>
                <Button onClick={() => deleteHandler(fragment._id)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showCreateFragment && (
        <CreateFragment
          setShowCreateFragment={setShowCreateFragment}
          setAffected={setAffected}
          affected={affected}
          selectedFragment={selectedFragment} // Pasar el fragmento seleccionado
        />
      )}
    </div>
  );
};

export default FragmentosPage;
