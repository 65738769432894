import { IQuotation } from "../types/types";
import axiosInstance from "./axiosConfig";

// Create a new quotation
export const createQuotation = async (formQuotation: any) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.post(
      "/quotations",
      { ...formQuotation, organizationId },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating quotation");
    throw new Error(
      error.response?.data?.message || "Error creating quotation"
    );
  }
};

// Get a single quotation by ID
export const getQuotation = async (quotationId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get("/quotations/" + quotationId, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching quotation");
    throw new Error(
      error.response?.data?.message || "Error fetching quotation"
    );
  }
};

// Get all quotations
export const getQuotations = async (
  limit: number = 10,
  page: number = 0
): Promise<IQuotation[]> => {
  try {
    const token = localStorage.getItem("token");
    const organizationId = localStorage.getItem("organizationId");

    const res = await axiosInstance.get<IQuotation[]>("/quotations", {
      headers: { Authorization: `${token}` },
      params: { organizationId, limit, page },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching quotations");
    throw new Error(
      error.response?.data?.message || "Error fetching quotations"
    );
  }
};

// Delete a quotation by ID
export const deleteQuotation = async (quotationId: string) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axiosInstance.delete("/quotations/" + quotationId, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting quotation");
    throw new Error(
      error.response?.data?.message || "Error deleting quotation"
    );
  }
};

// Update a quotation by ID
export const updateQuotation = async (
  quotationId: string,
  formQuotation: any
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(
      "/quotations/" + quotationId,
      { ...formQuotation, token: token },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating quotation");
    throw new Error(
      error.response?.data?.message || "Error updating quotation"
    );
  }
};

// Search for quotations based on a term
export const searchQuotation = async (term: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.get("/quotations/search/" + term, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error searching quotations"
    );
    throw new Error(
      error.response?.data?.message || "Error searching quotations"
    );
  }
};

// Advanced filter for quotations
export const advancedFilterQuotations = async (filters: any) => {
  const token = localStorage.getItem("token");

  try {
    const res = await axiosInstance.post(
      "/quotations/advanced-filter",
      filters,
      {
        headers: { Authorization: `${token}` },
      }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error filtering quotations"
    );
    throw new Error(
      error.response?.data?.message || "Error filtering quotations"
    );
  }
};
