import axiosInstance from "./axiosConfig";

const fetchNotifications = async () => {
  try {
    const response = await axiosInstance.get("/notifications");

    return [response.data];
  } catch (error) {
    console.error("Error al obtener las notificaciones:", error);
    throw error;
  }
};

const resetUnreadFormNotifications = async () => {
  try {
    const response = await axiosInstance.post("/notifications/reset");
    return response.data;
  } catch (error) {
    console.error(
      "Error al resetear las notificaciones de formularios:",
      error
    );
    throw error;
  }
};

export default {
  fetchNotifications,
  resetUnreadFormNotifications,
};
