import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 80px;
`;

export const SearchArea = styled.div`
  border: 1px solid ${theme.colors.gris3};
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  input {
    min-width: 40vw;
    padding: 10px;
    border: none;
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.gris3};
  background-color: ${theme.colors.rosado};
  color: white;
  cursor: pointer;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem;
  width: 100%;
`;
