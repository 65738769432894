import axios from "axios";
import axiosConfig from "./axiosConfig";

interface ImportResponse {
  message: string;
  importedCount?: number;
  error?: string;
}

const importContacts = async (
  formData: FormData
): Promise<ImportResponse | void> => {
  try {
    const response = await axiosConfig.post<ImportResponse>(
      "/import/contacts",
      formData
    );

    return response.data;
  } catch (error) {
    console.error("Error al importar contactos:", error);

    if (axios.isAxiosError(error)) {
      return {
        message: "Error al importar contactos",
        error: error.response?.data?.message || error.message,
      };
    } else {
      return {
        message: "Error al importar contactos",
        error: "Error desconocido",
      };
    }
  }
};

export default { importContacts };
