import debounce from "debounce";
import { createContext } from "react";
import {
  DealForm,
  Deals,
  DealsFields,
  DealsFieldsToSend,
  IContact,
  Status,
} from "../../../../types/types";

export interface Pipeline {
  _id: string;
  title: string;
  organizationId: string;
  __v: number;
}

export interface IPipelineContext {
  states: Status[];
  setStates: React.Dispatch<React.SetStateAction<Status[]>>;
  deals: Deals[];
  setDeals: React.Dispatch<React.SetStateAction<Deals[]>>;
  pipelines: Pipeline[];
  setPipelines: React.Dispatch<React.SetStateAction<Pipeline[]>>;
  selectedPipeline: Pipeline | undefined;
  setSelectedPipeline: React.Dispatch<
    React.SetStateAction<Pipeline | undefined>
  >;
  openCreatePipeline: boolean;
  setOpenCreatePipeline: React.Dispatch<React.SetStateAction<boolean>>;
  getStatusState: () => Promise<void>;
  getDealsState: () => Promise<void>;
  getPipelinesState: () => Promise<void>;
  editStatesByPipeline: () => Promise<void>;
  statesToEdit: Status[];
  setStatesToEdit: React.Dispatch<React.SetStateAction<Status[]>>;
  handleOpenCreatePipeline: () => void;
  loading?: boolean;
  openEditStates: boolean;
  handleOpenEditStates: () => void;
  handleChangeName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  debouncedSearch: debounce.DebouncedFunction<
    (e: React.ChangeEvent<HTMLInputElement>) => void
  >;
  isVisibleCreateDeal: boolean;
  crateDeal: () => void;
  form: DealForm;
  setForm: React.Dispatch<React.SetStateAction<DealForm>>;
  setIsVisibleCreateDeal: React.Dispatch<React.SetStateAction<boolean>>;
  dealsFieldsByPipeline: DealsFields[];
  fields: DealsFieldsToSend[];
  setFields: React.Dispatch<React.SetStateAction<DealsFieldsToSend[]>>;
  getDealDetail: (dealId: string) => Promise<void>;
  isEditing: boolean;
  onClickUpdateDeal: (estado: any) => void;
  handleCancel: () => void;
  openEditDealsFields: boolean;
  setOpenEditDealsFields: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpenEditDealsFields: () => void;
  handleAddDealField: (dealField: {
    name: string;
    key: string;
    required: boolean;
  }) => Promise<void>;
  handleDeleteField: (fieldId: string) => Promise<void>;
  handleEditDealField: (dealField: {
    name: string;
    key: string;
    _id: string;
    required: boolean;
  }) => Promise<void>;
  handleDeleteDeal: (dealId: string) => Promise<void>;
  contacts: IContact[];
  setFilterDates: React.Dispatch<React.SetStateAction<{}>>;
  filterDates: {};
  setDealId: React.Dispatch<React.SetStateAction<string>>;
}

export const PipelineContext = createContext<IPipelineContext>({
  states: [],
  setStates: () => {},
  deals: [],
  setDeals: () => {},
  pipelines: [],
  setPipelines: () => {},
  selectedPipeline: {} as Pipeline,
  setSelectedPipeline: () => {},
  openCreatePipeline: false,
  setOpenCreatePipeline: () => {},
  getStatusState: async () => {},
  getDealsState: async () => {},
  getPipelinesState: async () => {},
  handleOpenCreatePipeline: () => {},
  editStatesByPipeline: async () => {},
  statesToEdit: [],
  setStatesToEdit: () => {},
  loading: false,
  openEditStates: false,
  handleOpenEditStates: () => {},
  handleChangeName: () => {},
  debouncedSearch: debounce(async () => {}, 1000),
  isVisibleCreateDeal: false,
  crateDeal: () => {},
  form: {} as any,
  setForm: () => {},
  setIsVisibleCreateDeal: () => {},
  dealsFieldsByPipeline: [],
  fields: [],
  setFields: () => {},
  getDealDetail: async () => {},
  isEditing: false,
  onClickUpdateDeal: () => {},
  handleCancel: () => {},
  openEditDealsFields: false,
  setOpenEditDealsFields: () => {},
  handleOpenEditDealsFields: () => {},
  handleAddDealField: async () => {},
  handleDeleteField: async () => {},
  handleEditDealField: async () => {},
  handleDeleteDeal: async () => {},
  contacts: [],
  setFilterDates: () => {},
  filterDates: {},
  setDealId: () => {},
});
