import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyTokenStart,
  verifyTokenSuccess,
  verifyTokenFailure,
  logout,
} from "../redux/slices/userSlice";
import { RootState, AppDispatch } from "../redux/store";
import { verifyToken } from "../services/authService";
import { setOrganization } from "../redux/slices/organizationSlice";

const useAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    userId,
    firstName,
    lastName,
    email,
    mobile,
    token,
    loading,
    isAuthenticated,
  } = useSelector((state: RootState) => state.user);

  const verifyUserToken = async (token: string) => {
    dispatch(verifyTokenStart());
    try {
      const response = await verifyToken(token);
      dispatch(verifyTokenSuccess(response));
      dispatch(setOrganization(response.organization));
    } catch (err: any) {
      dispatch(verifyTokenFailure(err.message));
    }
  };

  useEffect(() => {
    if (token) {
      verifyUserToken(token);
    }
  }, [token, dispatch]);

  // Verificación periódica del token cada 15 minutos
  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        verifyUserToken(token);
      }
    }, 15 * 60 * 1000); // 15 minutos en milisegundos

    return () => clearInterval(interval);
  }, [token, dispatch]);

  // Manejo de logout
  const handleLogout = () => {
    dispatch(logout());
  };

  return {
    userId,
    firstName,
    lastName,
    email,
    mobile,
    loading,
    isAuthenticated,
    handleLogout,
  };
};

export default useAuth;
