import React, { useEffect, useState } from "react";
import { ChatBubble } from "../../../pages/Conversaciones/Whatsapp/WhatsappStyles";
import { Message } from "../../../types/types";
import { format } from "date-fns";
import { fetchMessages } from "../../../services/chatService";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/slices/alertSlice";
import { FaFile } from "react-icons/fa";
import { setLoading } from "../../../redux/slices/loadingSlice";

interface WhatsappMessagesProps {
  mobile: string; // mobile number
}

const WhatsappMessages: React.FC<WhatsappMessagesProps> = ({ mobile }) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<Message[]>([]);

  let lastDate: string | null = null;

  const findHTMLInText = (text: string) => {
    // Expresión regular para detectar URLs
    const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi;

    // Reemplazar cada URL encontrada con una etiqueta <a>
    const result = text
      .replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" style="color:#D1345B">${url}</a>`;
      })
      .replace(/\n/g, "<br>"); // Reemplazar saltos de línea por <br>

    return result;
  };

  const getWhatsAppMessages = async () => {
    if (!mobile) {
      dispatch(
        setAlert({
          title: "Error",
          message: "No se ha encontrado un número de teléfono" + mobile,
          type: "error",
          show: true,
        })
      );
      return;
    }

    // validar si el numero esta bien formateado

    if (mobile.length < 10) {
      dispatch(
        setAlert({
          title: "Error",
          message: "El número de teléfono no es válido",
          type: "error",
          show: true,
        })
      );
      return;
    }

    dispatch(setLoading(true));

    const data = await fetchMessages(mobile, 1, 100);
    dispatch(setLoading(false));

    setMessages(data.messages);

    if (data.messages && data.messages.length === 0) {
      dispatch(
        setAlert({
          title: "Error",
          message:
            "No se encontraron mensajes para el número de teléfono: " + mobile,
          type: "error",
          show: true,
        })
      );
    }
  };

  useEffect(() => {
    getWhatsAppMessages();
  }, [mobile]);

  return (
    <div>
      {messages.length === 0 ? (
        <ChatBubble className="left">
          <p>No hay mensajes o el numero esta mal formateado.</p>
        </ChatBubble>
      ) : (
        messages.map((message: Message, index: any) => {
          const messageDate = format(new Date(message.timestamp), "dd/MM/yyyy");
          const showDate = lastDate !== messageDate;
          lastDate = messageDate;

          return (
            <React.Fragment key={index}>
              {showDate && (
                <div style={{ textAlign: "center", margin: "10px 0" }}>
                  <span style={{ fontStyle: "italic" }}>{messageDate}</span>
                </div>
              )}
              <ChatBubble className={message.direction}>
                {message.type === "text" && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: findHTMLInText(message.message),
                    }}
                  />
                )}
                {message.type === "image" && (
                  <div>
                    <img
                      src={message.mediaUrl}
                      alt="Imagen"
                      style={{
                        maxWidth: "400px",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(message.mediaUrl, "_blank")}
                    />
                  </div>
                )}
                {message.type === "video" && (
                  <div>
                    <video controls style={{ maxWidth: "400px" }}>
                      <source
                        src={message.mediaUrl}
                        type="video/mp4"
                        style={{ maxWidth: "400px" }}
                      />
                      Tu navegador no soporta la etiqueta de video2.
                    </video>
                    {message.message && <p>{message.message}</p>}
                  </div>
                )}
                {message.type === "audio" && (
                  <div>
                    <audio controls>
                      <source src={message.mediaUrl} type="audio/mpeg" />
                      Tu navegador no soporta la etiqueta de audio.
                    </audio>
                  </div>
                )}
                {message.type === "document" && (
                  <div>
                    <FaFile />
                    <a
                      href={message.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="application/pdf"
                    >
                      {message.message || "Documento recibido"}
                    </a>
                  </div>
                )}
                {message.type === "location" && (
                  <div>
                    <p>
                      Ubicación: Lat {message.latitude}, Lon {message.longitude}
                    </p>
                  </div>
                )}
                <p>{format(new Date(message.timestamp), "HH:mm")}</p>
              </ChatBubble>
            </React.Fragment>
          );
        })
      )}
    </div>
  );
};

export default WhatsappMessages;
