import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import {
  CreateContactoButton,
  TableFilter,
  TableFilterContainer,
  TableSearch,
  TableTopActions,
} from "./TableStyles";
import useDebounce from "../../../helpers/useDebounce";

interface FilterProps<T> {
  setShowCreateItem: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilterItem: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  fetchData: () => Promise<T[]>;
  searchFunction: (term: string) => Promise<T[]>;
}

const Filter = <T extends { _id: string }>({
  setShowCreateItem,
  setShowFilterItem,
  setData,
  fetchData,
  searchFunction,
}: FilterProps<T>) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  useEffect(() => {
    const fetchAndSetData = async () => {
      const data =
        debouncedSearchTerm === ""
          ? await fetchData()
          : await searchFunction(debouncedSearchTerm);
      setData(data);
    };

    fetchAndSetData();
  }, [debouncedSearchTerm, fetchData, searchFunction, setData]);

  return (
    <TableTopActions>
      <TableSearch>
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Buscar por: Nombre, Empresa o Teléfono"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </TableSearch>
      <TableFilterContainer>
        <TableFilter onClick={() => setShowFilterItem(true)}>
          <FaFilter />
          Filtros
        </TableFilter>
        <CreateContactoButton onClick={() => setShowCreateItem(true)}>
          Crear Contacto
        </CreateContactoButton>
      </TableFilterContainer>
    </TableTopActions>
  );
};

export default Filter;
