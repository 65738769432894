import { Form } from "./CreateDealsStyles";
import { useContext, useState, useEffect } from "react";
import { DynamicForm } from "./DynamicForm";
import { PipelineContext } from "../../../pages/Negocios/Negocios/context/PipelineContext";
import { Status, IContact } from "../../../types/types";
import FormDealContactModal from "./FormDealContactModal";
import { useNavigate } from "react-router-dom";

const FormDeal = ({ form, setForm, status }: any) => {
  const { selectedPipeline, contacts } = useContext(PipelineContext);
  const [searchContact, setSearchContact] = useState("");
  const [showContactModal, setShowContactModal] = useState(false);
  const navigate = useNavigate();

  const associatedContact = contacts.find(
    (contact: IContact) => contact._id === form.associatedContactId._id
  );

  // Sincronizar searchContact con el nombre del contacto asociado cuando cambia el contacto asociado
  useEffect(() => {
    if (associatedContact) {
      setSearchContact(
        associatedContact.properties.find((property) =>
          property.key.includes("firstName")
        )?.value || ""
      );
    }
  }, [associatedContact]);

  // Establecer la fecha de cierre por defecto a hoy si no está definida
  useEffect(() => {
    if (!form.closingDate) {
      const today = new Date().toISOString().split("T")[0];
      setForm((prevForm: any) => ({ ...prevForm, closingDate: today }));
    }
  }, [form.closingDate, setForm]);

  return (
    <div>
      <Form action="">
        <div>
          <label htmlFor="title">Nombre del negocio</label>
          <input
            type="text"
            name="title"
            id="title"
            onChange={(e) => {
              setForm({ ...form, [e.target.name]: e.target.value });
            }}
            placeholder="Ingrese el nombre del negocio"
            value={form.title}
          />
        </div>
        {!status.length ? (
          <>
            <label htmlFor="status">Estado inicial</label>
            <input type="text" value={"No hay estados disponibles"} disabled />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <label htmlFor="status">Estado inicial</label>
            <select
              id="status"
              onChange={(e) => {
                setForm({ ...form, status: e.target.value });
              }}
              defaultValue={form.status ? form.status : "Selecciona un estado"}
            >
              <option value={""}>Selecciona un estado</option>
              {status.map((status: Status) => (
                <option key={status._id} value={status._id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {!selectedPipeline ? (
          <>
            <label htmlFor="status">Pipeline</label>
            <input
              type="text"
              value={"Debe seleccionar un pipeline para continuar"}
              disabled
            />
          </>
        ) : (
          <div>
            <label htmlFor="">Pipeline</label>
            <input type="text" disabled value={selectedPipeline?.title} />
          </div>
        )}
        <div>
          <label htmlFor="amount">Valor</label>
          <input
            type="number"
            name="amount"
            id="amount"
            onChange={(e) => {
              setForm({ ...form, [e.target.name]: e.target.value });
            }}
            placeholder="Ingrese el nombre del negocio"
            value={form.amount}
          />
        </div>
        <div>
          <label htmlFor="closingDate">Fecha de cierre</label>
          <input
            type="date"
            name="closingDate"
            id="closingDate"
            onChange={(e) => {
              setForm({ ...form, [e.target.name]: e.target.value });
            }}
            value={form.closingDate}
          />
        </div>
        <DynamicForm />

        <hr style={{ margin: "20px 0px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <label htmlFor="searchValue">Contacto</label>
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              type="text"
              name="searchValue"
              id="searchValue"
              onChange={(e) => setSearchContact(e.target.value)}
              onClick={() => setShowContactModal(!showContactModal)}
              value={searchContact}
              placeholder="Buscar contacto"
            />
            {showContactModal && (
              <FormDealContactModal
                searchValue={searchContact}
                setShowContactModal={setShowContactModal}
                setForm={setForm}
                form={form}
              />
            )}
            {form.associatedContactId ? (
              <h5
                onClick={() =>
                  navigate("/contactos/" + form.associatedContactId._id)
                }
              >
                Ver Contacto
              </h5>
            ) : (
              <h5>no hay contacto seleccionado</h5>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default FormDeal;
