// src/components/common/PrivateRoute.tsx
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Loading from "./Loading/Loading";

const PrivateRoute: React.FC = () => {
  const { isAuthenticated, loading } = useSelector(
    (state: RootState) => state.user
  );

  if (loading) {
    return <Loading />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
