import React, { useState } from "react";
import { Button, RawContainer } from "./ProveedoresPageStyles";
import {
  ProductTable,
  Observaciones,
  TotalsRow,
} from "pages/Negocios/Cotizaciones/CreateQuotationStyles";
import { usePurchase } from "./context/PurchasesContext";
import FormDealContactModal from "components/negocios/sideComponents/FormDealContactModal";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updatePurchaseNumber } from "redux/slices/organizationSlice";

const CreatePurchase = ({ setActiveView }: any) => {
  const {
    purchaseOrderForm,
    updateField,
    addItem,
    removeItem,
    updateItem,
    products,
    createPurchaseOrder,
    getPurchaseOrders,
    error,
  } = usePurchase();

  // Estados locales
  const [selectedProduct, setSelectedProduct] = useState("");
  const [showSupplierModal, setShowSupplierModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [supplier, setSupplier] = useState<any>(null);

  const dispatch = useDispatch();

  const handleSave = async () => {
    await createPurchaseOrder();
    dispatch(updatePurchaseNumber());
    await getPurchaseOrders();
    setActiveView("table");
  };

  const handleSelectProduct = (e: any) => {
    setSelectedProduct(e.target.value);
  };

  const handleAddProduct = () => {
    const product = products.find((p) => p._id === selectedProduct);
    if (product) {
      addItem(product);
      setSelectedProduct("");
    }
  };

  return (
    <RawContainer>
      <FaArrowCircleLeft onClick={() => setActiveView("table")} />
      <h1>Crear Compra</h1>
      <div>
        <div
          style={{
            display: "flex",
            gap: "20px",
            padding: "20px 0px",
          }}
        >
          <div>
            <h2>Proveedor</h2>
            <input
              type="text"
              value={
                supplier
                  ? supplier.properties.find(
                      (prop: any) => prop.key === "firstName"
                    )?.value || ""
                  : searchValue
              }
              onClick={() => setShowSupplierModal(true)}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Seleccione un proveedor"
            />
            {showSupplierModal && (
              <div
                style={{
                  position: "relative",
                  width: "400px",
                  marginTop: "0px",
                }}
              >
                <FormDealContactModal
                  searchValue={searchValue}
                  setShowContactModal={setShowSupplierModal}
                  setCustomer={(selectedSupplier: any) => {
                    setSupplier(selectedSupplier);
                    updateField("supplierId", selectedSupplier._id);
                    setShowSupplierModal(false);
                  }}
                />
              </div>
            )}
          </div>
          <table>
            <tbody>
              <tr>
                <td>No. O.C</td>
                <td>
                  <input
                    type="text"
                    name="orderNumber"
                    value={purchaseOrderForm.orderNumber}
                    onChange={(e) => updateField("orderNumber", e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>Fecha</td>
                <td>
                  <input
                    type="date"
                    name="purchaseDate"
                    value={purchaseOrderForm.purchaseDate
                      .toISOString()
                      .substr(0, 10)}
                    onChange={(e) =>
                      updateField("purchaseDate", new Date(e.target.value))
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Forma de pago</td>
                <td>
                  <input
                    type="text"
                    name="paymentMethod"
                    value={purchaseOrderForm.paymentMethod}
                    onChange={(e) =>
                      updateField("paymentMethod", e.target.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Sección para agregar productos */}
        <div
          style={{
            margin: "20px 0",
          }}
        >
          <h3>Agregar Producto</h3>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <select value={selectedProduct} onChange={handleSelectProduct}>
              <option value="">Seleccione un producto</option>
              {products.map((product) => (
                <option key={product._id} value={product._id}>
                  {product.name}
                </option>
              ))}
            </select>
            <Button onClick={handleAddProduct} disabled={!selectedProduct}>
              Agregar Producto
            </Button>
          </div>
        </div>

        {/* Tabla de productos agregados */}
        <ProductTable>
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Precio</th>
              <th>Impuesto (Ej: 19)</th>
              <th>Total</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {purchaseOrderForm.items.map((item) => (
              <tr key={item.uniqueId}>
                <td>{item.name}</td>
                <td>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      updateItem(
                        item.uniqueId,
                        "quantity",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.unitPrice}
                    onChange={(e) =>
                      updateItem(
                        item.uniqueId,
                        "unitPrice",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={item.tax}
                    onChange={(e) =>
                      updateItem(
                        item.uniqueId,
                        "tax",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                </td>
                <td>{item.total.toFixed(2)}</td>
                <td>
                  <button onClick={() => removeItem(item.uniqueId)}>
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </ProductTable>

        {/* Totales */}
        <TotalsRow>
          <table>
            <tbody>
              <tr>
                <td>Sub Total</td>
                <td>${purchaseOrderForm.subtotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td>IVA</td>
                <td>${purchaseOrderForm.tax.toFixed(2)}</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>${purchaseOrderForm.total.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </TotalsRow>

        {/* Observaciones */}
        <Observaciones>
          <p>Observaciones</p>
          <textarea
            name="description"
            value={purchaseOrderForm.description}
            onChange={(e) => updateField("description", e.target.value)}
          ></textarea>
        </Observaciones>

        {/* Botón de Guardar */}
        <div style={{ marginTop: "20px" }}>
          <Button onClick={handleSave}>{"Guardar"}</Button>
        </div>

        {/* Mostrar errores si existen */}
        {error && <p style={{ color: "red" }}>Error: {error}</p>}
      </div>
    </RawContainer>
  );
};

export default CreatePurchase;
