import styled from "styled-components";
import { theme } from "styles/theme";

export const LayoutLeads = styled.div`
  display: flex;
  gap: 2rem;
  padding: 1rem;
  height: calc(100vh - 200px);
  overflow: hidden;
`;

export const Instrucciones = styled.div`
  background-color: ${theme.colors.grisClaro};
  height: 100%;
  width: 20%;
  padding: 1rem;
`;

export const FormArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  div {
    margin-top: 1rem;
  }

  input {
    width: 100px;
  }

  button {
    margin: 20px 0px;
    padding: 0.5rem;
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 200px;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
    }
  }
`;

export const RadioOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-right: 0.5rem;
    width: 100%;
  }
  input {
    margin-right: 0.5rem;
    background-color: red;
    width: 20px;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  textarea {
    outline: none;
    border: none;
    height: 100px;
    background-color: ${theme.colors.grisClaro};
    border-radius: 5px;
    padding: 0.5rem;
  }
`;

export const ResultsCounter = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background-color: ${theme.colors.grisClaro};
  }
`;
export const Table = styled.div`
  width: 100%;
  overflow-x: auto; /* Permite desplazamiento horizontal si la tabla es muy ancha */

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead,
  tfoot {
    background-color: ${theme.colors.grisClaro};
  }

  tbody {
    display: block;
    height: 500px; /* Ajusta la altura del tbody a lo que necesites */
    overflow-y: auto; /* Permite el scroll vertical en el tbody */
  }

  thead,
  tfoot {
    display: table;
    width: 100%; /* Asegura que thead y tfoot ocupen el ancho completo */
    table-layout: fixed; /* Fija el ancho de las columnas */
  }

  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Fija el ancho de las columnas en tbody */
  }

  th,
  td {
    border: 1px solid ${theme.colors.grisClaro};
    padding: 0.5rem;
    text-align: left;
  }

  th {
    background-color: ${theme.colors.grisClaro};
    position: sticky;
    top: 0; /* Fija el encabezado de la tabla */
    z-index: 1; /* Asegura que el encabezado esté por encima del cuerpo al hacer scroll */
  }

  td {
    width: 20%;
  }

  tfoot > tr > td {
    text-align: right;
    button {
      margin-right: 1rem;
    }
  }

  button {
    padding: 0.5rem;
    background-color: ${theme.colors.rosado};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
    }
  }
`;
