import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Container = styled.div`
  padding: 10px;
  background-color: white;
  width: 300px;
  height: 100px;
  position: absolute; // Cambiado a absolute para que se superponga sobre el contenido
  top: -110px; // Ajustado para posicionar encima del texto "Fragmentos"
  left: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 1000; // Asegura que esté por encima de otros elementos
  h1 {
    font-size: 18px;
  }
`;

export const Link = styled.div`
  border-bottom: 1px solid ${theme.colors.gris3};
  padding: 10px;

  h3 {
    font-size: 16px;
    font-weight: 500;
  }
  &:hover {
    background-color: ${theme.colors.rosadoClaro};
  }
`;
