import React, { act, useContext } from "react";
import { NavbarContainer } from "./NavbarStyles";
import { useLocation } from "react-router-dom";
import { CampanaContext } from "../context/CampanaContext";

const Navbar = () => {
  const { setActiveStep, activeStep } = useContext(CampanaContext);

  return (
    <NavbarContainer>
      <ul>
        <li
          onClick={() => setActiveStep("Informacion")}
          style={
            activeStep === "Informacion" ? { backgroundColor: "#D1345B" } : {}
          }
        >
          Informacion
        </li>
        <li
          style={activeStep === "Publico" ? { backgroundColor: "#D1345B" } : {}}
          onClick={() => setActiveStep("Publico")}
        >
          Publico
        </li>
        <li
          onClick={() => setActiveStep("Plataforma")}
          style={
            activeStep === "Plataforma" ? { backgroundColor: "#D1345B" } : {}
          }
        >
          Plataforma
        </li>
        <li
          onClick={() => setActiveStep("Metricas")}
          style={
            activeStep === "Metricas" ? { backgroundColor: "#D1345B" } : {}
          }
        >
          Metricas
        </li>
        <li
          onClick={() => setActiveStep("Resultados")}
          style={
            activeStep === "Resultados" ? { backgroundColor: "#D1345B" } : {}
          }
        >
          Resultados
        </li>
      </ul>
    </NavbarContainer>
  );
};

export default Navbar;
