import axios from "axios";

interface FormuAppApi {
  formId: string;
  content: {};
}

export const getPDFFile = async (
  formId: string,
  content: FormuAppApi["content"]
) => {
  const apiURL = `https://formuapi2-production.up.railway.app/api/files/pdf/${formId}`;

  const response = await axios.post(apiURL, content, {
    headers: {
      "x-api-key":
        "f3b8e8f4d0a6b8d5c9fcd1a2e4b7c9f0d1a4b6c8d9e2f1a7b3c9e5d8f0a7b2c4e3f6",
    },
    responseType: "blob", // Esto asegura que el PDF se maneje correctamente como un archivo
  });

  return response.data; // Devuelve solo el blob del PDF
};

export const getFile = async (formId: string) => {
  const response = await axios.get(
    "https://formuapi2-production.up.railway.app/api/files/" + formId,
    {
      headers: {
        "x-api-key":
          "f3b8e8f4d0a6b8d5c9fcd1a2e4b7c9f0d1a4b6c8d9e2f1a7b3c9e5d8f0a7b2c4e3f6",
      },
    }
  );

  return response.data;
};

export const getFiles = async () => {
  const response = await axios.get(
    "https://formuapi2-production.up.railway.app/api/files/api",
    {
      headers: {
        "x-api-key":
          "f3b8e8f4d0a6b8d5c9fcd1a2e4b7c9f0d1a4b6c8d9e2f1a7b3c9e5d8f0a7b2c4e3f6",
      },
    }
  );

  return response.data;
};
