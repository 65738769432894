import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0px;

  h1 {
    color: ${theme.colors.rosado};
    margin-left: 10px;
  }

  svg {
    font-size: 30px;
  }
  path {
    color: ${theme.colors.rosado};
  }
`;

export const TableActions = styled.div`
  display: flex;
  margin-bottom: 20px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    background-color: ${theme.colors.gris3};
    margin-right: 20px;
    border: none;
    cursor: pointer;
  }
`;

export const DealsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;

  thead {
    background-color: ${theme.colors.gris3};
    color: ${theme.colors.blanco};
  }

  th,
  td {
    border: 1px solid ${theme.colors.gris3};
    padding: 10px;
    text-align: left;
  }

  tr {
    &:hover {
      background-color: ${theme.colors.gris2};
    }
  }
`;
