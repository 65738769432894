import {
  ConfirmationContainer,
  ConfirmationContent,
} from "./ConfirmationStyles";
import React from "react";
import { FaTimes } from "react-icons/fa";

interface IConfirmation {
  title: string;
  message: string;
  confirmation: () => void;
  setConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

const Confirmation = ({
  title,
  message,
  confirmation,
  setConfirm,
}: IConfirmation) => {
  return (
    <ConfirmationContainer>
      <ConfirmationContent>
        <div>
          <FaTimes onClick={() => setConfirm(false)} />
        </div>
        <h1>{title}</h1>
        <p>{message}</p>

        <div>
          <button onClick={() => confirmation()}>Confirmar</button>
          <button onClick={() => setConfirm(false)}>Cancelar</button>
        </div>
      </ConfirmationContent>
    </ConfirmationContainer>
  );
};

export default Confirmation;
