import { useState, useRef, useEffect, useCallback } from "react";
import { Chat, Message } from "../../../../types/types";
import {
  fetchMessages,
  fetchChatList,
  searchChat,
  subscribeToNewMessages,
  unsubscribeFromNewMessages,
  sendMessage,
  markAsRead,
  deleteChat,
} from "../../../../services/chatService";
import { useSocket } from "../../../../redux/SocketContext";
import { SendMessagePayload } from "../../../../types/types";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../redux/slices/notificationSlice";
import { setLoading } from "redux/slices/loadingSlice";
import { setAlert } from "redux/slices/alertSlice";

export const useWhatsapp = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatList, setChatList] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [affected, setAffected] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const socket = useSocket();
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  const loadChatList = useCallback(async () => {
    try {
      // dispatch(setLoading(true));

      const chatData = await fetchChatList();

      dispatch(setLoading(false));
      if (Array.isArray(chatData)) {
        setChatList(chatData);
      } else {
        setChatList([]);
        console.error("Chat list is not an array");
      }
    } catch (error) {
      console.error("Failed to load chat list", error);
      setChatList([]);
    }
  }, []);

  const loadMessages = useCallback(
    async (page: number) => {
      if (selectedChat) {
        try {
          const messageData = await fetchMessages(
            selectedChat.contact,
            page,
            10
          );
          setTotalPages(messageData.totalPages);
          setMessages((prevMessages) =>
            page === 1
              ? messageData.messages
              : [...messageData.messages, ...prevMessages]
          );
          setCurrentPage(page);
          if (page === 1) scrollToBottom();
        } catch (error) {
          console.error("Failed to load messages", error);
        }
      }
    },
    [selectedChat]
  );

  const markMessagesAsRead = useCallback(async (chat: Chat) => {
    if (chat.unreadCount === 0) return;

    try {
      await markAsRead(chat.contact);
      setChatList((prevChatList) =>
        prevChatList.map((c: Chat) =>
          c.contact === chat.contact ? { ...c, unreadCount: 0 } : c
        )
      );
    } catch (error) {
      console.error("Failed to mark messages as read:", error);
    }
  }, []);

  const handleChatClick = useCallback(
    async (chat: Chat) => {
      setSelectedChat(chat);
      await markMessagesAsRead(chat);
    },
    [markMessagesAsRead]
  );

  const updateChatListWithNewMessage = useCallback(
    (newMessage: Message) => {
      setChatList((prevChatList) =>
        prevChatList.map((chat) =>
          chat.contact === newMessage.from || chat.contact === newMessage.to
            ? {
                ...chat,
                lastMessage: newMessage.message,
                lastMessageTime: newMessage.timestamp,
                unreadCount:
                  chat.contact === newMessage.from
                    ? chat.unreadCount + 1
                    : chat.unreadCount,
              }
            : chat
        )
      );

      if (
        selectedChat &&
        (newMessage.from === selectedChat.contact ||
          newMessage.to === selectedChat.contact)
      ) {
        scrollToBottom();
      }
    },
    [selectedChat]
  );

  const handleNewMessage = useCallback(
    (newMessage: Message) => {
      setMessages((prevMessages) => {
        const messageExists = prevMessages.some(
          (msg) => msg._id === newMessage._id
        );
        return messageExists ? prevMessages : [...prevMessages, newMessage];
      });
      updateChatListWithNewMessage(newMessage);
    },
    [updateChatListWithNewMessage]
  );

  const handleSearchChat = useCallback(
    async (query: string) => {
      try {
        if (query.trim()) {
          const searchResults = await searchChat(query);
          console.log(searchResults);
          if (Array.isArray(searchResults)) {
            setChatList(searchResults);
          } else {
            setChatList([]);
            console.error("Search results are not an array");
          }
        } else {
          loadChatList();
        }
      } catch (error) {
        console.error("Failed to search chats", error);
        setChatList([]);
      }
    },
    [loadChatList]
  );

  const handleSendMessage = useCallback(
    async (
      messageType: "text" | "image" | "document" | "video" | "audio" = "text",
      mediaUrl?: string
    ) => {
      if (selectedChat) {
        try {
          let messagePayload: SendMessagePayload;

          if (messageType === "text") {
            messagePayload = {
              to: selectedChat.contact,
              message: newMessage,
              messageType,
            };
          } else {
            if (!mediaUrl) {
              throw new Error("mediaUrl is required for media messages");
            }
            messagePayload = {
              to: selectedChat.contact,
              message: newMessage,
              messageType,
              mediaUrl,
              caption: newMessage,
            };
          }

          await sendMessage(messagePayload);
          setNewMessage("");
          scrollToBottom();
        } catch (error) {
          console.error("Failed to send message", error);
        }
      } else {
        console.log("Error enviando un mensaje");
      }
    },
    [newMessage, selectedChat]
  );

  useEffect(() => {
    if (selectedChat) {
      setMessages([]); // Limpia los mensajes anteriores
      loadMessages(totalPages);
    }
  }, [selectedChat, loadMessages, totalPages]);

  useEffect(() => {
    loadChatList();
  }, [affected]);

  useEffect(() => {
    if (!socket) {
      console.log("No socket available yet");
      return;
    }

    console.log("Socket is now available, subscribing to events");
    subscribeToNewMessages(socket, handleNewMessage);

    return () => {
      unsubscribeFromNewMessages(socket, handleNewMessage);
    };
  }, [socket, handleNewMessage]);

  useEffect(() => {
    if (!socket) return;

    const handleReconnect = () => {
      console.log("Socket reconnected, resubscribing to events");
      subscribeToNewMessages(socket, handleNewMessage);
    };

    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("reconnect", handleReconnect);
    };
  }, [socket, handleNewMessage]);

  useEffect(() => {
    if (!socket) {
      console.log("No socket available yet");
      return;
    }

    const handleNewNotification = (data: any) => {
      const { contact, message } = data;
      console.log("Data recibida en newNotification:", data);
      // Verificar si 'message' existe y tiene la estructura esperada
      if (!message || !message.message) {
        console.error("Mensaje inválido recibido:", message);
        return;
      }

      // Actualiza el chat en el chatList si ya existe
      setChatList((prevChatList) =>
        prevChatList.map((chat) =>
          chat.contact === contact
            ? {
                ...chat,
                lastMessage: message.message,
                lastMessageTime: message.timestamp,
                unreadCount: chat.unreadCount + 1, // Incrementar el contador de mensajes no leídos
              }
            : chat
        )
      );

      // Si no existe el chat, añádelo a la lista de chats (si es un nuevo contacto que escribe)
      setChatList((prevChatList: any) => {
        const chatExists = prevChatList.some(
          (chat: any) => chat.contact === contact
        );
        if (!chatExists) {
          return [
            ...prevChatList,
            {
              _id: contact, // Si no tienes un ID real, podrías usar el contacto como ID
              contact: contact,
              name: "", // Ajusta si tienes un campo para el nombre
              lastMessage: message.message,
              lastMessageTime: message.timestamp,
              unreadCount: 1, // Primer mensaje no leído
            },
          ];
        }
        return prevChatList;
      });

      // Actualiza los mensajes si el chat seleccionado es el que ha recibido la notificación
      if (selectedChat && selectedChat.contact === contact) {
        loadMessages(totalPages); // Recarga los mensajes si es el chat activo
      }
    };

    socket.on("newNotification", handleNewNotification);

    return () => {
      socket.off("newNotification", handleNewNotification);
    };
  }, [socket, selectedChat, loadMessages, totalPages]);

  const handleDeleteChat = async () => {
    if (!selectedChat) return;
    await deleteChat(selectedChat.contact);

    setAffected(true);
    setSelectedChat(null);
    setMessages([]);

    dispatch(
      setAlert({
        title: "Chat eliminado",
        message: "El chat ha sido eliminado",
        type: "success",
        show: true,
      })
    );
  };

  return {
    handleDeleteChat,
    setChatList,
    chatList,
    selectedChat,
    messages,
    newMessage,
    setNewMessage,
    setSelectedChat,
    handleSendMessage,
    loadChatList,
    handleSearchChat,
    setAffected,
    affected,
    chatBodyRef,
    loadMoreMessages: async () => {}, // Implementar si es necesario
    updateChatListWithNewMessage,
    markMessagesAsRead,
    handleChatClick,
  };
};
