import React, { useState, useEffect } from "react";
import {
  TableContainer,
  TableResponsiveContainer,
  TableTag,
} from "./TableStyles";
import { IHeader } from "../../../types/types";
import TableActions from "./TableActions";
import Paginations from "./Paginations";
import Filter from "./Filter";
import Tools from "./Tools";
import TableRow from "./TableRow";
import Loading from "../Loading/Loading";

interface TableProps<T extends { _id: string }> {
  data: T[];
  headers: IHeader[];
  setShowCreateItem?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilterItem?: React.Dispatch<React.SetStateAction<boolean>>;
  setData?: React.Dispatch<React.SetStateAction<T[]>>;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  limit: number;
  page: number;
  affected: boolean;
  fetchData: () => Promise<T[]>;
  searchFunction: (term: string) => Promise<T[]>;
  deleteHandler: (ids: string[]) => Promise<void>;
  editHandler: (id: string) => void;
  printHandler?: (id: string) => void; // Nuevo prop para imprimir
  tableType: "quotations" | "contacts" | "list" | "fragments"; // Nuevo prop para determinar el tipo de tabla
}

const Table = <T extends { _id: string }>({
  data,
  headers,
  setShowCreateItem,
  setShowFilterItem,
  setData,
  setLimit,
  setPage,
  setAffected,
  limit,
  page,
  affected,
  fetchData,
  searchFunction,
  deleteHandler,
  editHandler,
  printHandler, // Nuevo prop para imprimir
  tableType, // Nuevo prop para determinar el tipo de tabla
}: TableProps<T>) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDataAndSet = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchData();
        setData?.(data);
      } catch (err) {
        setError("Error al cargar los datos.");
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndSet();
  }, [fetchData, setData, limit, page, affected]);

  const handleSelectAll = () => {
    if (selected.length === data.length) {
      setSelected([]);
    } else {
      const allIds = data.map((item) => item._id);
      setSelected(allIds);
    }
  };

  const handlePrint = (id: string) => {
    if (printHandler) {
      printHandler(id);
      setSelected([]); // Clear selection after printing
    }
  };

  return (
    <TableContainer>
      {setData && setShowCreateItem && setShowFilterItem && (
        <Filter
          setData={setData}
          setShowCreateItem={setShowCreateItem}
          setShowFilterItem={setShowFilterItem}
          fetchData={fetchData}
          searchFunction={searchFunction}
        />
      )}
      <TableActions
        setSelected={setSelected}
        selected={selected}
        setAffected={setAffected}
        affected={affected}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        printHandler={tableType === "quotations" ? handlePrint : undefined} // Condicional para mostrar "imprimir"
        asignHandler={tableType === "contacts" ? (id) => {} : undefined} // Condicional para mostrar "asignar"
      />
      <TableResponsiveContainer>
        {loading ? (
          <Loading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <TableTag>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selected.length === data.length}
                    onChange={handleSelectAll}
                  />
                </th>
                {headers.map((header, index) =>
                  header.isVisible ? <th key={index}>{header.label}</th> : null
                )}
              </tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td colSpan={headers.length + 1}>No hay registros</td>
                </tr>
              ) : (
                data.map((item, index) => (
                  <TableRow
                    key={index}
                    item={item}
                    headers={headers}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))
              )}
            </tbody>
          </TableTag>
        )}
      </TableResponsiveContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Paginations
          setLimit={setLimit}
          setAffected={setAffected}
          affected={affected}
          limit={limit}
          page={page}
          setPage={setPage}
        />
        <Tools setAffected={setAffected} affected={affected} />
      </div>
    </TableContainer>
  );
};

export default Table;
