import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid ${theme.colors.gris3};
  margin: 20px;

  th {
    padding: 10px;
    text-align: left;
    border: 1px solid ${theme.colors.gris3};
    min-width: 20%;
    text-align: center;
  }

  th:first-child {
    width: 50px;
  }

  td {
    border: 1px solid ${theme.colors.gris3};
    padding: 10px;
  }

  td > p {
    color: ${theme.colors.gris1};
    text-align: center;
  }

  tr > td:first-child {
    text-align: center;
  }

  tr > td:first-child input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid ${theme.colors.gris3};
    width: 16px;
    height: 16px;
    background-color: white;
    cursor: pointer;
  }
  tr > td:nth-child(2) > p {
    color: ${theme.colors.rosado};
  }
`;

export const Estado = styled.td<{ $estado: string }>`
  min-width: 200px;
  background-color: ${({ $estado }) =>
    $estado === "No Iniciado"
      ? theme.colors.gris2
      : $estado === "Completado"
      ? theme.colors.green
      : $estado === "En Progreso"
      ? theme.colors.green
      : $estado === "Atrasado"
      ? theme.colors.rojo
      : theme.colors.blanco};
  color: ${theme.colors.gris1};
  padding: 5px;
  text-align: center;
  width: 100px;
  margin: 0 auto;

  p {
    font-weight: 300;
    color: ${({ $estado }) =>
      $estado === "En Progreso"
        ? theme.colors.amarillo
        : $estado === "Completado"
        ? theme.colors.blanco + "!important"
        : theme.colors.grisOscuro};
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.gris3};
  border-radius: 5px;
  overflow: hidden;
`;

export const ProgressBar = styled.div<{ $progress: number }>`
  width: ${(props) => props.$progress}%;
  background-color: ${theme.colors.rosado};
  height: 20px;
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
`;
