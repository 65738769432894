import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { IProduct } from "../../types/types";
import { Container, Form, FormFooter, FormHeader } from "./NewProductStyles";

interface NewProductProps {
  product?: IProduct;
  setShowNewProduct: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (product: IProduct) => void;
}

interface Field {
  name: string;
  label: string;
  type?: string;
}

const NewProduct: React.FC<NewProductProps> = ({
  product,
  setShowNewProduct,
  onSave,
}) => {
  const [form, setForm] = useState<IProduct>(
    product || {
      name: "",
      description: "",
      unitPrice: 0,
      taxes: 0,
      imageUrl: "",
      cost: 0,
      sku: "",
      categoryId: "",
      notes: "",
      organizationId: "",
      dimensions: {
        width: 0,
        height: 0,
        length: 0,
      },
    }
  );

  const fields: Field[] = [
    { name: "name", label: "Nombre" },
    { name: "description", label: "Descripción" },
    { name: "unitPrice", label: "Precio Unitario", type: "number" },
    { name: "taxes", label: "Impuestos", type: "number" },
    { name: "imageUrl", label: "URL de la Imagen" },
    { name: "cost", label: "Costo", type: "number" },
    { name: "sku", label: "SKU" },
    { name: "categoryId", label: "ID de Categoría" },
    { name: "notes", label: "Notas" },
    { name: "organizationId", label: "ID de Organización" },
    { name: "userId", label: "ID de Usuario" },
    { name: "width", label: "Ancho" },
    { name: "height", label: "Altura" },
    { name: "length", label: "Largo" },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const submitHandler = () => {
    onSave(form);
    setShowNewProduct(false);
  };

  return (
    <Container>
      <FormHeader>
        <h2>{product ? "Editar Producto" : "Crear Producto"}</h2>
        <FaTimes onClick={() => setShowNewProduct(false)} />
      </FormHeader>
      <Form>
        {fields.map((field) => (
          <div key={field.name}>
            <label htmlFor={field.name}>{field.label}</label>
            <input
              type={field.type || "text"}
              name={field.name}
              id={field.name}
              onChange={handleChange}
              value={form[field.name as keyof IProduct] as string | number}
            />
          </div>
        ))}
      </Form>
      <FormFooter>
        <button onClick={submitHandler}>Guardar</button>
        <button onClick={() => setShowNewProduct(false)}>Cancelar</button>
      </FormFooter>
    </Container>
  );
};

export default NewProduct;
