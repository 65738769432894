import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const ListsChats = styled.div`
  width: 30%;
  height: 100%;
  overflow: auto;
`;

export const ListHeader = styled.div`
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f1f1f1;
  margin: 15px 20px;
  border: 1px solid ${theme.colors.gris3};
  padding: 5px;

  svg {
    margin-right: 5px;
  }

  path {
    color: ${theme.colors.gris2};
  }

  input {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background-color: #f1f1f1;
  }
`;

export const ListBody = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const ListChat = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid ${theme.colors.gris3};
  background-color: ${(props) =>
    props.selected ? theme.colors.rosadoClaro : "#f1f1f1"};
  cursor: pointer;
  height: 80px;
  position: relative;

  &:hover {
    background-color: ${theme.colors.gris3};
  }

  & > .indicator-container {
    width: 10px; /* Ancho fijo para el indicador */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .content-container {
    flex-grow: 1; /* Toma el espacio restante */
    margin-left: 10px; /* Espacio entre el indicador y el contenido */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & > .unread-count-container {
    width: 60px; /* Ancho fijo para el contador de mensajes no leídos */
    text-align: right;
    font-size: 12px;
    color: ${theme.colors.grisOscuro};
  }

  h4 {
    margin: 0;
    font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  }

  p {
    margin: 5px 0 0 0;
    color: ${theme.colors.grisOscuro};
    font-size: 14px;
  }
`;

export const UnreadIndicator = styled.div<{ unreadCount: number }>`
  width: 6px; /* Ajuste del ancho para que sea más estrecho */
  background-color: ${(props) =>
    props.unreadCount > 0 ? "#D1345B" : "transparent"};
  height: 40px; /* Tamaño fijo de la altura para evitar que se expanda */
  border-radius: 2px;
`;

export const UnreadCount = styled.div`
  font-size: 12px;
  color: ${theme.colors.grisOscuro};
`;
