import React, { useContext } from "react";
import { FaBoxes } from "react-icons/fa";
import TopMenu from "../../../components/negocios/TopMenu";
import Footer from "../../../components/negocios/Footer";
import TableWithDragAndDrop from "../../../components/negocios/Table";
import { PipelineContext } from "./context/PipelineContext";
import { CreatePipeline } from "../../../components/negocios/CreatePipeline";
import { EditState } from "../../../components/negocios/EditState";
import { EditDealsFields } from "../../../components/negocios/EditDealsFields";

import { usePipelines } from "./hooks/usePipelines";
import { theme } from "../../../styles/theme";

const Page: React.FC = () => {
  const pipelineContextInstance = useContext(PipelineContext);
  console.log("test desde negocios");

  return (
    <>
      {pipelineContextInstance.openEditStates && <EditState />}
      {pipelineContextInstance.openCreatePipeline && <CreatePipeline />}
      {pipelineContextInstance.openEditDealsFields && <EditDealsFields />}
      <div>
        <div>
          <FaBoxes />
          <h1>Negocios</h1>
        </div>
        <TopMenu />
        {!pipelineContextInstance.loading && (
          <>
            {pipelineContextInstance.pipelines.length > 0 ? (
              <div
                style={{
                  minHeight: "350px",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <TableWithDragAndDrop />
              </div>
            ) : (
              <>
                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "350px",
                    textAlign: "center",
                  }}
                >
                  <h1>
                    Por favor{" "}
                    <span
                      onClick={pipelineContextInstance.handleOpenCreatePipeline}
                      style={{
                        color: theme.colors.rosado,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      crea un pipeline
                    </span>{" "}
                    para comenzar a <br /> agregar negocios
                  </h1>
                </div>
              </>
            )}
            <Footer />
          </>
        )}
      </div>
    </>
  );
};

const App: React.FC = () => (
  <PipelineContext.Provider value={usePipelines()}>
    <Page />
  </PipelineContext.Provider>
);

export default App;
