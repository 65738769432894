import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ProductCard from "../../components/products/ProductCard";
import {
  createProduct,
  deleteProduct,
  getProducts,
  searchProduct,
  updateProduct,
} from "../../services/productService";
import { IProduct } from "../../types/types";
import { FaBox, FaSearch } from "react-icons/fa";
import { Button, CardContainer, Header, SearchArea } from "./ProductosStyles";
import NewProduct from "./NewProduct";
import useDebounce from "../../helpers/useDebounce";
import { setAlert } from "../../redux/slices/alertSlice";

const ProductosPage = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showNewProduct, setShowNewProduct] = useState(false);
  const [productToEdit, setProductToEdit] = useState<IProduct>({} as IProduct);
  const [activeProductId, setActiveProductId] = useState<string | null>(null);

  // Debounce el valor de searchTerm
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const dispatch = useDispatch();

  const fetchProducts = async () => {
    try {
      const newProducts = await getProducts(10, 1);
      setProducts(newProducts);
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al cargar productos",
          show: true,
          type: "error",
        })
      );
    }
  };

  const saveProduct = async (product: IProduct) => {
    try {
      await createProduct(product);
      fetchProducts();
      dispatch(
        setAlert({
          title: "Éxito",
          message: "Producto creado con éxito",
          show: true,
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al cargar productos",
          show: true,
          type: "error",
        })
      );
    }
  };

  const updateProducto = async (product: IProduct) => {
    if (!product._id) return;
    try {
      await updateProduct(product._id, product);
      fetchProducts();
      dispatch(
        setAlert({
          title: "Éxito",
          message: "Producto actualizado con éxito",
          show: true,
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al actualizar producto",
          show: true,
          type: "error",
        })
      );
    }
  };

  const deleteProducto = async (productId: string) => {
    if (!productId) return;
    try {
      await deleteProduct(productId);
      setProducts(products.filter((product) => product._id !== productId));

      dispatch(
        setAlert({
          title: "Éxito",
          message: "Producto eliminado con éxito",
          show: true,
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Error al eliminar producto",
          show: true,
          type: "error",
        })
      );
    }
  };

  const handleMenuToggle = (productId: string) => {
    setActiveProductId(activeProductId === productId ? null : productId);
  };

  const searchProducts = async (term: string) => {
    if (!term) {
      fetchProducts();
      return;
    }

    try {
      const results = await searchProduct(term);
      setProducts(results);
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error",
          message: "Error en la búsqueda de productos",
          show: true,
          type: "error",
        })
      );
    }
  };

  // Realizar la búsqueda cada vez que el debouncedSearchTerm cambie
  useEffect(() => {
    searchProducts(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div>
      <Header>
        <h1>
          <FaBox /> Listado de productos
        </h1>
        <SearchArea>
          <FaSearch />
          <input
            type="text"
            placeholder="Buscar productos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SearchArea>
        <Button
          onClick={() => {
            setShowNewProduct(!showNewProduct);
          }}
        >
          Crear Producto
        </Button>
      </Header>

      <CardContainer>
        {products.map((product: IProduct) => (
          <ProductCard
            key={product._id}
            product={product}
            onDelete={() => deleteProducto(product._id as string)}
            onEdit={() => {
              setShowNewProduct(true);
              setProductToEdit(product);
            }}
            isActive={activeProductId === product._id}
            onMenuToggle={handleMenuToggle}
          />
        ))}
      </CardContainer>

      {showNewProduct && (
        <NewProduct
          product={productToEdit}
          onSave={(product) => {
            if (product._id) {
              updateProducto(product);
            } else {
              saveProduct(product);
            }
          }}
          setShowNewProduct={setShowNewProduct}
        />
      )}
    </div>
  );
};

export default ProductosPage;
