import React, { useEffect, useState } from "react";
import { Container, Content } from "./PrintModalStyle";
import { FaPrint } from "react-icons/fa";
import axios from "axios";
import { getFile, getFiles, getPDFFile } from "../../../services/printService";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/loadingSlice";

interface PrintModalProps {
  setShowPrintModal: (value: boolean) => void;
  contact: any;
}

const PrintModal = ({ setShowPrintModal, contact }: PrintModalProps) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const formatData = (fileEtapas: any, contact: any) => {
    const data: any = {};

    fileEtapas.forEach((etapa: any) => {
      const etapaLabel = etapa.label;

      etapa.key.forEach((keyItem: any) => {
        const contactProperty = contact.properties.find(
          (property: any) => property.key === keyItem.key
        );

        if (contactProperty) {
          data[`${etapaLabel}.${keyItem.key}?`] = contactProperty.value;
        }
      });
    });
    console.log(data);
    return data;
  };

  const printHandler = async (formId: string) => {
    try {
      dispatch(setLoading(true));
      const files = await getFile(formId); // Obtiene el file para mapear las etapas

      const data = formatData(files.etapas, contact); // Formatea los datos para el PDF

      const pdfBlob = await getPDFFile(formId, data); // Obtiene el PDF con los datos

      const url = window.URL.createObjectURL(pdfBlob); // Crea una URL para el PDF

      dispatch(setLoading(false));
      // Abre el PDF en una nueva pestaña y activa el diálogo de impresión
      const newWindow = window.open(url);
      if (newWindow) {
        newWindow.addEventListener("load", () => {
          newWindow.print();
        });
      }

      setShowPrintModal(false);
    } catch (error) {
      console.error("Error al imprimir el PDF:", error);
    }
  };

  const fetchFiles = async () => {
    dispatch(setLoading(true));
    const response = await getFiles();
    setFiles(response);
    dispatch(setLoading(false));
    console.log(response);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  return (
    <Container>
      <Content>
        <ul>
          {files.map((file: any) => (
            <li key={file._id} onClick={() => printHandler(file._id)}>
              <FaPrint />
              {file.name}
            </li>
          ))}
        </ul>
      </Content>
    </Container>
  );
};

export default PrintModal;
