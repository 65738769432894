import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { read, utils } from "xlsx";
import importService from "../../services/importService";
import { setAlert } from "../../redux/slices/alertSlice";
import { setLoading } from "../../redux/slices/loadingSlice";

interface Mapping {
  [key: string]: string;
}

const Importar: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [mapping, setMapping] = useState<Mapping>({});
  const [headers, setHeaders] = useState<string[]>([]);

  const contactProperties = useSelector(
    (state: RootState) => state.organization.contactProperties
  );

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        if (typeof data === "string" || data instanceof ArrayBuffer) {
          const workbook = read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(sheet, { header: 1 }) as string[][];
          setHeaders(json[0]); // La primera fila contiene los headers
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  };

  const handleMappingChange = (header: string, value: string) => {
    setMapping((prevMapping) => ({
      ...prevMapping,
      [header]: value,
    }));
  };

  const handleImport = async () => {
    if (!file) {
      dispatch(
        setAlert({
          title: "Error de Importación",
          message: "Debe seleccionar un archivo antes de importar.",
          type: "error",
          show: true,
        })
      );
      return;
    }

    dispatch(setLoading(true));

    try {
      // Crear el FormData y agregar el archivo y el mapeo
      const formData = new FormData();
      formData.append("file", file);
      formData.append("mapping", JSON.stringify(mapping)); // Enviando el mapeo como JSON

      const result = await importService.importContacts(formData);

      if (result?.error) {
        dispatch(
          setAlert({
            title: "Error de Importación",
            message: `Importación fallida: ${result.error}`,
            type: "error",
            show: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            title: "Importación Exitosa",
            message: `Se importaron ${
              result?.importedCount || 0
            } contactos exitosamente.`,
            type: "success",
            show: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          title: "Error de Importación",
          message: "Ocurrió un error inesperado durante la importación.",
          type: "error",
          show: true,
        })
      );
      console.error("Import failed:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      <h1>Importar</h1>
      <div>
        <h3>1. Seleccione lo que desea importar</h3>
        <div>
          <input
            type="radio"
            name="importOption"
            id="contactos"
            value="contactos"
            checked={selectedOption === "contactos"}
            onChange={handleOptionChange}
          />
          <label htmlFor="contactos">Contactos</label>
        </div>
        <div>
          <input
            type="radio"
            name="importOption"
            id="productos"
            value="productos"
            checked={selectedOption === "productos"}
            onChange={handleOptionChange}
          />
          <label htmlFor="productos">Productos</label>
        </div>
        <div>
          <input
            type="radio"
            name="importOption"
            id="negocios"
            value="negocios"
            checked={selectedOption === "negocios"}
            onChange={handleOptionChange}
          />
          <label htmlFor="negocios">Negocios</label>
        </div>
      </div>
      <div>
        <h3>2. Prepare su plantilla</h3>
        <p>
          Descargue la plantilla de importación y complete los datos que desea
          importar. <span>Descargar</span>
        </p>
      </div>
      <div>
        <h3>3. Importar</h3>
        <input type="file" onChange={handleFileChange} />
      </div>
      {file && headers.length > 0 && (
        <div>
          <h3>4. Mapear campos</h3>
          <p>
            Asocie los campos de la plantilla con los campos de la base de
            datos.
          </p>
          {headers.map((header) => (
            <div key={header}>
              <label>{header}</label>
              <select
                value={mapping[header] || ""}
                onChange={(e) => handleMappingChange(header, e.target.value)}
              >
                <option value="">Seleccionar campo...</option>
                <option value="ignore">Ignorar</option>
                {contactProperties.map((property) => (
                  <option key={property.key} value={property.key}>
                    {property.label}
                  </option>
                ))}
                <option value={`create:${header}`}>
                  Crear nueva propiedad
                </option>
              </select>
            </div>
          ))}
        </div>
      )}
      <div>
        <h3>5. Importar</h3>
        <button onClick={handleImport}>Importar</button>
      </div>
    </div>
  );
};

export default Importar;
