import React, { useState, useEffect, useRef } from "react";
import { FaTrash, FaArrowRight } from "react-icons/fa";
import { evaluate } from "mathjs";

import {
  ProductLinesContainer,
  Table,
  TableHeader,
  TableBody,
  AddProductRow,
  Modal,
  ModalList,
  Input,
  Select,
  Footer,
  Observaciones,
} from "./ProductLinesStyles";
import Totals from "./Totals";
import { getProducts, createProduct } from "../../../services/productService";
import { IProduct } from "../../../types/types";
import { useNavigate } from "react-router-dom";

interface IProductLine {
  product: IProduct | null;
  quantity: number;
  unitPrice: number;
  taxes: number;
  total: number;
  searchTerm: string;
}

interface ITotals {
  subtotal: number;
  taxes: { [key: number]: number };
  total: number;
}

const initialProductLine: IProductLine = {
  product: null,
  quantity: 1,
  unitPrice: 0,
  taxes: 0,
  total: 0,
  searchTerm: "",
};

const taxOptions = [
  { label: "19% IVA", value: 19 },
  { label: "5% IVA", value: 5 },
  { label: "0% IVA", value: 0 },
];

const ProductLines: React.FC<{
  productLines: IProductLine[];
  setProductLines: React.Dispatch<React.SetStateAction<IProductLine[]>>;
  setObservations: React.Dispatch<React.SetStateAction<string>>;
  notes: any;
}> = ({ productLines, setProductLines, setObservations, notes }) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([]);
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState<
    number | null
  >(null);
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [totals, setTotals] = useState<ITotals>({
    subtotal: 0,
    taxes: {},
    total: 0,
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const rowRefs = useRef<HTMLTableRowElement[]>([]);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    const fetchedProducts = await getProducts();
    setProducts(fetchedProducts);
    setFilteredProducts(fetchedProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    console.log("productLines state updated:", productLines);
    calculateTotals(productLines);
  }, [productLines]);

  const processLine = (index: number) => {
    setProductLines((prevProductLines) => {
      const updatedProductLines = [...prevProductLines];
      const line = { ...updatedProductLines[index] };

      console.log("Before processing:", { ...line });

      if (line.product) {
        // Parse and evaluate unitPrice
        if (isExpressionValid(line.unitPrice.toString())) {
          const numericUnitPrice = calcularResultado(line.unitPrice.toString());
          if (numericUnitPrice !== null) {
            line.unitPrice = numericUnitPrice;
          }
        } else {
          line.unitPrice = 0;
        }

        // Parse and evaluate quantity
        if (isExpressionValid(line.quantity.toString())) {
          const numericQuantity = calcularResultado(line.quantity.toString());
          if (numericQuantity !== null) {
            line.quantity = numericQuantity;
          }
        } else {
          line.quantity = 0;
        }

        // Parse and evaluate taxes
        if (isExpressionValid(line.taxes.toString())) {
          const numericTaxes = calcularResultado(line.taxes.toString());
          if (numericTaxes !== null) {
            line.taxes = numericTaxes;
          }
        } else {
          line.taxes = 0;
        }

        // Calculate total
        line.total = line.unitPrice * line.quantity;
      }

      updatedProductLines[index] = line; // Update the line in the array

      console.log("After processing:", { ...line });

      return updatedProductLines; // Return the new state
    });
  };

  const handleProductSelect = (product: IProduct) => {
    if (selectedProductIndex === null) return;

    setProductLines((prevProductLines) => {
      const updatedProductLines = [...prevProductLines];
      const line = { ...updatedProductLines[selectedProductIndex] };

      line.product = product;
      line.unitPrice = product.unitPrice;
      line.taxes = product.taxes || 0;
      line.total = product.unitPrice * line.quantity;
      line.searchTerm = product.name; // Set searchTerm to product name

      updatedProductLines[selectedProductIndex] = line;
      return updatedProductLines;
    });

    setShowProductModal(false);
    setEditMode(true);
  };

  const isExpressionValid = (value: string) => {
    // Validar que la expresión contenga solo números, operadores, y paréntesis
    const validExpression = /^[0-9+\-*/().\s,]+$/.test(value);
    return validExpression;
  };

  const calcularResultado = (expresion: string) => {
    try {
      // Reemplazar comas por puntos
      const expresionCorregida = expresion.replace(/,/g, ".");

      // Evaluar la expresión
      const resultado = evaluate(expresionCorregida);

      // Devolver el resultado redondeado a 2 decimales
      return parseFloat(resultado.toFixed(2));
    } catch (error) {
      console.error("Error al evaluar la expresión:", error);
      return null; // o lanzar un error, dependiendo de cómo quieras manejarlo
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    field: keyof IProductLine
  ) => {
    const value: string = e.target.value.trim();

    setProductLines((prevProductLines) => {
      const updatedProductLines = [...prevProductLines];
      updatedProductLines[index] = {
        ...updatedProductLines[index],
        [field]: value,
      };
      return updatedProductLines;
    });
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const updatedProductLines = [...productLines];
    if (updatedProductLines[index].product) {
      updatedProductLines[index].product!.description = e.target.value;
    }
    setProductLines(updatedProductLines);
  };

  const handleRowClick = (index: number) => {
    setEditingIndex(index);
    setSelectedProductIndex(index);
    setEditMode(true);

    setProductLines((prevProductLines) => {
      const updatedProductLines = [...prevProductLines];
      const line = { ...updatedProductLines[index] };
      // Initialize searchTerm if it's empty
      if (line.searchTerm === "") {
        line.searchTerm = line.product?.name || "";
        updatedProductLines[index] = line;
      }
      return updatedProductLines;
    });
  };

  const handleProductInputFocus = (
    e: React.FocusEvent<HTMLInputElement>,
    index: number
  ) => {
    const rect = e.target.getBoundingClientRect();
    setModalPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
    setShowProductModal(true);
    setSelectedProductIndex(index);
  };

  const handleBlur = (index: number) => {
    processLine(index);
  };
  const calculateTotals = (lines: IProductLine[]) => {
    console.log("Calculating totals with lines:", lines);

    let subtotal = 0;
    const taxes: { [key: number]: number } = {};

    lines.forEach((line) => {
      if (line.product) {
        const unitPrice = Number(line.unitPrice) || 0;
        const quantity = Number(line.quantity) || 0;
        const taxRate = Number(line.taxes) || 0;

        const lineSubtotal = unitPrice * quantity;
        subtotal += lineSubtotal;

        if (!taxes[taxRate]) {
          taxes[taxRate] = 0;
        }
        taxes[taxRate] += (lineSubtotal * taxRate) / 100;
      }
    });

    const total =
      subtotal + Object.values(taxes).reduce((acc, curr) => acc + curr, 0);

    console.log("Totals calculated:", { subtotal, taxes, total });

    setTotals({ subtotal, taxes, total });
  };

  const handleAddProductLine = () => {
    if (productLines.some((line) => !line.product)) {
      alert("Debe seleccionar un producto antes de agregar uno nuevo.");
      return;
    }
    setProductLines([...productLines, initialProductLine]);
  };

  const handleDeleteProductLine = (index: number) => {
    const updatedProductLines = productLines.filter((_, i) => i !== index);
    setProductLines(updatedProductLines);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      editingIndex !== null &&
      rowRefs.current[editingIndex] &&
      !rowRefs.current[editingIndex].contains(event.target as Node)
    ) {
      processLine(editingIndex);

      // Clear searchTerm if no product is selected
      setProductLines((prevProductLines) => {
        const updatedProductLines = [...prevProductLines];
        const line = { ...updatedProductLines[editingIndex!] };
        if (!line.product) {
          line.searchTerm = "";
          updatedProductLines[editingIndex!] = line;
        }
        return updatedProductLines;
      });

      setEditingIndex(null);
      setEditMode(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingIndex]);

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;

    setProductLines((prevProductLines) => {
      const updatedProductLines = [...prevProductLines];
      updatedProductLines[index] = {
        ...updatedProductLines[index],
        searchTerm: value,
      };
      return updatedProductLines;
    });

    // Update the filtered products based on the new search term
    const filtered = products.filter((product) =>
      product.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered);
  };

  const handleCreateProduct = async () => {
    const newProduct = {
      name: searchTerm,
      description: "",
      unitPrice: 0,
      taxes: 0,
      imageUrl: "",
      cost: 0,
      sku: "",
      categoryId: "",
      notes: "",
      organizationId: localStorage.getItem("organizationId") || "",
      createdAt: new Date(),
      updatedAt: new Date(),
      dimensions: {
        width: 0,
        height: 0,
        length: 0,
      },
    };
    try {
      const createdProduct = await createProduct(newProduct);
      setProducts([...products, createdProduct]);
      setFilteredProducts([...products, createdProduct]);
      handleProductSelect(createdProduct);
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  const handleOpenProductDetails = (productId: string) => {
    navigate(`/productos/${productId}`);
  };

  return (
    <ProductLinesContainer>
      <Table>
        <TableHeader>
          <tr>
            <th>Producto</th>
            <th>Descripción</th>
            <th>Cantidad</th>
            <th>Precio unitario</th>
            <th>Impuestos</th>
            <th>Total Sin iva</th>
            <th></th>
          </tr>
        </TableHeader>
        <TableBody>
          {productLines.map((line, index) => (
            <tr
              key={index}
              ref={(el) => (rowRefs.current[index] = el!)}
              onClick={() => handleRowClick(index)}
            >
              {editMode && editingIndex === index ? (
                <>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        type="text"
                        value={line.searchTerm}
                        onFocus={(e) => handleProductInputFocus(e, index)}
                        onChange={(e) => handleSearchChange(e, index)}
                      />
                      {line.product && (
                        <FaArrowRight
                          style={{ cursor: "pointer", marginLeft: "8px" }}
                          onClick={() =>
                            handleOpenProductDetails(line.product!._id || "")
                          }
                        />
                      )}
                    </div>
                  </td>

                  <td>
                    <Input
                      type="text"
                      value={line.product?.description || ""}
                      onChange={(e) => handleDescriptionChange(e, index)}
                    />
                  </td>
                  <td>
                    <Input
                      type="number"
                      value={line.quantity}
                      onChange={(e) => handleInputChange(e, index, "quantity")}
                      onBlur={() => handleBlur(index)}
                    />
                  </td>
                  <td>
                    <Input
                      type="text"
                      onChange={(e) => handleInputChange(e, index, "unitPrice")}
                      onBlur={() => handleBlur(index)}
                      value={line.unitPrice}
                    />
                  </td>
                  <td>
                    <Select
                      value={line.taxes}
                      onChange={(e) => handleInputChange(e, index, "taxes")}
                    >
                      {taxOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </td>
                  <td>{line.total.toFixed(2)}</td>
                  <td>
                    <FaTrash onClick={() => handleDeleteProductLine(index)} />
                  </td>
                </>
              ) : (
                <>
                  <td>{line.product?.name}</td>
                  <td>{line.product?.description}</td>
                  <td>{line.quantity}</td>
                  <td>{line.unitPrice.toFixed(2)}</td>
                  <td>{`${line.taxes}%`}</td>
                  <td>{line.total.toFixed(2)}</td>
                  <td>
                    <FaTrash onClick={() => handleDeleteProductLine(index)} />
                  </td>
                </>
              )}
            </tr>
          ))}
          <AddProductRow onClick={handleAddProductLine}>
            <td colSpan={7}>
              <p>Agregar un producto</p>
            </td>
          </AddProductRow>
        </TableBody>
      </Table>

      {showProductModal && (
        <Modal position={modalPosition}>
          <ModalList>
            {filteredProducts.map((product) => (
              <li
                key={product._id}
                onClick={() => handleProductSelect(product)}
              >
                {product.name}
              </li>
            ))}
            {searchTerm && (
              <li onClick={handleCreateProduct}>Crear "{searchTerm}"</li>
            )}
          </ModalList>
        </Modal>
      )}
      <Footer>
        <Observaciones
          name="observations"
          id="observations"
          placeholder="Observaciones..."
          onChange={(e) => setObservations(e.target.value)}
          defaultValue={notes}
        />
        <div>
          <Totals totals={totals} />
        </div>
      </Footer>
    </ProductLinesContainer>
  );
};

export default ProductLines;
