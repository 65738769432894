import React, { useContext } from "react";
import {
  Button,
  CheckboxGroup,
  DetailArea,
  Layout,
} from "../common/CommonStyles";
import { CampanaContext } from "../context/CampanaContext";

const Plataforma = () => {
  const { setActiveStep, setNewCampana, newCampana } =
    useContext(CampanaContext);

  const handleCheckboxChange = (field: string, value: string) => {
    setNewCampana((prev: any) => {
      const updatedPlatforms = prev.platforms?.platformName || [];

      if (updatedPlatforms.includes(value)) {
        // Si la plataforma ya está seleccionada, la quitamos
        return {
          ...prev,
          platforms: {
            ...prev.platforms,
            platformName: updatedPlatforms.filter(
              (item: string) => item !== value
            ),
          },
        };
      } else {
        // Si no está seleccionada, la añadimos
        return {
          ...prev,
          platforms: {
            ...prev.platforms,
            platformName: [...updatedPlatforms, value],
          },
        };
      }
    });
  };

  const handleMetricCheckboxChange = (field: string, value: string) => {
    setNewCampana((prev: any) => {
      const updatedMetrics = prev.platforms?.metricsName || [];

      if (updatedMetrics.includes(value)) {
        // Si la métrica ya está seleccionada, la quitamos
        return {
          ...prev,
          platforms: {
            ...prev.platforms,
            metricsName: updatedMetrics.filter(
              (item: string) => item !== value
            ),
          },
        };
      } else {
        // Si no está seleccionada, la añadimos
        return {
          ...prev,
          platforms: {
            ...prev.platforms,
            metricsName: [...updatedMetrics, value],
          },
        };
      }
    });
  };

  return (
    <div>
      <Layout>
        <div
          style={{
            width: "40%",
          }}
        >
          <h3>Selecciona la(s) plataformas para tu campaña:</h3>
          <CheckboxGroup>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "WhatsApp Masivo"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "WhatsApp Masivo")
                }
              />
              <label>WhatsApp Masivo</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "Correo Masivo"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "Correo Masivo")
                }
              />
              <label>Correo Masivo</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "Facebook Ads"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "Facebook Ads")
                }
              />
              <label>Facebook Ads</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes("Google Ads") ||
                  false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "Google Ads")
                }
              />
              <label>Google Ads</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "Linkedin Ads"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "Linkedin Ads")
                }
              />
              <label>Linkedin Ads</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes("SEO") || false
                }
                onChange={() => handleCheckboxChange("platformName", "SEO")}
              />
              <label>SEO</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "Orgánico: Redes Sociales"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange(
                    "platformName",
                    "Orgánico: Redes Sociales"
                  )
                }
              />
              <label>Orgánico: Redes Sociales</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "Email Marketing"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "Email Marketing")
                }
              />
              <label>Email Marketing</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes(
                    "Llamadas Directas"
                  ) || false
                }
                onChange={() =>
                  handleCheckboxChange("platformName", "Llamadas Directas")
                }
              />
              <label>Llamadas Directas</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.platformName?.includes("Otro") || false
                }
                onChange={() => handleCheckboxChange("platformName", "Otro")}
              />
              <label>Otro</label>
            </div>
          </CheckboxGroup>

          <h3>Selecciona las métricas que deseas medir:</h3>
          <CheckboxGroup>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes("Clics") || false
                }
                onChange={() =>
                  handleMetricCheckboxChange("metricsName", "Clics")
                }
              />
              <label>Clics</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes("Impresiones") ||
                  false
                }
                onChange={() =>
                  handleMetricCheckboxChange("metricsName", "Impresiones")
                }
              />
              <label>Impresiones</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes("Leads") || false
                }
                onChange={() =>
                  handleMetricCheckboxChange("metricsName", "Leads")
                }
              />
              <label>Leads</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes(
                    "Tiempo en el sitio"
                  ) || false
                }
                onChange={() =>
                  handleMetricCheckboxChange(
                    "metricsName",
                    "Tiempo en el sitio"
                  )
                }
              />
              <label>Tiempo en el sitio</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes("Frecuencia") ||
                  false
                }
                onChange={() =>
                  handleMetricCheckboxChange("metricsName", "Frecuencia")
                }
              />
              <label>Frecuencia</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes(
                    "Interacciones"
                  ) || false
                }
                onChange={() =>
                  handleMetricCheckboxChange("metricsName", "Interacciones")
                }
              />
              <label>Interacciones</label>
            </div>
            <div>
              <input
                type="checkbox"
                checked={
                  newCampana?.platforms?.metricsName?.includes(
                    "Visitas a una URL"
                  ) || false
                }
                onChange={() =>
                  handleMetricCheckboxChange("metricsName", "Visitas a una URL")
                }
              />
              <label>Visitas a una URL</label>
            </div>
          </CheckboxGroup>

          <Button onClick={() => setActiveStep("Metricas")}>Continuar</Button>
        </div>
        <DetailArea>
          <h2>Descripción del input</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
            voluptatem, quod, quae, quas quos quia nesciunt voluptates
            repudiandae, quibusdam tempore doloremque. Quisquam voluptatem,
            quod, quae, quas quos quia nesciunt voluptates repudiandae,
            quibusdam tempore doloremque.
          </p>
        </DetailArea>
      </Layout>
    </div>
  );
};

export default Plataforma;
