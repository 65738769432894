import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 10000;
`;

export const Content = styled.div`
  background-color: #f1f1f1;
  width: 350px;
  height: 100px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  overflow: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;

    :hover {
      background-color: #ccc;
    }
  }
`;
