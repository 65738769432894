import React, { useContext, useState, useCallback } from "react";
import {
  CreateProyectInput,
  FirstColumn,
  FirstColumnHeader,
  FirstColumnProyectList,
  FirstColumnSearch,
} from "./TareasPageStyles";
import {
  FaCheck,
  FaEllipsisV,
  FaFolder,
  FaFolderOpen,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { ProjectContext } from "./context/ProjectContext";
import { IProject } from "../../../types";
import OptionsMenu from "./menu/OptionsMenu";

const ProjectColumn = () => {
  const {
    projects,
    createProject,
    setSelectedProject,
    selectedProject,
    setMenuIsVisible,
    menuIsVisible,
    queryProjects,
    setQueryProjects,
  } = useContext(ProjectContext);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const [project, setProject] = useState({ name: "" });

  const handleCreateProject = useCallback(async () => {
    await createProject(project);
    setProject({ name: "" });
    setShowCreateProject(false);
  }, [createProject, project]);

  const toggleShowCreateProject = useCallback(() => {
    setShowCreateProject((prev) => !prev);
  }, []);

  return (
    <FirstColumn>
      <FirstColumnHeader>
        <FirstColumnSearch>
          <FaSearch />
          <input
            type="text"
            placeholder="Buscar Proyectos..."
            value={queryProjects}
            onChange={(e) => setQueryProjects(e.target.value)}
          />
        </FirstColumnSearch>
      </FirstColumnHeader>
      <FirstColumnProyectList>
        {projects.length > 0 ? (
          <>
            <ul>
              <li
                onClick={() => setSelectedProject(null)}
                style={
                  !selectedProject
                    ? {
                        background: "#D1345B",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                      }
                    : {}
                }
              >
                <div>
                  {!selectedProject ? (
                    <FaFolderOpen style={{ marginRight: "10px" }} />
                  ) : (
                    <FaFolder style={{ marginRight: "10px" }} />
                  )}

                  <label
                    style={{
                      cursor: "pointer",
                      fontSize: "14px",
                      color: !selectedProject ? "white" : "",
                    }}
                  >
                    Todas mis tareas
                  </label>
                </div>
              </li>
              {projects.map((project: IProject) => (
                <li
                  key={project._id}
                  onClick={() => setSelectedProject(project)}
                  style={
                    selectedProject?._id === project._id
                      ? {
                          background: "#D1345B",
                          color: "white",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        }
                      : {}
                  }
                >
                  <div>
                    {selectedProject?._id === project._id ? (
                      <FaFolderOpen style={{ marginRight: "10px" }} />
                    ) : (
                      <FaFolder style={{ marginRight: "10px" }} />
                    )}
                    <label
                      style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        color:
                          selectedProject?._id === project._id ? "white" : "",
                      }}
                    >
                      {project.name}
                    </label>
                  </div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    {selectedProject?._id === project._id && (
                      <>
                        <FaEllipsisV
                          onClick={() => setMenuIsVisible((prev) => !prev)}
                        />
                        {menuIsVisible && <OptionsMenu />}
                      </>
                    )}
                  </div>
                </li>
              ))}
            </ul>
            <CreateProjectButton
              showCreateProject={showCreateProject}
              toggleShowCreateProject={toggleShowCreateProject}
              project={project}
              setProject={setProject}
              handleCreateProject={handleCreateProject}
            />
          </>
        ) : (
          <div style={{ padding: "10px" }}>
            <p
              style={{
                textAlign: "center",
                color: "grey",
                marginBottom: "20px",
              }}
            >
              No tienes proyectos creados
            </p>
            <CreateProjectButton
              showCreateProject={showCreateProject}
              toggleShowCreateProject={toggleShowCreateProject}
              project={project}
              setProject={setProject}
              handleCreateProject={handleCreateProject}
            />
          </div>
        )}
      </FirstColumnProyectList>
    </FirstColumn>
  );
};

const CreateProjectButton = React.memo(
  ({
    showCreateProject,
    toggleShowCreateProject,
    project,
    setProject,
    handleCreateProject,
  }: any) => (
    <>
      <p
        onClick={toggleShowCreateProject}
        style={{
          cursor: "pointer",
          color: "#D1345B",
          fontSize: "14px",
          marginTop: "10px",
        }}
      >
        + Crear Proyecto
      </p>
      {showCreateProject && (
        <CreateProyectInput>
          <input
            type="text"
            placeholder="Nombre del Proyecto"
            onChange={(e) => setProject({ name: e.target.value })}
            value={project.name}
          />
          <FaTimes onClick={toggleShowCreateProject} />
          <FaCheck onClick={handleCreateProject} />
        </CreateProyectInput>
      )}
    </>
  )
);

export default ProjectColumn;
