import { FilterContainer, FilterGroup } from "../FilterContactsStyles";
import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";

interface FilterProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  step: { results: number; properties: number; filter: number };
  label: string;
}

const Filter: React.FC<FilterProps> = ({ setActiveStep, step, label }) => {
  const [isActive, setIsActive] = useState(0);

  return (
    <FilterContainer>
      <div
        onClick={() => {
          setActiveStep(step.properties);
        }}
      >
        <FaAngleLeft />
        <small>Volver</small>
      </div>
      <h3>{label}</h3>
      <FilterGroup>
        <div>
          <input
            defaultChecked
            type="radio"
            name="filter"
            id="filter1"
            onClick={() => {
              setIsActive(0);
            }}
          />
          <label htmlFor="filter1">Es Cualquiera de</label>
        </div>
        {isActive === 0 ? (
          <input type="text" name="filter" id="filter" />
        ) : null}
      </FilterGroup>
      <FilterGroup>
        <div>
          <input
            type="radio"
            name="filter"
            id="filter2"
            onClick={() => {
              setIsActive(1);
            }}
          />
          <label htmlFor="filter2">No es Cualquiera</label>
        </div>
        {isActive === 1 ? (
          <input type="text" name="filter" id="filter" />
        ) : null}
      </FilterGroup>
      <FilterGroup>
        <input type="radio" name="filter" id="filter3" />
        <label htmlFor="filter3">Es un valor conocido</label>
      </FilterGroup>
      <FilterGroup>
        <input type="radio" name="filter" id="filter4" />
        <label htmlFor="filter4">Es un valor desconocido</label>
      </FilterGroup>

      <button>Aplicar Filtro</button>
    </FilterContainer>
  );
};

export default Filter;
