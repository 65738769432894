import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: #333;
  width: 350px;
  height: 100vh;
  color: white;
  padding: 20px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    font-size: 24px;
    cursor: pointer;
    color: white;

    &:hover {
      color: #ccc;
    }
  }
`;

export const Title = styled.h3`
  margin-top: 0;
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  color: #00aced;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: #0084b4;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0 0;
  overflow-y: auto;
  flex-grow: 1;

  li {
    padding: 15px;
    background: #444;
    border-radius: 8px;
    margin-bottom: 10px;
    font-weight: 300;
    transition: background 0.3s ease;

    &:hover {
      background: #555;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    p {
      margin: 0;
      color: #ddd;
    }

    button {
      margin-top: 5px;
      background: none;
      border: none;
      color: #00aced;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: #0084b4;
      }
    }
  }

  p {
    text-align: center;
    color: #aaa;
  }
`;
