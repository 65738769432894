import React, { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ProjectContext } from "../context/ProjectContext";
import {
  KanbanColumn,
  KanbanContainer,
  KanbanTitle,
  TaskCard,
  TaskList,
} from "./KanbanStyles";
import { FaCalendar, FaEllipsisV, FaUser } from "react-icons/fa";
import MenuOptions from "./MenuOptions";

const TaskKanban = () => {
  const { project, setProject, selectedProject, updateTask } =
    useContext(ProjectContext);

  // Almacena el ID de la tarea seleccionada para mostrar el menú
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);

  const onDragEnd = async (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = project.tasks.filter(
      (task: any) => task.status === source.droppableId
    );
    const finish = project.tasks.filter(
      (task: any) => task.status === destination.droppableId
    );

    if (start === finish) {
      const newTaskIds = Array.from(start);
      const [movedTask] = newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, movedTask);

      const newTasks = project.tasks.map((task: any) =>
        task._id === draggableId
          ? { ...task, status: destination.droppableId }
          : task
      );

      setProject((prevProject: any) => ({
        ...prevProject,
        tasks: newTasks,
      }));
      return;
    }

    const startTaskIds = Array.from(start);
    const [movedTask] = startTaskIds.splice(source.index, 1);

    const finishTaskIds = Array.from(finish);
    finishTaskIds.splice(destination.index, 0, movedTask);

    const newTasks = project.tasks.map((task: any) =>
      task._id === draggableId
        ? { ...task, status: destination.droppableId }
        : task
    );

    setProject((prevProject: any) => ({
      ...prevProject,
      tasks: newTasks,
    }));

    const updatedTask = newTasks.find((task: any) => task._id === draggableId);
    if (updatedTask) {
      try {
        await updateTask(updatedTask);
      } catch (error) {
        console.error("Error al actualizar la tarea:", error);
      }
    }
  };

  const columns = ["No Iniciado", "En Progreso", "Atrasado", "Completado"];

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <KanbanContainer>
        {columns.map((column) => (
          <KanbanColumn key={column}>
            <KanbanTitle
              style={
                column === "No Iniciado"
                  ? { backgroundColor: "#d9d9d9" }
                  : column === "En Progreso"
                  ? { backgroundColor: "#F0C808" }
                  : column === "Completado"
                  ? { backgroundColor: "#03BA20" }
                  : column === "Atrasado"
                  ? { backgroundColor: "#AD0303" }
                  : {}
              }
            >
              <h2>{column}</h2>
            </KanbanTitle>
            <Droppable droppableId={column}>
              {(provided) => (
                <TaskList ref={provided.innerRef} {...provided.droppableProps}>
                  {project.tasks &&
                    project.tasks
                      .filter((task: any) => task.status === column)
                      .map((task: any, index: any) => (
                        <Draggable
                          key={task._id}
                          draggableId={task._id}
                          index={index}
                        >
                          {(provided) => (
                            <TaskCard
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <h4>{task.title}</h4>
                                <div>
                                  <FaUser />
                                  <p>
                                    {task.responsibleId.firstName}{" "}
                                    {task.responsibleId.lastName}
                                  </p>
                                </div>
                                <div>
                                  <FaCalendar />
                                  <p>
                                    {task.dueDate
                                      ? new Date(
                                          task.dueDate
                                        ).toLocaleDateString()
                                      : "Sin fecha"}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <FaEllipsisV
                                  onClick={() =>
                                    setSelectedTaskId(
                                      selectedTaskId === task._id
                                        ? null
                                        : task._id
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                />

                                {selectedTaskId === task._id && (
                                  <MenuOptions taskId={task._id} />
                                )}
                              </div>
                            </TaskCard>
                          )}
                        </Draggable>
                      ))}
                  {provided.placeholder}
                </TaskList>
              )}
            </Droppable>
          </KanbanColumn>
        ))}
      </KanbanContainer>
    </DragDropContext>
  );
};

export default TaskKanban;
