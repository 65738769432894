import React, { useEffect, useRef, useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import {
  ButtonContainer,
  Container,
  FileItem,
  FileList,
  Header,
  SearchContainer,
} from "./FileSelectorStyles";
import { fetchFiles, uploadFile } from "../../../services/fileService";
import { IFile } from "../../../types/types";
import { set } from "date-fns";

interface FileSelectorProps {
  setShowFileSelector: (show: boolean) => void;
  onFileSelect: (file: any) => void;
  handleFileUpload: (file: any, isVisible: boolean, isPrivate: boolean) => void;
  setFilesAffected?: any;
  filesAffected?: any;
}

const FileSelector: React.FC<FileSelectorProps> = ({
  setShowFileSelector,
  onFileSelect,
  handleFileUpload,
  setFilesAffected,
  filesAffected,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<IFile[]>();

  const closeFileSelector = () => {
    setShowFileSelector(false);
  };

  const getFiles = async () => {
    try {
      const files = await fetchFiles();
      setFiles(files);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    getFiles();
    setFilesAffected(false);
  }, [filesAffected]);

  return (
    <Container>
      <Header>
        <h1>Seleccionar Archivo</h1>
        <FaTimes onClick={closeFileSelector} />
      </Header>
      <SearchContainer>
        <FaSearch />
        <input type="text" placeholder="Buscar archivos y carpetas" />
      </SearchContainer>
      <FileList>
        {files ? (
          files.map(
            (file: IFile) =>
              file.isVisible && (
                <FileItem
                  key={file._id}
                  onClick={() => {
                    closeFileSelector();
                    onFileSelect(file);
                  }}
                >
                  <img src={file.mediaURL} alt="" width={100} />
                  <h3>{file.name}</h3>
                </FileItem>
              )
          )
        ) : (
          <p>No hay archivos para mostrar</p>
        )}
      </FileList>

      <ButtonContainer>
        <button
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click();
            }
          }}
        >
          Subir
        </button>
        <input
          type="file"
          name="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              handleFileUpload(e.target.files[0], true, true);
            }
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

export default FileSelector;
