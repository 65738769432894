import React, { useContext } from "react";
import { Button, DetailArea, FormGroup, Layout } from "../common/CommonStyles";
import { CampanaContext } from "../context/CampanaContext";

const Resultados = () => {
  const { setActiveStep, setActiveView, handleChange, newCampana } =
    useContext(CampanaContext);

  return (
    <Layout>
      <div
        style={{
          width: "40%",
        }}
      >
        <FormGroup>
          <label>Total Ventas Generadas</label>
          <input
            type="text"
            name="totalRevenueGenerated"
            value={newCampana?.totalRevenueGenerated || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Total presupuesto gastado</label>
          <input
            type="text"
            name="totalExpenses"
            value={newCampana?.totalExpenses || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <label>Clientes Nuevos Obtenidos</label>
          <input
            type="text"
            name="newCustomersAcquired"
            value={newCampana?.newCustomersAcquired || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <Button
          onClick={() => {
            setActiveStep("Informacion");
            setActiveView("Reportes");
          }}
        >
          Guardar
        </Button>
      </div>
      <DetailArea>
        <h2>Descripción del input</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
          voluptatem, quod, quae, quas quos quia nesciunt voluptates
          repudiandae, quibusdam tempore doloremque. Quisquam voluptatem, quod,
          quae, quas quos quia nesciunt voluptates repudiandae, quibusdam
          tempore doloremque.
        </p>
      </DetailArea>
    </Layout>
  );
};

export default Resultados;
