import { useSelector } from "react-redux";
import { Item } from "./NewTaskStyles";
import { useContext, useState } from "react";
import { ProjectContext } from "./context/ProjectContext";
import { ITask } from "../../../types/types";
import { FaCheck, FaTimes } from "react-icons/fa";

const NewTask = () => {
  const employees = useSelector((state: any) => state.organization.employees);
  const { createTask, selectedProject, setIsVisibleCreateTask } =
    useContext(ProjectContext);
  const [newTask, setNewTask] = useState<ITask>({
    title: "",
    status: "Completed",
    projectId: "",
    responsibleId: "",
  });

  const handleSubmit = async (e: any) => {
    await createTask(newTask);
  };

  return (
    <tr>
      <Item></Item>
      <Item>
        <input
          type="text"
          name=""
          id=""
          onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
        />
      </Item>
      <Item>
        <select
          name="responsibleId"
          id="responsibleId"
          onChange={(e) =>
            setNewTask({ ...newTask, responsibleId: e.target.value })
          }
        >
          <option value="">-- Seleccione --</option>
          {employees?.map((employee: any) => {
            return (
              <option value={employee._id} key={employee._id}>
                {employee.firstName} {employee.lastName}
              </option>
            );
          })}
        </select>
      </Item>
      <Item>
        <select
          name=""
          id=""
          onChange={(e) => setNewTask({ ...newTask, status: e.target.value })}
        >
          <option value="">-- Seleccione --</option>
          <option value="No Iniciado">No Iniciado</option>
          <option value="En Progreso">En Progreso</option>
          <option value="Atrasado">Atrasado</option>
          <option value="Completado">Completado</option>
        </select>
      </Item>

      <Item>
        <input
          type="date"
          name=""
          id=""
          onChange={(e) =>
            setNewTask({ ...newTask, dueDate: new Date(e.target.value) })
          }
        />
      </Item>

      <td>
        <FaCheck onClick={handleSubmit} />
        <FaTimes
          onClick={() => {
            setIsVisibleCreateTask(false);
          }}
        />
      </td>
    </tr>
  );
};

export default NewTask;
