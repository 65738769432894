import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState } from "../../types/types";

// Define the initial state using that type
const initialState: UserState = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  token: localStorage.getItem("token") || sessionStorage.getItem("token"),
  loading: false,
  error: null,
  isAuthenticated: !!(
    localStorage.getItem("token") || sessionStorage.getItem("token")
  ),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const { user, token } = action.payload;
      state.userId = user._id;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.email = user.email;
      state.mobile = user.mobile;
      state.token = token;
      state.isAuthenticated = true;
      state.error = null;
      if (token) {
        localStorage.setItem("token", token);
      }
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    },
    registerStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    registerSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const { user, token } = action.payload;
      state.userId = user._id;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.email = user.email;
      state.mobile = user.mobile;
      state.token = token;
      state.isAuthenticated = true;
      state.error = null;
      if (token) {
        localStorage.setItem("token", token);
      }
    },
    registerFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    },
    verifyTokenStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    verifyTokenSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      const { user, token } = action.payload;
      state.userId = user._id;
      state.firstName = user.firstName;
      state.lastName = user.lastName;
      state.email = user.email;
      state.mobile = user.mobile;
      state.token = token;
      state.isAuthenticated = true;
      state.error = null;
    },
    verifyTokenFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    },
    logout: (state) => {
      state.userId = "";
      state.firstName = "";
      state.lastName = "";
      state.email = "";
      state.mobile = "";
      state.token = null;
      state.isAuthenticated = false;
      state.error = null;
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    },
  },
});

// Exporta las acciones y el reducer
export const {
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  verifyTokenStart,
  verifyTokenSuccess,
  verifyTokenFailure,
  logout,
} = userSlice.actions;
export default userSlice.reducer;
