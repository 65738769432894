import { FaPlus, FaTrash } from "react-icons/fa";
import { ResultContainer } from "../FilterContactsStyles";

const Results = ({ setActiveStep, step }: any) => {
  return (
    <div>
      <h3>Filtros avanzados (0)</h3>
      <div>
        <button
          onClick={() => {
            setActiveStep(step.properties);
          }}
        >
          <FaPlus />
          Agregar Filtro
        </button>
      </div>
      <ResultContainer>
        <p>
          <span>Direccion</span> es cualquiera de Cll 12 no 11 33
        </p>
        <FaTrash />
      </ResultContainer>
    </div>
  );
};

export default Results;
