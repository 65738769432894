// src/theme.ts
import { DefaultTheme } from "styled-components";

interface Theme {
  colors: {
    rosado: string;
    rosadoOscuro: string;
    azulOscuro: string;
    blanco: string;
    rojo: string;
    amarillo: string;
    grisOscuro: string;
    gris1: string;
    gris2: string;
    gris3: string;
    grisClaro: string;
    rosadoClaro: string;
    green: string;
  };
  fontSize: {
    h1: string;
    h2: string;
    h3: string;
    h4: string;
    h5: string;
    h6: string;
    p: string;
  };
}

export const theme: Theme = {
  colors: {
    rosado: "#D1345B",
    rosadoClaro: "#d1345b42",
    rosadoOscuro: "#791e35",
    azulOscuro: "#1F2A48",
    blanco: "#ffffff",
    rojo: "#EB1717",
    amarillo: "#F0C808",
    grisOscuro: "#636363",
    gris1: "#848484",
    gris2: "#C1C1C1",
    gris3: "#D9D9D9",
    grisClaro: "#F1F1F1",
    green: "#22AA00",
  },
  fontSize: {
    h1: "48px",
    h2: "36px",
    h3: "24px",
    h4: "18px",
    h5: "16px",
    h6: "14px",
    p: "14px",
  },
};
