import styled from "styled-components";

// Estilos
export const KanbanContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const KanbanColumn = styled.div`
  background-color: #f4f5f7;
  border-radius: 8px;
  width: 30%;
  padding: 10px;
  max-height: calc(100vh - 300px);
  overflow: auto;
`;

export const TaskList = styled.div`
  min-height: 500px;

  padding: 10px;
  border-radius: 8px;
`;

export const TaskCard = styled.div`
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  h4 {
    color: #505050;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  div {
    display: flex;
    margin-bottom: 5px;

    p {
      color: #8d8d8d;
    }

    svg {
      margin-right: 10px;
    }
    path {
      color: #8f8f8f;
    }
  }
`;

export const KanbanTitle = styled.div`
  padding: 10px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;

  h2 {
    font-weight: 400;
    text-align: center;
    font-size: 15px;
  }
`;
