import styled from "styled-components";

export const MenuOptionsContainer = styled.div`
  position: absolute;
  top: 20px;
  background-color: white;
  width: 200px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
`;
