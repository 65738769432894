import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 80px;
`;

export const Button = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.gris3};
  background-color: ${theme.colors.rosado};
  color: white;
  cursor: pointer;
  margin-top: 20px;
`;

export const TableContainer = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 1rem;
  background-color: transparent;
  border-spacing: 0;
  border-color: ${theme.colors.gris3};
  min-height: 100%;
  width: 100%;
  table-layout: fixed;

  thead {
    background-color: ${theme.colors.gris3};
    color: black;
    th {
      text-align: center;
      text-transform: capitalize;
    }
  }

  th {
    text-align: left;
    padding: 8px;
    border: 1px solid ${theme.colors.gris3};
  }

  td {
    text-align: left;
    padding: 10px;
    border: 1px solid ${theme.colors.gris3};
  }
`;

export const FormGroup = styled.div`
  width: 400px;

  input {
    background-color: #f1f1f1;
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 8px 10px;
  }
`;

export const NewListContainer = styled.div`
  background-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  height: 50vh;
  margin-top: 40px;
`;

export const FirstNewListColumn = styled.div`
  border: 1px solid grey;
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.gris3};
    background-color: ${theme.colors.rosado};
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
`;

export const SecondNewListColumn = styled.div`
  border: 1px solid grey;
  width: 80%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
