import React, { useState } from "react";
import {
  ListBody,
  ListContainer,
  ListItem,
  ListHeader,
  SelectedListItem,
  ListDate,
} from "./ListStyles";
import { FaPaperclip } from "react-icons/fa";
import { formatDate } from "../../../utils/formatDate";
import { IEmail } from "../../../types/types";

interface ListProps {
  emails: any;
  handleSelect: (emailId: IEmail) => void;
  selectedEmail: IEmail;
}

const List = ({ emails, handleSelect, selectedEmail }: ListProps) => {
  return (
    <ListContainer>
      {emails.length > 0 ? (
        emails.map((email: any) => {
          const isSelected = selectedEmail === email.uid;
          const ListItemComponent = isSelected ? SelectedListItem : ListItem;

          return (
            <ListItemComponent
              key={email._id}
              onClick={() => handleSelect(email.uid)}
            >
              <div>
                <input type="checkbox" checked={isSelected} />
              </div>

              <ListHeader>
                <ListBody>
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginBottom: "7px",
                        fontSize: "12px",
                      }}
                    >
                      {email.from}
                    </p>
                    <ListDate>{formatDate(new Date(email.date))}</ListDate>
                  </div>
                  <p>{email.subject}</p>
                </ListBody>
              </ListHeader>
            </ListItemComponent>
          );
        })
      ) : (
        <p>No hay correos</p>
      )}
    </ListContainer>
  );
};

export default List;
