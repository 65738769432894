import React, { useContext } from "react";
import { FaTrash } from "react-icons/fa";
import { OptionsMenuContainer } from "./OptionsMenuStyles";
import { ProjectContext } from "../context/ProjectContext";

const OptionsMenu = () => {
  const { deleteProject } = useContext(ProjectContext);

  return (
    <OptionsMenuContainer>
      <ul>
        <li
          onClick={() => {
            deleteProject();
          }}
        >
          <FaTrash /> Eliminar
        </li>
      </ul>
    </OptionsMenuContainer>
  );
};

export default OptionsMenu;
