import axiosInstance from "./axiosConfig";
import { IContact, IList, IListFilters } from "../types/types";

// Crear una lista estática
export const createStaticList = async (
  list: { name: string; description?: string; filters: IListFilters[] },
  query: any
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(
      "/lists/static",
      { ...list, query },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error creando lista estática"
    );
    throw new Error(
      error.response?.data?.message || "Error creando lista estática"
    );
  }
};

// Crear una lista dinámica
export const createDynamicList = async (
  list: {
    name: string;
    description?: string;
    filters: IListFilters[];
  },
  query: any
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.post(
      "/lists/dynamic",
      { ...list, query },
      { headers: { Authorization: `${token}` } }
    );
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error creando lista dinámica"
    );
    throw new Error(
      error.response?.data?.message || "Error creando lista dinámica"
    );
  }
};

// Obtener contactos de una lista dinámica
export const getDynamicListContacts = async (
  listId: string,
  page: number = 1,
  limit: number = 10
) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get(`/lists/dynamic/${listId}`, {
      headers: { Authorization: `${token}` },
      params: { organizationId, page, limit },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message ||
        "Error obteniendo contactos de la lista dinámica"
    );
    throw new Error(
      error.response?.data?.message ||
        "Error obteniendo contactos de la lista dinámica"
    );
  }
};

// Obtener todas las listas de una organización
export const getAllLists = async () => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<IList[]>("/lists", {
      headers: { Authorization: `${token}` },
      params: { organizationId },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error obteniendo las listas"
    );
    throw new Error(
      error.response?.data?.message || "Error obteniendo las listas"
    );
  }
};

// Actualizar una lista
export const updateList = async (
  listId: string,
  updatedList: {
    name?: string;
    description?: string;
    filters?: IListFilters;
    isDynamic?: boolean;
  }
) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.put(`/lists/${listId}`, updatedList, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(
      error.response?.data?.message || "Error actualizando la lista"
    );
    throw new Error(
      error.response?.data?.message || "Error actualizando la lista"
    );
  }
};

// Eliminar una lista
export const deleteList = async (listId: string) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axiosInstance.delete(`/lists/${listId}`, {
      headers: { Authorization: `${token}` },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error eliminando la lista");
    throw new Error(
      error.response?.data?.message || "Error eliminando la lista"
    );
  }
};

// Buscar listas
export const searchLists = async (searchTerm: string) => {
  const token = localStorage.getItem("token");
  const organizationId = localStorage.getItem("organizationId");
  try {
    const res = await axiosInstance.get<IList[]>(`/lists/search`, {
      headers: { Authorization: `${token}` },
      params: { organizationId, searchTerm },
    });
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error buscando listas");
    throw new Error(error.response?.data?.message || "Error buscando listas");
  }
};
