import { configureStore } from "@reduxjs/toolkit";
import organizationReducer from "./slices/organizationSlice";
import alertReducer from "./slices/alertSlice";
import userReducer from "./slices/userSlice";
import notificationReducer from "./slices/notificationSlice";
import loadingreducer from "./slices/loadingSlice";
import searchReducer from "./slices/searchSlice";

export const store = configureStore({
  reducer: {
    organization: organizationReducer,
    alert: alertReducer,
    user: userReducer,
    notifications: notificationReducer,
    loading: loadingreducer,
    search: searchReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
