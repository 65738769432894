import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { Container, FormBody, FormHeader } from ".//FilterContactsStyles";
import Results from "./filter/Results";
import Properties from "./filter/Properties";
import Filter from "./filter/Filter";

interface FilterContactsProps {
  setShowFilterContact: React.Dispatch<React.SetStateAction<boolean>>;
}

interface StepI {
  results: number;
  properties: number;
  filter: number;
}

const step: StepI = {
  results: 0,
  properties: 1,
  filter: 2,
};

const FilterContacts: React.FC<FilterContactsProps> = ({
  setShowFilterContact,
}) => {
  const [activeStep, setActiveStep] = useState(step.results);
  const [label, setLabel] = useState<string>("");

  return (
    <Container>
      <FormHeader>
        <h2>Filtros</h2>
        <FaTimes
          onClick={() => {
            setShowFilterContact(false);
            setActiveStep(step.results);
          }}
        />
      </FormHeader>
      <FormBody>
        {activeStep === step.results && (
          <Results setActiveStep={setActiveStep} step={step} />
        )}
        {activeStep === step.properties && (
          <Properties
            setActiveStep={setActiveStep}
            step={step}
            setLabel={setLabel}
          />
        )}
        {activeStep === step.filter && (
          <Filter setActiveStep={setActiveStep} step={step} label={label} />
        )}
      </FormBody>
    </Container>
  );
};

export default FilterContacts;
