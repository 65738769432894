import styled from "styled-components";

export const CardBackground = styled.div`
  width: 280px;
  height: 100%;
  background-color: #f1f1f1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  padding: 20px;
  border: 1px solid #bbbbbb;
  margin: 10px 0px;
  &:hover {
    transform: scale(1.02);
  }

  h3 {
    color: #d1345b;
  }

  p {
    padding: 3px 0px;
  }
`;
