import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const EditContactContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  flex-direction: column;
  display: flex;
  z-index: 100;
`;

export const Form = styled.form`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  /* padding-bottom: 100px; */
  overflow: auto;
  max-height: 500px;

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      font-size: 1rem;
      color: #666;
    }

    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: ${theme.colors.azulOscuro};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  h2 {
    font-size: 1.5rem;
    color: white;
  }

  svg {
    cursor: pointer;
    font-size: 25px;
    path {
      color: ${theme.colors.rosado};

      &:hover {
        color: ${theme.colors.rosadoOscuro};
        transition: ease all 0.3s;
      }
    }
  }
`;

export const FormFooter = styled.div`
  /* position: absolute; */
  bottom: 0;
  background-color: ${theme.colors.grisClaro};
  right: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  button:first-child {
    background-color: ${theme.colors.rosado};
    color: white;
  }
  button:last-child {
    color: ${theme.colors.rosado};
  }

  button {
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.rosado};
    margin: 0px 15px;
    font-weight: bold;
    cursor: pointer;
    min-width: 100px;
  }
`;

export const DealsList = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;

  h3 {
    color: ${theme.colors.rosado};
    font-size: 14px;
    font-weight: 400;
  }
`;

export const DealsListTotal = styled.p`
  color: ${theme.colors.grisOscuro};
  font-size: 14px;
  font-weight: 500;
`;

export const DealsDate = styled.p`
  color: ${theme.colors.gris1};
  font-size: 14px;
`;
