import { IProject } from "../types/types";
import axiosInstance from "./axiosConfig";

const getProject = async (id: string) => {
  if (!id) {
    console.log("no hay id");
    return;
  }
  try {
    const res = await axiosInstance.get(`/projects/${id}`);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching project");
    throw new Error(error.response?.data?.message || "Error fetching project");
  }
};

const getProjects = async () => {
  try {
    const res = await axiosInstance.get("/projects");

    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching projects");
    throw new Error(error.response?.data?.message || "Error fetching projects");
  }
};

const createProject = async (project: IProject) => {
  try {
    const res = await axiosInstance.post("/projects", project);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating project");
    throw new Error(error.response?.data?.message || "Error creating project");
  }
};

const createTask = async (newTask: any) => {
  try {
    const res = await axiosInstance.post("/tasks", newTask);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error creating task");
    throw new Error(error.response?.data?.message || "Error creating task");
  }
};

const updateTask = async (task: any) => {
  try {
    const res = await axiosInstance.put(`/tasks/${task._id}`, task);
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error updating task");
    throw new Error(error.response?.data?.message || "Error updating task");
  }
};

const searchProjects = async (query: string) => {
  const response = await axiosInstance.get(`/projects/search?q=${query}`);

  return response.data;
};

const searchTasks = async (query: string) => {
  const response = await axiosInstance.get(`/tasks/search?q=${query}`);

  return response.data;
};

const deleteProject = async (id: string) => {
  try {
    await axiosInstance.delete(`/projects/${id}`);
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting project");
    throw new Error(error.response?.data?.message || "Error deleting project");
  }
};

const deleteTask = async (id: string) => {
  try {
    await axiosInstance.delete(`/tasks/${id}`);
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error deleting task");
    throw new Error(error.response?.data?.message || "Error deleting task");
  }
};

const getAllTasks = async () => {
  try {
    const res = await axiosInstance.get("/tasks");
    return res.data;
  } catch (error: any) {
    console.error(error.response?.data?.message || "Error fetching tasks");
    throw new Error(error.response?.data?.message || "Error fetching tasks");
  }
};

export const projectService = {
  getAllTasks,
  getProject,
  getProjects,
  createProject,
  createTask,
  updateTask,
  searchProjects,
  searchTasks,
  deleteProject,
  deleteTask,
};
