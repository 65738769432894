// src/components/Auth/RegisterComponent.tsx
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FaEnvelope,
  FaFacebook,
  FaGoogle,
  FaLock,
  FaMobile,
} from "react-icons/fa";

import { RootState, AppDispatch } from "../../../redux/store";
import {
  RegisterContainer,
  LeftColumn,
  RightColumn,
  InputField,
  InputContainer,
  FormGroup,
  Button,
  SocialMediaContainer,
  Footer,
} from "./RegisterStyles";
import { newUser } from "../../../services/authService";

interface User {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  mobile: string;
}

const RegisterComponent: React.FC = () => {
  const [error, setError] = useState(false);
  const [user, setUser] = useState<User>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
  });
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { loading } = useSelector((state: RootState) => state.user);

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user.firstName || !user.lastName || !user.email || !user.password) {
      setError(true);
      return;
    }

    try {
      const response = await newUser(user);

      navigate("/");
    } catch (err: any) {
      setError(true);
    }
  };

  return (
    <RegisterContainer>
      <LeftColumn>
        <img src="/images/logo/logo-rosado.png" alt="Logo" />
        <div>
          <h1>Bienvenido</h1>
          <p>
            Estás a un paso de organizar todo tu negocio en una sola aplicación.
          </p>
        </div>
        <div>
          <p>Términos y Condiciones</p>
          <p>Contacto</p>
        </div>
      </LeftColumn>
      <RightColumn>
        <h2>Crea tu cuenta</h2>
        <p>Crea una cuenta y comienza a utilizar los beneficios del CRM</p>

        <form onSubmit={submitHandler} style={{ margin: "40px 0px" }}>
          <FormGroup>
            <InputField
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Nombre"
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
            />
            <InputField
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Apellidos"
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
            />
          </FormGroup>
          <InputContainer>
            <FaMobile />
            <input
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Teléfono"
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <FaEnvelope />
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Correo"
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <FaLock />
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Contraseña"
              onChange={(e) =>
                setUser({ ...user, [e.target.name]: e.target.value })
              }
            />
          </InputContainer>

          <Button type="submit" disabled={loading}>
            {loading ? "Cargando..." : "Crear Cuenta"}
          </Button>
        </form>

        {error && (
          <p style={{ background: "red", color: "white", padding: "5px" }}>
            Verifica que todos los campos sean válidos
          </p>
        )}
        <SocialMediaContainer>
          <div>
            <FaGoogle />
          </div>
          <div>
            <FaFacebook />
          </div>
        </SocialMediaContainer>
        <Footer>
          <p>
            ¿Ya tienes una cuenta? <a href="/login"> Inicia Sesión</a>
          </p>
        </Footer>
      </RightColumn>
    </RegisterContainer>
  );
};

export default RegisterComponent;
