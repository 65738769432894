import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Table from "../../../components/common/Table/Table";
import { RootState } from "../../../redux/store";
import { IContact, IHeader } from "../../../types/types";
import styled from "styled-components";
import { FaUser } from "react-icons/fa";
import {
  getContacts,
  searchContact,
  deleteContact,
} from "../../../services/contactService";
import CreateContact from "./CreateContact";
import FilterContacts from "./FilterContacts";
import { useNavigate } from "react-router-dom";

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h1 {
    margin-left: 10px;
  }

  small {
    margin-left: 10px;
    color: grey;
  }
`;

const ContactPage: React.FC = () => {
  const [showCreateContact, setShowCreateContact] = useState(false);
  const [showFilterContact, setShowFilterContact] = useState(false);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [affected, setAffected] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const headers = useSelector(
    (state: RootState) => state.organization.contactProperties
  ) as IHeader[];

  // useCallback to memoize the fetch function
  const fetchContacts = useCallback(async () => {
    const contactos = await getContacts(limit, page);

    setContacts(contactos);

    return contactos;
  }, [limit, page]);

  useEffect(() => {
    fetchContacts();
  }, [affected, limit, page, fetchContacts]);

  const deleteHandler = async (ids: string[]) => {
    try {
      await Promise.all(ids.map((id: any) => deleteContact(id)));
      setAffected(!affected);
    } catch (err) {
      console.error("Error al eliminar los contactos:", err);
    }
  };

  const editHandler = (id: string) => {
    navigate(`/contactos/editar/${id}`);
  };

  return (
    <div>
      <Title>
        <FaUser size={32} />
        <div>
          <h1>Contactos</h1>
          <small>{contacts.length} Registros</small>
        </div>
      </Title>
      <Table<IContact>
        headers={headers}
        data={contacts}
        setData={setContacts}
        setShowCreateItem={setShowCreateContact}
        setShowFilterItem={setShowFilterContact}
        setLimit={setLimit}
        limit={limit}
        setPage={setPage}
        page={page}
        setAffected={setAffected}
        affected={affected}
        fetchData={fetchContacts}
        searchFunction={searchContact}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        tableType="contacts"
      />
      {showCreateContact && (
        <CreateContact
          setShowCreateContact={setShowCreateContact}
          setAffected={setAffected}
          affected={affected}
        />
      )}
      {showFilterContact && (
        <FilterContacts setShowFilterContact={setShowFilterContact} />
      )}
    </div>
  );
};

export default ContactPage;
