import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const ChatFooter = styled.div`
  background-color: ${theme.colors.gris3};
  padding: 20px;
  display: flex;
  flex-direction: column;

  button {
    background-color: ${theme.colors.rosado};
    border: none;
    padding: 10px 40px;
    color: white;
    border-radius: 5px;
    margin: 20px 0;

    &:hover {
      background-color: ${theme.colors.rosadoOscuro};
      cursor: pointer;
      transition: ease 0.3s;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${theme.colors.gris3};
    margin-bottom: 10px;
  }

  svg {
    font-size: 40px;
    cursor: pointer;
  }

  span {
    margin: 0 10px;
    cursor: pointer;
  }
`;

export const TextArea = styled.textarea`
  min-height: 100px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const ToolsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: "relative";
`;

export const Tools = styled.div`
  display: flex;
  gap: 20px;
  position: relative;

  p {
    cursor: pointer;
    &:hover {
      color: ${theme.colors.rosado};
    }
  }

  svg {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const SelectedFiles = styled.div`
  background-color: ${theme.colors.rosadoClaro};
  margin: 15px;
  max-width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 10px;

  svg {
    font-size: 20px;
  }
`;
