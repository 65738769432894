import styled from "styled-components";
import { theme } from "../../../styles/theme";

export const RegisterContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const LeftColumn = styled.div`
  height: 100vh;
  width: 60vw;
  background-color: ${theme.colors.azulOscuro};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 100px;

  p,
  h1 {
    color: white;
  }

  img {
    width: 200px;
  }
`;
export const RightColumn = styled.div`
  height: 100vh;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputField = styled.input`
  border: none;
  outline: none;
  background-color: ${theme.colors.gris3};
  width: 48%;

  padding: 15px 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  border: 1px solid ${theme.colors.gris3};
  border-radius: 5px;
  background-color: ${theme.colors.gris3};
  svg {
    margin: 0px 15px;
    background-color: transparent;
  }

  input {
    border: none;
    outline: none;
    background-color: ${theme.colors.gris3};
    width: 100%;
    padding: 15px 10px;
  }
`;

export const Button = styled.button`
  background-color: ${theme.colors.rosado};
  color: ${theme.colors.blanco};
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 15px 20px;
  font-size: 16px;
  width: 100%;
  margin-top: 20px;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.azulOscuro};
    transition: ease all 0.3s;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    background-color: ${theme.colors.grisClaro};
    padding: 10px;
    margin: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  p {
    color: ${theme.colors.grisOscuro};
    font-size: 14px;
    margin: 0px 10px;
    a {
      color: ${theme.colors.rosado};
      font-weight: 500;
      &:hover {
        cursor: pointer;
        font-weight: 500;
      }
    }
  }
`;
