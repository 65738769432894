import { FaCheck } from "react-icons/fa";
import {
  Estado,
  ProgressBar,
  ProgressBarContainer,
  Table,
} from "./TaskTableStyles";
import React, { useContext } from "react";
import { ProjectContext } from "./context/ProjectContext";
import NewTask from "./NewTask";
import { ITask } from "../../../types/types";

const TaskTable = () => {
  const {
    project,
    selectedProject,
    isVisibleCreateTask,
    setIsVisibleCreateTask,
  } = useContext(ProjectContext);

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <input type="checkbox" />
          </th>
          <th>Tarea</th>
          <th>Encargado</th>
          <th>Estado</th>
          <th>Fecha Vencimiento</th>
        </tr>
      </thead>
      <tbody>
        {project?.tasks?.map((task: ITask) => (
          <TaskRow key={task._id} task={task} />
        ))}
        <CreateTaskRow
          isVisible={isVisibleCreateTask}
          setIsVisible={setIsVisibleCreateTask}
        />
      </tbody>
    </Table>
  );
};

const TaskRow = React.memo(({ task }: any) => (
  <tr>
    <td>
      <input type="checkbox" />
    </td>
    <td>
      <p>{task.title}</p>
    </td>
    <td>
      <p>Alejandro Cabrejo</p>
    </td>
    <Estado $estado={task.status}>
      <p>{task.status}</p>
    </Estado>

    <td>
      <p>{new Date(task.dueDate).toLocaleDateString()}</p>
    </td>
  </tr>
));

const CreateTaskRow = React.memo(({ isVisible, setIsVisible }: any) => (
  <>
    {!isVisible ? (
      <tr>
        <td
          colSpan={7}
          style={{ color: "#007bff", cursor: "pointer" }}
          onClick={() => setIsVisible(true)}
        >
          + Crear Tarea
        </td>
      </tr>
    ) : (
      <NewTask />
    )}
  </>
));

export default TaskTable;
