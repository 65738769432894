import React, { createContext, ReactNode, useContext } from "react";
import { Chat, Message } from "../../../../types/types";
import { useWhatsapp } from "../hooks/useWhatsapp";

export interface IChatContextProps {
  chatList: Chat[];
  selectedChat: Chat | null;
  messages: Message[];
  newMessage: string;
  setNewMessage: (message: string) => void;
  setSelectedChat: (chat: Chat | null) => void;
  handleSendMessage: (
    messageType: "text" | "image" | "document" | "video" | "audio",
    mediaUrl?: string
  ) => void;
  loadChatList: () => void;
  handleSearchChat: (query: string) => void;
  setAffected: React.Dispatch<React.SetStateAction<boolean>>;
  affected: boolean;
  chatBodyRef: React.RefObject<HTMLDivElement>;
  loadMoreMessages: () => void;
  setChatList: React.Dispatch<React.SetStateAction<Chat[]>>;
  markMessagesAsRead: (chat: Chat) => Promise<void>;
  updateChatListWithNewMessage: (newMessage: Message) => void;
  handleChatClick: (chat: Chat) => void;
  handleDeleteChat: () => void;
}

export const ChatContext = createContext<IChatContextProps | undefined>(
  undefined
);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const whatsapp = useWhatsapp();

  return (
    <ChatContext.Provider value={whatsapp}>{children}</ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
